import type { OrgUnitFormValues } from 'orgUnit/OrgUnitForm';
import {
  resolveSetRemoveIdInput as resolveSelectOrgUnitInputSetRemoveIdInput,
  resolveInitialValue as resolveSelectOrgUnitInputInitialValue,
} from 'orgUnit/SelectOrgUnitInput';
import type { Theme } from 'theme';
import type { Org, User } from 'types.graphql.generated';

import type {
  EditOrgUnitMutationVariables,
  EditOrgUnitOrgUnitFragment,
} from './EditOrgUnitModal.graphql.generated';

export const resolveInitialValues = (
  activeOrg: Pick<Org, 'displayName'>,
  orgUnit: EditOrgUnitOrgUnitFragment,
  theme: Theme,
): OrgUnitFormValues => ({
  name: orgUnit.name || '',
  nameAbbreviated: orgUnit.nameAbbreviated || '',
  description: orgUnit.description || '',
  lead: orgUnit.orgUnitLead.email,
  admins: orgUnit.orgUnitAdmins.map((admin) => admin.email),
  parent: resolveSelectOrgUnitInputInitialValue(
    activeOrg,
    orgUnit.parentOrgUnit,
  ),
  backgroundColor:
    orgUnit.backgroundColorAsHex || theme.legacyColor.colorAshGray,
});

export const resolveEditOrgUnitInput = (params: {
  allUsers: Pick<User, 'id' | 'email'>[];
  initialValues: OrgUnitFormValues;
  orgUnit: EditOrgUnitOrgUnitFragment;
  values: OrgUnitFormValues;
}): EditOrgUnitMutationVariables['input'] => {
  const { orgUnit, values, initialValues, allUsers } = params;

  return {
    idToUpdate: orgUnit.id,
    name: values.name,
    nameAbbreviated: values.nameAbbreviated,
    description: values.description,
    orgUnitLead: { emailToSet: values.lead },
    orgUnitAdmins: [
      ...values.admins.map((emailToSet) => ({ emailToSet })),
      ...initialValues.admins
        .filter((initialEmail) => !values.admins.includes(initialEmail))
        .map((emailToRemove) => ({
          idToRemove:
            allUsers.find((user) => user.email === emailToRemove)?.id || '',
        })),
    ],
    parentOrgUnit: resolveSelectOrgUnitInputSetRemoveIdInput({
      previousOrgUnit: orgUnit?.parentOrgUnit,
      option: values.parent,
    }),
    backgroundColorAsHex: values.backgroundColor,
  };
};
