import type { ReactNode } from 'react';
import type { TFunction } from 'react-i18next';

import Scrollspy from 'shared/components/Scrollspy';

import ThemesAndComments from './InsightReportSummaryThemesAndComments';
import StrategyMap from './InsightReportSummaryStrategyMap';
import Objectives from './InsightReportSummaryObjectives';
import StrategicInitiatives from './InsightReportSummaryStrategicInitiatives';

type InsightReportSummarySection = {
  heading: string;
  id: string;
  render: (
    props: Pick<InsightReportSummarySection, 'heading' | 'id'>,
  ) => ReactNode;
};

export const createSections = (
  t: TFunction,
  year: number,
): InsightReportSummarySection[] => [
  {
    id: 'executiveSummary',
    heading: t('report.insightReportSummary.executiveSummary.heading'),
    render: ({ id, heading }) => (
      <Scrollspy.Waypoint id={id}>
        <ThemesAndComments heading={heading} />
      </Scrollspy.Waypoint>
    ),
  },
  {
    id: 'strategyMap',
    heading: t('report.insightReportSummary.strategyMap.heading'),
    render: ({ id }) => (
      <Scrollspy.Waypoint id={id}>
        <StrategyMap />
      </Scrollspy.Waypoint>
    ),
  },
  {
    id: 'strategicInitiatives',
    heading: t('report.insightReportSummary.strategicInitiatives.heading', {
      year,
    }),
    render: ({ id }) => (
      <Scrollspy.Waypoint id={id}>
        <StrategicInitiatives year={year} />
      </Scrollspy.Waypoint>
    ),
  },
  {
    id: 'objectives',
    heading: t('report.insightReportSummary.objectives.heading'),
    render: ({ id, heading }) => (
      <Scrollspy.Waypoint id={id}>
        <Objectives heading={heading} />
      </Scrollspy.Waypoint>
    ),
  },
  {
    id: 'completedObjectives',
    heading: t('report.insightReportSummary.completedObjectives.heading'),
    render: ({ id, heading }) => (
      <Scrollspy.Waypoint id={id}>
        <Objectives
          heading={heading}
          showOnlyCompleted={true}
          defaultCollapsed={true}
        />
      </Scrollspy.Waypoint>
    ),
  },
  {
    id: 'summary',
    heading: t('report.insightReportSummary.summary.heading'),
    render: ({ id, heading }) => (
      <Scrollspy.Waypoint id={id}>
        <ThemesAndComments
          heading={heading}
          statusCommentsProps={{
            headings: {
              ACTION_COMMENT: t(
                'report.insightReportSummary.summary.comments.heading',
              ),
            },
            types: ['ACTION_COMMENT'],
          }}
        />
      </Scrollspy.Waypoint>
    ),
  },
];
