import { Trans, useTranslation } from 'react-i18next';

import type { MetricScoreFormProps } from 'metric/MetricScoreForm';
import MetricScoreForm from 'metric/MetricScoreForm';
import Heading from 'shared/components/Heading';
import Space from 'shared/components/Space';
import Text from 'shared/components/Text';
import type { Metric } from 'types.graphql.generated';
import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import InfoCard from 'shared/components/InfoCard';
import useHandleError from 'shared/errors/useHandleError';
import PreviousInsightsSidecard from 'status/PreviousInsightsSidecard/PreviousInsightsSidecard';

import ScoreSummary from '../MetricStatusWizardScoreSummary';
import styles from './MetricStatusWizardScore.module.scss';
import { useMetricStatusWizardScoreQuery } from './MetricStatusWizardScore.graphql.generated';

type MetricStatusWizardScoreProps = Pick<
  MetricScoreFormProps,
  | 'id'
  | 'initialValues'
  | 'onSubmit'
  | 'onChange'
  | 'onError'
  | 'validateOnMount'
> & {
  metric: Pick<
    Metric,
    'id' | 'targetValue' | 'name' | 'description' | 'unitOfMeasure'
  >;
};

const MetricStatusWizardScore = ({
  metric,
  ...restProps
}: MetricStatusWizardScoreProps) => {
  const { t } = useTranslation();

  const handleApolloError = useHandleError();

  const { data } = useMetricStatusWizardScoreQuery({
    variables: {
      metricId: metric.id,
    },
    onError: handleApolloError,
  });

  const renderAboveForm = () => (
    <>
      <Heading level={3}>
        {t('metric.metricStatusWizard.steps.score.heading')}
      </Heading>

      <Space direction={'vertical'}>
        <Text>
          <Trans
            i18nKey={'metric.metricStatusWizard.steps.score.metricName'}
            values={{ metricName: metric.name }}
          >
            <Text variant={'emphasis'} />
          </Trans>
        </Text>
        {metric.description && metric.description !== '' && (
          <Text>
            <Trans
              i18nKey={
                'metric.metricStatusWizard.steps.score.metricDescription'
              }
              values={{ description: metric.description }}
            >
              <Text variant={'emphasis'} />
            </Trans>
          </Text>
        )}
        <ScoreSummary
          metric={{
            targetValue: metric.targetValue,
            currentMetricStatus: data?.metric.currentMetricStatus,
            unitOfMeasure: metric.unitOfMeasure,
          }}
        />
      </Space>
    </>
  );

  const renderForm = () => (
    <>
      {renderAboveForm()}
      <MetricScoreForm {...restProps} className={styles.form} />
    </>
  );

  const renderCardInfo = () => (
    <InfoCard
      title={t('metric.metricStatusWizard.steps.score.infoCard.heading')}
      contentKey={'metric.metricStatusWizard.steps.score.infoCard.content'}
    />
  );

  return (
    <FormWithInfoCardLayout
      renderForm={renderForm}
      renderInfoCard={renderCardInfo}
      renderAfterInfoCard={<PreviousInsightsSidecard />}
    />
  );
};

export default MetricStatusWizardScore;
