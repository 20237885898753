import { createContext } from 'react';

export type ActingOrgKeyContextValue = {
  actingOrgKey?: string;
  clearActingOrgKey: () => void;
  setActingOrgKey: (actingOrgKey: string) => void;
};

export const ActingOrgKeyContext = createContext<
  ActingOrgKeyContextValue | undefined
>(undefined);
