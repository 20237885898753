import type { PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useHandleError from 'shared/errors/useHandleError';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import { useErrorPage } from 'shared/errors/ErrorPage/useErrorPage';

import type { MetricOverviewContextValue } from './MetricOverviewProvider.context';
import { MetricOverviewContext } from './MetricOverviewProvider.context';
import { useMetricOverviewQuery } from './MetricOverviewProvider.graphql.generated';

type MetricOverviewProviderProps = PropsWithChildren<object>;

const MetricOverviewProvider = ({ children }: MetricOverviewProviderProps) => {
  const { metricId } = useParams() as { metricId: Maybe<string> };

  const onError = useHandleError();

  const { data, loading: isMetricLoading } = useMetricOverviewQuery({
    variables: {
      metricId: metricId!,
    },
    skip: !metricId,
    onError,
  });

  const { onErrorCode } = useErrorPage();

  useEffect(() => {
    if (data && !canPerformStrategyElementAction(data.metric, 'READ')) {
      onErrorCode('ACCESS_DENIED');
    }
  }, [data, onErrorCode]);

  const contextValue = useMemo<MetricOverviewContextValue>(
    () => ({ metric: data?.metric, isMetricLoading }),

    [data, isMetricLoading],
  );

  return (
    <MetricOverviewContext.Provider value={contextValue}>
      {children}
    </MetricOverviewContext.Provider>
  );
};

export default MetricOverviewProvider;
