import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useToggle } from 'react-use';

import TableView from 'shared/components/TableView';
import { date } from 'shared/services/date.service';
import { useExportReportMutation } from 'report/report.graphql.generated';
import ExportReportModal from 'report/InsightReportSummary/InsightReportSummaryExportReportModal/InsightReportSummaryExportReportModal';
import useHandleError from 'shared/errors/useHandleError';

import {
  ReportStageCell,
  ReportOwnerCell,
  ReportNameCell,
} from './ReportsTable.utils';
import type { ReportsTableRow } from './ReportsTable.type';
import ReportsTableContextMenu from './ReportsTableContextMenu';
import type { ReportsTableContextMenuItem } from './ReportsTableContextMenu';

type ReportsTableProps = {
  onClickReportName: (report: ReportsTableRow) => void;
  onDeleteReport: (reportId: ReportsTableRow['id']) => void;
  onEditReport: (reportId: ReportsTableRow['id'], step: Maybe<string>) => void;
  onViewReport: (reportId: ReportsTableRow['id']) => void;
  reports: ReportsTableRow[];
};

const ReportsTable = ({
  reports,
  onEditReport,
  onDeleteReport,
  onViewReport,
  onClickReportName,
}: ReportsTableProps) => {
  const { t } = useTranslation();

  const handleApolloError = useHandleError();

  const [exportReport, { loading: exportLoading, error: exportError }] =
    useExportReportMutation({ onError: handleApolloError });

  const [isExportReportModalOpened, toggleIsExportReportModalOpened] =
    useToggle(false);

  const onMenuAction = useCallback(
    (id: ReportsTableContextMenuItem['id'], row: ReportsTableRow) => {
      let step = null;
      if (row.reportStage === 'STATUS_REQUESTED') {
        step = 'statusUpdate';
      } else if (row.reportStage === 'PUBLISHED') {
        step = 'publish';
      }
      switch (id) {
        case 'open': {
          onViewReport(row.id);
          break;
        }
        case 'edit': {
          onEditReport(row.id, step);
          break;
        }
        case 'delete': {
          onDeleteReport(row.id);
          break;
        }
        case 'export': {
          toggleIsExportReportModalOpened(true);
          exportReport({
            variables: {
              id: row.id,
            },
          });
          break;
        }
      }
    },
    [
      onEditReport,
      onViewReport,
      onDeleteReport,
      exportReport,
      toggleIsExportReportModalOpened,
    ],
  );

  return (
    <>
      <TableView<ReportsTableRow>
        hasBorder={false}
        columns={[
          {
            Header: t('report.reportsTable.name'),
            accessor: 'name',
            width: '45%',
            Cell: (table) => (
              <ReportNameCell {...table} onClick={onClickReportName} />
            ),
          },
          {
            Header: t('report.reportsTable.createDateTime'),
            Cell: (table) => (
              <>
                {table.value
                  ? date.formatShort(table.value.createDateTime)
                  : null}
              </>
            ),
            accessor: 'auditRecord',
          },
          {
            Header: t('report.reportsTable.publishedTime'),
            Cell: (table) => (
              <>
                {table.value ? date.formatShort(new Date(table.value)) : null}
              </>
            ),
            accessor: 'publishedTime',
          },
          {
            Header: t('report.reportsTable.owner'),
            Cell: ReportOwnerCell,
            accessor: 'owner',
          },
          {
            Header: t('report.reportsTable.reportStage'),
            Cell: ReportStageCell,
            accessor: 'reportStage',
          },
          {
            Cell: (table) => (
              <ReportsTableContextMenu
                onAction={(id) => {
                  onMenuAction(id, table.row.original);
                }}
                row={table.row.original}
              />
            ),
            accessor: 'id',
            disableSortBy: true,
            width: 1,
          },
        ]}
        data={reports}
        sortable={true}
      />
      <ExportReportModal
        isError={!!exportError}
        isLoading={exportLoading}
        isOpen={isExportReportModalOpened}
        onClose={toggleIsExportReportModalOpened}
      />
    </>
  );
};

export default ReportsTable;
