import ReactDOM from 'react-dom/client';

import { initializeDatadog } from 'shared/config/datadog.config';

import App from './App';

initializeDatadog();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<App />);
