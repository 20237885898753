import { useTranslation } from 'react-i18next';
import { isPast } from 'date-fns';
import type { PropsWithChildren, ReactNode } from 'react';
import styled from '@emotion/styled';

import { date } from 'shared/services/date.service';
import Emoji from 'shared/components/Emoji';
import { transientOptions } from 'shared/utils/emotion.utils';

import type { FollowUpActivityItem } from './ContributionMyFollowUpsViewMore.type';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  &:hover {
    background-color: ${(props) =>
      props.onClick ? props.theme.color.hoverLight : props.theme.color.white};
  }
`;

const Left = styled.div`
  align-items: center;
  display: flex;
`;

const Right = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: right;
  margin-left: 12px;
  gap: 16px;
`;

const Icon = styled.div`
  flex-shrink: 0;
  align-items: center;
  background-color: ${(props) => props.theme.legacyColor.colorLightOragne};
  border-radius: 12px;
  display: flex;
  font-size: 24px;
  height: 40px;
  justify-content: center;
  width: 40px;
`;

const Type = styled.div`
  color: ${(props) => props.theme.color.secondaryVariant};
  font-size: 0.857rem;
  font-weight: 700;
  margin-left: 8px;
  text-transform: uppercase;
  white-space: nowrap;
`;

const Description = styled.div`
  font-size: 1rem;
  margin-left: 12px;
`;

const DueDate = styled('div', transientOptions)<{ $date: Date }>`
  color: ${(props) =>
    isPast(props.$date)
      ? props.theme.color.error
      : props.theme.color.typoSecondary};
  font-size: 0.857rem;
`;

const ChildrenContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-grow: 1;
  max-width: 210px;
  justify-content: flex-end;
`;

type Props = PropsWithChildren<{
  description: string | ReactNode;
  icon: string;
  item: FollowUpActivityItem;
  onClick?: () => void;
  typeLabel: string;
}>;

const TimelineItemContentSummary = ({
  icon,
  typeLabel,
  description,
  item,
  children,
  onClick,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Container onClick={onClick}>
      <Left>
        <Icon>
          <Emoji icon={icon} />
        </Icon>
        <Type>{typeLabel}</Type>
        <Description>{description}</Description>
      </Left>
      <Right>
        {!item.isDone && item.deadline && (
          <DueDate $date={item.deadline}>
            {t('followUps.timeline.dueDate', {
              dueDate: date.formatShort(item.deadline),
            })}
          </DueDate>
        )}
        <ChildrenContainer>{children}</ChildrenContainer>
      </Right>
    </Container>
  );
};

export default TimelineItemContentSummary;
