import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserLimitedProfileFragmentDoc } from '../../../user/userProfile.graphql.generated';
import { OrgUnitForTeamImageFragmentDoc } from '../../../orgUnit/OrgUnit.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ObjectiveDetailsQueryVariables = Types.Exact<{
  objectiveId: Types.Scalars['ID'];
}>;


export type ObjectiveDetailsQuery = { __typename: 'Query', objective: { __typename: 'Objective', id: string, name?: string | undefined, description?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, parentObjective?: { __typename: 'Objective', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions> } | undefined, attributes: Array<{ __typename: 'StrategyItemAttribute', id: string, name?: string | undefined, title?: string | undefined, description?: string | undefined, orderNumber?: number | undefined }>, timeLine: { __typename: 'TimeLine', completeDateTime?: any | undefined, endDate?: any | undefined, startDate?: any | undefined }, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, teamMembers: Array<{ __typename: 'TeamMember', id: string, role?: string | undefined, user?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined }>, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, orgUnitLinks: Array<{ __typename: 'OrgUnit', id: string, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined }> } };

export type ObjectiveDetailsObjectiveFragment = { __typename: 'Objective', id: string, name?: string | undefined, description?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, parentObjective?: { __typename: 'Objective', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions> } | undefined, attributes: Array<{ __typename: 'StrategyItemAttribute', id: string, name?: string | undefined, title?: string | undefined, description?: string | undefined, orderNumber?: number | undefined }>, timeLine: { __typename: 'TimeLine', completeDateTime?: any | undefined, endDate?: any | undefined, startDate?: any | undefined }, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, teamMembers: Array<{ __typename: 'TeamMember', id: string, role?: string | undefined, user?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined }>, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, orgUnitLinks: Array<{ __typename: 'OrgUnit', id: string, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined }> };

export const ObjectiveDetailsObjectiveFragmentDoc = gql`
    fragment objectiveDetailsObjective on Objective {
  id
  name
  description
  currentUserAuthorizedActions
  parentObjective {
    id
    name
    currentUserAuthorizedActions
  }
  attributes {
    id
    name
    title
    description
    orderNumber
  }
  timeLine {
    completeDateTime
    endDate
    startDate
  }
  owner {
    ...userLimitedProfile
  }
  teamMembers {
    id
    role
    user {
      ...userLimitedProfile
    }
  }
  orgUnit {
    id
  }
  orgUnitLinks {
    id
    currentUserAuthorizedActions
    ...orgUnitForTeamImage
  }
}
    ${UserLimitedProfileFragmentDoc}
${OrgUnitForTeamImageFragmentDoc}`;
export const ObjectiveDetailsDocument = gql`
    query ObjectiveDetails($objectiveId: ID!) {
  objective(id: $objectiveId) {
    ...objectiveDetailsObjective
  }
}
    ${ObjectiveDetailsObjectiveFragmentDoc}`;

/**
 * __useObjectiveDetailsQuery__
 *
 * To run a query within a React component, call `useObjectiveDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectiveDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectiveDetailsQuery({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *   },
 * });
 */
export function useObjectiveDetailsQuery(baseOptions: Apollo.QueryHookOptions<ObjectiveDetailsQuery, ObjectiveDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectiveDetailsQuery, ObjectiveDetailsQueryVariables>(ObjectiveDetailsDocument, options);
      }
export function useObjectiveDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectiveDetailsQuery, ObjectiveDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectiveDetailsQuery, ObjectiveDetailsQueryVariables>(ObjectiveDetailsDocument, options);
        }
export type ObjectiveDetailsQueryHookResult = ReturnType<typeof useObjectiveDetailsQuery>;
export type ObjectiveDetailsLazyQueryHookResult = ReturnType<typeof useObjectiveDetailsLazyQuery>;
export type ObjectiveDetailsQueryResult = Apollo.QueryResult<ObjectiveDetailsQuery, ObjectiveDetailsQueryVariables>;