import type { LegendOptions, ChartOptions } from 'chart.js';
import type { DeepPartial } from 'chart.js/dist/types/utils';
import {
  differenceInDays,
  differenceInMonths,
  differenceInQuarters,
  differenceInWeeks,
  differenceInYears,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  isValid,
  isWithinInterval,
  max,
  min,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { sortBy } from 'lodash';
import type { TFunction } from 'i18next';

import styles from 'metric/MetricInsightsChart/MetricInsightsChart.module.scss';

import type {
  MetricInsightsChartMetric,
  ChartData,
  MetricInsightsChartTimeUnit,
} from './MetricInsightsChart.type';

export const getLegendOptions = (
  datasets: ChartData['datasets'],
): DeepPartial<LegendOptions<'bar' | 'line'>> => ({
  fullSize: true,
  position: 'bottom',
  align: 'start',
  labels: {
    boxWidth: 10,
    boxHeight: 10,
    generateLabels: () => [
      {
        datasetIndex: 0,
        text: datasets.previous.label,
        fillStyle: datasets.previous.color,
        borderRadius: 0,
        lineWidth: 0,
      },
      {
        datasetIndex: 1,
        text: datasets.current.label,
        fillStyle: datasets.current.color,
        borderRadius: 0,
        lineWidth: 0,
      },
      {
        datasetIndex: 2,
        text: datasets.target.label,
        fillStyle: datasets.target.color,
        borderRadius: 0,
        lineWidth: 0,
      },
      {
        datasetIndex: 3,
        text: datasets.forecast.label,
        strokeStyle: datasets.forecast.color,
        fillStyle: 'transparent',
        lineWidth: 2,
      },
    ],
  },
});

export const getChartOptions = (
  datasets: ChartData['datasets'],
): ChartOptions<'bar' | 'line'> => ({
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {
        font: {
          size: 14,
        },
      },
    },
    x: {
      stacked: true,
      offset: true,
      ticks: {
        font: {
          size: 14,
        },
      },
    },
  },
  plugins: {
    legend: getLegendOptions(datasets),
  },
});

export const getChartUnit = (metric: MetricInsightsChartMetric) => {
  const { startDate, endDate } = getDateBoundaries(metric);

  const threshold = 3;

  if (startDate && endDate) {
    if (differenceInYears(endDate, startDate) >= threshold) {
      return 'year';
    } else if (differenceInQuarters(endDate, startDate) >= threshold) {
      return 'quarter';
    } else if (differenceInMonths(endDate, startDate) >= threshold) {
      return 'month';
    } else if (differenceInWeeks(endDate, startDate) >= threshold) {
      return 'week';
    } else if (differenceInDays(endDate, startDate) >= threshold) {
      return 'day';
    }
  }

  return 'day';
};

export const getDateBoundaries = (metric: MetricInsightsChartMetric) => {
  const sortedStatuses = sortBy(
    metric.metricStatusListAll,
    (status) => status.statusDateTime,
  );

  const metricStartDate =
    metric.timeLine.startDate || metric.auditRecord.createDateTime;
  const startDate = min(
    [sortedStatuses[0]?.statusDateTime, metricStartDate].filter(Boolean),
  );

  const lastStatusDate = sortedStatuses.at(-1)?.statusDateTime;
  const endDate = max(
    [metric.timeLine.endDate, lastStatusDate].filter(Boolean),
  );

  return {
    startDate,
    endDate,
  };
};

export const getChartData = (
  t: TFunction,
  metric: MetricInsightsChartMetric,
  unit: MetricInsightsChartTimeUnit,
): ChartData => {
  const sortedStatuses = sortBy(
    metric.metricStatusListAll,
    (status) => status.statusDateTime,
  );

  const { startDate, endDate } = getDateBoundaries(metric);

  const dateRange = isValid(endDate)
    ? getDateRange(startDate, endDate, unit)
    : [];

  const metricStartDate =
    metric.timeLine.startDate || metric.auditRecord.createDateTime;

  const lastStatusDate = sortedStatuses.at(-1)?.statusDateTime;
  const metricStartDateLabel = formatDateLabel(metricStartDate, unit);
  const endDateOrLastStatusDate = metric.timeLine.endDate || lastStatusDate;

  const dateRangeStatuses = dateRange
    .map((date) => {
      const sortedPeriodStatuses = sortedStatuses.filter((status) =>
        isWithinInterval(status.statusDateTime, {
          start: date,
          end: endOfPeriod(date, unit),
        }),
      );

      const lastStatusInPeriod = sortedPeriodStatuses.at(-1);
      const statusWithForecastValue = sortedPeriodStatuses
        .reverse()
        .find((status) => hasValue(status.forecastValue));

      const dateLabel = formatDateLabel(date, unit);

      if (lastStatusInPeriod) {
        return {
          dateLabel,
          currentValue: lastStatusInPeriod.statusValue,
          forecastValue: statusWithForecastValue?.forecastValue,
        };
      } else if (
        dateLabel === metricStartDateLabel &&
        hasValue(metric.startValue)
      ) {
        return {
          dateLabel,
          currentValue: metric.startValue,
        };
      }
    })
    .filter(Boolean);

  const previousData: Record<string, number> = dateRangeStatuses
    .slice(0, dateRangeStatuses.length - 1)
    .reduce(
      (result, { dateLabel, currentValue }) => ({
        ...result,
        [dateLabel]: currentValue,
      }),
      {},
    );
  const previousLabel = t('metric.insights.chart.previous.label', {
    value:
      Object.values(previousData).length > 0
        ? Object.values(previousData).at(-1)
        : t('none'),
  });

  const lastDateRangeStatus = dateRangeStatuses.at(-1);
  const currentData =
    lastDateRangeStatus && hasValue(lastDateRangeStatus.currentValue)
      ? {
          [lastDateRangeStatus.dateLabel]: lastDateRangeStatus.currentValue,
        }
      : {};
  const currentLabel = t('metric.insights.chart.current.label', {
    value: lastDateRangeStatus ? lastDateRangeStatus.currentValue : t('none'),
  });

  const targetLabel = t('metric.insights.chart.target.label', {
    value: hasValue(metric.targetValue) ? metric.targetValue : t('none'),
  });
  const targetData =
    hasValue(metric.targetValue) && endDateOrLastStatusDate
      ? { [formatDateLabel(endDateOrLastStatusDate, unit)]: metric.targetValue }
      : {};

  const forecastData = dateRangeStatuses.reduce(
    (result, { dateLabel, forecastValue }) =>
      hasValue(forecastValue)
        ? { ...result, [dateLabel]: forecastValue }
        : result,
    {},
  );
  const forecastLabel = t('metric.insights.chart.forecast.label', {
    value:
      Object.values(forecastData).length > 0
        ? Object.values(forecastData).at(-1)
        : t('none'),
  });

  const labels = dateRange.map((date) => formatDateLabel(date, unit));

  return {
    datasets: {
      previous: {
        label: previousLabel,
        data: previousData,
        color: styles.previousDatasetColor,
      },
      current: {
        label: currentLabel,
        data: currentData,
        color: styles.currentDatasetColor,
      },
      target: {
        label: targetLabel,
        data: targetData,
        color: styles.targetDatasetColor,
      },
      forecast: {
        label: forecastLabel,
        data: forecastData,
        color: styles.forecastDatasetColor,
      },
    },
    labels,
  };
};

const hasValue = (value?: Maybe<number>): value is number => {
  return value !== undefined && value !== null;
};

const getDateRange = (
  start: Date,
  end: Date,
  period: MetricInsightsChartTimeUnit,
) => {
  switch (period) {
    case 'day':
      return eachDayOfInterval({ start, end });
    case 'week':
      return eachWeekOfInterval({ start, end });
    case 'month':
      return eachMonthOfInterval({ start, end });
    case 'quarter':
      return eachQuarterOfInterval({ start, end });
    case 'year':
      return eachYearOfInterval({ start, end });
  }
};

const formatDateLabel = (date: Date, unit: MetricInsightsChartTimeUnit) => {
  const periodStartDate = startOfPeriod(date, unit);

  switch (unit) {
    case 'day':
      return format(periodStartDate, 'P');
    case 'week':
      return format(periodStartDate, 'ww yyyy');
    case 'month':
      return format(periodStartDate, 'MMM yyyy');
    case 'quarter':
      return format(periodStartDate, 'QQQ yyyy');
    case 'year':
      return format(periodStartDate, 'yyyy');
  }
};

const startOfPeriod = (date: Date, period: MetricInsightsChartTimeUnit) => {
  switch (period) {
    case 'day':
      return startOfDay(date);
    case 'week':
      return startOfWeek(date);
    case 'month':
      return startOfMonth(date);
    case 'quarter':
      return startOfQuarter(date);
    case 'year':
      return startOfYear(date);
  }
};

const endOfPeriod = (date: Date, period: MetricInsightsChartTimeUnit) => {
  switch (period) {
    case 'day':
      return endOfDay(date);
    case 'week':
      return endOfWeek(date);
    case 'month':
      return endOfMonth(date);
    case 'quarter':
      return endOfQuarter(date);
    case 'year':
      return endOfYear(date);
  }
};
