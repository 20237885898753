import { useNavigate } from 'react-router-dom';

import GlobalSpinner from 'shared/spinner/GlobalSpinner';
import CreateInitiativeStatusDialog from 'initiative/CreateInitiativeStatusDialog';
import { useInitiativeOverview } from 'initiative/InitiativeOverview/InitiativeOverviewProvider';
import { TeamAdapter, useTeamAdapter } from 'team/TeamAdapter';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';

const CreateInitiativeStatusPage = () => {
  const navigate = useNavigate();
  const { initiative } = useInitiativeOverview();
  const { teamAdapter } = useTeamAdapter();

  const teamSlug = initiative?.orgUnit
    ? TeamAdapter.fromOrgUnit(initiative.orgUnit, teamAdapter.orgKey).toParam()
    : teamAdapter.toParam();

  if (!initiative) return <GlobalSpinner />;

  return (
    <CreateInitiativeStatusDialog
      isOpen={true}
      initiativeId={initiative.id}
      onClose={() =>
        navigate(
          objectiveRoutesPaths.initiatives.initiative.insights.root({
            params: {
              teamSlug,
              initiativeId: initiative.id,
              objectiveId: initiative.objective?.id || '',
            },
          }),
        )
      }
    />
  );
};

export default CreateInitiativeStatusPage;
