import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { SelectOrgUnitInputFieldOption } from 'orgUnit/SelectOrgUnitInput';
import SelectOrgUnitInput, {
  SelectOrgUnitInputProvider,
} from 'orgUnit/SelectOrgUnitInput';
import Dialog from 'shared/components/Dialog';
import { useObjectiveDetails } from 'objective/ObjectiveDetails/ObjectiveDetailsProvider';
import { useToasts } from 'shared/toast/useToasts';
import useHandleError from 'shared/errors/useHandleError';

import { useLinkObjectiveToOrgUnitMutation } from './LinkedOrgUnitsSection.graphql.generated';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const AddCollaboratingOrgUnitDialog = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { objective } = useObjectiveDetails();

  const [orgUnit, setOrgUnit] = useState<SelectOrgUnitInputFieldOption | null>(
    null,
  );

  const onError = useHandleError();

  const [linkObjectiveToOrgUnit, { loading }] =
    useLinkObjectiveToOrgUnitMutation({
      variables: {
        objectiveId: objective?.id || '',
        orgUnitId: orgUnit?.value?.id || '',
      },
      onCompleted: () => {
        addToast({
          variant: 'success',
          children: t(
            'objective.objectiveLayout.sider.linkedOrgUnits.addDialog.success',
          ),
        });
        onClose();
      },
      onError,
    });

  const excludeOrgUnitIds = [
    objective?.orgUnit?.id,
    ...(objective?.orgUnitLinks.map((orgUnit) => orgUnit.id) || []),
  ];

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      size={'small'}
      confirmLabel={t('confirm')}
      cancelLabel={t('cancel')}
      headerTitle={t(
        'objective.objectiveLayout.sider.linkedOrgUnits.addDialog.heading',
      )}
      headerSubtitle={t(
        'objective.objectiveLayout.sider.linkedOrgUnits.addDialog.subtitle',
      )}
      onConfirm={linkObjectiveToOrgUnit}
      isConfirmDisabled={!orgUnit || loading}
    >
      <SelectOrgUnitInputProvider
        excludeOrgUnitIds={excludeOrgUnitIds}
        filterOrgUnitsByAction={'WRITE'}
        excludeOrg={true}
      >
        <SelectOrgUnitInput onChange={setOrgUnit} />
      </SelectOrgUnitInputProvider>
    </Dialog>
  );
};

export default AddCollaboratingOrgUnitDialog;
