import styled from '@emotion/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'shared/components/Button';
import { useTeamAdapter } from 'team/TeamAdapter';
import TextArea from 'shared/components/TextArea';
import useHandleError from 'shared/errors/useHandleError';

import { useSaveCampaignUserResponseMutation } from './SaveCampaignResponse.graphql.generated';
import { useChat } from './ChatProvider';
import MessageBubble from './MessageBubble';
import { useCampaignResponsesQuery } from './CampaignResponses.graphql.generated';
import { useQuestions } from './QuestionsProvider.context';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;

  button {
    align-self: flex-start;
  }
`;

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  flex-grow: 1;
`;

type Props = {
  campaignId: string;
};

const Comment = ({ campaignId }: Props) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const { teamAdapter } = useTeamAdapter();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { continueChat } = useChat();
  const { isReadOnly } = useQuestions();

  const handleApolloError = useHandleError();

  const { data } = useCampaignResponsesQuery({
    variables: { campaignId },
    onError: handleApolloError,
  });

  const [saveResponse] = useSaveCampaignUserResponseMutation({
    onError: handleApolloError,
  });

  const comments =
    data?.campaign.currentUserResponse?.comments.map(
      (comment) => comment.text,
    ) || [];

  const handleSubmit = () => {
    if (comment.trim() !== '') {
      saveResponse({
        variables: {
          input: {
            campaignId,
            orgKey: teamAdapter.orgKey,
            comments: {
              add: [comment],
            },
          },
        },
      });
    }
    setIsSubmitted(true);
    continueChat();
  };

  if (isSubmitted || isReadOnly) {
    return (
      <CommentsContainer>
        {comments.map((comment, index) => (
          <MessageBubble key={`${index}-${comment}`} source={'local'}>
            {comment}
          </MessageBubble>
        ))}
      </CommentsContainer>
    );
  }

  return (
    <FormContainer>
      <TextArea value={comment} onChange={setComment} />
      <Button onPress={handleSubmit}>
        {t('toolkit.toolPage.walkOfStrategy.survey.button.submit')}
      </Button>
    </FormContainer>
  );
};

export default Comment;
