import { useTheme } from '@emotion/react';
import type { ReactNode } from 'react';
import { Fragment, useState } from 'react';
import styled from '@emotion/styled';

import SideCard from 'shared/components/SideCard';
import Button from 'shared/components/Button';
import useHandleError from 'shared/errors/useHandleError';
import type {
  InitiativeStatus,
  MetricStatus,
  ObjectiveStatus,
  StatusCommentType,
} from 'types.graphql.generated';
import Text from 'shared/components/Text';

import StrategyItem from './StrategyItem';
import type { ViewReportCommentsReportObjectiveFragment } from './AllReportComments.graphql.generated';
import { useAllReportCommentsQuery } from './AllReportComments.graphql.generated';

const Separator = styled.div`
  width: calc(100% - 1rem);
  height: 1px;
  border-radius: 1px;
  background-color: ${(props) => props.theme.color.neutral3};
  margin-left: 0.5rem;
`;

type Props = {
  commentType: StatusCommentType;
  emptyMessage: string;
  hideButtonLabel: string;
  icon: ReactNode;
  reportId?: string;
  showButtonLabel: string;
  title: string;
};

const AllCommentsSideCard = ({
  reportId,
  commentType,
  icon,
  title,
  showButtonLabel,
  hideButtonLabel,
  emptyMessage,
}: Props) => {
  const theme = useTheme();
  const onError = useHandleError();

  const [isTriggered, setIsTriggered] = useState(false);

  const { data } = useAllReportCommentsQuery({
    skip: !isTriggered,
    onError,
    variables: { reportId: reportId || '' },
  });

  const objectiveReportsWithComments =
    data?.report.reportSelection.reportObjectives.filter((reportObjective) =>
      hasComments(commentType, reportObjective),
    );

  const isEmpty = objectiveReportsWithComments?.length === 0;

  return (
    <SideCard
      title={title}
      backgroundColor={theme.legacyColor.colorAlabaster}
      iconBackgroundColor={theme.legacyColor.colorDarkGrey}
      icon={icon}
    >
      {isEmpty && <Text isCentered={true}>{emptyMessage}</Text>}

      {objectiveReportsWithComments?.map((reportObjective, index) => (
        <Fragment key={reportObjective.objective.id}>
          {index > 0 && <Separator />}

          <StrategyItem
            commentType={commentType}
            strategyElement={reportObjective.objective}
            author={
              reportObjective.objective.currentObjectiveStatus?.auditRecord
                .createBy
            }
            comments={getComments(
              commentType,
              reportObjective.objective.currentObjectiveStatus,
            )}
          />

          {reportObjective.reportMetrics.map((reportMetric) => (
            <StrategyItem
              key={reportMetric.id}
              commentType={commentType}
              strategyElement={reportMetric.metric}
              author={
                reportMetric.metric.currentMetricStatus?.auditRecord.createBy
              }
              comments={getComments(
                commentType,
                reportMetric.metric.currentMetricStatus,
              )}
            />
          ))}

          {reportObjective.reportInitiatives.map((reportInitiative) => (
            <StrategyItem
              key={reportInitiative.id}
              commentType={commentType}
              strategyElement={reportInitiative.initiative}
              author={
                reportInitiative.initiative.currentInitiativeStatus?.auditRecord
                  .createBy
              }
              comments={getComments(
                commentType,
                reportInitiative.initiative.currentInitiativeStatus,
              )}
            />
          ))}

          {reportObjective.reportActions.map((reportAction) => (
            <StrategyItem
              key={reportAction.id}
              commentType={commentType}
              strategyElement={reportAction.action}
              author={reportAction.action.currentStatus?.auditRecord.createBy}
              comments={filterComments([
                reportAction.action.currentStatus?.comment,
              ])}
            />
          ))}
        </Fragment>
      ))}

      {isTriggered && data?.report ? (
        !isEmpty && (
          <Button
            variant={'outlined'}
            onPress={() => setIsTriggered(false)}
            fullWidth={true}
          >
            {hideButtonLabel}
          </Button>
        )
      ) : (
        <Button
          variant={'outlined'}
          onPress={() => setIsTriggered(true)}
          fullWidth={true}
          isDisabled={!reportId || isTriggered}
        >
          {showButtonLabel}
        </Button>
      )}
    </SideCard>
  );
};

export default AllCommentsSideCard;

function hasComments(
  type: StatusCommentType,
  reportObjective: ViewReportCommentsReportObjectiveFragment,
) {
  return (
    getComments(type, reportObjective.objective.currentObjectiveStatus)
      .length ||
    reportObjective.reportMetrics.some(
      (reportMetric) =>
        getComments(type, reportMetric.metric.currentMetricStatus).length,
    ) ||
    reportObjective.reportInitiatives.some(
      (reportInitiative) =>
        getComments(type, reportInitiative.initiative.currentInitiativeStatus)
          .length,
    ) ||
    reportObjective.reportActions.some(
      (reportAction) =>
        filterComments([reportAction.action.currentStatus?.comment]).length,
    )
  );
}

function getComments(
  type: StatusCommentType,
  status?:
    | Pick<
        ObjectiveStatus,
        'successComments' | 'challengeComments' | 'actionComments'
      >
    | Pick<
        MetricStatus,
        'successComments' | 'challengeComments' | 'actionComments'
      >
    | Pick<
        InitiativeStatus,
        'successComments' | 'challengeComments' | 'actionComments'
      >,
) {
  if (!status) return [];

  switch (type) {
    case 'SUCCESS_COMMENT':
      return filterComments(
        status.successComments.map((comment) => comment.text),
      );
    case 'CHALLENGE_COMMENT':
      return filterComments(
        status.challengeComments.map((comment) => comment.text),
      );
    case 'ACTION_COMMENT':
      return filterComments(
        status.actionComments.map((comment) => comment.text),
      );
  }
}

function filterComments(comments: Maybe<string>[]) {
  return comments.filter(
    (comment): comment is string =>
      comment !== null && comment !== undefined && comment !== '',
  );
}
