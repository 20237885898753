import type { i18n } from 'i18next';

import type { Source } from 'org/OrgTermsPostProcessorModule';
import { OrgTermsPostProcessorModule } from 'org/OrgTermsPostProcessorModule';
import type { Org } from 'types.graphql.generated';

export const loadSource = async (
  terminologyFileUrl: string,
): Promise<Maybe<Source>> => {
  try {
    const result = await fetch(terminologyFileUrl, { mode: 'cors' });
    const source = await result.json();

    if (OrgTermsPostProcessorModule.isValidSource(source)) {
      return source;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error loading terminology file', error);
  }
};

export const isTerminologyFileUrlValid = (value: unknown): value is string =>
  !!value && typeof value === 'string';

export const emitLoadOrgTermSourceEvent = (
  i18n: i18n,
  orgKey?: Org['orgKey'],
  source?: Maybe<Source>,
): void => {
  if (orgKey && source) {
    i18n.emit('loadOrgTermSource', orgKey, source);
  }
};
