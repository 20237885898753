import { useTranslation } from 'react-i18next';
import { css, useTheme } from '@emotion/react';

import ConfirmationModal from 'shared/components/__DEPRECATED__/ConfirmationModal';
import { useToasts } from 'shared/toast/useToasts';
import type { CampaignStage } from 'types.graphql.generated';
import Text from 'shared/components/Text';
import useHandleError from 'shared/errors/useHandleError';

import { OneThingCampaignsDocument } from './OneThingCampaigns.graphql.generated';
import { useDeleteOneThingCampaignMutation } from './DeleteOneThingCampaign.graphql.generated';

type Props = {
  campaignId: string;
  campaignStage: CampaignStage;
  onClose: () => void;
};

const DeleteCampaignDialog = ({
  campaignId,
  campaignStage,
  onClose,
}: Props) => {
  const theme = useTheme();
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const handleApolloError = useHandleError();

  const [deleteCampaign] = useDeleteOneThingCampaignMutation({
    variables: { campaignId },
    onError: handleApolloError,
    refetchQueries: [OneThingCampaignsDocument],
  });

  const message =
    campaignStage === 'ACTIVE'
      ? t('toolkit.toolPage.oneThing.campaigns.deleteDialog.confirmationActive')
      : t('toolkit.toolPage.oneThing.campaigns.deleteDialog.confirmation');

  return (
    <ConfirmationModal
      isOpen={true}
      onClose={onClose}
      onConfirm={async () => {
        const result = await deleteCampaign();

        if (result.data?.deleteOneThingCampaigns) {
          addToast({
            children: t(
              'toolkit.toolPage.oneThing.campaigns.deleteDialog.success',
            ),
            variant: 'success',
          });
        } else {
          addToast({
            children: t(
              'toolkit.toolPage.oneThing.campaigns.deleteDialog.error',
            ),
            variant: 'error',
          });
        }

        onClose();
      }}
    >
      <Text css={css({ color: theme.color.black, whiteSpace: 'pre-wrap' })}>
        {message}
      </Text>
    </ConfirmationModal>
  );
};

export default DeleteCampaignDialog;
