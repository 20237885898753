import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { uniq } from 'lodash';

import CalendarControls from 'shared/components/CalendarControls';
import CompletedFilterButton from 'shared/components/CompletedFilterButton';
import FiltersButton from 'shared/components/FiltersButton';
import Flex from 'shared/components/Flex';
import Heading from 'shared/components/Heading';
import SelectAllButton from 'shared/components/SelectAllButton';
import InfoIcon from 'shared/components/InfoIcon';

import SelectableStrategyItemsFilters from './SelectableStrategyItemsFilters';
import { useSelectableStrategyItems } from './SelectableStrategyItemsProvider/useSelectableStrategyItems';
import { getAllIds } from './SelectableStrategyItems.utils';
import type { SelectableStrategyItemsValues } from './SelectableStrategyItems.type';
import useChipGroupItems from './SelectableStrategyItemsFiltersChipGroup/useChipGroupItems';

export type Props = {
  onInitiativeChange: (values: string[]) => void;
  onMetricChange: (values: string[]) => void;
  onObjectiveChange: (values: string[]) => void;
  values: SelectableStrategyItemsValues;
};

const SelectableStrategyItemsHeader = ({
  values,
  onObjectiveChange,
  onMetricChange,
  onInitiativeChange,
}: Props) => {
  const { t } = useTranslation();

  const {
    filteredObjectives,
    filter: { clearFilters },
  } = useSelectableStrategyItems();

  const filteredObjectiveIds = filteredObjectives.map(
    (objective) => objective.id,
  );

  const filteredMetricIds = filteredObjectives
    .flatMap((objective) => objective.metrics)
    .map((metric) => metric.id);

  const filteredInitiativeIds = filteredObjectives
    .flatMap((objective) => objective.initiatives)
    .map((initiaive) => initiaive.id);

  const selectedFilteredObjectiveCount = values.objectiveIds.filter(
    (objectiveId) => filteredObjectiveIds.includes(objectiveId),
  ).length;

  const selectedFilteredMetricCount = values.metricIds.filter((metricId) =>
    filteredMetricIds.includes(metricId),
  ).length;

  const selectedFilteredInitiativeCount = values.initiativeIds.filter(
    (initiativeId) => filteredInitiativeIds.includes(initiativeId),
  ).length;

  const allFilteredItemIds = useMemo(
    () => getAllIds(filteredObjectives),
    [filteredObjectives],
  );

  const totalSelectedFilteredCount =
    selectedFilteredObjectiveCount +
    selectedFilteredMetricCount +
    selectedFilteredInitiativeCount;

  const isNoFilteredItemSelected = totalSelectedFilteredCount === 0;

  const areAllFilteredItemsSelected =
    totalSelectedFilteredCount === allFilteredItemIds.count;

  const handleSelectAll = useCallback(() => {
    onObjectiveChange(
      uniq([...values.objectiveIds, ...allFilteredItemIds.objectivesIds]),
    );

    onMetricChange(
      uniq([...values.metricIds, ...allFilteredItemIds.metricsIds]),
    );

    onInitiativeChange(
      uniq([...values.initiativeIds, ...allFilteredItemIds.initiativesIds]),
    );
  }, [
    allFilteredItemIds.initiativesIds,
    allFilteredItemIds.metricsIds,
    allFilteredItemIds.objectivesIds,
    onInitiativeChange,
    onMetricChange,
    onObjectiveChange,
    values.initiativeIds,
    values.metricIds,
    values.objectiveIds,
  ]);

  const handleDeselectAll = useCallback(() => {
    onObjectiveChange(
      values.objectiveIds.filter(
        (objectiveId) => !filteredObjectiveIds.includes(objectiveId),
      ),
    );

    onMetricChange(
      values.metricIds.filter(
        (metricId) => !filteredMetricIds.includes(metricId),
      ),
    );

    onInitiativeChange(
      values.initiativeIds.filter(
        (initiativeId) => !filteredInitiativeIds.includes(initiativeId),
      ),
    );
  }, [
    filteredInitiativeIds,
    filteredMetricIds,
    filteredObjectiveIds,
    onInitiativeChange,
    onMetricChange,
    onObjectiveChange,
    values.initiativeIds,
    values.metricIds,
    values.objectiveIds,
  ]);

  const handleToggleSelectAll = useCallback(
    () => (isNoFilteredItemSelected ? handleSelectAll() : handleDeselectAll()),
    [handleDeselectAll, handleSelectAll, isNoFilteredItemSelected],
  );

  const chipGroupItems = useChipGroupItems();

  return (
    <>
      <Heading level={3} hasMargin={false} css={css({ marginBottom: '7px' })}>
        {t('report.insightReportWizard.objectives.heading')}
      </Heading>

      <Flex justifyContent={'space-between'}>
        <div css={css({ flex: '1' })}>
          <SelectAllButton
            css={css({ fontWeight: 400, height: '3rem' })}
            variant={'outlined'}
            areAllSelected={areAllFilteredItemsSelected}
            isNoneSelected={isNoFilteredItemSelected}
            onPress={handleToggleSelectAll}
            height={'3rem'}
          />
        </div>

        <CalendarControls css={css({ flex: '1' })} />

        <Flex gap={8} css={css({ flex: '1' })} justifyContent={'flex-end'}>
          <CompletedFilterButton />

          <FiltersButton
            clearFilters={clearFilters}
            selectionCount={chipGroupItems.length}
          >
            <SelectableStrategyItemsFilters />
          </FiltersButton>

          <InfoIcon
            content={t('strategy.selectableStrategyItems.filterInfo')}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default SelectableStrategyItemsHeader;
