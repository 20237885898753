import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { capitalize } from 'lodash';

import { useObjective } from 'objective/ObjectiveProvider';
import { usePageTitle } from 'shared/hooks/usePageTitle';
import StatusTag from 'shared/status/StatusTag/StatusTag';
import { date } from 'shared/services/date.service';
import type { LinkItem } from 'shared/components/Layout';
import LabeledHeadingText from 'shared/components/LabeledHeadingText';
import UserAvatar from 'user/UserAvatar';
import { strategyRoutesPaths } from 'strategy/strategy.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useMyTeamRoutePath } from 'team/useMyTeamRoutePath';

import {
  getBreadcrumbItems,
  getNavigationItems,
} from './ObjectiveLayout.utils';
import ObjectiveHeader from './ObjectiveHeader';
import { useOrgUnit } from '../../orgUnit/OrgUnitProvider';
import ObjectiveContextMenu from '../ObjectiveContextMenu';

const ObjectiveLayout = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { teamAdapter } = useTeamAdapter();
  const { objective } = useObjective();
  const { name, currentObjectiveStatus } = objective;

  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const { routePath: myTeamRoutePath, isMatch: isMyTeamRoutePathMatch } =
    useMyTeamRoutePath();

  usePageTitle(name);

  const breadcrumbsItems = useMemo(
    () =>
      getBreadcrumbItems({
        t,
        objectiveId: objective.id,
        teamAdapter,
        activeOrg,
        orgUnit,
      }),
    [activeOrg, objective.id, orgUnit, t, teamAdapter],
  );

  const navigationItems = useMemo(
    () => getNavigationItems({ t, objective, teamAdapter }),
    [objective, t, teamAdapter],
  );

  const description = useMemo(() => {
    const startDate = objective?.timeLine.startDate
      ? date.format(objective?.timeLine.startDate, 'dd MMM yyyy')
      : t('notSet');
    const endDate = objective?.timeLine.endDate
      ? date.format(objective?.timeLine.endDate, 'dd MMM yyyy')
      : t('notSet');

    return `${startDate} - ${endDate}`;
  }, [objective?.timeLine.endDate, objective?.timeLine.startDate, t]);

  const heading = useMemo(
    () => ({
      content: (
        <LabeledHeadingText
          label={capitalize(t('objective.objective'))}
          textContent={name}
        />
      ),
      isBold: false,
    }),
    [name, t],
  );

  const topRightNavItem: Maybe<LinkItem> = !isMyTeamRoutePathMatch
    ? {
        name: t('team.layout.goToTeam'),
        to: myTeamRoutePath,
      }
    : null;

  return (
    <>
      <ObjectiveHeader
        heading={heading}
        description={description}
        theme={objective.theme?.name}
        priority={objective.priority?.label}
        breadcrumbsItems={breadcrumbsItems}
        navigationItems={navigationItems}
        contextMenu={
          <ObjectiveContextMenu
            objective={objective}
            onDelete={() =>
              navigate(
                strategyRoutesPaths.objectives.root({
                  params: { teamSlug: teamAdapter.toParam() },
                }),
              )
            }
          />
        }
        topRightNavItem={topRightNavItem}
        orgUnit={objective.orgUnit}
      >
        <StatusTag statusIndicator={currentObjectiveStatus?.statusIndicator} />
        <UserAvatar user={objective?.owner} />
      </ObjectiveHeader>

      <Outlet />
    </>
  );
};

export default ObjectiveLayout;
