import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useRef } from 'react';

import { resolveInitialValue as resolveSelectObjectiveInputInitialValue } from 'objective/SelectObjectiveInput';
import type { ActionInput } from 'types.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import { resolvePriorityInitialValue } from 'strategy/PrioritySelect/resolvePriorityInitialValue';
import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';
import Dialog from 'shared/components/Dialog';
import ActionQuickFormFields from 'actions/CreateActionButton/CreateAction/ActionQuickFormFields';
import { ObjectiveActionsDocument } from 'actions/ObjectiveActions/ObjectiveActionsProvider/ObjectiveActionsProvider.graphql.generated';
import {
  StrategyActionsOrgDocument,
  StrategyActionsOrgUnitDocument,
} from 'actions/StrategyActions/StrategyActionsProvider.graphql.generated';

import { useUpdateActionMutation } from './UpdateAction.graphql.generated';
import type { ActionFormValues } from './ActionForm';
import ActionForm from './ActionForm';
import { useEditActionQuery } from './EditAction.graphql.generated';

type Props = {
  actionId: string;
  isOpen: boolean;
  onClose: () => void;
};

const EditAction = ({ actionId, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const formId = useRef<string>(`action-form-${uuid()}`);

  const { addToast } = useToasts();

  const onError = useHandleError();

  const { data } = useEditActionQuery({
    variables: { actionId },
    onError,
    skip: !isOpen,
  });

  const handleApolloError = useHandleError();

  const [updateAction, { loading }] = useUpdateActionMutation({
    onError: handleApolloError,
    refetchQueries: [
      ObjectiveActionsDocument,
      StrategyActionsOrgDocument,
      StrategyActionsOrgUnitDocument,
    ],
  });

  const action = data?.action;

  const initialValues: ActionFormValues | undefined = useMemo(
    () =>
      action
        ? {
            id: action.id,
            name: action.name || '',
            description: action.description || '',
            owner: action.owner?.email || '',
            objective: resolveSelectObjectiveInputInitialValue(
              action.objective,
            ),
            priority: resolvePriorityInitialValue(action.priority),
            timeLine: {
              endDate: action.timeLine.endDate,
            },
          }
        : undefined,
    [action],
  );

  const handleSubmit = useCallback(
    async (input: ActionInput) => {
      const result = await updateAction({ variables: { input } });

      const isSuccess = result.data?.updateAction.id && !result.errors;

      if (isSuccess) {
        addToast({
          variant: 'success',
          children: t('actions.toasts.editActionSuccess'),
        });

        onClose();
      }
    },
    [addToast, onClose, t, updateAction],
  );

  if (!isOpen) return null;
  if (!initialValues) return <Spinner.Circle />;

  return (
    <ActionForm
      id={formId.current}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        headerTitle={t('actions.form.heading.edit')}
        confirmLabel={t('update')}
        cancelLabel={t('cancel')}
        formId={formId.current}
        isConfirmDisabled={loading}
      >
        <ActionQuickFormFields showObjectiveField={true} />
      </Dialog>
    </ActionForm>
  );
};

export default EditAction;
