import { ReactComponent as FlagIcon } from 'shared/static/icons/icon-flag.svg';
import CollapsibleList from 'shared/components/CollapsibleList';

import InitiativeRow from '../InitiativesContentListRow';
import type { InitiativesContentObjectiveFragment } from '../InitiativesContentProvider/InitiativesContent.graphql.generated';

type InitiativesContentListSectionProps = {
  hasHeader?: boolean;
  isExpanded?: boolean;

  objective: InitiativesContentObjectiveFragment;
};

const InitiativesContentListSection = ({
  hasHeader = true,
  objective,
  isExpanded,
}: InitiativesContentListSectionProps) => (
  <CollapsibleList
    title={objective.name}
    titleIcon={FlagIcon}
    type={'initiative'}
    hasHeader={hasHeader}
    expanded={isExpanded}
  >
    {objective.initiatives?.map((initiative) => (
      <InitiativeRow
        initiative={initiative}
        objective={objective}
        key={initiative.id}
      />
    ))}
  </CollapsibleList>
);

export default InitiativesContentListSection;
