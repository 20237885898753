import type { PostProcessorModule, i18n as I18nType, TOptions } from 'i18next';

import type { Org } from 'types.graphql.generated';

import { sourceValidationSchema } from './OrgTermsPostProcessorModule.utils';
import type { Source } from './OrgTermsPostProcessorModule.type';

export class OrgTermsPostProcessorModule implements PostProcessorModule {
  name = 'orgTerms';
  type = 'postProcessor' as const;
  sources: Map<Org['orgKey'], Source> = new Map();
  i18n: I18nType;
  actingOrgKey?: string;

  constructor(i18n: I18nType, actingOrgKey?: string) {
    this.i18n = i18n;
    this.actingOrgKey = actingOrgKey;
    this.addEventListeners();
    this.addToI18nModules();
  }

  process(
    value: string,
    keyOrKeys: string | string[],
    options?: TOptions,
    translator?: any,
  ): string {
    const key = Array.isArray(keyOrKeys) ? keyOrKeys[0] : keyOrKeys;
    const language = this.i18n.language;
    const pluralResolver = translator?.pluralResolver as any;
    const keyWithSuffix = this.addPluralSuffix(
      key,
      pluralResolver,
      options?.count,
    );
    const actingOrgSource = this.getActingOrgSource();
    const replacementValue = actingOrgSource?.[language]?.[keyWithSuffix];
    if (replacementValue) {
      return replacementValue;
    }
    return value;
  }

  hasKeyInSource(key: string) {
    return !!this.process('', [key]);
  }

  private addPluralSuffix(
    key: string,
    pluralResolver: any,
    count?: number,
  ): string {
    const language = this.i18n.language;
    const initialPluralSuffix = count
      ? pluralResolver.getSuffix(language, count)
      : '';
    if (
      !initialPluralSuffix ||
      initialPluralSuffix === '_one' ||
      key.includes(initialPluralSuffix)
    ) {
      return key;
    }
    return `${key}${initialPluralSuffix}`;
  }

  private addEventListeners(): void {
    this.i18n.on(
      'loadOrgTermSource',
      (orgKey: Org['orgKey'], source: Source) => {
        this.sources.set(orgKey, source);
      },
    );
  }

  private addToI18nModules() {
    this.i18n.modules.orgTermsPostProcessor = this;
  }

  private getActingOrgSource(): Maybe<Source> {
    if (this.actingOrgKey) {
      return this.sources.get(this.actingOrgKey);
    }
  }

  static isValidSource(value: unknown): value is Source {
    return !!value && sourceValidationSchema.isValidSync(value);
  }
}
