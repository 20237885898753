import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AskSophieSidePanelSubscriptionVariables = Types.Exact<{
  input: Types.AiStreamInput;
}>;


export type AskSophieSidePanelSubscription = { __typename: 'Subscription', aiStream: string };


export const AskSophieSidePanelDocument = gql`
    subscription AskSophieSidePanel($input: AiStreamInput!) {
  aiStream(input: $input)
}
    `;

/**
 * __useAskSophieSidePanelSubscription__
 *
 * To run a query within a React component, call `useAskSophieSidePanelSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAskSophieSidePanelSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAskSophieSidePanelSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAskSophieSidePanelSubscription(baseOptions: Apollo.SubscriptionHookOptions<AskSophieSidePanelSubscription, AskSophieSidePanelSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AskSophieSidePanelSubscription, AskSophieSidePanelSubscriptionVariables>(AskSophieSidePanelDocument, options);
      }
export type AskSophieSidePanelSubscriptionHookResult = ReturnType<typeof useAskSophieSidePanelSubscription>;
export type AskSophieSidePanelSubscriptionResult = Apollo.SubscriptionResult<AskSophieSidePanelSubscription>;