import { useTranslation } from 'react-i18next';

import StrategyRoomThemesLayout from 'strategy/StrategyRoomThemesLayout';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import Spinner from 'shared/spinner/Spinner';
import InfoCard from 'shared/components/InfoCard';
import type { OrgUnitFieldsFragment } from 'orgUnit/OrgUnitProvider/OrgUnitProvider.graphql.generated';
import CreateObjectiveButton from 'objective/CreateObjectiveButton';
import useHandleError from 'shared/errors/useHandleError';

import { useStrategyRoomThemesManageObjectivesQuery } from './StrategyRoomThemesManageObjectives.graphql.generated';
import TeamRow from './TeamRow';
import ObjectiveSelectionProvider from './ObjectiveSelctionProvider';
import ObjectivesSelection from './ObjectivesSelection';

const StrategyRoomThemesManageObjectives = () => {
  const { t } = useTranslation();
  const { orgUnit } = useOrgUnit();

  const handleApolloError = useHandleError();

  const orgUnitIds = orgUnit
    ? [
        orgUnit.id,
        ...(orgUnit.parentOrgUnitTree.map((orgUnit) => orgUnit.id) || []),
      ]
    : [];

  const { data } = useStrategyRoomThemesManageObjectivesQuery({
    fetchPolicy: 'cache-and-network',
    onError: handleApolloError,
    variables: {
      themesOrgUnitIds: orgUnitIds,
      objectivesOrgUnitIdsFilter: orgUnit
        ? { operator: 'IN', values: [orgUnit.id] }
        : { operator: 'IS_NULL' },
    },
  });

  if (!data) return <Spinner.Circle />;

  const teams: (OrgUnitFieldsFragment | undefined)[] = [
    ...(orgUnit ? [orgUnit, ...orgUnit.parentOrgUnitTree] : []),
    undefined,
  ].reverse();

  const allThemes = [
    ...data.orgThemes.edges.map((edge) => edge.node),
    ...data.orgUnitThemes.edges.map((edge) => edge.node),
  ];

  const currentTeamObjectives = data.teamObjectives.edges.map(
    (edge) => edge.node,
  );

  return (
    <StrategyRoomThemesLayout
      description={t(
        'strategy.strategyRoom.themes.tabs.manageObjectives.description',
      )}
      headerButton={
        <CreateObjectiveButton variant={'outlined'}>
          {t('strategy.strategyRoom.themes.newObjective')}
        </CreateObjectiveButton>
      }
      infoCard={
        <InfoCard
          title={t(
            'strategy.strategyRoom.themes.tabs.manageObjectives.infoCard.heading',
          )}
          contentKey={
            'strategy.strategyRoom.themes.tabs.manageObjectives.infoCard.content'
          }
        />
      }
    >
      <ObjectiveSelectionProvider>
        {teams.map((team) => (
          <TeamRow
            key={team?.id || -1}
            themes={allThemes}
            objectives={currentTeamObjectives}
            orgUnit={team}
          />
        ))}

        <ObjectivesSelection themes={allThemes} />
      </ObjectiveSelectionProvider>
    </StrategyRoomThemesLayout>
  );
};

export default StrategyRoomThemesManageObjectives;
