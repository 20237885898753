import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { capitalize } from 'lodash';
import { css } from '@emotion/react';

import LayoutHeaderContainer from 'shared/components/Layout/LayoutHeaderContainer';
import type { LinkItem } from 'shared/components/Layout/LayoutHeaderTopSection';
import LayoutHeaderTopSection from 'shared/components/Layout/LayoutHeaderTopSection';
import LayoutHeaderHeading from 'shared/components/Layout/LayoutHeaderHeading';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import { date } from 'shared/services/date.service';
import LabeledHeadingText from 'shared/components/LabeledHeadingText';
import StatusTag from 'shared/status/StatusTag';
import UserAvatar from 'user/UserAvatar/UserAvatar';
import LinkTabs from 'shared/components/LinkTabs';
import { useTeamAdapter } from 'team/TeamAdapter';
import { useMyTeamRoutePath } from 'team/useMyTeamRoutePath';
import Flex from 'shared/components/Flex';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import MetricContextMenu from 'metric/MetricContextMenu/MetricContextMenu';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import TeamAvatar from 'team/TeamAvatar/TeamAvatar';

import styles from './MetricOverviewLayoutHeader.module.scss';
import {
  getBreadcrumbsItems,
  getNavigationItems,
} from './MetricOverviewLayoutHeader.utils';
import { useMetricOverview } from '../MetricOverviewProvider';
import { useOrgUnit } from '../../../orgUnit/OrgUnitProvider';

const MetricLayoutHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { routePath: myTeamRoutePath, isMatch: isMyTeamRoutePathMatch } =
    useMyTeamRoutePath();

  const { teamAdapter } = useTeamAdapter();
  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();
  const { metric } = useMetricOverview();
  const { priorities } = useStrategyProfilePriorities();

  const { objectiveId, metricId } = useParams() as {
    metricId: string;
    objectiveId: string;
  };

  const topRightNavItem: Maybe<LinkItem> = !isMyTeamRoutePathMatch
    ? {
        name: t('team.layout.goToTeam'),
        to: myTeamRoutePath,
      }
    : undefined;

  const dates = useMemo(() => {
    const startDate = metric?.timeLine.startDate
      ? date.format(metric?.timeLine.startDate, 'dd MMM yyyy')
      : t('notSet');
    const endDate = metric?.timeLine.endDate
      ? date.format(metric?.timeLine.endDate, 'dd MMM yyyy')
      : t('notSet');

    return `${startDate} - ${endDate}`;
  }, [metric, t]);

  const breadcrumbsItems = getBreadcrumbsItems({
    t,
    objective: metric?.objective,
    metricId,
    teamAdapter,
    activeOrg,
    orgUnit,
  });

  const navigationItems = useMemo<LinkItem[]>(() => getNavigationItems(t), [t]);

  return (
    <LayoutHeaderContainer>
      <div className={styles.content}>
        <LayoutHeaderTopSection
          breadcrumbs={breadcrumbsItems}
          topRightLinkItem={topRightNavItem}
          className={styles.topSection}
        />
        <Flex
          justifyContent={'space-between'}
          alignItems={'center'}
          css={css({ marginBottom: 12 })}
          gap={12}
        >
          {metric && <TeamAvatar orgUnit={metric.orgUnit} size={'small'} />}

          <LayoutHeaderHeading
            content={
              <LabeledHeadingText
                label={capitalize(t('metric.metric'))}
                textContent={metric?.name}
              />
            }
            isBold={false}
          />
          <div className={styles.headingExtra}>
            <StatusTag
              statusIndicator={metric?.currentMetricStatus?.statusIndicator}
            />
            <UserAvatar user={metric?.owner} />
            {metric && (
              <MetricContextMenu
                metric={metric}
                onDelete={() => {
                  navigate(
                    objectiveRoutesPaths.root({
                      params: {
                        teamSlug: teamAdapter.toParam(),
                        objectiveId,
                      },
                    }),
                  );
                }}
              />
            )}
          </div>
        </Flex>
        <Flex gap={20}>
          {dates && <p className={styles.description}>{dates}</p>}
          {(priorities || metric?.priority) && (
            <p className={styles.description}>
              {t('priority.priority')}
              {': '}
              {metric?.priority?.label || t('priority.noPriority')}
            </p>
          )}
        </Flex>
        <LinkTabs items={navigationItems} />
      </div>
    </LayoutHeaderContainer>
  );
};

export default MetricLayoutHeader;
