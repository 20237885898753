import { Trans, useTranslation } from 'react-i18next';
import type { ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import isNil from 'lodash/isNil';

import Button from 'shared/components/Button';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import Space from 'shared/components/Space';
import Text from 'shared/components/Text';
import ConfirmationModal from 'shared/components/__DEPRECATED__/ConfirmationModal';
import type { AddTeamMemberModalProps } from 'user/AddTeamMemberModal';
import AddTeamMemberModal from 'user/AddTeamMemberModal';
import type { AddTeamMemberFormValues } from 'user/AddTeamMemberForm';
import Heading from 'shared/components/Heading';
import Flex from 'shared/components/Flex';

import MembersMember from './MembersMember';
import type { Member } from './Members.type';
import { getMembersWithOwner, getMembersWithEmail } from './Members.utils';

export type MembersProps = {
  canAddNewMember?: boolean;
  canRemoveMember?: boolean;
  members?: Member[];
  onAddNewMember?: (
    data: AddTeamMemberFormValues,
    closeModal: () => void,
  ) => Promise<void>;
  onRemoveMember?: (member: Member) => void;
  owner?: Member;
  renderAddTeamMemberModalChildren?: () => ReactNode;
} & Pick<AddTeamMemberModalProps, 'teamAdapter' | 'usersToExclude'>;

const Members = ({
  renderAddTeamMemberModalChildren,
  members: membersProp = [],
  owner,
  onAddNewMember,
  canAddNewMember,
  canRemoveMember,
  onRemoveMember,
  teamAdapter,
  usersToExclude,
}: MembersProps) => {
  const { t } = useTranslation();
  const [isAddingTeamMember, setIsAddingTeamMember] = useState(false);

  const members = useMemo(
    () =>
      getMembersWithOwner({
        owner,
        members: getMembersWithEmail(membersProp),
      }),
    [membersProp, owner],
  );

  const [memberIdToRemove, setMemberIdToRemove] = useState<Member['id'] | null>(
    null,
  );
  const [isAddMemberModalOpened, setIsAddMemberModalOpened] = useState(false);

  const isRemoveConfirmationModalOpened = !isNil(memberIdToRemove);
  const closeRemoveConfirmationModal = () => {
    setMemberIdToRemove(null);
  };

  const memberToRemove = members?.find(
    (memberOrOwner) => memberOrOwner.id === memberIdToRemove,
  );

  const membersList = useMemo(() => {
    if (members.length) {
      return (
        <Flex direction={'column'}>
          {members.map((member) => {
            const isOwner = member.id === owner?.id;
            return (
              <MembersMember
                key={member.id}
                member={member}
                isOwner={isOwner}
                onRemoveMember={
                  !isOwner && canRemoveMember && onRemoveMember
                    ? () => setMemberIdToRemove(member.id)
                    : undefined
                }
              />
            );
          })}
        </Flex>
      );
    }
    return <p>{t('user.members.noMembers')}</p>;
  }, [members, canRemoveMember, onRemoveMember, t, owner?.id]);

  const actions = useMemo(() => {
    if (canAddNewMember && onAddNewMember) {
      return (
        <Space>
          <Button
            variant={'outlined'}
            color={'mono'}
            icon={PlusIcon}
            iconPosition={'start'}
            onPress={() => setIsAddMemberModalOpened(true)}
          >
            {t('user.members.addNewMemberButton')}
          </Button>
        </Space>
      );
    }
    return null;
  }, [canAddNewMember, onAddNewMember, t]);

  const handleAddTeamMemberSubmit = useCallback(
    async (values: AddTeamMemberFormValues) => {
      setIsAddingTeamMember(true);
      await onAddNewMember?.(values, () => setIsAddMemberModalOpened(false));
      setIsAddingTeamMember(false);
    },
    [onAddNewMember],
  );

  return (
    <>
      <Flex direction={'column'} gap={'1rem'}>
        <Heading level={3} as={5} hasMargin={false}>
          {t('team.team')}
        </Heading>
        {membersList}
        {actions}
      </Flex>

      <ConfirmationModal
        isOpen={isRemoveConfirmationModalOpened}
        onClose={closeRemoveConfirmationModal}
        onConfirm={() => onRemoveMember?.(memberToRemove!)}
      >
        <Text>
          <Trans
            i18nKey={'user.members.confirmRemoveModal.content'}
            values={{
              nameOrEmail:
                memberToRemove?.user?.displayName ||
                memberToRemove?.user?.email,
            }}
          >
            <Text variant={'emphasis'} />
          </Trans>
        </Text>
      </ConfirmationModal>

      <AddTeamMemberModal
        isOpen={isAddMemberModalOpened}
        onClose={() => setIsAddMemberModalOpened(false)}
        onSubmit={handleAddTeamMemberSubmit}
        teamAdapter={teamAdapter}
        usersToExclude={usersToExclude}
        isSubmitButtonDisabled={isAddingTeamMember}
      >
        {renderAddTeamMemberModalChildren?.()}
      </AddTeamMemberModal>
    </>
  );
};

export default Members;
