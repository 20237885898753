import type { PropsWithChildren } from 'react';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import type { OrgUnit } from 'types.graphql.generated';
import Tooltip from 'shared/components/Tooltip';

type Props = PropsWithChildren<{
  orgUnit?: Maybe<Pick<OrgUnit, 'id' | 'name' | 'nameAbbreviated'>>;
}>;

const TeamTooltip = ({ orgUnit, children }: Props) => {
  const { activeOrg } = useActiveOrg();

  const orgName =
    activeOrg.displayName ||
    activeOrg.displayNameAbbreviated ||
    activeOrg.domainNames[0] ||
    activeOrg.id;

  const orgUnitName = orgUnit?.name || orgUnit?.nameAbbreviated || orgUnit?.id;

  const longName = orgUnitName || orgName;

  return (
    <Tooltip content={longName} delay={0}>
      {children}
    </Tooltip>
  );
};

export default TeamTooltip;
