import { useTranslation } from 'react-i18next';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { capitalize } from 'lodash';
import styled from '@emotion/styled';

import Space from 'shared/components/Space';
import StatusMark from 'shared/status/StatusMark';
import Text from 'shared/components/Text';
import { ReactComponent as LinkIcon } from 'shared/static/icons/icon-link.svg';
import { ReactComponent as ChevronRightIcon } from 'shared/static/icons/icon-chevron-right.svg';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import type { TeamAdapter } from 'team/TeamAdapter';
import type { Objective } from 'types.graphql.generated';
import LockedLink from 'shared/components/LockedLink';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import { transientOptions } from 'shared/utils/emotion.utils';

import type { InitiativeChipInitiative } from './InitiativeChip.type';

export type InitiativeChipProps = {
  className?: string;
  contextObjective: Pick<Objective, 'id' | 'name'>;
  initiative: InitiativeChipInitiative;
  left?: number;
  right?: number;
  teamAdapter: TeamAdapter;
};

const linkIconWidth = 16;
const gapWidth = 16;

const InitiativeChipContainer = styled('div', transientOptions)<{
  $hasLink: boolean;
  $isExpandable: boolean;
  $isObjectiveExpandable: boolean;
  $leftPosition: number;
}>`
  align-items: flex-start;
  cursor: ${({ $hasLink }) => ($hasLink ? 'pointer' : 'default')};
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem;
  position: absolute;
  left: ${({ $leftPosition }) => `${$leftPosition}%`};
  right: 0;

  &:hover {
    ${({ $isExpandable }) =>
      $isExpandable &&
      `
      ${InitiativeChipContent} {
        ${InitiativeChipNameText} {
          overflow: visible;
          transform: translateX(calc(var(--width) - (var(--initiative-text-width) + var(--initiative-label-width))));
        }
        ${InitiativeChipStatusMark} {
          transform: translateX(calc(var(--width) - (var(--initiative-text-width) + var(--initiative-label-width))));
        }
      }
    `}

    ${({ $isObjectiveExpandable }) =>
      $isObjectiveExpandable &&
      `
      ${InitiativeChipObjectiveLink} {
        overflow: visible;
        transform: translateX(calc(var(--width) - var(--objective-link-width)));
      }
    `}
  }
`;

const InitiativeChipContent = styled(Space, transientOptions)<{
  $isExpandable: boolean;
}>`
  display: flex;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  ${({ $isExpandable }) => $isExpandable && `white-space: nowrap;`}
`;

const InitiativeChipStatusMark = styled(StatusMark)`
  background-color: #ececec;
  flex-shrink: 0;
  margin: 0.45rem 0 0 0.25rem;
  transform: translateX(0);
  transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
`;

const InitiativeChipNameRef = styled(Space)`
  color: ${({ theme }) => theme.color.typoPrimary};
`;

const InitiativeChipNameText = styled('span', transientOptions)<{
  $isOverflowHidden: boolean;
}>`
  color: ${({ theme }) => theme.color.typoPrimary};
  margin-bottom: 8px;
  overflow: ${({ $isOverflowHidden }) =>
    $isOverflowHidden ? 'hidden' : 'visible'};
  text-overflow: ellipsis;
  transform: translateX(0);
  transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  white-space: nowrap;
`;

const InitiativeChipNameType = styled(Text)`
  margin-right: 0.5rem;
`;

const InitiativeChipName = styled(Text)`
  color: ${({ theme }) => theme.color.typoPrimary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const InitiativeChipObjectiveLink = styled.div<{
  isObjectiveExpandable: boolean;
}>`
  margin-top: -0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translateX(0);
  transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  white-space: nowrap;
  ${({ isObjectiveExpandable }) =>
    isObjectiveExpandable && `white-space: nowrap;`}
`;

const InitiativeChipObjectiveLinkContent = styled(Text)`
  color: ${({ theme }) => theme.color.typoSecondary};
  margin-left: 0.5rem;
`;

const StyledLinkIcon = styled(LinkIcon)`
  color: ${({ theme }) => theme.color.typoSecondary};
  display: inline-block;
  height: 16px;
  position: relative;
  top: -1.5px;
  width: 16px;
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  color: ${({ theme }) => theme.color.typoSecondary};
  display: inline-block;
  height: 16px;
  position: relative;
  top: -1.5px;
  width: 16px;
`;

const InitiativeChip = ({
  contextObjective,
  initiative,
  teamAdapter,
  className,
  left = 0,
}: InitiativeChipProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement>(null);
  const initiativeChipNameRef = useRef<HTMLDivElement | null>(null);

  const [containerRightPosition, setContainerRightPosition] = useState(0);
  const [initiativeNameRightPosition, setInitiativeNameRightPosition] =
    useState(0);
  const [objectiveRightPosition, setObjectiveRightPosition] = useState(0);
  const [isOverflowHidden, setIsOverflowHidden] = useState(true);

  const handleMouseEnter = () => {
    debouncedHandleMouseLeave.cancel();
    setIsOverflowHidden(false);
  };

  const debouncedHandleMouseLeave = debounce(
    () => setIsOverflowHidden(true),
    2000,
  );

  const horizontalPosition = useMemo(() => {
    const leftPosition = left <= 0 ? 0 : left;
    return leftPosition;
  }, [left]);

  const isLink = contextObjective.id !== initiative.objective?.id;

  const canRead = canPerformStrategyElementAction(initiative, 'READ');

  const navigationTarget = objectiveRoutesPaths.initiatives.initiative.root({
    params: {
      teamSlug: teamAdapter.toParam(),
      initiativeId: initiative.id,
      objectiveId: contextObjective.id,
    },
  });

  const isExpandable = containerRightPosition < initiativeNameRightPosition;
  const isObjectiveExpandable = containerRightPosition < objectiveRightPosition;

  return (
    <InitiativeChipContainer
      className={className}
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={debouncedHandleMouseLeave}
      onClick={() => contextObjective && canRead && navigate(navigationTarget)}
      $hasLink={canRead}
      $leftPosition={horizontalPosition}
      $isExpandable={isExpandable}
      $isObjectiveExpandable={isObjectiveExpandable}
    >
      <InitiativeChipContent $isExpandable={isExpandable}>
        <InitiativeChipStatusMark
          statusIndicator={initiative?.currentInitiativeStatus?.statusIndicator}
          hasBorder={false}
        />
        <InitiativeChipNameRef
          direction={'vertical'}
          size={'tiny'}
          ref={(el) => {
            if (el) {
              initiativeChipNameRef.current = el;
              setContainerRightPosition(el.getBoundingClientRect().right);
              containerRef.current?.style.setProperty(
                '--width',
                `${el.getBoundingClientRect().width}px`,
              );
            }
          }}
        >
          <InitiativeChipNameText $isOverflowHidden={isOverflowHidden}>
            <InitiativeChipNameType
              variant={'strong'}
              isUppercase={true}
              ellipsis={true}
              whiteSpace={'noWrap'}
              ref={(el) => {
                containerRef.current?.style.setProperty(
                  '--initiative-label-width',
                  `${el?.getBoundingClientRect().width}px`,
                );
              }}
            >
              {capitalize(t('initiative.initiative'))}
            </InitiativeChipNameType>
            <LockedLink
              isActive={canRead}
              to={navigationTarget}
              isInline={true}
              decorationOnHover={false}
            >
              <InitiativeChipName
                size={'medium'}
                ellipsis={true}
                whiteSpace={'noWrap'}
                ref={(el) => {
                  if (el) {
                    setInitiativeNameRightPosition(
                      el.getBoundingClientRect().right,
                    );
                    containerRef.current?.style.setProperty(
                      '--initiative-text-width',
                      `${el.getBoundingClientRect().width + gapWidth}px`,
                    );
                  }
                }}
              >
                {initiative.name}
              </InitiativeChipName>
            </LockedLink>
          </InitiativeChipNameText>
          <InitiativeChipObjectiveLink
            isObjectiveExpandable={isObjectiveExpandable}
          >
            {isLink ? <StyledLinkIcon /> : <StyledChevronRightIcon />}
            <InitiativeChipObjectiveLinkContent
              ellipsis={true}
              whiteSpace={'noWrap'}
              ref={(el) => {
                if (el) {
                  setObjectiveRightPosition(el.getBoundingClientRect().right);
                  el.parentElement?.style.setProperty(
                    '--objective-link-width',
                    `${
                      el.getBoundingClientRect().width +
                      linkIconWidth +
                      gapWidth
                    }px`,
                  );
                }
              }}
            >
              {contextObjective.name}
            </InitiativeChipObjectiveLinkContent>
          </InitiativeChipObjectiveLink>
        </InitiativeChipNameRef>
      </InitiativeChipContent>
    </InitiativeChipContainer>
  );
};

export default InitiativeChip;
