import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useTeamAdapter } from 'team/TeamAdapter';
import Form from 'shared/form/Form';
import type {
  CampaignInput,
  OrgUnitAuthorizedAction,
} from 'types.graphql.generated';
import { date } from 'shared/services/date.service';
import { useToasts } from 'shared/toast/useToasts';
import { WalkOfStrategyCampaignsDocument } from 'toolkit/WalkOfStrategyCampaigns/WalkOfStrategyCampaigns.graphql.generated';
import useHandleError from 'shared/errors/useHandleError';
import { dateBefore1900Validation } from 'shared/utils/timeLine.utils';

import { useSaveWalkOfStrategyCampaignMutation } from './SaveWalkOfStrategyCampaign.graphql.generated';
import CampaignFields from './CampaignFields';

export type CampaignFormValues = {
  deadline?: Date;
  endDate?: Date;
  id?: string;
  name: string;
  owner?: string;
  startDate?: Date;
  strategy?: {
    id: string;
    orgUnit?: {
      currentUserAuthorizedActions: OrgUnitAuthorizedAction[];
      id: string;
      name?: string;
    };
  };
};

type Props = {
  initialValues: CampaignFormValues;
  onSuccess: () => void;
};

export const campaignFormId = 'campaign';

const CampaignForm = ({ initialValues, onSuccess }: Props) => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();
  const { addToast } = useToasts();

  const handleApolloError = useHandleError();

  const [saveCampaign] = useSaveWalkOfStrategyCampaignMutation({
    onError: handleApolloError,
    refetchQueries: initialValues.id ? [] : [WalkOfStrategyCampaignsDocument],
  });

  const handleSubmit = async (values: CampaignFormValues) => {
    const input = {
      ...(values.id && {
        idToUpdate: values.id,
      }),
      orgKey: teamAdapter.orgKey,
      strategy: {
        idToSet: values.strategy?.id,
      },
      name: values.name,
      timeLine: {
        startDate: values.startDate
          ? date.format(values.startDate, 'yyyy-MM-dd')
          : null,
        endDate: values.endDate
          ? date.format(values.endDate, 'yyyy-MM-dd')
          : null,
      },
      deadline: values.deadline
        ? date.format(values.deadline, 'yyyy-MM-dd')
        : null,
      owner: { emailToSet: values.owner },
    } satisfies CampaignInput;

    await saveCampaign({ variables: { input } });

    addToast({
      variant: 'success',
      children: t('toolkit.toolPage.walkOfStrategy.campaignForm.toast.success'),
    });

    onSuccess();
  };

  const validationSchema = yup.object({
    strategy: yup.object().required(),
    name: yup.string().required(),
    startDate: dateBefore1900Validation(t).required(),
    owner: yup.string().email().required(),
  });

  return (
    <Form<CampaignFormValues>
      id={campaignFormId}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      autoFocus={{ isEnabled: false }}
    >
      <CampaignFields />
    </Form>
  );
};

export default CampaignForm;
