import ActiveOrgProvider from 'org/ActiveOrgProvider';
import SetActiveOrgLanguage from 'org/SetActiveOrgLanguage';
import TeamLayout from 'team/TeamLayout';
import NavigateToTeamRoot from 'team/NavigateToTeamRoot';
import OrgUnitProvider from 'orgUnit/OrgUnitProvider';
import OrgTermsProvider from 'org/OrgTermsProvider';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import UserProvider from 'user/UserProvider';

const TeamsRoot = () => (
  <ActiveOrgProvider>
    <OrgTermsProvider>
      <UserProvider>
        <OrgUnitProvider
          pathsToSkipOrgUnitAccessCheck={[objectiveRoutesPaths.root()]}
        >
          <SetActiveOrgLanguage>
            <TeamLayout>
              <NavigateToTeamRoot />
            </TeamLayout>
          </SetActiveOrgLanguage>
        </OrgUnitProvider>
      </UserProvider>
    </OrgTermsProvider>
  </ActiveOrgProvider>
);

export default TeamsRoot;
