import { useTranslation } from 'react-i18next';

import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import { useObjectiveDetails } from 'objective/ObjectiveDetails/ObjectiveDetailsProvider';
import useHandleError from 'shared/errors/useHandleError';
import useDialogState from 'shared/hooks/useDialogState';
import IconButton from 'shared/components/IconButton';
import { useToasts } from 'shared/toast/useToasts';
import Dialog from 'shared/components/Dialog';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';

import { useUnlinkObjectiveFromOrgUnitMutation } from './LinkedOrgUnitsSection.graphql.generated';

type Props = {
  orgUnitId: string;
};

const UnlinkObjectiveFromOrgUnitButton = ({ orgUnitId }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { isOpen, onOpen, onClose } = useDialogState();

  const { objective } = useObjectiveDetails();

  const onError = useHandleError();

  const [unlinkObjective, { loading }] = useUnlinkObjectiveFromOrgUnitMutation({
    variables: {
      objectiveId: objective?.id || '',
      orgUnitId: orgUnitId,
    },
    onCompleted: () => {
      addToast({
        variant: 'success',
        children: t(
          'objective.objectiveLayout.sider.linkedOrgUnits.removeDialog.success',
        ),
      });
      onClose();
    },
    onError,
  });

  const canUnlink =
    objective && canPerformStrategyElementAction(objective, 'WRITE');

  if (!canUnlink) return null;

  return (
    <>
      <IconButton icon={TrashIcon} onClick={onOpen}>
        {t('remove')}
      </IconButton>

      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        confirmLabel={t('confirm')}
        cancelLabel={t('cancel')}
        size={'tiny'}
        onConfirm={unlinkObjective}
        isConfirmDisabled={loading}
      >
        {t(
          'objective.objectiveLayout.sider.linkedOrgUnits.removeDialog.content',
        )}
      </Dialog>
    </>
  );
};

export default UnlinkObjectiveFromOrgUnitButton;
