import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { css, useTheme } from '@emotion/react';

import StrategyOperationalItemRow from 'strategy/StrategyOperationalItemRow';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import Result from 'shared/components/Result';
import { ReactComponent as EmptyIllustration } from 'shared/static/illustrations/illustration-empty-state.svg';
import Space from 'shared/components/Space';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import Flex from 'shared/components/Flex';

import { useTeamInsightsOverview } from '../TeamInsightsOverviewProvider';
import NestedRows from '../TeamInsightsOverviewOperationalItemsNestedRows';
import { hasChildren } from './TeamInsightsOverviewOperationalItems.utils';

type TeamInsightsOverviewOperationalItemsProps = PropsWithChildren<object>;

const TeamInsightsOverviewOperationalItems = ({
  children,
}: TeamInsightsOverviewOperationalItemsProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { teamAdapter } = useTeamAdapter();

  const { filteredObjectives } = useTeamInsightsOverview();

  const isEmpty = filteredObjectives.length === 0;

  if (isEmpty) {
    return (
      <Space isCentered={true}>
        <Result
          description={t('noMatchingResults')}
          illustration={EmptyIllustration}
          hasBackground={true}
        />
      </Space>
    );
  }

  return (
    <Flex
      direction={'column'}
      alignItems={'stretch'}
      gap={28}
      css={css`
        align-items: stretch;
        border: 1px solid ${theme.color.strokeMedium};
        border-radius: 12px;
        width: 100%;
      `}
    >
      {children}

      <Flex direction={'column'} css={css({ margin: '0 28px 28px 28px' })}>
        {filteredObjectives.map((objective) => (
          <StrategyOperationalItemRow
            isDisabled={!objective.isFiltersMatch}
            date={objective.currentObjectiveStatus?.statusDateTime}
            dateTooltip={t('status.form.date.label')}
            statusIndicator={objective.currentObjectiveStatus?.statusIndicator}
            titleItem={{
              linkTo: objectiveRoutesPaths.root({
                params: {
                  teamSlug: teamAdapter.toParam(),
                  objectiveId: objective.id,
                },
              }),
              text: objective.name,
              isLocked: !canPerformStrategyElementAction(objective, 'READ'),
            }}
            user={objective.owner}
            typename={objective.__typename}
            key={objective.id}
            isLink={false}
            orgUnit={objective.orgUnit}
          >
            {hasChildren(objective) ? (
              <NestedRows objective={objective} />
            ) : null}
          </StrategyOperationalItemRow>
        ))}
      </Flex>
    </Flex>
  );
};

export default TeamInsightsOverviewOperationalItems;
