import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { groupBy } from 'lodash';

import CompactModal from 'shared/components/__DEPRECATED__/CompactModal/CompactModal';
import Button from 'shared/components/Button';
import Space from 'shared/components/Space';
import Text from 'shared/components/Text';
import Select from 'shared/components/Select/Select';
import { useToasts } from 'shared/toast/useToasts';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useUpdateObjectiveMutation } from 'objective/EditObjective/UpdateObjective.graphql.generated';
import useHandleError from 'shared/errors/useHandleError';

import { useObjectiveSelection } from './ObjectiveSelctionProvider';
import type { StrategyRoomManageObjectivesThemeFragment } from './StrategyRoomThemesManageObjectives.graphql.generated';
import { StrategyRoomThemesManageObjectivesDocument } from './StrategyRoomThemesManageObjectives.graphql.generated';

type ThemeOption = {
  id: string;
  title: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  themes: StrategyRoomManageObjectivesThemeFragment[];
};

const ChangeThemeModal = ({ themes, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { activeOrg } = useActiveOrg();

  const { selectedObjectives, clearSelectedObjectives } =
    useObjectiveSelection();

  const noThemeOption = useMemo(
    () => ({
      id: 'no-theme',
      title: t('strategy.strategyRoom.themes.changeTheme.noTheme'),
    }),
    [t],
  );

  const handleApolloError = useHandleError();

  const [updateObjective] = useUpdateObjectiveMutation({
    onError: handleApolloError,
    refetchQueries: [StrategyRoomThemesManageObjectivesDocument],
  });

  const [selectedTheme, setSelectedTheme] = useState<ThemeOption | undefined>(
    noThemeOption,
  );

  const updateObjectives = async () => {
    const result = await Promise.all(
      selectedObjectives.map((objective) =>
        updateObjective({
          variables: {
            input: {
              idToUpdate: objective.id,
              theme: {
                ...(selectedTheme === noThemeOption
                  ? { idToRemove: objective.theme?.id }
                  : { idToSet: selectedTheme?.id }),
              },
            },
          },
        }),
      ),
    );

    const isSuccess = result.every((result) => !result.errors);

    if (isSuccess) {
      addToast({
        children: t('strategy.strategyRoom.themes.changeTheme.toast.success', {
          count: selectedObjectives.length,
        }),
        variant: 'success',
      });
      clearSelectedObjectives();
    } else {
      addToast({
        children: t('strategy.strategyRoom.themes.changeTheme.toast.error', {
          count: selectedObjectives.length,
        }),
        variant: 'error',
      });
    }

    onClose();
  };

  const activeThemes = themes.filter((theme) => theme.isActive);

  const orgName = activeOrg.displayName || activeOrg.domainNames[0];

  const themesByOrgUnit = groupBy(
    activeThemes,
    (theme) => theme.orgUnit?.name || orgName,
  );

  const orgUnitOptions = Object.entries(themesByOrgUnit).map(
    ([orgUnit, themes]) => ({
      id: orgUnit,
      title: orgUnit,
      children: themes.map((theme) => ({
        id: theme.id,
        title: theme.name || '',
      })),
    }),
  );

  const options = [noThemeOption, ...orgUnitOptions];

  return (
    <CompactModal
      heading={t('strategy.strategyRoom.themes.changeTheme.heading')}
      isOpen={isOpen}
      onClose={onClose}
      renderFooter={() => (
        <CompactModal.Controls>
          <Button
            type={'submit'}
            variant={'contained'}
            onPress={updateObjectives}
          >
            {t('confirm')}
          </Button>
          <Button color={'mono'} variant={'outlined'} onPress={onClose}>
            {t('cancel')}
          </Button>
        </CompactModal.Controls>
      )}
    >
      <Space direction={'vertical'}>
        <Space>
          <Text variant={'emphasis'}>
            {t('strategy.strategyRoom.themes.changeTheme.content', {
              count: selectedObjectives.length,
            })}
          </Text>
        </Space>
        <Space>
          <Select<ThemeOption>
            items={options}
            value={selectedTheme}
            onChange={setSelectedTheme}
            triggerStyle={css({ minWidth: 200 })}
          />
        </Space>
      </Space>
    </CompactModal>
  );
};

export default ChangeThemeModal;
