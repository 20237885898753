import { css } from '@emotion/react';

import FiltersButton from 'shared/components/FiltersButton';
import CalendarControls from 'shared/components/CalendarControls';
import Flex from 'shared/components/Flex';
import CompletedFilterButton from 'shared/components/CompletedFilterButton';
import SendMultipleStatusRequestButton from 'status/SendMultipleStatusRequestsButton';

import useChipGroupItems from '../TeamInsightsOverviewFiltersChipGroup/useChipGroupItems';
import TeamInsightsCreateReportButton from '../TeamInsightsCreateReportButton';
import TeamInsightsOverviewFilters from '../TeamInsightsOverviewFilters';
import TeamInsightsOverviewAskSophieButton from './TeamInsightsOverviewAskSophieButton';
import { useTeamInsightsOverview } from '../TeamInsightsOverviewProvider';

const TeamInsightsOverviewControls = () => {
  const {
    filteredObjectives,
    filters: { clearFilters },
  } = useTeamInsightsOverview();

  const { length: selectionCount } = useChipGroupItems();

  const statusRequestElements = [
    ...filteredObjectives.filter(
      (objective) =>
        objective.isFiltersMatch && !objective.currentObjectiveStatus?.complete,
    ),
    ...filteredObjectives
      .flatMap((objective) => objective.metrics)
      .filter((metric) => !metric.currentMetricStatus?.complete),
    ...filteredObjectives
      .flatMap((objective) => objective.initiatives)
      .filter((initiative) => !initiative.currentInitiativeStatus?.complete),
  ];

  return (
    <>
      <CalendarControls />

      <Flex gap={8} css={css({ flex: '1', justifyContent: 'flex-end' })}>
        <CompletedFilterButton />

        <FiltersButton
          clearFilters={clearFilters}
          selectionCount={selectionCount}
        >
          <TeamInsightsOverviewFilters />
        </FiltersButton>

        <TeamInsightsOverviewAskSophieButton />
        <SendMultipleStatusRequestButton
          strategyElements={statusRequestElements}
        />
        <TeamInsightsCreateReportButton />
      </Flex>
    </>
  );
};

export default TeamInsightsOverviewControls;
