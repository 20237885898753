import type { ReactNode } from 'react';
import { useMemo, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { ReactComponent as CalendarIcon } from 'shared/static/icons/icon-calendar.svg';
import Space from 'shared/components/Space';
import Text from 'shared/components/Text';
import { ReactComponent as LinkIcon } from 'shared/static/icons/icon-link.svg';
import StatusMark from 'shared/status/StatusMark/StatusMark';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import UserAvatar from 'user/UserAvatar/UserAvatar';
import { date } from 'shared/services/date.service';
import { TeamAdapter } from 'team/TeamAdapter';
import type { Org } from 'types.graphql.generated';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import Flex from 'shared/components/Flex';
import LockedLink from 'shared/components/LockedLink';
import { transientOptions } from 'shared/utils/emotion.utils';
import TeamAvatar from 'team/TeamAvatar/TeamAvatar';

import type { ObjectiveRelatedObjectivesObjectiveFragment } from '../ObjectiveRelatedObjectivesProvider/ObjectiveRelatedObjectivesProvider.graphql.generated';

const Container = styled('div', transientOptions)<{ $isHighlighted: boolean }>`
  display: flex;
  gap: 1em;
  align-items: center;
  height: 100%;
  padding-left: 1rem;
  position: relative;
  user-select: none;
  width: 100%;
  z-index: 1;
  background-color: ${(props) =>
    props.$isHighlighted ? props.theme.color.backgroundDark : 'transparent'};
  border-radius: 8px;
`;

const Header = styled(Space)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ChildObjectives = styled(Text)`
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  gap: 0.5em;
`;

const Metadata = styled(Space)`
  color: ${(props) => props.theme.color.typoSecondary};
`;

const DateContainer = styled(Space)`
  align-items: center;
`;

const DateIcon = styled(CalendarIcon)`
  display: inline-block;
  flex-basis: 14px;
  margin-right: 6px;
  min-height: 14px;
  min-width: 14px;
`;

const LinkIconStyled = styled(LinkIcon)`
  height: 1em;
  width: 1em;
`;

const ObjectiveNameText = styled(Text, transientOptions)<{ $isBold: boolean }>`
  font-weight: ${(props) => (props.$isBold ? 'bold' : 'normal')};
`;

type ObjectiveRelatedObjectivesRowProps = {
  dropdownButton: ReactNode;
  isHighlighted?: boolean;
  objective: ObjectiveRelatedObjectivesObjectiveFragment;
  orgKey: Org['orgKey'];
};

const ObjectiveRelatedObjectivesRow = ({
  objective,
  dropdownButton,
  isHighlighted = false,
  orgKey,
}: ObjectiveRelatedObjectivesRowProps) => {
  const { t } = useTranslation();

  const teamAdapter = useMemo(() => {
    if (objective.orgUnit) {
      return TeamAdapter.fromOrgUnit(objective.orgUnit, orgKey);
    }
    return TeamAdapter.fromOrg({ orgKey });
  }, [objective, orgKey]);

  const renderRowContent = useMemo(
    () =>
      objective.childObjectives.length ? (
        <>
          <LinkIconStyled />
          <Trans
            i18nKey={'objective.relatedObjectives.relatedObjectivesCount'}
            values={{
              relatedObjectives: t('objective.childObjective', {
                count: objective.childObjectives.length,
              }),
              count: objective.childObjectives.length,
            }}
          />
        </>
      ) : (
        t('objective.noChildObjectives')
      ),
    [objective, t],
  );

  const { startDate, endDate } = useMemo(() => {
    const startDate = objective.timeLine.startDate
      ? date.format(objective.timeLine.startDate, 'dd MMM yyyy')
      : t('notSet');
    const endDate = objective.timeLine.endDate
      ? date.format(objective.timeLine.endDate, 'dd MMM yyyy')
      : t('notSet');

    return { startDate, endDate };
  }, [objective, t]);

  const canReadObjective = canPerformStrategyElementAction(objective, 'READ');
  const objectiveLink = objectiveRoutesPaths.root({
    params: { teamSlug: teamAdapter.toParam(), objectiveId: objective.id },
  });

  return (
    <Container $isHighlighted={isHighlighted}>
      <StatusMark
        statusIndicator={objective.currentObjectiveStatus?.statusIndicator}
        hasBorder={false}
      />
      <Header direction={'horizontal'} size={'custom'} value={0}>
        <Space direction={'vertical'} size={'custom'} value={0}>
          <Flex alignItems={'center'} gap={8}>
            <LockedLink to={objectiveLink} isActive={canReadObjective}>
              <ObjectiveNameText
                variant={'emphasis'}
                ellipsis={true}
                $isBold={isHighlighted}
              >
                {objective.name}
              </ObjectiveNameText>
            </LockedLink>

            {dropdownButton}
          </Flex>
          <ChildObjectives>{renderRowContent}</ChildObjectives>
        </Space>
        <Metadata direction={'horizontal'} size={'custom'} value={12}>
          <DateContainer direction={'horizontal'} size={'custom'}>
            <DateIcon />
            <Text>{`${startDate} - ${endDate}`}</Text>
          </DateContainer>
          <TeamAvatar orgUnit={objective.orgUnit} size={'tiny'} />
          <UserAvatar user={objective.owner} />
        </Metadata>
      </Header>
    </Container>
  );
};

export default memo(ObjectiveRelatedObjectivesRow);
