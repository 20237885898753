import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnlinkInitiativeMutationVariables = Types.Exact<{
  objectiveId: Types.Scalars['ID'];
  initiativeId: Types.Scalars['ID'];
}>;


export type UnlinkInitiativeMutation = { __typename: 'Mutation', updateObjective: { __typename: 'Objective', id: string, initiatives: Array<{ __typename: 'Initiative', id: string }> } };


export const UnlinkInitiativeDocument = gql`
    mutation UnlinkInitiative($objectiveId: ID!, $initiativeId: ID!) {
  updateObjective(
    input: {idToUpdate: $objectiveId, initiativeLinks: {idsToRemove: [$initiativeId]}}
  ) {
    id
    initiatives {
      id
    }
  }
}
    `;
export type UnlinkInitiativeMutationFn = Apollo.MutationFunction<UnlinkInitiativeMutation, UnlinkInitiativeMutationVariables>;

/**
 * __useUnlinkInitiativeMutation__
 *
 * To run a mutation, you first call `useUnlinkInitiativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkInitiativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkInitiativeMutation, { data, loading, error }] = useUnlinkInitiativeMutation({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *      initiativeId: // value for 'initiativeId'
 *   },
 * });
 */
export function useUnlinkInitiativeMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkInitiativeMutation, UnlinkInitiativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlinkInitiativeMutation, UnlinkInitiativeMutationVariables>(UnlinkInitiativeDocument, options);
      }
export type UnlinkInitiativeMutationHookResult = ReturnType<typeof useUnlinkInitiativeMutation>;
export type UnlinkInitiativeMutationResult = Apollo.MutationResult<UnlinkInitiativeMutation>;
export type UnlinkInitiativeMutationOptions = Apollo.BaseMutationOptions<UnlinkInitiativeMutation, UnlinkInitiativeMutationVariables>;