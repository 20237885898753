import type { TFunction } from 'react-i18next';

import type { Objective, SetRemoveIdInput } from 'types.graphql.generated';

import type { SelectObjectiveInputFieldOption } from './SelectObjectiveInput.type';

export const getOptionLabel = (
  t: TFunction,
  option: SelectObjectiveInputFieldOption,
): string => option.value?.name ?? t('objective.noObjective');

export const resolveInitialValue = (
  objective?: Maybe<Pick<Objective, 'id' | 'name'>>,
): SelectObjectiveInputFieldOption => {
  if (objective) {
    return {
      value: {
        name: objective.name,
        id: objective.id,
      },
    };
  }
  return {
    value: null,
  };
};

export const resolveSetRemoveIdInput = (params: {
  option: SelectObjectiveInputFieldOption;
  previousObjective?: Pick<Objective, 'id' | 'name'>;
}): SetRemoveIdInput => {
  const { option, previousObjective } = params;
  if (option.value) {
    return {
      idToSet: option.value.id,
    };
  }
  if (!option.value && previousObjective) {
    return {
      idToRemove: previousObjective.id,
    };
  }
  return {};
};
