import { useState } from 'react';

import Calendar from 'shared/components/Calendar';
import StrategyInsightsCalendarView from 'strategy/StrategyInsights/StrategyInsightsCalendarView';
import { date } from 'shared/services/date.service';

import { useInitiativesListObjectives } from '../InitiativesContentProvider/useInitiativesContent';

const InitiativesContentCalendar = () => {
  const [year, setYear] = useState(date.getYear(new Date()));

  const { allObjectives } = useInitiativesListObjectives();

  if (!allObjectives) return null;

  return (
    <>
      <Calendar.Controls year={year} onYearChange={setYear} />
      <StrategyInsightsCalendarView year={year} objectives={allObjectives} />
    </>
  );
};

export default InitiativesContentCalendar;
