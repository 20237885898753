import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import ListView from 'shared/components/ListView';
import CreateUserButton from 'user/CreateUserButton';
import Flex from 'shared/components/Flex';
import Text from 'shared/components/Text';
import Spinner from 'shared/spinner/Spinner';
import Space from 'shared/components/Space';
import Result from 'shared/components/Result';
import { ReactComponent as LookingAtCardBoardBoxIllustration } from 'shared/static/illustrations/illustration-looking-at-cardboard-box.svg';

import TeamMembersAddMemberButton from '../TeamMembersAddMemberButton';
import { useTeamMembers } from '../TeamMembersProvider';
import TeamMembersListViewRow from '../TeamMembersListViewRow';
import type { TeamMembersUserFragment } from '../TeamMembersProvider/TeamMembersProvider.graphql.generated';

const TeamMembersListView = () => {
  const { t } = useTranslation();
  const { users, orgUnit } = useTeamMembers();

  if (!users) return <Spinner.Circle />;

  if (users.length === 0) {
    return (
      <Space isCentered={true}>
        <Result
          description={t('team.teamMembers.noMembers')}
          illustration={LookingAtCardBoardBoxIllustration}
          hasBackground={true}
          after={
            orgUnit ? <TeamMembersAddMemberButton /> : <CreateUserButton />
          }
        />
      </Space>
    );
  }

  return (
    <ListView<TeamMembersUserFragment>
      items={users}
      Row={(rowProps) => <TeamMembersListViewRow {...rowProps} />}
      before={
        <Flex
          justifyContent={'space-between'}
          alignItems={'center'}
          css={css`
            padding: 0.75rem 0;
          `}
        >
          <Text size={'large'} color={'black'} css={css({ fontSize: 21 })}>
            {t('team.teamMembers.title')}
          </Text>
          {orgUnit ? <TeamMembersAddMemberButton /> : <CreateUserButton />}
        </Flex>
      }
    />
  );
};

export default TeamMembersListView;
