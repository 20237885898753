import styled from '@emotion/styled';
import type { FocusEventHandler, HTMLInputTypeAttribute } from 'react';
import { Input } from 'react-aria-components';

import { transientOptions } from 'shared/utils/emotion.utils';
import { ReactComponent as CircleAlertIcon } from 'shared/static/icons/icon-circle-alert.svg';

const widthBySize = {
  tiny: '40px',
  full: '100%',
};

type InputSize = keyof typeof widthBySize;

const Container = styled.div`
  position: relative;
`;

const ErrorIconContainer = styled.div`
  position: absolute;
  top: 9px;
  right: 9px;

  svg {
    color: ${(props) => props.theme.color.error};
  }
`;

const StyledInput = styled(Input, transientOptions)<{
  $hasError: boolean;
  $size?: InputSize;
}>`
  border: 1px solid
    ${(props) =>
      props.$hasError ? props.theme.color.error : props.theme.color.strokeDark};
  border-radius: 12px;
  height: 42px;
  padding: 0.75rem;
  width: ${(props) => widthBySize[props.$size || 'full']};

  &[aria-invalid='true'] {
    background-color: ${(props) => props.theme.color.errorLight};
  }

  &[data-focused='true'] {
    border: 1px solid ${(props) => props.theme.color.black};
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px ${(props) => props.theme.color.primary};
  }
`;

type Props = {
  disabled?: boolean;
  hasError?: boolean;
  id?: string;
  max?: string;
  maxLength?: number;
  min?: string;
  name?: string;
  onBlur: FocusEventHandler<HTMLInputElement>;
  onChange: (value: string | number) => void;
  placeholder?: string;
  size?: InputSize;
  type?: HTMLInputTypeAttribute;
  value?: string;
};

const TextInput = ({
  id,
  name,
  value,
  placeholder,
  type,
  maxLength,
  min,
  max,
  disabled,
  hasError = false,
  size,
  onChange,
  onBlur,
}: Props) => {
  return (
    <Container>
      <StyledInput
        id={id || name}
        name={name}
        value={value}
        placeholder={placeholder}
        type={type}
        maxLength={maxLength}
        min={min}
        max={max}
        disabled={disabled}
        aria-invalid={hasError}
        onChange={(event) => {
          const value = event.target.value;

          const convertedValue =
            type === 'number' && value !== '' ? Number(value) : value;

          onChange(convertedValue);
        }}
        onBlur={onBlur}
        $size={size}
        $hasError={hasError}
      />
      {hasError && size === 'full' && (
        <ErrorIconContainer>
          <CircleAlertIcon />
        </ErrorIconContainer>
      )}
    </Container>
  );
};

export default TextInput;
