import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';
import { useState } from 'react';
import type { TFuncKey } from 'react-i18next';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

import OrgTermAlsoKnownAs from 'org/OrgTermAlsoKnownAs';

import Emoji from '../Emoji';
import Drawer from '../Drawer';
import Button from '../Button';
import InfoCardHeader from './InfoCardHeader';
import SideCard from '../SideCard';

const Content = styled.div`
  ul,
  ol {
    padding-left: 20px;
  }

  li {
    margin-bottom: 5px;
  }

  *:first-of-type {
    margin-top: 0;
  }
`;

type Props = PropsWithChildren<{
  contentKey: TFuncKey;
  isSidesheetOpen?: boolean;
  onLearnMorePress?: () => void;
  orgTermKey?: TFuncKey;
  setIsSidesheetOpen?: (isSidesheetOpen: boolean) => void;
  sideSheetTitle?: string;
  title: string;
}>;

const InfoCard = ({
  title,
  orgTermKey,
  contentKey,
  sideSheetTitle,
  isSidesheetOpen: isSidesheetOpenProp,
  setIsSidesheetOpen: setIsSidesheetOpenProp,
  onLearnMorePress,
  children,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isSidesheetsOpenState, setIsSidesheetOpenState] = useState(false);

  const isSidesheetsOpen =
    isSidesheetOpenProp === undefined
      ? isSidesheetsOpenState
      : isSidesheetOpenProp;

  const setIsSidesheetOpen =
    setIsSidesheetOpenProp === undefined
      ? setIsSidesheetOpenState
      : setIsSidesheetOpenProp;

  const translation: string | string[] = t(contentKey, { returnObjects: true });

  const isArray = Array.isArray(translation);

  const i18nKey = isArray ? undefined : contentKey;

  const defaults = isArray ? translation.join('') : undefined;

  return (
    <>
      <SideCard
        title={title}
        backgroundColor={theme.legacyColor.colorAlabaster}
        iconBackgroundColor={theme.legacyColor.colorDarkGrey}
        icon={<Emoji icon={'🎓'} />}
      >
        <Content>
          {orgTermKey && <OrgTermAlsoKnownAs i18nKey={orgTermKey} />}

          <Trans
            i18nKey={i18nKey}
            defaults={defaults}
            components={{
              b: <b />,
              i: <i />,
              p: <p />,
              h3: <InfoCardHeader />,
              ul: <ul />,
              ol: <ol />,
              li: <li />,
            }}
          />
        </Content>

        {children && (
          <Button
            variant={'outlined'}
            onPress={() => {
              onLearnMorePress?.();
              setIsSidesheetOpen(true);
            }}
          >
            {t('learnMore')}
          </Button>
        )}
      </SideCard>

      {children && (
        <Drawer
          heading={sideSheetTitle}
          width={'medium'}
          padding={'none'}
          isOpen={isSidesheetsOpen}
          onClose={() => setIsSidesheetOpen(false)}
          scrollbarGutter={'auto'}
          contentScrollbarGutter={'stable'}
        >
          {children}
        </Drawer>
      )}
    </>
  );
};

export default InfoCard;
