/* eslint-disable i18next/no-literal-string */
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Space from 'shared/components/Space';
import Heading from 'shared/components/Heading';
import EmojiIcon from 'shared/components/EmojiIcon/EmojiIcon';
import { useTeamAdapter } from 'team/TeamAdapter';
import Button from 'shared/components/Button/Button';
import { teamRoutesPaths } from 'team/team.routing.paths';

import styles from './NoStrategyCreateStrategyCard.module.scss';

const NoStrategyCreateStrategyCard = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { teamAdapter } = useTeamAdapter();

  const handleButtonClick = () => {
    navigate(
      teamRoutesPaths.strategyRoom({
        params: {
          teamSlug: teamAdapter.toParam(),
        },
      }),
    );
  };

  return (
    <Space isCentered={true} direction={'vertical'} className={styles.card}>
      <EmojiIcon emoji={'⛰️'} />
      <Heading level={3} hasMargin={false}>
        {t('strategy.noStrategy.heading')}
      </Heading>
      <p className={styles.description}>
        {t('strategy.noStrategy.description')}
      </p>
      <Button onPress={handleButtonClick}>
        {t('strategy.strategyRoom.openStrategyRoom')}
      </Button>
    </Space>
  );
};

export default NoStrategyCreateStrategyCard;
