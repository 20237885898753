import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import Tag from 'shared/components/Tag';
import Flex from 'shared/components/Flex';
import type { ListBoxRowComponentProps } from 'shared/components/ListBox';

import type { SelectUserTenantInputOption } from './SelectUserTenantInput.type';

type Props = ListBoxRowComponentProps<SelectUserTenantInputOption>;

export const SelectUserTenantInputRow = ({ item }: Props) => {
  const { t } = useTranslation();

  const name = item.value.displayName || item.value.domainNames[0];

  return item.value.isLive ? (
    <>{name}</>
  ) : (
    <Flex gap={5} css={css({ width: '100%' })}>
      <Flex
        grow={1}
        css={css({
          flexShrink: 1,
          overflow: 'hidden',
        })}
      >
        {name}
      </Flex>
      <Flex>
        <Tag variant={'danger'} size={'tiny'}>
          {t('tenantSelect.notActive')}
        </Tag>
      </Flex>
    </Flex>
  );
};

export default SelectUserTenantInputRow;
