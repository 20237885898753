import type { ApolloError } from '@apollo/client';
import type { GraphQLError } from 'graphql';
import { isArray, isObject } from 'lodash';
import type { TFunction } from 'react-i18next';

import { isServerError } from 'shared/utils/apollo.utils';
import { enumerate } from 'shared/utils/string.utils';

export const findGraphQLErrorByErrorCode = (params: {
  apolloError: ApolloError;
  errorCode: string;
}): Maybe<GraphQLError> => {
  const { errorCode, apolloError } = params;
  return apolloError.graphQLErrors.find(
    (graphqlError) => graphqlError.extensions.errorCode === errorCode,
  );
};

export const isServerErrorCode = (error: ApolloError, statusCode: number) =>
  isServerError(error.networkError) &&
  error.networkError.statusCode === statusCode;

export const getCurrentGraphQLError = (error: ApolloError) =>
  error.graphQLErrors?.length > 0 ? error.graphQLErrors[0] : undefined;

export const getGraphQLErrorCode = (error: GraphQLError) =>
  error.extensions ? error.extensions.errorCode : undefined;

export const isGraphqlError = (error: unknown): error is GraphQLError =>
  isObject(error) && 'extensions' in error;

export const getGraphqlErrorMessage = (t: TFunction, error: GraphQLError) => {
  const errorCode = getGraphQLErrorCode(error);

  if (!errorCode) return t(`errorMessage.unknown`);

  const hasErrorCodeMessage = (
    t('errorMessage', { returnObjects: true }) satisfies Record<string, string>
  )[errorCode];

  if (!hasErrorCodeMessage) return t(`errorMessage.unknown`);

  const translatedExtensions = Object.fromEntries(
    Object.entries(error.extensions).map(([key, value]) => [
      key,
      isArray(value)
        ? enumerate(
            t,
            value.map((eachValue) => translateExtension(t, eachValue)),
            false,
            true,
          ).join('')
        : translateExtension(t, value),
    ]),
  );

  return t(`errorMessage.${errorCode}`, translatedExtensions);
};

const translateExtension = (t: TFunction, extensionValue: unknown) =>
  t(`errorExtension.${extensionValue}`) || extensionValue;
