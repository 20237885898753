import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { InitiativesContentObjectiveFragmentDoc } from '../../InitiativesContent/InitiativesContentProvider/InitiativesContent.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ObjectiveInitiativesQueryVariables = Types.Exact<{
  objectiveId: Types.Scalars['ID'];
}>;


export type ObjectiveInitiativesQuery = { __typename: 'Query', objective: { __typename: 'Objective', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, isCurrentUserOwner: boolean, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, complete: boolean } | undefined, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined, completeDateTime?: any | undefined }, initiatives: Array<{ __typename: 'Initiative', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, isCurrentUserOwner: boolean, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, currentInitiativeStatus?: { __typename: 'InitiativeStatus', id: string, complete: boolean, statusIndicator: StatusIndicatorClass, keyIndicatorOnOutcome?: boolean | undefined, keyIndicatorOnTime?: boolean | undefined, keyIndicatorOnBudget?: boolean | undefined } | undefined, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined, completeDateTime?: any | undefined }, milestones: Array<{ __typename: 'Milestone', id: string, name?: string | undefined, description?: string | undefined, currentMilestoneStatus?: { __typename: 'MilestoneStatus', id: string, statusIndicator: StatusIndicatorClass, completionRate?: number | undefined, comment?: string | undefined, complete: boolean } | undefined }>, objective?: { __typename: 'Objective', id: string, name?: string | undefined, orgUnit?: { __typename: 'OrgUnit', currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined } | undefined } | undefined }> } };


export const ObjectiveInitiativesDocument = gql`
    query ObjectiveInitiatives($objectiveId: ID!) {
  objective(id: $objectiveId) {
    ...initiativesContentObjective
  }
}
    ${InitiativesContentObjectiveFragmentDoc}`;

/**
 * __useObjectiveInitiativesQuery__
 *
 * To run a query within a React component, call `useObjectiveInitiativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectiveInitiativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectiveInitiativesQuery({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *   },
 * });
 */
export function useObjectiveInitiativesQuery(baseOptions: Apollo.QueryHookOptions<ObjectiveInitiativesQuery, ObjectiveInitiativesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectiveInitiativesQuery, ObjectiveInitiativesQueryVariables>(ObjectiveInitiativesDocument, options);
      }
export function useObjectiveInitiativesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectiveInitiativesQuery, ObjectiveInitiativesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectiveInitiativesQuery, ObjectiveInitiativesQueryVariables>(ObjectiveInitiativesDocument, options);
        }
export type ObjectiveInitiativesQueryHookResult = ReturnType<typeof useObjectiveInitiativesQuery>;
export type ObjectiveInitiativesLazyQueryHookResult = ReturnType<typeof useObjectiveInitiativesLazyQuery>;
export type ObjectiveInitiativesQueryResult = Apollo.QueryResult<ObjectiveInitiativesQuery, ObjectiveInitiativesQueryVariables>;