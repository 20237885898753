import { useTranslation } from 'react-i18next';
import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useTeamAdapter } from 'team/TeamAdapter';
import Layout from 'shared/components/Layout/Layout';
import TeamImage from 'team/TeamImage';
import Space from 'shared/components/Space';

import { getBreadcrumbsItems } from './TeamTeamHierarchyLayout.utils';

type TeamTeamHierarchyLayoutProps = PropsWithChildren<object>;

const TeamTeamHierarchyLayout = ({
  children,
}: TeamTeamHierarchyLayoutProps) => {
  const { t } = useTranslation();

  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const { teamAdapter } = useTeamAdapter();

  const breadcrumbsItems = useMemo(
    () =>
      getBreadcrumbsItems({
        t,
        teamAdapter,
        orgUnit,
        activeOrg,
      }),
    [activeOrg, orgUnit, t, teamAdapter],
  );

  return (
    <>
      <Layout.Header
        heading={
          <Space isCentered={true} size={'small'}>
            <TeamImage orgUnit={orgUnit} size={'small'} />
            {t('team.teamHierarchy.title')}
          </Space>
        }
        breadcrumbsItems={breadcrumbsItems}
      />
      <Layout.Content hasPadding={false}>{children}</Layout.Content>
    </>
  );
};

export default TeamTeamHierarchyLayout;
