import { useCallback } from 'react';

import Avatar from 'shared/components/Avatar';
import type { MenuTriggerComponentProps } from 'shared/components/Menu';
import { useUser } from 'user/UserProvider';

const UserAvatarContextMenuTrigger = ({
  state,
  triggerRef,
}: MenuTriggerComponentProps) => {
  const { user } = useUser();

  const handleClick = useCallback(() => {
    state.toggle();
  }, [state]);

  return (
    <Avatar
      onClick={handleClick}
      ref={triggerRef}
      displayName={user.displayName}
      email={user.email}
      photoUrl={user.photoUrl}
      testId={'nav-user-menu'}
    />
  );
};

export default UserAvatarContextMenuTrigger;
