import { Fragment } from 'react';

import { useInitiativesListObjectives } from '../InitiativesContentProvider/useInitiativesContent';
import InitiativesListSection from '../InitiativesContentListSection';

type InitiativesContentListProps = {
  areAllSectionsExpanded?: boolean;
  hasHeaders?: boolean;
};

const InitiativesContentList = ({
  hasHeaders,
  areAllSectionsExpanded,
}: InitiativesContentListProps) => {
  const { allObjectives } = useInitiativesListObjectives();

  return (
    <>
      {allObjectives?.map((objective) => (
        <InitiativesListSection
          hasHeader={hasHeaders}
          objective={objective}
          key={objective.id}
          isExpanded={areAllSectionsExpanded}
        />
      ))}
    </>
  );
};

export default InitiativesContentList;
