import { useMemo } from 'react';

import Calendar from 'shared/components/Calendar';
import InitiativeChip from 'initiative/InitiativeChip';
import { useTeamAdapter } from 'team/TeamAdapter';
import type { InitiativesContentObjectiveFragment } from 'initiative/InitiativesContent/InitiativesContentProvider/InitiativesContent.graphql.generated';

type StrategyInsightsCalendarViewProps = {
  objectives: InitiativesContentObjectiveFragment[];
  year: number;
};

const StrategyInsightsCalendarView = ({
  year,
  objectives,
}: StrategyInsightsCalendarViewProps) => {
  const { teamAdapter } = useTeamAdapter();

  const items = useMemo(
    () =>
      objectives
        .flatMap((objective) =>
          objective.initiatives.map((initiative) => {
            const { endDate, startDate } = initiative.timeLine;
            if (endDate && startDate) {
              return {
                children: (
                  <InitiativeChip
                    contextObjective={objective}
                    initiative={initiative}
                    teamAdapter={teamAdapter}
                  />
                ),
                startDateTime: initiative.timeLine.startDate!,
                endDateTime: initiative.timeLine.endDate!,
              };
            }
          }),
        )
        .filter(Boolean),
    [objectives, teamAdapter],
  );

  const startDateTime = useMemo(() => new Date(year, 0), [year]);

  const endDateTime = useMemo(
    () => new Date(new Date(year + 1, 0).getTime() - 1),
    [year],
  );

  return (
    <Calendar
      items={items}
      intervalUnit={'month'}
      startDateTime={startDateTime}
      endDateTime={endDateTime}
      isWrapped={true}
    />
  );
};

export default StrategyInsightsCalendarView;
