import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useTeamAdapter } from 'team/TeamAdapter';
import {
  groupObjectivesByTheme,
  sortObjectivesByTheme,
} from 'objective/objective.utils';
import { getNodesFromConnection } from 'shared/graphql/utils';
import Spinner from 'shared/spinner/Spinner';
import { useStatusFilter } from 'shared/status/useStatusFilter';
import { useThemeFilter } from 'themes/useThemeFilter';
import { useUserFilter } from 'user/useUserFilter';
import { useDateFilter } from 'shared/hooks/useDateFilter';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import { usePriorityFilter } from 'shared/priority/usePriorityFilter';
import useHandleError from 'shared/errors/useHandleError';
import { useCalendarControls } from 'shared/components/CalendarControls/useCalendarControls';
import { useCompletedFilter } from 'shared/components/CompletedFilterButton/useCompletedFilter';
import type { StatusMultiSelectItemId } from 'shared/status/StatusMultiSelect';

import type { TeamInsightsOverviewProviderContextValue } from './TeamInsightsOverviewProvider.context';
import { TeamInsightsOverviewProviderContext } from './TeamInsightsOverviewProvider.context';
import {
  useTeamInsightsOverviewForOrgQuery,
  useTeamInsightsOverviewForOrgUnitQuery,
} from './TeamInsightsOverviewProvider.graphql.generated';
import type {
  TeamInsightsOverviewTheme,
  TeamInsightsOverviewObjective,
} from './TeamInsightsOverviewProvider.type';
import {
  filterObjectivesByUser,
  filterObjectivesByTheme,
  filterObjectivesByStatus,
  filterObjectivesByDates,
  getActiveTheme,
  getActiveThemeObjectives,
  getObjectivesOwners,
  filterObjectivesByPriority,
} from './TeamInsightsOverviewProvider.utils';

type TeamInsightsOverviewProviderProps = PropsWithChildren<object>;

const TeamInsightsOverviewProvider = ({
  children,
}: TeamInsightsOverviewProviderProps) => {
  const [, setSearchParams] = useSearchParams();

  const { teamAdapter } = useTeamAdapter();

  const { priorities, isPrioritiesLoading } = useStrategyProfilePriorities();

  const handleApolloError = useHandleError();

  const activeQuery = teamAdapter.isOrgUnit
    ? useTeamInsightsOverviewForOrgUnitQuery
    : useTeamInsightsOverviewForOrgQuery;

  const { data: orgData, loading: isOrgLoading } =
    useTeamInsightsOverviewForOrgQuery({
      skip: activeQuery !== useTeamInsightsOverviewForOrgQuery,
      fetchPolicy: 'cache-and-network',
      onError: handleApolloError,
    });

  const { data: orgUnitData, loading: isOrgUnitLoading } =
    useTeamInsightsOverviewForOrgUnitQuery({
      variables: {
        orgUnitId: teamAdapter.keyArg,
      },
      skip: activeQuery !== useTeamInsightsOverviewForOrgUnitQuery,
      fetchPolicy: 'cache-and-network',
      onError: handleApolloError,
    });

  const isLoading =
    (!orgData && isOrgLoading) || (!orgUnitData && isOrgUnitLoading);

  const { statusFilter, setStatusFilter } = useStatusFilter();

  const { priorityFilter, setPriorityFilter } = usePriorityFilter({
    priorities,
  });

  const { themeFilter, setThemeFilter } = useThemeFilter();

  const { userFilter, setUserFilter } = useUserFilter();

  const { dateFilter: startDateFilter, setDateFilter: setStartDateFilter } =
    useDateFilter({
      paramName: 'startDate',
    });

  const { dateFilter: endDateFilter, setDateFilter: setEndDateFilter } =
    useDateFilter({
      paramName: 'endDate',
    });

  const { calendarIntervalStartDate, calendarIntervalEndDate, calendarCheck } =
    useCalendarControls();

  const { showCompleted } = useCompletedFilter();

  const clearFilters = useCallback(() => {
    setSearchParams((searchParams) => {
      searchParams.delete('status');
      searchParams.delete('user');
      searchParams.delete('startDate');
      searchParams.delete('endDate');
      searchParams.delete('priority');
      return searchParams;
    });
  }, [setSearchParams]);

  const filters = useMemo<TeamInsightsOverviewProviderContextValue['filters']>(
    () => ({
      themeFilter,
      setThemeFilter,
      setStatusFilter,
      statusFilter,
      userFilter,
      setUserFilter,
      startDateFilter,
      setStartDateFilter,
      endDateFilter,
      setEndDateFilter,
      clearFilters,
      priorityFilter,
      setPriorityFilter,
    }),
    [
      themeFilter,
      setThemeFilter,
      setStatusFilter,
      statusFilter,
      userFilter,
      setUserFilter,
      startDateFilter,
      setStartDateFilter,
      endDateFilter,
      setEndDateFilter,
      clearFilters,
      priorityFilter,
      setPriorityFilter,
    ],
  );

  const contextValue = useMemo<
    Maybe<TeamInsightsOverviewProviderContextValue>
  >(() => {
    const objectiveConnection =
      orgData?.activeOrg.objectives || orgUnitData?.orgUnit.objectives;

    if (!objectiveConnection) return;

    const objectives = getNodesFromConnection(objectiveConnection).map(
      (objective) => ({ ...objective, isFiltersMatch: true }),
    );

    const objectivesByTheme = sortObjectivesByTheme(
      groupObjectivesByTheme<
        TeamInsightsOverviewObjective,
        TeamInsightsOverviewTheme
      >(objectives, true),
      orgUnitData
        ? [
            ...orgUnitData.orgUnit.parentOrgUnitTree,
            orgUnitData.orgUnit,
          ].reverse()
        : [],
      true,
    );

    return {
      objectivesByTheme,
      filters,
      objectives,
      filteredObjectives: filterObjectivesByDates(
        filterObjectivesByDates(
          filterObjectivesByUser(
            filterObjectivesByStatus(
              filterObjectivesByTheme(
                filterObjectivesByPriority(objectives, filters.priorityFilter),
                filters.themeFilter,
              ),
              [
                ...filters.statusFilter,
                showCompleted && ('COMPLETED' as StatusMultiSelectItemId),
              ].filter(Boolean),
            ),
            filters.userFilter,
          ),
          filters.startDateFilter,
          filters.endDateFilter,
        ),
        calendarIntervalStartDate,
        calendarIntervalEndDate,
        calendarCheck,
      ),
      activeTheme: getActiveTheme(objectivesByTheme, filters.themeFilter),
      activeThemeObjectives: getActiveThemeObjectives(
        objectivesByTheme,
        filters.themeFilter,
      ),
      users: getObjectivesOwners(objectives),
    };
  }, [
    calendarCheck,
    calendarIntervalEndDate,
    calendarIntervalStartDate,
    filters,
    orgData?.activeOrg.objectives,
    orgUnitData,
    showCompleted,
  ]);

  if (isLoading || isPrioritiesLoading) {
    return <Spinner.Circle />;
  }

  if (contextValue) {
    return (
      <TeamInsightsOverviewProviderContext.Provider value={contextValue}>
        {children}
      </TeamInsightsOverviewProviderContext.Provider>
    );
  }

  return null;
};

export default TeamInsightsOverviewProvider;
