import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import EmojiIcon from 'shared/components/EmojiIcon';
import Heading from 'shared/components/Heading';
import Space from 'shared/components/Space';
import CreateStrategyButton from 'strategy/CreateStrategyButton';

import { strategyRoomRoutesPaths } from '../../StrategyRoom/StrategyRoom.routing.paths';
import styles from './StrategyRoomNoStrategyCard.module.scss';

const StrategyRoomNoStrategyCard = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleCreateStrategy = useCallback(() => {
    navigate(strategyRoomRoutesPaths.create());
  }, [navigate]);

  return (
    <Space isCentered={true} direction={'vertical'} className={styles.card}>
      <EmojiIcon emoji={'⛰️'} />
      <Heading level={3} hasMargin={false}>
        {t('strategy.noStrategy.heading')}
      </Heading>
      <p className={styles.description}>
        {t('strategy.noStrategy.description')}
      </p>
      <CreateStrategyButton
        onPress={handleCreateStrategy}
        variant={'contained'}
      >
        {t('strategy.strategyRoom.createStrategy')}
      </CreateStrategyButton>
    </Space>
  );
};

export default StrategyRoomNoStrategyCard;
