import styled from '@emotion/styled';

import Flex from 'shared/components/Flex';
import type { OrgUnitForTeamImageFragment } from 'orgUnit/OrgUnit.graphql.generated';
import type { OrgUnitWithAuthorizedActions } from 'user/ability/canPerformOrgUnitAction';
import TeamAvatar from 'team/TeamAvatar/TeamAvatar';

import UnlinkObjectiveFromOrgUnitButton from './UnlinkObjectiveFromOrgUnitButton';

const RemoveButtonContainer = styled(Flex)`
  opacity: 0;
`;

const Row = styled(Flex)`
  border-radius: 4px;
  padding: 8px;
  margin-left: -8px;

  &:hover {
    background-color: ${(props) => props.theme.color.hoverDark};

    ${RemoveButtonContainer} {
      opacity: 1;
    }
  }
`;

type Props = {
  orgUnit: OrgUnitForTeamImageFragment & OrgUnitWithAuthorizedActions;
};

const LinkedOrgUnitsRow = ({ orgUnit }: Props) => {
  return (
    <Row key={orgUnit.id} alignItems={'center'} gap={8}>
      <TeamAvatar orgUnit={orgUnit} size={'small'} hasTooltip={false} />
      {orgUnit.name}
      <RemoveButtonContainer justifyContent={'flex-end'} grow={1}>
        <UnlinkObjectiveFromOrgUnitButton orgUnitId={orgUnit.id} />
      </RemoveButtonContainer>
    </Row>
  );
};

export default LinkedOrgUnitsRow;
