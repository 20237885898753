import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import type { Strategy } from 'types.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import type { TeamAdapter } from 'team/TeamAdapter';
import useHandleError from 'shared/errors/useHandleError';
import useCanPerformOrgOrOrgUnitAction from 'user/ability/useCanPerformOrgOrOrgUnitAction';

import {
  useLaunchStrategyStrategyQuery,
  useLaunchStrategyUpdateOrgMutation,
  useLaunchStrategyUpdateOrgUnitMutation,
} from './useLaunchStrategy.graphql.generated';

export type LaunchStrategyParams = {
  strategyId: Strategy['id'];
  teamAdapter: TeamAdapter;
};

type LaunchStrategyFn = (params: {
  onSuccess?: () => void;
}) => Promise<unknown>;

type UseLaunchStrategyReturnType = {
  canLaunchStrategy: boolean;
  launchStrategy: LaunchStrategyFn;
};

export const useLaunchStrategy = ({
  strategyId,
  teamAdapter,
}: LaunchStrategyParams): UseLaunchStrategyReturnType => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const handleApolloError = useHandleError();

  const { data: strategyData } = useLaunchStrategyStrategyQuery({
    variables: { strategyId },
  });

  const [launchOrgStrategy] = useLaunchStrategyUpdateOrgMutation({
    variables: { orgKey: teamAdapter.keyArg!, strategyId },
    onError: handleApolloError,
  });
  const [launchOrgUnitStrategy] = useLaunchStrategyUpdateOrgUnitMutation({
    variables: { orgUnitId: teamAdapter.keyArg!, strategyId },
    onError: handleApolloError,
  });

  const canWrite = useCanPerformOrgOrOrgUnitAction('WRITE_STRATEGY', 'WRITE');

  const canLaunchStrategy =
    canWrite &&
    strategyData !== undefined &&
    !strategyData.strategy.isLive &&
    !strategyData.strategy.isComplete;

  const handleSuccess = useCallback(() => {
    addToast({
      variant: 'success',
      children: t('strategy.launchSuccessToast'),
    });
  }, [addToast, t]);

  const handleLaunchStrategy = useCallback<LaunchStrategyFn>(
    async ({ onSuccess }) => {
      if (canLaunchStrategy && teamAdapter.isOrg) {
        return launchOrgStrategy({
          onCompleted: () => {
            handleSuccess();
            onSuccess?.();
          },
        });
      } else if (canLaunchStrategy && teamAdapter.isOrgUnit) {
        return launchOrgUnitStrategy({
          onCompleted: () => {
            handleSuccess();
            onSuccess?.();
          },
        });
      }
    },
    [
      launchOrgStrategy,
      launchOrgUnitStrategy,
      handleSuccess,
      canLaunchStrategy,
      teamAdapter,
    ],
  );

  return {
    canLaunchStrategy,
    launchStrategy: handleLaunchStrategy,
  };
};
