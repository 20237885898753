import { useDefinedContext } from 'shared/utils/context.utils';

import { UserTenantsContext } from './UserTenants.context';
import UserTenantsProvider from './UserTenantsProvider';

export const useUserTenants = () =>
  useDefinedContext(UserTenantsContext, {
    hookName: useUserTenants.name,
    providerName: UserTenantsProvider.name,
  });
