import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import ContributionLineOfSightSection from 'contribution/ContributionLineOfSightSection/ContributionLineOfSightSection';
import Layout from 'shared/components/Layout';
import { useTeamAdapter } from 'team/TeamAdapter';
import Spinner from 'shared/spinner/Spinner';
import ContributionStrategySection from 'contribution/ContributionStrategySection';
import useHandleError from 'shared/errors/useHandleError';

import UserDetails from './UserDetails';
import { useContributionUserContributionQuery } from './ContributionUserContribution.graphql.generated';
import { getBreadcrumbItems } from './ContributionUserContribution.utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ContributionUserContribution = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const { teamAdapter } = useTeamAdapter();

  if (!userId) {
    throw new Error('User id URL param missing');
  }

  const handleApolloError = useHandleError();

  const { data } = useContributionUserContributionQuery({
    variables: { userId },
    onError: handleApolloError,
    fetchPolicy: 'cache-and-network',
  });

  const breadcrumbItems = useMemo(
    () => data && getBreadcrumbItems(t, teamAdapter, data.user),
    [data, t, teamAdapter],
  );

  if (!data) {
    return <Spinner.Circle />;
  }

  return (
    <>
      <Layout.Header breadcrumbsItems={breadcrumbItems} />
      <Layout.Content hasPadding={false}>
        <Container>
          <UserDetails user={data.user} />
          <ContributionLineOfSightSection user={data.user} subject={'user'} />
          <ContributionStrategySection
            user={data.user}
            contributions={data.user.contributingTo.asOwner}
            title={t(`contribution.strategySection.heading.user`)}
            description={t(`contribution.strategySection.description.user`)}
            emptyMessage={t(
              `contribution.strategySection.emptyDescription.user`,
            )}
          />
          <ContributionStrategySection
            user={data.user}
            contributions={data.user.contributingTo.asTeamMember}
            title={t(`contribution.myMemberships.user.heading`)}
            description={t(`contribution.myMemberships.user.description`)}
            emptyMessage={t(`contribution.myMemberships.user.emptyDescription`)}
          />
        </Container>
      </Layout.Content>
    </>
  );
};

export default ContributionUserContribution;
