import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { ButtonProps } from 'shared/components/Button';
import Button from 'shared/components/Button';
import { useTeamAdapter } from 'team/TeamAdapter';
import { toolkitRoutesPaths } from 'toolkit/toolkit.routing.paths';
import type { CampaignStage, ProgressStatus } from 'types.graphql.generated';

type Props = {
  campaignId: string;
  stage: CampaignStage;
  status?: ProgressStatus;
  variant?: ButtonProps['variant'];
};

const RespondCampaignButton = ({
  campaignId,
  stage,
  status = 'NOT_STARTED',
  variant,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamAdapter } = useTeamAdapter();

  const isActive = stage === 'ACTIVE';
  const isNotStarted = status === 'NOT_STARTED';
  const isDone = status === 'DONE';

  const hideButton = !isActive && isNotStarted;

  if (hideButton) return null;

  const copy = isActive
    ? {
        NOT_STARTED: t('getStarted'),
        IN_PROGRESS: t('continue'),
        DONE: t('toolkit.toolPage.walkOfStrategy.button.viewMyAnswers'),
      }[status]
    : t('toolkit.toolPage.walkOfStrategy.button.viewMyAnswers');

  const forceOutlined = !isActive || isDone;

  return (
    <Button
      variant={forceOutlined ? 'outlined' : variant}
      onPress={() =>
        navigate(
          toolkitRoutesPaths.walkOfStrategy.campaignResponses.respond({
            params: {
              teamSlug: teamAdapter.toParam(),
              campaignId,
            },
          }),
        )
      }
    >
      {copy}
    </Button>
  );
};

export default RespondCampaignButton;
