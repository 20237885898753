import { createContext } from 'react';

import type { UserTenantsTenantFragment } from './UserTenants.graphql.generated';

export type UserTenantsValue = {
  tenants: UserTenantsTenantFragment[];
};

export const UserTenantsContext = createContext<UserTenantsValue | undefined>(
  undefined,
);
