import { useNavigate, useParams } from 'react-router-dom';
import { useCallback } from 'react';

import EditInitiative from 'initiative/EditInitiative';

const EditInitiativePage = () => {
  const { initiativeId } = useParams() as { initiativeId: Maybe<string> };
  const navigate = useNavigate();

  const handleClose = useCallback(() => navigate('../'), [navigate]);

  if (!initiativeId) return null;

  return (
    <EditInitiative
      initiativeId={initiativeId}
      isOpen={true}
      onClose={handleClose}
    />
  );
};

export default EditInitiativePage;
