import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useHandleError from 'shared/errors/useHandleError';

import type { ObjectiveDetailsContextValue } from './ObjectiveDetailsProvider.context';
import { ObjectiveDetailsContext } from './ObjectiveDetailsProvider.context';
import { useObjectiveDetailsQuery } from './ObjectiveDetailsProvider.graphql.generated';

type ObjectiveDetailsProviderProps = PropsWithChildren<object>;

const ObjectiveDetailsProvider = ({
  children,
}: ObjectiveDetailsProviderProps) => {
  const { objectiveId } = useParams<{ objectiveId: string }>();

  const handleApolloError = useHandleError();

  const { data, loading: isObjectiveLoading } = useObjectiveDetailsQuery({
    variables: {
      objectiveId: objectiveId!,
    },
    skip: !objectiveId,
    onError: handleApolloError,
  });

  const contextValue = useMemo<ObjectiveDetailsContextValue>(
    () => ({
      objective: data?.objective,
      isObjectiveLoading,
    }),
    [data, isObjectiveLoading],
  );

  return (
    <ObjectiveDetailsContext.Provider value={contextValue}>
      {children}
    </ObjectiveDetailsContext.Provider>
  );
};

export default ObjectiveDetailsProvider;
