import { useMemo } from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';

import { determineIfIsOverdue } from 'actions/action.utils';
import ActionChipOverdue from 'actions/ActionChipOverdue';
import { ReactComponent as DateIcon } from 'shared/static/icons/icon-date.svg';
import { ReactComponent as CheckSquareIcon } from 'shared/static/icons/icon-check-square.svg';
import UserAvatar from 'user/UserAvatar';
import { date } from 'shared/services/date.service';
import CompletionRate from 'shared/components/CompletionRate';
import type { ActionWithObjective } from 'actions/actions.type';
import useDialogState from 'shared/hooks/useDialogState';
import ActionStatusModal from 'actions/ActionStatusModal';
import ActionContextMenu from 'actions/ActionContextMenu/ActionContextMenu';
import TeamAvatar from 'team/TeamAvatar/TeamAvatar';

import styles from './ActionRow.module.scss';

type ActionRowProps = {
  action: ActionWithObjective;
};

const ActionRow = ({ action }: ActionRowProps) => {
  const { isOpen, onOpen, onClose } = useDialogState();

  const isActionOverdue = useMemo(() => determineIfIsOverdue(action), [action]);

  return (
    <>
      <motion.li className={styles.row} onClick={onOpen} layout={true}>
        <motion.div layout={true} className={styles.name}>
          <CheckSquareIcon className={styles.nameIcon} />
          <p className={styles.nameText}>{action.name}</p>
        </motion.div>
        {isActionOverdue && <ActionChipOverdue />}
        <motion.div
          layout={true}
          className={cn(styles.timeline, {
            [styles.timelineMargin]: !isActionOverdue,
          })}
        >
          <DateIcon className={styles.timelineIcon} />
          <motion.div
            layout={true}
            className={cn(styles.timelineText, {
              [styles.timelineTextOverdue]: isActionOverdue,
            })}
          >
            {date.format(action.timeLine.endDate, 'dd MMM yyyy')}
          </motion.div>
        </motion.div>
        <CompletionRate
          complete={action.currentStatus?.complete}
          completionRate={action.currentStatus?.completionRate}
        />
        <TeamAvatar orgUnit={action.orgUnit} size={'small'} />
        <UserAvatar
          user={action.owner}
          containerClassName={styles.avatarContainer}
        />
        <ActionContextMenu action={action} />
      </motion.li>

      <ActionStatusModal
        actionId={action.id}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default ActionRow;
