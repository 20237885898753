import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AskSohpieButton from 'ai/AskSohpieButton';
import type { PromptMessage } from 'ai/AskSohpieButton/AskSohpieButton.type';
import StrategyProvider, { useStrategy } from 'strategy/StrategyProvider';

import { useStrategyMetrics } from './StrategyMetricsProvider';

const StrategyMetricsAskSophieButton = () => {
  const { t } = useTranslation();

  const { strategy } = useStrategy();
  const { objectives } = useStrategyMetrics();

  const objectiveIds = objectives?.map((objective) => objective.id);

  const metricIds = objectives
    ?.flatMap((objective) => objective.metrics)
    .map((metric) => metric.id);

  const promptMessages = useMemo<PromptMessage[]>(
    () => [
      {
        content: t('ai.strategySummary.button.metricAlignment'),
        mainPrompt:
          'Please analyze if our metrics are aligned. Focus on alignment, not on the status of each element.',
        numberOfCharacters: 5000,
        temperature: 1,
        hints: [
          'Analyze alignment of the given metrics.',
          'In your response focus on alignment not on the status of each metric.',
          'Group the results first per theme, then within each theme group by a) Seems to be well aligned, b) potentially misaligned.',
        ],
      },
    ],
    [t],
  );

  return (
    <AskSohpieButton
      promptMessages={promptMessages}
      strategyId={strategy?.id}
      objectiveIds={objectiveIds}
      metricIds={metricIds}
      infoText={t('ai.strategySummary.start.subHeader.strategyMetrics')}
    />
  );
};

export default () => (
  <StrategyProvider>
    <StrategyMetricsAskSophieButton />
  </StrategyProvider>
);
