import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import Spinner from 'shared/spinner/Spinner';
import { useTeamAdapter } from 'team/TeamAdapter';
import useHandleError from 'shared/errors/useHandleError';

import type { StrategyProviderContextValue } from './StrategyProvider.context';
import { StrategyProviderContext } from './StrategyProvider.context';
import {
  useStrategyProviderOrgQuery,
  useStrategyProviderOrgUnitQuery,
} from './StrategyProvider.graphql.generated';

type StrategyProviderProps = PropsWithChildren<object>;

const StrategyProvider = ({ children }: StrategyProviderProps) => {
  const { teamAdapter } = useTeamAdapter();

  const onError = useHandleError();

  const { data: strategyIdOrgData } = useStrategyProviderOrgQuery({
    skip: !teamAdapter.isOrg,
    onError,
  });

  const { data: strategyIdOrgUnitData } = useStrategyProviderOrgUnitQuery({
    variables: { orgUnitId: teamAdapter.keyArg },
    skip: teamAdapter.isOrg,
    onError,
  });

  const hasData = strategyIdOrgData || strategyIdOrgUnitData;

  const strategy =
    strategyIdOrgData?.activeOrg.currentStrategy ||
    strategyIdOrgUnitData?.orgUnit.currentStrategy;

  const contextValue = useMemo<StrategyProviderContextValue | undefined>(
    () => (hasData ? { strategy } : undefined),
    [hasData, strategy],
  );

  if (!hasData) return <Spinner.Circle />;

  return (
    <StrategyProviderContext.Provider value={contextValue}>
      {children}
    </StrategyProviderContext.Provider>
  );
};

export default StrategyProvider;
