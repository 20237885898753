import { useTranslation } from 'react-i18next';

import type { ObjectiveStatusDialogObjective } from 'objective/CreateObjectiveStatusDialog/CreateObjectiveStatusDialog';
import {
  canPerformStrategyElementAction,
  type StrategyElementWithAuthorizedActions,
} from 'user/ability/canPerformStrategyElementAction';
import Button from 'shared/components/Button';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import useDialogState from 'shared/hooks/useDialogState';
import CreateObjectiveStatusDialog from 'objective/CreateObjectiveStatusDialog/CreateObjectiveStatusDialog';

type Props = {
  objective: ObjectiveStatusDialogObjective &
    StrategyElementWithAuthorizedActions;
};

const ShareObjectiveStatusButton = ({ objective }: Props) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDialogState();

  const canAddStatus = canPerformStrategyElementAction(objective, 'WRITE');

  if (!canAddStatus) return null;

  return (
    <>
      <Button icon={PlusIcon} iconPosition={'start'} onPress={onOpen}>
        {t('objective.insights.actions.addButton')}
      </Button>

      <CreateObjectiveStatusDialog
        isOpen={isOpen}
        objective={objective}
        onClose={onClose}
      />
    </>
  );
};

export default ShareObjectiveStatusButton;
