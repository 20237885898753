import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import Space from 'shared/components/Space';
import type { ToolGroup } from 'toolkit/toolkit.static';

import Button from '../ToolkitOverviewButton';
import styles from './ToolkitOverviewCategoryControls.module.scss';

export type ToolkitOverviewCategoryControlsProps = {
  groups: ToolGroup[];
  isGroupSelected: (groupId: ToolGroup) => boolean;
  noGroupsSelected: boolean;
  selectGroup: (groupId?: Maybe<ToolGroup>) => void;
};

const StyledButton = styled(Button)`
  border-color: ${(props) => props.theme.color.strokeLight};
  padding-left: 0.714rem !important;
  padding-right: 0.714rem !important;
`;

const ToolkitOverviewCategoryControls = ({
  groups,
  selectGroup,
  noGroupsSelected,
  isGroupSelected,
}: ToolkitOverviewCategoryControlsProps) => {
  const { t } = useTranslation();

  return (
    <Space isCentered={true} className={styles.container}>
      <StyledButton
        variant={'outlined'}
        onPress={() => {
          selectGroup();
        }}
        aria-pressed={noGroupsSelected}
        fontWeight={'light'}
      >
        {t('toggle.all')}
      </StyledButton>
      {groups.map((group) => (
        <StyledButton
          variant={'outlined'}
          key={group}
          onPress={() => selectGroup(group)}
          aria-pressed={isGroupSelected(group)}
          fontWeight={'light'}
        >
          {t(`toolkit.groups.${group}.name`)}
        </StyledButton>
      ))}
    </Space>
  );
};

export default ToolkitOverviewCategoryControls;
