import { setContext } from '@apollo/client/link/context';

import { getActingOrgHeaders } from './getActingOrgHeaders';

export function getActingOrgLink(actingOrgKey: string) {
  return setContext((_, previousContext) => ({
    ...previousContext,
    headers: {
      ...getActingOrgHeaders(actingOrgKey),
      ...previousContext.headers,
    },
  }));
}
