import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import type { ChangeEvent } from 'react';
import { useEffect, useRef } from 'react';
import { Input, TextArea } from 'react-aria-components';

import { ReactComponent as CircleAlertIcon } from 'shared/static/icons/icon-circle-alert.svg';
import { transientOptions } from 'shared/utils/emotion.utils';

type FontSize = 'regular' | 'big';
type InputType = 'text' | 'textarea';

const Container = styled('div', transientOptions)<{ $hasError: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;

  border-radius: 8px;
  background-color: ${(props) =>
    props.$hasError ? props.theme.color.errorLight : props.theme.color.white};

  &:focus-within {
    box-shadow: 0 0 0 2px ${(props) => props.theme.color.primary};
  }
`;

const InputContainer = styled.div`
  flex-grow: 1;
`;

const ErrorIconContainer = styled.div`
  padding-right: 8px;
`;

type Props = {
  autoFocus?: boolean;
  fontSize?: FontSize;
  hasError: boolean;
  inputType?: InputType;
  maxLength?: number;
  name: string;
  onBlur: () => void;
  onChange: (value: string) => void;
  placeholder: string;
  value?: string;
};

const OpenTextInput = ({
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  fontSize = 'regular',
  inputType = 'text',
  hasError,
  autoFocus,
  maxLength,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const theme = useTheme();

  const style = css`
    font-size: ${fontSize === 'regular' ? '1rem' : '1.5rem'};
    border: none;
    width: 100%;
    background-color: transparent;

    ::placeholder {
      color: ${theme.color.typoSecondary};
    }

    &:focus-visible {
      outline: none;
    }
  `;

  useEffect(() => {
    const element = inputRef.current || textAreaRef.current;
    if (autoFocus && element) {
      setTimeout(element.focus.bind(element), 0);
    }
  }, [autoFocus]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const newValue =
      maxLength === undefined
        ? event.target.value
        : event.target.value.substring(0, maxLength);

    onChange(newValue);
  };

  return (
    <Container $hasError={hasError}>
      <InputContainer>
        {inputType === 'text' ? (
          <Input
            id={name}
            ref={inputRef}
            css={style}
            name={name}
            onChange={handleChange}
            onBlur={onBlur}
            value={value}
            placeholder={placeholder}
            aria-invalid={hasError}
          />
        ) : (
          <TextArea
            id={name}
            ref={textAreaRef}
            css={[style, css({ minHeight: 70 })]}
            name={name}
            onChange={handleChange}
            onBlur={onBlur}
            value={value}
            placeholder={placeholder}
            aria-invalid={hasError}
          />
        )}
      </InputContainer>

      {hasError && (
        <ErrorIconContainer>
          <CircleAlertIcon color={theme.color.error} />
        </ErrorIconContainer>
      )}
    </Container>
  );
};

export default OpenTextInput;
