import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import * as yup from 'yup';

import type { StatusIndicator as StatusIndicatorEnum } from 'types.graphql.generated';
import Form from 'shared/form/Form';
import { SliderField } from 'shared/components/Slider';
import FieldBox from 'shared/form/FieldBox';
import { TextInputField } from 'shared/components/TextInput';
import Space from 'shared/components/Space';
import StatusRadioCard from 'shared/status/StatusRadioCard';
import { StatusIndicator } from 'shared/status/StatusIndicator';

export type MilestoneStatusFormValues = {
  comment: string;
  complete: boolean;
  completionRate: number;
  statusIndicator: `${StatusIndicatorEnum}` | null;
};

export type MilestoneStatusFormSubmitValues = {
  comment: string;
  complete: boolean;
  completionRate: number;
  statusIndicator: `${StatusIndicatorEnum}`;
};

const createInitialValues = (): MilestoneStatusFormValues => ({
  comment: '',
  complete: false,
  completionRate: 0,
  statusIndicator: null,
});

export const milestoneStatusFormId = 'milestoneStatusForm';

const selectableStatusIndicators = ['ON_TRACK', 'AT_RISK', 'BLOCKED'] as const;

import styles from './MilestoneStatusForm.module.scss';

type MilestoneStatusFormProps = {
  className?: string;
  id?: string;
  initialValues?: MilestoneStatusFormValues;
  onSuccess: (values: MilestoneStatusFormSubmitValues) => void;
};

const MilestoneStatusForm = ({
  onSuccess,
  initialValues = createInitialValues(),
  id = milestoneStatusFormId,
  className,
}: MilestoneStatusFormProps) => {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    statusIndicator: yup
      .string()
      .required(t('initiative.milestoneStatusForm.statusIndicator.missing'))
      .nullable(),
    completionRate: yup
      .number()
      .min(0, t('initiative.milestoneStatusForm.completionRate.tooSmall'))
      .max(100, t('initiative.milestoneStatusForm.completionRate.tooBig')),
  });

  return (
    <Form<MilestoneStatusFormValues>
      initialValues={initialValues}
      onSubmit={(values) => {
        onSuccess(values as MilestoneStatusFormSubmitValues);
      }}
      validationSchema={validationSchema}
      id={id}
      className={className}
      enableReinitialize={true}
    >
      {({ touched, errors }) => (
        <>
          <FieldBox
            name={'statusIndicator'}
            label={t('initiative.milestoneStatusForm.statusIndicator.label')}
          >
            <Space direction={'vertical'}>
              {selectableStatusIndicators.map((statusIndicator) => (
                <Field
                  type={'radio'}
                  name={'statusIndicator'}
                  as={StatusRadioCard}
                  isError={touched.statusIndicator && errors.statusIndicator}
                  value={statusIndicator}
                  statusIndicator={new StatusIndicator(statusIndicator)}
                  key={statusIndicator}
                />
              ))}
            </Space>
          </FieldBox>
          <FieldBox
            name={'completionRate'}
            label={t('initiative.milestoneStatusForm.completionRate.label')}
          >
            <Field
              name={'completionRate'}
              component={TextInputField}
              max={100}
              size={'small'}
              type={'number'}
              addons={[{ placement: 'after', children: '%' }]}
            />
            <Field
              component={SliderField}
              size={'small'}
              name={'completionRate'}
              marks={{
                0: 0,
                25: 25,
                50: 50,
                75: 75,
                100: 100,
              }}
              className={styles.slider}
            />
          </FieldBox>
          <FieldBox
            name={'comment'}
            label={t('initiative.milestoneStatusForm.comment.label')}
            isOptional={true}
          >
            <Field
              name={'comment'}
              component={TextInputField}
              maxLength={250}
            />
          </FieldBox>
        </>
      )}
    </Form>
  );
};

export default MilestoneStatusForm;
