import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { css, useTheme } from '@emotion/react';

import Heading from 'shared/components/Heading';
import Layout from 'shared/components/Layout';
import useHandleError from 'shared/errors/useHandleError';
import { useObjective } from 'objective/ObjectiveProvider';
import Spinner from 'shared/spinner/Spinner';
import Flex from 'shared/components/Flex';
import Caption from 'shared/components/Caption';
import InfoIcon from 'shared/components/InfoIcon';

import { useObjectiveAlignmentQuery } from './ObjectiveAlignment.graphql.generated';
import ObjectiveAlignmentRow from './ObjectiveAlignmentRow';

const ObjectiveAlignment = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { objective } = useObjective();

  const onError = useHandleError();

  const { data } = useObjectiveAlignmentQuery({
    onError,
    variables: { objectiveId: objective.id },
  });

  if (!data) return <Spinner.Circle />;

  return (
    <Layout.Content variant={'contained'}>
      <Flex direction={'column'} gap={'2rem'}>
        <Flex alignItems={'center'} css={css({ height: 42 })}>
          <Heading level={3} hasMargin={false}>
            {t('objective.alignment.heading')}
            <InfoIcon
              content={t('objective.alignment.help')}
              isInline={true}
              css={css({ marginLeft: 8 })}
            />
          </Heading>
        </Flex>

        <Flex direction={'column'} gap={'1rem'}>
          <Flex gap={20}>
            <Caption size={'C2'} color={'secondary'} css={css({ flex: '1' })}>
              {capitalize(t('objective.objective_other'))}
            </Caption>
            <Caption size={'C2'} color={'secondary'} css={css({ flex: '1' })}>
              {capitalize(t('metric.metric_other'))}
            </Caption>
            <Caption size={'C2'} color={'secondary'} css={css({ flex: '1' })}>
              {capitalize(t('initiative.initiative_other'))}
            </Caption>
          </Flex>

          <ObjectiveAlignmentRow objective={data.objective} />

          {data.objective.childObjectives.map((childObjective) => (
            <>
              <div
                css={css({
                  borderBottom: `1px solid ${theme.color.strokeLight}`,
                })}
              />
              <ObjectiveAlignmentRow
                key={childObjective.id}
                objective={childObjective}
              />
            </>
          ))}
        </Flex>
      </Flex>
    </Layout.Content>
  );
};

export default ObjectiveAlignment;
