import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import type { OrgUnit, User } from 'types.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import useHandleError from 'shared/errors/useHandleError';
import type { OrgUnitWithAuthorizedActions } from 'user/ability/canPerformOrgUnitAction';
import { canPerformOrgUnitAction } from 'user/ability/canPerformOrgUnitAction';
import {
  TeamMembersFromOrgDocument,
  TeamMembersFromOrgUnitDocument,
} from 'team/TeamMembers/TeamMembersProvider/TeamMembersProvider.graphql.generated';

import { useRemoveOrgUnitMemberMutation } from './useRemoveOrgUnitMember.graphql.generated';

export type UseRemoveOrgUnitMemberParams = {
  orgUnit: OrgUnitWithAuthorizedActions;
  orgUnitId: OrgUnit['id'];
  userId: User['id'];
};

export type UseRemoveOrgUnitMemberReturnType = {
  canRemoveOrgUnitMember: boolean;
  removeOrgUnitMember: (params: { onSuccess?: () => void }) => Promise<void>;
};

export const useRemoveOrgUnitMember = ({
  userId,
  orgUnitId,
  orgUnit,
}: UseRemoveOrgUnitMemberParams): UseRemoveOrgUnitMemberReturnType => {
  const handleApolloError = useHandleError();

  const { t } = useTranslation();

  const { addToast } = useToasts();

  const [removeOrgUnitMember] = useRemoveOrgUnitMemberMutation({
    variables: {
      idToUpdate: orgUnitId,
      userIdToRemove: userId,
    },
    onError: handleApolloError,
    onCompleted: () => {
      addToast({
        id: 'removeOrgUnitMemberSuccessToast',
        children: t('updateSuccessToast'),
        variant: 'success',
      });
    },
    refetchQueries: [
      TeamMembersFromOrgDocument,
      TeamMembersFromOrgUnitDocument,
    ],
  });

  const canRemoveOrgUnitMember = canPerformOrgUnitAction(orgUnit, 'WRITE');

  const handleRemoveOrgUnitMember = useCallback<
    UseRemoveOrgUnitMemberReturnType['removeOrgUnitMember']
  >(
    async ({ onSuccess }) => {
      if (canRemoveOrgUnitMember)
        await removeOrgUnitMember({ onCompleted: onSuccess });
    },
    [canRemoveOrgUnitMember, removeOrgUnitMember],
  );

  return {
    canRemoveOrgUnitMember,
    removeOrgUnitMember: handleRemoveOrgUnitMember,
  };
};
