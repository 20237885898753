import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Markdown from 'markdown-to-jsx';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import AiLoadingIcon from 'ai/AiLoadingIcon';
import useHandleError from 'shared/errors/useHandleError';
import AiSophieIcon from 'ai/AiSophieIcon';

import { useAskSophieSidePanelSubscription } from './AskSophieSidePanel.graphql.generated';
import type { SummaryMessage } from './AskSophieSidePanel';
import ChatBubble from './ChatBubble';

const IconContainer = styled.div`
  align-self: flex-start;
  margin-top: 14px;
  width: 28px;
`;

export const Spacer = styled.div`
  flex-shrink: 0;
  width: 28px;
`;

type Props = {
  setIsLoading: (isLoading: boolean) => void;
  summary: SummaryMessage;
};

const SummaryMessageItem = ({ summary, setIsLoading }: Props) => {
  const { activeOrg } = useActiveOrg();
  const [content, setContent] = useState('');
  const [isComplete, setIsComplete] = useState(false);

  const handleApolloError = useHandleError();

  useEffect(() => {
    setIsLoading(true);
  }, [setIsLoading]);

  const { error } = useAskSophieSidePanelSubscription({
    variables: {
      input: {
        languageLocaleCode: activeOrg.languageLocaleCode,
        maxNumberOfCharacters: summary.numberOfCharacters,
        mainPrompt: summary.mainPrompt,
        mainPromptKey: summary.mainPromptKey,
        elements: {
          objectiveIds: summary.objectiveIds,
          metricIds: summary.metricIds,
          initiativeIds: summary.initiativeIds,
          actionIds: summary.actionIds,
          strategyIds: summary.strategyId ? [summary.strategyId] : undefined,
        },
        hints: [
          ...(summary.hints || []),
          'Use markdown to format your response',
        ],
        model: {
          temperature: summary.temperature,
        },
      },
    },
    onSubscriptionData: (result) => {
      setContent(
        (prevData) => prevData + result.subscriptionData.data?.aiStream,
      );
    },
    onSubscriptionComplete: () => {
      setIsComplete(true);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (error) {
      handleApolloError(error);
      setIsLoading(false);
    }
  }, [error, handleApolloError, setIsLoading]);

  const hasContent = content !== '';
  const shouldShow = !error && (!isComplete || hasContent);

  if (!shouldShow) return null;

  return (
    <>
      <IconContainer>
        <AiSophieIcon />
      </IconContainer>
      <ChatBubble $messageType={'SummaryMessage'}>
        {hasContent ? <Markdown>{content}</Markdown> : <AiLoadingIcon />}
      </ChatBubble>
      <Spacer />
    </>
  );
};

export default SummaryMessageItem;
