import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { hasChoices } from 'strategy/strategy.utils';
import StrategyRoadmapChips from 'strategy/StrategyRoadmap/StrategyRoadmapChips';
import type { CardBorder } from 'shared/components/Card/Card.type';
import useDialogState from 'shared/hooks/useDialogState';

import { backgroundColors, illustrations } from '../StrategyRoadmap.static';
import StrategyRoadmapCard from '../StrategyRoadmapCard';
import StrategyRoadmapChoicesModal from '../StrategyRoadmapChoicesModal';
import type { StrategyRoadmapStrategyFieldsFragment } from '../StrategyRoadmapProvider/StrategyRoadmapProvider.graphql.generated';

type StrategyRoadmapChoicesCardProps = {
  border?: CardBorder;
  hasShadow?: boolean;
  showEditButton?: boolean;
  strategy: StrategyRoadmapStrategyFieldsFragment;
};

const StrategyRoadmapChoicesCard = ({
  strategy,
  hasShadow,
  border,
  showEditButton = true,
}: StrategyRoadmapChoicesCardProps) => {
  const { choices } = strategy;

  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDialogState();

  const isEmpty = useMemo(() => !hasChoices(strategy), [strategy]);
  const content = useMemo(() => {
    if (!isEmpty) {
      const items = choices!.map((choice) => ({
        id: choice.id,
        children: choice.name,
      }));
      return (
        <StrategyRoadmapChips
          items={items}
          maxItemsCount={3}
          onClickMore={onOpen}
        />
      );
    }
    return t('noData');
  }, [choices, isEmpty, onOpen, t]);

  return (
    <>
      <StrategyRoadmapCard
        illustration={illustrations.choices}
        illustrationBackgroundColor={backgroundColors.choices}
        heading={t('strategy.strategicChoice_other')}
        onMaximize={onOpen}
        hasShadow={hasShadow}
        border={border}
      >
        {content}
      </StrategyRoadmapCard>
      <StrategyRoadmapChoicesModal
        strategy={strategy}
        isOpen={isOpen}
        onClose={onClose}
        showEditButton={showEditButton}
      />
    </>
  );
};

export default StrategyRoadmapChoicesCard;
