import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import Text from 'shared/components/Text';

import styles from './TeamCardDescriptionsItem.module.scss';
import type { TeamCardDescriptionsItemVariant } from './TeamCardDescriptionsItem.type';

type TeamCardDescriptionsItemProps = {
  onAction?: (action: TeamCardDescriptionsItemVariant) => void;
  value: string | number;
  variant: TeamCardDescriptionsItemVariant;
};

const TeamCardDescriptionsItem = ({
  variant,
  onAction,
  value,
}: TeamCardDescriptionsItemProps) => {
  const { t } = useTranslation();

  const content = useMemo(() => {
    switch (variant) {
      case 'childTeamsCount':
        return t('team.team', { count: Number(value) });
      case 'membersCount':
        return t('member', { count: Number(value) });
    }
  }, [variant, value, t]);

  const handleClick = useCallback(
    () => onAction?.(variant),
    [onAction, variant],
  );

  return (
    <Text
      className={styles.teamCardDescriptionsItem}
      ellipsis={true}
      onClick={handleClick}
    >
      {value} <span>{content}</span>
    </Text>
  );
};

export default TeamCardDescriptionsItem;
