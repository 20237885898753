import type { PropsWithChildren } from 'react';
import { v4 as uuid } from 'uuid';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ToastContext } from 'shared/toast/Toast.context';
import type { ToastContextValue } from 'shared/toast/Toast.context';
import type { ToastProps } from 'shared/toast/Toast';
import ToastsLayout from 'shared/toast/ToastsLayout';
import { TOAST_DISAPPEAR_TIME } from 'shared/toast/toast.config';

type ToastProviderProps = PropsWithChildren<object>;

const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toasts, setToasts] = useState<Array<ToastProps & { id: string }>>([]);

  const removeToast = useCallback((id: string) => {
    setToasts((previousToasts) =>
      previousToasts.filter((toast) => toast.id !== id),
    );
  }, []);

  const addToast = useCallback<ToastContextValue['addToast']>(
    (toastProps) => {
      const toastId = toastProps.id || uuid();
      setToasts((previousToasts) => [
        ...previousToasts.filter((toast) => toast.id !== toastId),
        { ...toastProps, id: toastId },
      ]);
      setTimeout(() => {
        removeToast(toastId);
      }, TOAST_DISAPPEAR_TIME);
    },
    [removeToast],
  );

  const toastContextValue = useMemo<ToastContextValue>(
    () => ({ addToast }),
    [addToast],
  );

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && toasts.length > 0) {
        setToasts([]);
        event.preventDefault();
        event.stopPropagation();
      }
    };

    document.addEventListener('keydown', handleEscKeyPress);

    return () => {
      document.removeEventListener('keydown', handleEscKeyPress);
    };
  }, [toasts.length]);

  return (
    <ToastContext.Provider value={toastContextValue}>
      {children}
      <ToastsLayout toasts={toasts} />
    </ToastContext.Provider>
  );
};

export default ToastProvider;
