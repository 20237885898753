import { createContext } from 'react';
import type { NavigateFunction } from 'react-router-dom';

export type Value = {
  clearRedirectPath: () => void;
  performRedirect: (navigate: NavigateFunction) => void;
  setRedirectPath: (path: string) => void;
};

const RedirectContext = createContext<Value | undefined>(undefined);

export default RedirectContext;
