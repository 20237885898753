import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import { sortBy, uniqBy } from 'lodash';
import { useSearchParams } from 'react-router-dom';

import Spinner from 'shared/spinner/Spinner';
import type { TeamAdapter } from 'team/TeamAdapter';
import useHandleError from 'shared/errors/useHandleError';
import { useUserFilter } from 'user/useUserFilter';
import {
  testStatusIndicatorAgainstId,
  type StatusMultiSelectItemId,
} from 'shared/status/StatusMultiSelect';
import { usePriorityFilter } from 'shared/priority/usePriorityFilter/usePriorityFilter';
import { useCompletedFilter } from 'shared/components/CompletedFilterButton/useCompletedFilter';
import { testPriorityAgainstId } from 'shared/priority/PriorityMultiSelect';
import { testStrategyItemAgainstInterval } from 'strategy/strategy.utils';
import { useCalendarControls } from 'shared/components/CalendarControls/useCalendarControls';
import { useStatusFilter } from 'shared/status/useStatusFilter';
import { useThemeFilter } from 'themes/useThemeFilter';
import { useDateFilter } from 'shared/hooks/useDateFilter';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import {
  groupObjectivesByTheme,
  sortObjectivesByTheme,
} from 'objective/objective.utils';

import type {
  SelectableStrategyItemsObjectiveFragment,
  SelectableStrategyItemsThemeFragment,
} from './SelectableStrategyItemsProvider.graphql.generated';
import {
  useSelectableStrategyItemsForOrgQuery,
  useSelectableStrategyItemsForOrgUnitQuery,
} from './SelectableStrategyItemsProvider.graphql.generated';
import type { SelectableStrategyItemsProviderContextValue } from './SelectableStrategyItemsProvider.context';
import { SelectableStrategyItemsProviderContext } from './SelectableStrategyItemsProvider.context';
import { getThemes } from '../SelectableStrategyItems.utils';

type SelectableStrategyItemsProviderProps = PropsWithChildren<{
  teamAdapter: TeamAdapter;
}>;

const SelectableStrategyItemsProvider = ({
  teamAdapter,
  children,
}: SelectableStrategyItemsProviderProps) => {
  const [, setSearchParams] = useSearchParams();

  const handleApolloError = useHandleError();

  const activeQuery = teamAdapter.isOrgUnit
    ? useSelectableStrategyItemsForOrgUnitQuery
    : useSelectableStrategyItemsForOrgQuery;

  const { data: orgData } = useSelectableStrategyItemsForOrgQuery({
    skip: activeQuery !== useSelectableStrategyItemsForOrgQuery,
    nextFetchPolicy: 'cache-only',
    onError: handleApolloError,
  });

  const { data: orgUnitData } = useSelectableStrategyItemsForOrgUnitQuery({
    skip: activeQuery !== useSelectableStrategyItemsForOrgUnitQuery,
    nextFetchPolicy: 'cache-only',
    variables: { orgUnitId: teamAdapter.keyArg },
    onError: handleApolloError,
  });

  const objectivesConnection =
    activeQuery === useSelectableStrategyItemsForOrgQuery
      ? orgData?.activeOrg.objectives
      : orgUnitData?.orgUnit.objectives;

  const objectives = objectivesConnection?.edges.map((edge) => edge.node);

  const themes = useMemo(
    () => (objectives ? getThemes(objectives) : []),
    [objectives],
  );

  const { statusFilter, setStatusFilter } = useStatusFilter();

  const { themeFilter, setThemeFilter } = useThemeFilter();

  const { userFilter, setUserFilter } = useUserFilter();

  const { dateFilter: startDateFilter, setDateFilter: setStartDateFilter } =
    useDateFilter({ paramName: 'startDate' });

  const { dateFilter: endDateFilter, setDateFilter: setEndDateFilter } =
    useDateFilter({ paramName: 'endDate' });

  const { priorities, isPrioritiesLoading } = useStrategyProfilePriorities();

  const { priorityFilter, setPriorityFilter } = usePriorityFilter({
    priorities,
  });

  const { calendarIntervalStartDate, calendarIntervalEndDate, calendarCheck } =
    useCalendarControls();

  const { showCompleted } = useCompletedFilter();

  const clearFilters = useCallback(() => {
    setSearchParams((searchParams) => {
      searchParams.delete('status');
      searchParams.delete('theme');
      searchParams.delete('user');
      searchParams.delete('startDate');
      searchParams.delete('endDate');
      searchParams.delete('priority');
      return searchParams;
    });
  }, [setSearchParams]);

  const users = sortBy(
    uniqBy(
      (objectives || [])
        .filter((objective) => objective.owner)
        .map((objective) => objective.owner),
      (user) => user?.id,
    ),
    (user) => user?.displayName || user?.email,
  ).filter(Boolean);

  const filteredObjectives = useMemo(() => {
    const filterByThemes = new Set(themeFilter);

    const filterByStatus = [
      ...statusFilter,
      showCompleted && ('COMPLETED' as StatusMultiSelectItemId),
    ].filter(Boolean);

    return (objectives || []).filter(
      (objective) =>
        (filterByThemes.size === 0 ||
          (objective.theme && filterByThemes.has(objective.theme.id))) &&
        filterByStatus.some((eachStatus) =>
          testStatusIndicatorAgainstId(
            eachStatus,
            objective.currentObjectiveStatus?.statusIndicator,
          ),
        ) &&
        priorityFilter.some((eachPriority) =>
          testPriorityAgainstId(eachPriority, objective.priority),
        ) &&
        (userFilter.length === 0 ||
          (objective.owner && userFilter.includes(objective.owner?.id))) &&
        testStrategyItemAgainstInterval(objective, {
          start: startDateFilter,
          end: endDateFilter,
        }) &&
        testStrategyItemAgainstInterval(
          objective,
          {
            start: calendarIntervalStartDate,
            end: calendarIntervalEndDate,
          },
          calendarCheck,
        ),
    );
  }, [
    calendarCheck,
    calendarIntervalEndDate,
    calendarIntervalStartDate,
    endDateFilter,
    objectives,
    priorityFilter,
    showCompleted,
    startDateFilter,
    statusFilter,
    themeFilter,
    userFilter,
  ]);

  const filteredObjectivesByThemes = useMemo(() => {
    const objectivesByThemes = groupObjectivesByTheme<
      SelectableStrategyItemsObjectiveFragment,
      SelectableStrategyItemsThemeFragment
    >(filteredObjectives);

    return sortObjectivesByTheme(
      objectivesByThemes,
      orgUnitData
        ? [
            ...orgUnitData.orgUnit.parentOrgUnitTree,
            orgUnitData.orgUnit,
          ].reverse()
        : [],
    );
  }, [filteredObjectives, orgUnitData]);

  const contextValue = useMemo<
    SelectableStrategyItemsProviderContextValue | undefined
  >(
    () =>
      !isPrioritiesLoading && objectives
        ? {
            objectives,
            filteredObjectives,
            filteredObjectivesByThemes,
            themes,
            users,
            filter: {
              clearFilters,
              themeFilter,
              setThemeFilter,
              userFilter,
              setUserFilter,
              statusFilter,
              setStatusFilter,
              priorityFilter,
              setPriorityFilter,
              startDateFilter,
              setStartDateFilter,
              endDateFilter,
              setEndDateFilter,
            },
          }
        : undefined,
    [
      isPrioritiesLoading,
      objectives,
      filteredObjectives,
      filteredObjectivesByThemes,
      themes,
      users,
      clearFilters,
      themeFilter,
      setThemeFilter,
      userFilter,
      setUserFilter,
      statusFilter,
      setStatusFilter,
      priorityFilter,
      setPriorityFilter,
      startDateFilter,
      setStartDateFilter,
      endDateFilter,
      setEndDateFilter,
    ],
  );

  if (contextValue) {
    return (
      <SelectableStrategyItemsProviderContext.Provider value={contextValue}>
        {children}
      </SelectableStrategyItemsProviderContext.Provider>
    );
  }

  return <Spinner.Circle />;
};

export default SelectableStrategyItemsProvider;
