import { useTranslation } from 'react-i18next';

import type { ConfirmationModalProps } from 'shared/components/__DEPRECATED__/ConfirmationModal';
import ConfirmationModal from 'shared/components/__DEPRECATED__/ConfirmationModal';
import useHandleError from 'shared/errors/useHandleError';
import { useToasts } from 'shared/toast/useToasts';

import { useActivateUserMutation } from './ActivateUser.graphql.generated';

type RemoveOrgUnitMemberConfirmationModalProps = Pick<
  ConfirmationModalProps,
  'isOpen' | 'onClose'
> & { userId: string };

const ActivateUserDialog = ({
  isOpen,
  onClose,
  userId,
}: RemoveOrgUnitMemberConfirmationModalProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const handleApolloError = useHandleError();

  const [activateUser] = useActivateUserMutation({
    variables: { userId },
    onError: handleApolloError,
  });

  const onConfirm = async () => {
    const result = await activateUser();

    if (result.errors) {
      addToast({ variant: 'error', children: t('userMenu.activate.error') });
    } else {
      addToast({
        variant: 'success',
        children: t('userMenu.activate.success'),
      });
    }
  };

  return (
    <ConfirmationModal isOpen={isOpen} onClose={onClose} onConfirm={onConfirm}>
      {t('userMenu.activate.cofirmation')}
    </ConfirmationModal>
  );
};

export default ActivateUserDialog;
