import type { PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useHandleError from 'shared/errors/useHandleError';
import { useErrorPage } from 'shared/errors/ErrorPage/useErrorPage';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';

import type { InitiativeContextValue } from './InitiativeOverviewProvider.context';
import { InitiativeContext } from './InitiativeOverviewProvider.context';
import { useInitiativeOverviewQuery } from './InitiativeOverviewProvider.graphql.generated';

type InitiativeProviderProps = PropsWithChildren<object>;

const InitiativeProvider = ({ children }: InitiativeProviderProps) => {
  const { initiativeId } = useParams() as { initiativeId: Maybe<string> };

  const onError = useHandleError();

  const { data, loading: isInitiativeLoading } = useInitiativeOverviewQuery({
    variables: {
      initiativeId: initiativeId!,
    },
    skip: !initiativeId,
    onError,
  });

  const { onErrorCode } = useErrorPage();

  useEffect(() => {
    if (data && !canPerformStrategyElementAction(data.initiative, 'READ')) {
      onErrorCode('ACCESS_DENIED');
    }
  }, [data, onErrorCode]);

  const contextValue = useMemo<InitiativeContextValue>(
    () => ({
      initiative: data?.initiative,
      isInitiativeLoading,
    }),
    [data, isInitiativeLoading],
  );

  return (
    <InitiativeContext.Provider value={contextValue}>
      {children}
    </InitiativeContext.Provider>
  );
};

export default InitiativeProvider;
