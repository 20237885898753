import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Space from 'shared/components/Space';
import CreateStrategyButton from 'strategy/CreateStrategyButton';
import Heading from 'shared/components/Heading';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import { strategyRoomRoutesPaths } from 'strategy/StrategyRoom/StrategyRoom.routing.paths';

import styles from './StrategyRoomTableViewBefore.module.scss';

const StrategyRoomTableViewBefore = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleCreateStrategy = useCallback(() => {
    navigate(strategyRoomRoutesPaths.create());
  }, [navigate]);

  return (
    <Space className={styles.strategyRoomTableViewBefore}>
      <Heading level={4} hasMargin={false}>
        {t('strategy.strategyRoom.tableView.before.heading')}
      </Heading>
      <CreateStrategyButton
        onPress={handleCreateStrategy}
        icon={PlusIcon}
        iconPosition={'start'}
      >
        {t('create')}
      </CreateStrategyButton>
    </Space>
  );
};

export default StrategyRoomTableViewBefore;
