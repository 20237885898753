import { useMemo } from 'react';
import cn from 'classnames';

import { determineIfIsOverdue } from 'actions/action.utils';
import ActionChipOverdue from 'actions/ActionChipOverdue';
import type { ActionWithObjective } from 'actions/actions.type';
import { ReactComponent as DateIcon } from 'shared/static/icons/icon-date.svg';
import { ReactComponent as CheckSquareIcon } from 'shared/static/icons/icon-check-square.svg';
import UserAvatar from 'user/UserAvatar';
import { date } from 'shared/services/date.service';
import CompletionRate from 'shared/components/CompletionRate';
import useDialogState from 'shared/hooks/useDialogState';
import ActionStatusModal from 'actions/ActionStatusModal';
import ActionContextMenu from 'actions/ActionContextMenu/ActionContextMenu';
import TeamAvatar from 'team/TeamAvatar/TeamAvatar';

import styles from './ActionCard.module.scss';

type ActionCardProps = {
  action: ActionWithObjective;
};

const ActionCard = ({ action }: ActionCardProps) => {
  const { name, description, timeLine, owner } = action;

  const isActionOverdue = useMemo(() => determineIfIsOverdue(action), [action]);

  const { isOpen, onOpen, onClose } = useDialogState();

  return (
    <>
      <div className={styles.card} onClick={onOpen}>
        <div className={styles.content}>
          <div className={styles.name}>
            <CheckSquareIcon className={styles.nameIcon} />
            <div className={styles.nameText}>{name}</div>
          </div>
          {description && (
            <div className={styles.description}>{description}</div>
          )}
        </div>
        <footer className={styles.footer}>
          <div className={styles.timeline}>
            <DateIcon className={styles.timelineIcon} />
            <div
              className={cn(styles.timelineText, {
                [styles.timelineTextOverdue]: isActionOverdue,
              })}
            >
              {date.format(timeLine.endDate, 'dd MMM')}
            </div>
          </div>
          {isActionOverdue && <ActionChipOverdue />}
          <CompletionRate
            complete={action.currentStatus?.complete}
            completionRate={action.currentStatus?.completionRate}
          />
          <TeamAvatar orgUnit={action.orgUnit} size={'small'} />
          <UserAvatar user={owner} containerClassName={styles.avatar} />
          <ActionContextMenu action={action} />
        </footer>
      </div>

      <ActionStatusModal
        actionId={action.id}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default ActionCard;
