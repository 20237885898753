import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { sortBy } from 'lodash';

import Spinner from 'shared/spinner/Spinner';
import type { TeamAdapter } from 'team/TeamAdapter';
import useHandleError from 'shared/errors/useHandleError';
import { getNodesFromConnection } from 'shared/graphql/utils';

import {
  useTeamMembersFromOrgQuery,
  useTeamMembersFromOrgUnitQuery,
} from './TeamMembersProvider.graphql.generated';
import { TeamMembersContext } from './TeamMembersProvider.context';

type TeamMembersProviderProps = {
  teamAdapter: TeamAdapter;
} & PropsWithChildren<object>;

const TeamMembersProvider = ({
  teamAdapter,
  children,
}: TeamMembersProviderProps) => {
  const onError = useHandleError();

  const { data: teamMembersFromOrgData } = useTeamMembersFromOrgQuery({
    fetchPolicy: 'cache-and-network',
    skip: !teamAdapter.isOrg,
    onError,
  });

  const { data: teamMembersFromOrgUnitData } = useTeamMembersFromOrgUnitQuery({
    fetchPolicy: 'cache-and-network',
    skip: teamAdapter.isOrg,
    onError,
    variables: { orgUnitId: teamAdapter.keyArg },
  });

  const users = useMemo(() => {
    if (teamMembersFromOrgData) {
      return getNodesFromConnection(teamMembersFromOrgData.allUsers);
    }

    if (teamMembersFromOrgUnitData) {
      const orgUnit = teamMembersFromOrgUnitData.orgUnit;

      const orgUnitHierarchyIds = [
        orgUnit.id,
        ...orgUnit.childOrgUnitTree.map((orgUnit) => orgUnit.id),
      ];

      return getNodesFromConnection(teamMembersFromOrgUnitData.allUsers).filter(
        (user) =>
          user.orgUnitMemberships.memberships.some((membership) =>
            orgUnitHierarchyIds.includes(membership.orgUnit.id),
          ),
      );
    }

    return undefined;
  }, [teamMembersFromOrgData, teamMembersFromOrgUnitData]);

  const sortedUsers = users
    ? sortBy(users, (user) => (user.displayName || user.email).toUpperCase())
    : undefined;

  const contextValue = useMemo(
    () => ({
      users: sortedUsers,
      orgUnit: teamMembersFromOrgUnitData?.orgUnit,
    }),
    [sortedUsers, teamMembersFromOrgUnitData?.orgUnit],
  );

  if (!contextValue) return <Spinner.Circle />;

  return (
    <TeamMembersContext.Provider value={contextValue}>
      {children}
    </TeamMembersContext.Provider>
  );
};

export default TeamMembersProvider;
