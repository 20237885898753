import { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';

import type { ActivityTimelineProps } from 'shared/components/ActivityTimeline/ActivityTimeline';
import ActivityTimeline from 'shared/components/ActivityTimeline/ActivityTimeline';

import type { InitiativeOverviewInitiativeStatus } from '../InitiativeOverview.type';
import InitiativeInsightsFirstRow from '../InitiativeOverviewInsightsFirstRow';
import InitiativeInsightsRow from '../InitiativeOverviewInsightsRow';
import styles from './InitiativeOverviewList.module.scss';
import { getInitiativeStatusListWithInitiativeCreationDate } from '../InitiativeOverview.utils';
import { useInitiativeOverview } from '../InitiativeOverviewProvider';

const Container = styled.div`
  border-top: 1px solid ${(props) => props.theme.color.strokeMedium};
`;

const InitiativeList = () => {
  const { initiative } = useInitiativeOverview();

  const initiativeStatusList = useMemo(
    () =>
      getInitiativeStatusListWithInitiativeCreationDate(
        initiative,
        initiative?.initiativeStatusListAll,
      ),
    [initiative],
  );

  const renderRowContent = useCallback<
    ActivityTimelineProps<InitiativeOverviewInitiativeStatus>['renderRowContent']
  >(
    ({ isRowExpanded, isLastRow, item }) =>
      item.context === 'initiativeCreated' ? (
        <InitiativeInsightsFirstRow
          isLastRow={isLastRow}
          initiative={initiative}
          key={initiative?.id}
        />
      ) : (
        initiative && (
          <InitiativeInsightsRow
            initiative={initiative}
            isRowExpanded={isRowExpanded}
            isLastRow={isLastRow}
            status={item}
            key={item.id}
          />
        )
      ),
    [initiative],
  );

  return (
    <Container>
      {initiative ? (
        <ActivityTimeline
          itemDateKey={'statusDateTime'}
          items={initiativeStatusList}
          renderRowContent={renderRowContent}
          className={styles.activityTimeline}
        />
      ) : null}
    </Container>
  );
};

export default InitiativeList;
