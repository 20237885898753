import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StrategyOverviewAskSophieButtonQueryVariables = Types.Exact<{
  filter: Types.ObjectiveFilterInput;
}>;


export type StrategyOverviewAskSophieButtonQuery = { __typename: 'Query', allObjectives: { __typename: 'ObjectiveConnection', edges: Array<{ __typename: 'ObjectiveEdge', node: { __typename: 'Objective', id: string } }> } };


export const StrategyOverviewAskSophieButtonDocument = gql`
    query StrategyOverviewAskSophieButton($filter: ObjectiveFilterInput!) {
  allObjectives(filter: $filter) {
    edges {
      node {
        id
      }
    }
  }
}
    `;

/**
 * __useStrategyOverviewAskSophieButtonQuery__
 *
 * To run a query within a React component, call `useStrategyOverviewAskSophieButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategyOverviewAskSophieButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategyOverviewAskSophieButtonQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStrategyOverviewAskSophieButtonQuery(baseOptions: Apollo.QueryHookOptions<StrategyOverviewAskSophieButtonQuery, StrategyOverviewAskSophieButtonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StrategyOverviewAskSophieButtonQuery, StrategyOverviewAskSophieButtonQueryVariables>(StrategyOverviewAskSophieButtonDocument, options);
      }
export function useStrategyOverviewAskSophieButtonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StrategyOverviewAskSophieButtonQuery, StrategyOverviewAskSophieButtonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StrategyOverviewAskSophieButtonQuery, StrategyOverviewAskSophieButtonQueryVariables>(StrategyOverviewAskSophieButtonDocument, options);
        }
export type StrategyOverviewAskSophieButtonQueryHookResult = ReturnType<typeof useStrategyOverviewAskSophieButtonQuery>;
export type StrategyOverviewAskSophieButtonLazyQueryHookResult = ReturnType<typeof useStrategyOverviewAskSophieButtonLazyQuery>;
export type StrategyOverviewAskSophieButtonQueryResult = Apollo.QueryResult<StrategyOverviewAskSophieButtonQuery, StrategyOverviewAskSophieButtonQueryVariables>;