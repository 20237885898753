import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { transientOptions } from 'shared/utils/emotion.utils';
import LockedLink from 'shared/components/LockedLink';

import type { BreadcrumbsItem } from '../Breadcrumbs.type';

type BreadcrumbProps = {
  isFirstItem: boolean;
  isLinkMatched: boolean;
  item: BreadcrumbsItem;
};

const BreadcrumbLi = styled(motion.li)`
  &:last-of-type:not(:first-of-type) a {
    color: ${(props) => props.theme.color.neutral2};
  }
`;

const BreadcrumbLink = styled(LockedLink, transientOptions)<{
  $isFirstItem: boolean;
  $isLinkMatched: boolean;
}>`
  color: ${(props) => props.theme.color.typoPrimary};
  font-size: 0.857rem;

  ${(props) =>
    props.$isLinkMatched &&
    !props.$isFirstItem &&
    css`
      color: ${props.theme.color.neutral2};
    `}

  ${(props) =>
    !props.$isFirstItem &&
    css`
      &::before {
        content: '/';
        display: inline-block;
        margin: 0 4px;
      }
    `}

  &:hover {
    color: ${(props) => props.theme.color.neutral1};
  }
`;

const Breadcrumb = ({ isFirstItem, isLinkMatched, item }: BreadcrumbProps) => (
  <BreadcrumbLi
    animate={{ x: 0, opacity: 1 }}
    initial={{ x: isFirstItem ? 0 : -8, opacity: 0 }}
    transition={{ type: 'spring', duration: 0.35, bounce: 0 }}
  >
    <BreadcrumbLink
      to={item.to || '.'}
      $isLinkMatched={isLinkMatched}
      $isFirstItem={isFirstItem}
      isActive={!item.isLocked}
      decorationOnHover={false}
      isInline={true}
    >
      {item.name}
    </BreadcrumbLink>
  </BreadcrumbLi>
);

export default Breadcrumb;
