import { useTranslation } from 'react-i18next';

import Button from 'shared/components/Button';
import Heading from 'shared/components/Heading';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import useDialogState from 'shared/hooks/useDialogState';
import Flex from 'shared/components/Flex';
import { useObjectiveDetails } from 'objective/ObjectiveDetails/ObjectiveDetailsProvider';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import Text from 'shared/components/Text';

import LinkOrgUnitDialog from './LinkOrgUnitDialog';
import LinkedOrgUnitsRow from './LinkedOrgUnitsRow';

const LinkedOrgUnitsSection = () => {
  const { t } = useTranslation();

  const { objective } = useObjectiveDetails();

  const { isOpen, onOpen, onClose } = useDialogState();

  const canLinkOrgUnit =
    objective && canPerformStrategyElementAction(objective, 'WRITE');

  return (
    <>
      <Flex direction={'column'} gap={'1rem'}>
        <Heading level={3} as={5} hasMargin={false}>
          {t('objective.objectiveLayout.sider.linkedOrgUnits.heading')}
        </Heading>

        {objective?.orgUnitLinks.length === 0 ? (
          <Text color={'regular'}>
            {t('objective.objectiveLayout.sider.linkedOrgUnits.empty')}
          </Text>
        ) : (
          <Flex direction={'column'}>
            {objective?.orgUnitLinks.map((orgUnit) => (
              <LinkedOrgUnitsRow key={orgUnit.id} orgUnit={orgUnit} />
            ))}
          </Flex>
        )}

        <div>
          {canLinkOrgUnit && (
            <Button
              variant={'outlined'}
              icon={PlusIcon}
              iconPosition={'start'}
              onPress={onOpen}
            >
              {t('objective.objectiveLayout.sider.linkedOrgUnits.button')}
            </Button>
          )}
        </div>
      </Flex>

      <LinkOrgUnitDialog isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default LinkedOrgUnitsSection;
