import type { PropsWithChildren } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import Form from 'shared/form/Form';
import { useTeamAdapter } from 'team/TeamAdapter';
import type { ObjectiveInput } from 'types.graphql.generated';
import type { SelectObjectiveInputFieldOption } from 'objective/SelectObjectiveInput';
import type { SelectThemeInputOption } from 'objective/SelectThemeInput';
import { date } from 'shared/services/date.service';
import type { AttributeFieldValue } from 'shared/components/AttributesFields';
import type { SelectOrgUnitInputFieldOption } from 'orgUnit/SelectOrgUnitInput';
import type { PriorityFieldValue } from 'strategy/PrioritySelect/PrioritySelect';
import { timeLineValidations } from 'shared/utils/timeLine.utils';

export type ObjectiveFormValues = {
  attributes: AttributeFieldValue[];
  description: string;
  id: string | null;
  name: string;
  orgUnit: SelectOrgUnitInputFieldOption;
  owner: string;
  parentObjective: SelectObjectiveInputFieldOption;
  priority: PriorityFieldValue | null;
  theme: SelectThemeInputOption;
  timeLine: {
    endDate?: Date | null;
    startDate?: Date | null;
  };
};

type Props = PropsWithChildren<{
  formId: string;
  initialValues: ObjectiveFormValues;
  onSubmit: (input: ObjectiveInput) => Promise<unknown>;
}>;

const ObjectiveForm = ({
  initialValues,
  onSubmit,
  formId,
  children,
}: Props) => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();

  const validationSchema = yup.object({
    name: yup.string().required(),
    owner: yup.string().email().required(),
    timeLine: timeLineValidations(t),
  });

  const handleSubmit = (values: ObjectiveFormValues) => {
    onSubmit({
      idToUpdate: values.id,
      orgKey: teamAdapter.orgKey,
      name: values.name,
      description: values.description,
      orgUnit: values.orgUnit.value?.id
        ? { idToSet: values.orgUnit.value.id }
        : values.id
        ? { idToRemove: initialValues.orgUnit.value?.id }
        : undefined,
      owner: {
        emailToSet: values.owner,
      },
      timeLine: {
        startDate: values.timeLine.startDate
          ? date.format(values.timeLine.startDate, 'yyyy-MM-dd')
          : null,
        endDate: values.timeLine.endDate
          ? date.format(values.timeLine.endDate, 'yyyy-MM-dd')
          : null,
      },
      parentObjective: values.parentObjective.value
        ? { idToSet: values.parentObjective.value.id }
        : values.id
        ? { idToRemove: initialValues.parentObjective.value?.id }
        : undefined,
      theme: values.theme.value
        ? { idToSet: values.theme.value.id }
        : values.id
        ? { idToRemove: initialValues.theme.value?.id }
        : undefined,
      priority: values.priority,
      attributes: {
        ...(values.id
          ? {
              update: values.attributes.map((attribute) => ({
                idToUpdate: attribute.id,
                description: attribute.description,
              })),
            }
          : {
              add: values.attributes.map((attribute, orderNumber) => ({
                ...attribute,
                orderNumber,
              })),
            }),
      },
    });
  };

  return (
    <Form<ObjectiveFormValues>
      id={formId}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

export default ObjectiveForm;
