import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { signOut } from 'firebase/auth';

import type { ContextMenuOnActionFn } from 'shared/components/ContextMenu';
import ContextMenu from 'shared/components/ContextMenu';
import { ReactComponent as IconExit } from 'shared/static/icons/icon-exit.svg';
import { ReactComponent as IconEdit } from 'shared/static/icons/icon-edit.svg';
import EditUserProfileModal from 'user/EditUserProfileModal';
import { useUser } from 'user/UserProvider';
import { auth } from 'auth/AuthProvider';
import { useRedirect } from 'shared/providers/RedirectProvider/useRedirect';

import type { UserContextMenuItem } from './UserAvatarContextMenu.type';
import UserContextMenuTrigger from './UserAvatarContextMenuTrigger';

const UserAvatarContextMenu = () => {
  const { t } = useTranslation();
  const { clearRedirectPath } = useRedirect();

  const { user } = useUser();
  const [isEditOpen, setIsEditOpen] = useState(false);

  const items = useMemo<UserContextMenuItem[]>(
    () => [
      {
        icon: IconEdit,
        id: 'editProfile',
        title: t('userMenu.editProfile'),
      },
      {
        icon: IconExit,
        id: 'signOut',
        title: t('userMenu.logOut'),
      },
    ],
    [t],
  );

  const onAction = useCallback<
    ContextMenuOnActionFn<UserContextMenuItem['id']>
  >(
    (id) => {
      switch (id) {
        case 'editProfile': {
          setIsEditOpen(true);
          break;
        }
        case 'signOut': {
          signOut(auth).then(() => setTimeout(clearRedirectPath, 0));
          break;
        }
      }
    },
    [clearRedirectPath],
  );

  const handleClose = useCallback(() => setIsEditOpen(false), []);

  return (
    <>
      <ContextMenu<UserContextMenuItem>
        Trigger={UserContextMenuTrigger}
        items={items}
        onAction={onAction}
      />

      <EditUserProfileModal
        userId={user.id}
        isOpen={isEditOpen}
        onClose={handleClose}
      />
    </>
  );
};

export default UserAvatarContextMenu;
