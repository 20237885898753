import { useTranslation } from 'react-i18next';

import { useTeamAdapter } from 'team/TeamAdapter';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';

import type {
  StrategyOperationalItem,
  StrategyOperationalItemType,
} from './StrategyBoardList.type';
import StrategyBoardListRow from '../StrategyBoardListRow';
import styles from './StrategyBoardList.module.scss';
import { getStrategyOperationalItemLink } from './StrategyBoardList.utils';

type StrategyBoardListProps = {
  isDisabled?: boolean;
  items: StrategyOperationalItem[];
  operationalItemType: StrategyOperationalItemType;
  showOrgUnit?: boolean;
};

const StrategyBoardList = ({
  items,
  operationalItemType,
  showOrgUnit = false,
  isDisabled,
}: StrategyBoardListProps) => {
  const { t } = useTranslation();

  const { teamAdapter } = useTeamAdapter();

  return (
    <section className={styles.operationalItemsListContainer}>
      <ul className={styles.operationalItemsList}>
        {items.length ? (
          items.map((item) => (
            <StrategyBoardListRow
              key={item.id}
              owner={item.owner}
              statusIndicator={item.status?.statusIndicator}
              textContent={item.name}
              isLink={item.isLink}
              isLocked={!canPerformStrategyElementAction(item, 'READ')}
              isDisabled={isDisabled}
              linkTo={getStrategyOperationalItemLink(item, {
                teamAdapter,
              })}
              orgUnit={
                item.__typename === 'Objective' ? item.orgUnit : undefined
              }
              showOrgUnit={showOrgUnit}
            />
          ))
        ) : (
          <StrategyBoardListRow
            textContent={t(`strategy.strategyBoard.no${operationalItemType}`)}
            isLink={false}
            isLocked={false}
            showOrgUnit={false}
          />
        )}
      </ul>
    </section>
  );
};

export default StrategyBoardList;
