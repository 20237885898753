import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import useHandleError from 'shared/errors/useHandleError';

import type { InsightReportSummaryProps } from '../InsightReportSummary';
import { useInsightReportSummaryProviderReportQuery } from './InsightReportSummaryProvider.graphql.generated';
import type { InsightReportSummaryProviderContextValue } from './InsightReportSummaryProvider.context';
import { InsightReportSummaryProviderContext } from './InsightReportSummaryProvider.context';

type InsightReportSummaryProviderProps = PropsWithChildren<
  Pick<InsightReportSummaryProps, 'report'>
>;

const InsightReportSummaryProvider = ({
  report,
  children,
}: InsightReportSummaryProviderProps) => {
  const handleApolloError = useHandleError();

  const { data } = useInsightReportSummaryProviderReportQuery({
    variables: { id: report.id },
    onError: handleApolloError,
  });

  const contextValue = useMemo<
    InsightReportSummaryProviderContextValue | undefined
  >(() => {
    if (data) {
      return {
        report: data.report,
      };
    }
    return undefined;
  }, [data]);

  if (contextValue) {
    return (
      <InsightReportSummaryProviderContext.Provider value={contextValue}>
        {children}
      </InsightReportSummaryProviderContext.Provider>
    );
  }

  return null;
};

export default InsightReportSummaryProvider;
