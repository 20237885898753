import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Field } from 'formik';
import * as yup from 'yup';

import Dialog from 'shared/components/Dialog';
import useHandleError from 'shared/errors/useHandleError';
import { useTeamAdapter } from 'team/TeamAdapter';
import { useUser } from 'user/UserProvider';
import { useToasts } from 'shared/toast/useToasts';
import Form from 'shared/form/Form';
import OpenTextInputField from 'shared/components/OpenTextInput/OpenTextInput.field';
import { ContributionMyContributionDocument } from 'contribution/ContributionMyContribution/ContributionMyContribution.graphql.generated';
import { ContributionMyFollowUpsViewMoreDocument } from 'contribution/ContributionMyFollowUpsViewMore/ContributionMyFollowUpsViewMore.graphql.generated';
import {
  StrategyRoadmapOrgDocument,
  StrategyRoadmapOrgUnitDocument,
} from 'strategy/StrategyRoadmap/StrategyRoadmapProvider/StrategyRoadmapProvider.graphql.generated';

import type {
  CreateTaskObjective,
  OneThingCampaignForCreateTask,
} from './OneThingCreateTaskButton.types';
import { useCreateOneThingTaskMutation } from './CreateOneThingTask.graphql.generated';

type FormValues = {
  task: string;
};

const formId = 'task';

type Props = {
  campaign: OneThingCampaignForCreateTask;
  isOpen: boolean;
  objective: CreateTaskObjective;
  onBack: () => void;
  onClose: () => void;
  onSelect: (objective: CreateTaskObjective) => void;
};

const TaskDialog = ({
  campaign,
  objective,
  isOpen,
  onBack,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();
  const { user } = useUser();
  const { addToast } = useToasts();

  const handleApolloError = useHandleError();
  const [createOneThingTask, { loading }] = useCreateOneThingTaskMutation({
    onError: handleApolloError,
    refetchQueries: [
      ContributionMyContributionDocument,
      ContributionMyFollowUpsViewMoreDocument,
      StrategyRoadmapOrgDocument,
      StrategyRoadmapOrgUnitDocument,
    ],
  });

  const handleSubmit = async ({ task }: FormValues) => {
    if (loading) return;

    const result = await createOneThingTask({
      variables: {
        input: {
          orgKey: teamAdapter.orgKey,
          campaign: campaign.id,
          owner: { idToSet: user.id },
          objective: objective.id,
          task: task,
          timeLine: {
            startDate: format(new Date(), 'yyyy-MM-dd'),
          },
        },
      },
    });

    if (result.data?.saveOneThingTask.id) {
      onClose();
      addToast({
        variant: 'success',
        children: t(
          'toolkit.toolPage.oneThing.createTask.wizard.toast.success',
        ),
      });
    }
  };

  const validationSchema = yup.object({
    task: yup.string().required(),
  });

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      headerPretitle={'2/2'}
      headerTitle={t(
        'toolkit.toolPage.oneThing.createTask.wizard.defineOneThing.title',
      )}
      headerSubtitle={[
        t(
          'toolkit.toolPage.oneThing.createTask.wizard.defineOneThing.subtitle',
          { objective: objective.name },
        ),
        campaign.deadline
          ? t(
              'toolkit.toolPage.oneThing.createTask.wizard.defineOneThing.deadline',
              { deadline: format(campaign.deadline, 'P') },
            )
          : undefined,
      ].filter(Boolean)}
      confirmLabel={t('create')}
      isConfirmDisabled={loading}
      cancelLabel={t('back')}
      onCancel={onBack}
      formId={formId}
      animateOpen={false}
      animateClose={false}
    >
      <Form<FormValues>
        id={formId}
        initialValues={{ task: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Field
          component={OpenTextInputField}
          name={'task'}
          placeholder={t(
            'toolkit.toolPage.oneThing.createTask.wizard.defineOneThing.input.placeholder',
          )}
          fontSize={'big'}
          inputType={'textarea'}
        />
      </Form>
    </Dialog>
  );
};

export default TaskDialog;
