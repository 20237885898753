import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'shared/components/Tooltip';
import { ReactComponent as LockOnIcon } from 'shared/static/icons/icon-lock-on.svg';

const LockIcon = () => {
  const { t } = useTranslation();

  return (
    <Tooltip
      content={t('lockIcon.tooltip')}
      delay={0}
      css={css`
        width: 14px;
        height: 14px;
      `}
    >
      <LockOnIcon
        data-testid={'lock-icon'}
        css={css`
          width: 14px;
          height: 14px;
        `}
      />
    </Tooltip>
  );
};

export default LockIcon;
