import { useToggle } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { Trans, useTranslation } from 'react-i18next';
import { Fragment, useMemo } from 'react';
import cn from 'classnames';
import styled from '@emotion/styled';

import { date } from 'shared/services/date.service';
import { expandChildAnimation } from 'shared/animations/expandChild.animation';
import InsightsCommentSection from 'shared/components/InsightsCommentSection';
import ObjectiveInsightsActivityTimelineRowHeader from 'objective/ObjectiveInsights/ObjectiveInsightsActivityTimelineRowHeader';
import InsightsComment from 'shared/components/InsightsComment';
import Divider from 'shared/components/Divider';
import InitiativeStatusContextMenu from 'initiative/InitiativeStatusContextMenu';
import type { InitiativeForStatusContextMenu } from 'initiative/InitiativeStatusContextMenu/InitiativeStatusContextMenu';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';

import styles from './InitiativeOverviewInsightsRow.module.scss';
import InitiativeInsightsRowScoreComment from '../InitiativeOverviewInsightsRowScoreComment';
import type { InitiativeOverviewInitiativeStatus } from '../InitiativeOverview.type';
import InitiativeOverviewMilestoneStatusSection from '../InitiativeOverviewMilestoneStatusSection';

const ContextMenuContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

type InitiativeInsightsRowProps = {
  initiative: InitiativeForStatusContextMenu;
  isLastRow?: boolean;
  isRowExpanded?: boolean;
  status: InitiativeOverviewInitiativeStatus;
};

const InitiativeInsightsRow = ({
  initiative,
  status,
  isRowExpanded: isRowExpandedProp = false,
  isLastRow = false,
}: InitiativeInsightsRowProps) => {
  const { t } = useTranslation();

  const [isRowExpanded, toggleIsRowExpanded] = useToggle(isRowExpandedProp);

  const updateDateTime = useMemo(
    () =>
      status.auditRecord.updateDateTime
        ? date.format(status.auditRecord.updateDateTime, 'dd MMM yyyy')
        : '',
    [status.auditRecord.updateDateTime],
  );

  const canReadStatusComments = canPerformStrategyElementAction(
    initiative,
    'READ_STATUS_COMMENTS',
  );

  return (
    <motion.li
      key={status.id}
      className={cn(styles.item, { [styles.lastRowItem]: isLastRow })}
      {...expandChildAnimation}
    >
      <AnimatePresence initial={false}>
        <Fragment key={status.id + status.statusIndicator.value}>
          <ObjectiveInsightsActivityTimelineRowHeader
            status={status}
            isRowExpanded={isRowExpanded}
            onClick={toggleIsRowExpanded}
          />
          {isRowExpanded ? (
            <motion.div {...expandChildAnimation} className={styles.container}>
              <ContextMenuContainer>
                <InitiativeStatusContextMenu
                  initiative={initiative}
                  status={status}
                />
              </ContextMenuContainer>
              <InsightsComment
                title={t('objective.insights.recentInsights.score.heading')}
                comment={<InitiativeInsightsRowScoreComment status={status} />}
              />
              <InitiativeOverviewMilestoneStatusSection
                milestoneStatuses={status.milestoneStatuses}
              />
              {canReadStatusComments && (
                <>
                  <InsightsComment comment={status.comment} />
                  <Divider />
                  <InsightsCommentSection
                    context={'successes'}
                    comments={status.successComments}
                  />
                  <InsightsCommentSection
                    context={'challenges'}
                    comments={status.challengeComments}
                  />
                  <InsightsCommentSection
                    context={'actions'}
                    comments={status.actionComments}
                  />
                </>
              )}
              <div className={styles.updateDate}>
                <Trans
                  i18nKey={'objective.insights.recentInsights.updated'}
                  values={{
                    date: updateDateTime,
                  }}
                />
              </div>
            </motion.div>
          ) : null}
        </Fragment>
      </AnimatePresence>
    </motion.li>
  );
};

export default InitiativeInsightsRow;
