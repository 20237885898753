import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChipGroupProps } from 'shared/components/ChipGroup';
import ChipGroup from 'shared/components/ChipGroup';

import { useStatusMultiSelect } from '../StatusMultiSelectProvider';
import type { StatusMultiSelectItem } from '../StatusMultiSelect.type';

type StatusMultiSelectChipGroupProps = {
  className?: string;
};

const StatusMultiSelectChipGroup = ({
  className,
}: StatusMultiSelectChipGroupProps) => {
  const { t } = useTranslation();
  const { state, items } = useStatusMultiSelect();

  const handleRemove = useCallback<
    ChipGroupProps<StatusMultiSelectItem>['onRemove']
  >(
    (item) => {
      if (state.selectionManager.selectedKeys.size === 1) {
        state.selectionManager.toggleSelection('ALL');
      } else {
        state.selectionManager.toggleSelection(item.id);
      }
    },
    [state],
  );

  const handleRemoveAll = useCallback<
    NonNullable<ChipGroupProps<StatusMultiSelectItem>['onRemoveAll']>
  >(() => {
    state.selectionManager.replaceSelection('ALL');
  }, [state]);

  const renderLabel = useCallback<
    NonNullable<ChipGroupProps<StatusMultiSelectItem>['renderLabel']>
  >((item) => item.statusIndicator?.getName(t), [t]);

  const chipGroupItems = useMemo(
    () =>
      items.filter(
        (item) =>
          item.statusIndicator && state.selectionManager.isSelected(item.id),
      ),
    [items, state],
  );

  return (
    <ChipGroup<StatusMultiSelectItem>
      items={chipGroupItems}
      onRemove={handleRemove}
      onRemoveAll={chipGroupItems.length ? handleRemoveAll : undefined}
      renderLabel={renderLabel}
      className={className}
    />
  );
};

export default StatusMultiSelectChipGroup;
