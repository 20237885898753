import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import useUserHasAuthority from 'user/ability/useUserHasAuthority';
import TextArea from 'shared/components/TextArea';
import Flex from 'shared/components/Flex';
import Button from 'shared/components/Button';

import type { Message } from './AskSophieSidePanel';
import { useAskSophie } from '../AskSophieProvider';

type Props = {
  addMessage: (message: Message) => void;
  isLoading: boolean;
};

const FreeTextPrompt = ({ addMessage, isLoading }: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const isSuperAdmin = useUserHasAuthority('ROLE_SUPER_ADMIN');

  const { objectiveIds, metricIds, initiativeIds, actionIds, strategyId } =
    useAskSophie();

  const handleSubmit = useCallback(() => {
    addMessage({ type: 'InputMessage', content: value });
    addMessage({
      type: 'SummaryMessage',
      objectiveIds,
      metricIds,
      initiativeIds,
      actionIds,
      strategyId,
      mainPrompt: value,
    });

    setValue('');
  }, [
    actionIds,
    addMessage,
    initiativeIds,
    metricIds,
    objectiveIds,
    strategyId,
    value,
  ]);

  if (!isSuperAdmin) return null;

  const isSendDisabled = isLoading || value.length === 0;

  return (
    <Flex gap={8} alignSelf={'stretch'}>
      <TextArea
        value={value}
        onChange={setValue}
        isDisabled={isLoading}
        fullWidth={true}
        placeholder={t('ai.strategySummary.freeTextPrompt.placeholder')}
        maxLength={7000}
      />
      <Button
        isDisabled={isSendDisabled}
        onPress={handleSubmit}
        css={css({ height: 63 })}
      >
        {t('submit')}
      </Button>
    </Flex>
  );
};

export default FreeTextPrompt;
