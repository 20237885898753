import { useToggle } from 'react-use';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import Layout from 'shared/components/Layout';
import type { StatusMultiSelectItemId } from 'shared/status/StatusMultiSelect';
import StatusMultiSelect from 'shared/status/StatusMultiSelect';
import type { ThemeMultiSelectItemId } from 'themes/ThemeMultiSelect';
import ThemeMultiSelect from 'themes/ThemeMultiSelect';
import type { UserMultiSelectItemId } from 'user/UserMultiSelect';
import UserMultiSelect from 'user/UserMultiSelect';
import type { PriorityMultiSelectItemId } from 'shared/priority/PriorityMultiSelect';
import PriorityMultiSelect from 'shared/priority/PriorityMultiSelect';
import ToggleExpandIconButton from 'shared/components/ToggleExpandIconButton';
import CalendarControls, {
  CalendarControlsProvider,
} from 'shared/components/CalendarControls';
import Flex from 'shared/components/Flex';
import CreateObjectiveButton from 'objective/CreateObjectiveButton';
import CompletedFilterProvider from 'shared/components/CompletedFilterButton/CompletedFilterProvider';
import CompletedFilterButton from 'shared/components/CompletedFilterButton';

import StrategyObjectivesHeader from './StrategyObjectivesHeader';
import StrategyObjectivesContent from './StrategyObjectivesContent';
import StrategyObjectivesProvider, {
  useStrategyObjectives,
} from './StrategyObjectivesProvider';
import StrategyObjectiveFiltersButton from './StrategyObjectiveFiltersButton';
import StrategyObjectivesAskSophieButton from './StrategyObjectivesAskSophieButton';

const StrategyObjectives = () => {
  const { t } = useTranslation();
  const [areAllSectionsExpanded, toggleAreAllSectionsExpanded] =
    useToggle(true);

  const {
    themes,
    filters: {
      statusIndicatorFilter,
      setStatusIndicatorFilter,
      themeFilter,
      setThemeFilter,
      setUserFilter,
      userFilter,
      priorityFilter,
      setPriorityFilter,
      clearFilters,
    },
    users,
  } = useStrategyObjectives();

  return (
    <UserMultiSelect.Provider
      selectedKeys={userFilter}
      onSelectionChange={(selection) => {
        setUserFilter([...selection] as UserMultiSelectItemId[]);
      }}
      users={users}
    >
      <StatusMultiSelect.Provider
        selectedKeys={statusIndicatorFilter}
        onSelectionChange={(selection) => {
          setStatusIndicatorFilter([...selection] as StatusMultiSelectItemId[]);
        }}
      >
        <ThemeMultiSelect.Provider
          themes={themes || []}
          selectedKeys={themeFilter}
          onSelectionChange={(selection) => {
            setThemeFilter([...selection] as ThemeMultiSelectItemId[]);
          }}
        >
          <PriorityMultiSelect.Provider
            selectedKeys={priorityFilter}
            onSelectionChange={(selection) => {
              setPriorityFilter([...selection] as PriorityMultiSelectItemId[]);
            }}
          >
            <Layout.Content hasPadding={false} variant={'contained'}>
              <StrategyObjectivesHeader>
                <CalendarControls />

                <Flex
                  gap={8}
                  css={css({ flex: '1' })}
                  justifyContent={'flex-end'}
                >
                  <CompletedFilterButton />

                  <StrategyObjectiveFiltersButton clearFilters={clearFilters} />

                  <ToggleExpandIconButton
                    onToggle={toggleAreAllSectionsExpanded}
                    isExpanded={areAllSectionsExpanded}
                  />

                  <StrategyObjectivesAskSophieButton />

                  <CreateObjectiveButton
                    variant={'contained'}
                    showSuccessDialog={true}
                  >
                    {t('strategy.strategyObjectives.addNewObjective')}
                  </CreateObjectiveButton>
                </Flex>
              </StrategyObjectivesHeader>

              <StrategyObjectivesContent
                areAllSectionsExpanded={areAllSectionsExpanded}
              />
            </Layout.Content>
          </PriorityMultiSelect.Provider>
        </ThemeMultiSelect.Provider>
      </StatusMultiSelect.Provider>
    </UserMultiSelect.Provider>
  );
};

export default () => (
  <CalendarControlsProvider storeToSession={true}>
    <CompletedFilterProvider storeToSession={true}>
      <StrategyObjectivesProvider>
        <StrategyObjectives />
      </StrategyObjectivesProvider>
    </CompletedFilterProvider>
  </CalendarControlsProvider>
);
