import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import Spinner from 'shared/spinner/Spinner';
import { useTeamAdapter } from 'team/TeamAdapter';
import { getNodesFromConnection } from 'shared/graphql/utils';
import useHandleError from 'shared/errors/useHandleError';

import {
  useStrategyInsightsReportsForOrgQuery,
  useStrategyInsightsReportsForOrgUnitQuery,
} from './StrategyInsightsReportsProvider.graphql.generated';
import type { StrategyInsightsReportsProviderContextValue } from './StrategyInsightsReportsProvider.context';
import { StrategyInsightsReportsProviderContext } from './StrategyInsightsReportsProvider.context';

type StrategyInsightsReportsProviderProps = PropsWithChildren<object>;

const StrategyInsightsReportsProvider = ({
  children,
}: StrategyInsightsReportsProviderProps) => {
  const { teamAdapter } = useTeamAdapter();

  const handleApolloError = useHandleError();

  const activeQuery = teamAdapter.isOrgUnit
    ? useStrategyInsightsReportsForOrgUnitQuery
    : useStrategyInsightsReportsForOrgQuery;

  const {
    data: orgData,
    loading: orgLoading,
    refetch: refetchOrg,
  } = useStrategyInsightsReportsForOrgQuery({
    skip: activeQuery !== useStrategyInsightsReportsForOrgQuery,
    fetchPolicy: 'cache-and-network',
    onError: handleApolloError,
  });

  const {
    data: orgUnitData,
    loading: orgUnitLoading,
    refetch: refetchOrgUnit,
  } = useStrategyInsightsReportsForOrgUnitQuery({
    skip: activeQuery !== useStrategyInsightsReportsForOrgUnitQuery,
    fetchPolicy: 'cache-and-network',
    variables: { orgUnitId: teamAdapter.keyArg },
    onError: handleApolloError,
  });

  const data =
    activeQuery === useStrategyInsightsReportsForOrgUnitQuery
      ? orgUnitData?.orgUnit
      : orgData?.activeOrg;
  const refetch =
    activeQuery === useStrategyInsightsReportsForOrgUnitQuery
      ? refetchOrgUnit
      : refetchOrg;
  const loading =
    activeQuery === useStrategyInsightsReportsForOrgUnitQuery
      ? orgUnitLoading
      : orgLoading;

  const contextValue = useMemo<
    Maybe<StrategyInsightsReportsProviderContextValue>
  >(() => {
    if (data) {
      const reports = getNodesFromConnection(data.reports).flat();
      return {
        reports,
        refetchReports: refetch,
        areReportsLoading: loading,
      };
    }
    return undefined;
  }, [loading, data, refetch]);

  if (contextValue) {
    return (
      <StrategyInsightsReportsProviderContext.Provider value={contextValue}>
        {children}
      </StrategyInsightsReportsProviderContext.Provider>
    );
  }

  return <Spinner.Circle />;
};

export default StrategyInsightsReportsProvider;
