import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { capitalize } from 'lodash';

import { getBehaviorItems, hasBehaviors } from 'strategy/strategy.utils';
import type { CardBorder } from 'shared/components/Card/Card.type';
import useDialogState from 'shared/hooks/useDialogState';

import { backgroundColors, illustrations } from '../StrategyRoadmap.static';
import StrategyRoadmapCard from '../StrategyRoadmapCard';
import StrategyRoadmapChips from '../StrategyRoadmapChips';
import StrategyRoadmapBehaviorsModal from '../StrategyRoadmapBehaviorsModal';
import type { StrategyRoadmapStrategyFieldsFragment } from '../StrategyRoadmapProvider/StrategyRoadmapProvider.graphql.generated';

type StrategyRoadmapBehaviorsCardProps = {
  border?: CardBorder;
  hasShadow?: boolean;
  showEditButton?: boolean;
  strategy: StrategyRoadmapStrategyFieldsFragment;
};

const StrategyRoadmapBehaviorsCard = ({
  strategy,
  hasShadow,
  border,
  showEditButton = true,
}: StrategyRoadmapBehaviorsCardProps) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDialogState();

  const isEmpty = !hasBehaviors(strategy);

  const content = useMemo(() => {
    if (!isEmpty) {
      const items = getBehaviorItems(strategy);

      if (items.length > 0) {
        return (
          <StrategyRoadmapChips
            items={items}
            maxItemsCount={3}
            onClickMore={onOpen}
          />
        );
      }
    }
    return t('noData');
  }, [isEmpty, onOpen, strategy, t]);

  return (
    <>
      <StrategyRoadmapCard
        illustration={illustrations.behaviors}
        illustrationBackgroundColor={backgroundColors.behaviors}
        heading={capitalize(t('strategy.behavior_other'))}
        onMaximize={onOpen}
        hasShadow={hasShadow}
        border={border}
      >
        {content}
      </StrategyRoadmapCard>
      <StrategyRoadmapBehaviorsModal
        strategy={strategy}
        isOpen={isOpen}
        onClose={onClose}
        showEditButton={showEditButton}
      />
    </>
  );
};

export default StrategyRoadmapBehaviorsCard;
