import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import useHandleError from 'shared/errors/useHandleError';
import GlobalSpinner from 'shared/spinner/GlobalSpinner';

import type { UserTenantsValue } from './UserTenants.context';
import { UserTenantsContext } from './UserTenants.context';
import { useUserTenantsQuery } from './UserTenants.graphql.generated';

const UserTenantsProvider = ({ children }: PropsWithChildren) => {
  const onError = useHandleError();

  const { data } = useUserTenantsQuery({ onError });

  const contextValue = useMemo<UserTenantsValue | undefined>(
    () => (data ? { tenants: data.allTenantsForCurrentUser } : undefined),
    [data],
  );

  if (!contextValue) return <GlobalSpinner />;

  return (
    <UserTenantsContext.Provider value={contextValue}>
      {children}
    </UserTenantsContext.Provider>
  );
};

export default UserTenantsProvider;
