import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAction } from 'actions/ActionProvider';
import { useActionsLayout } from 'actions/ActionsLayout/ActionsLayoutProvider';
import { useTeamSlug } from 'team/TeamAdapter/useTeamSlug';

import { handleOnClose } from './handleOnClose';
import EditAction from './EditAction';

const EditActionPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { teamSlug } = useTeamSlug();
  const { action } = useAction();
  const { layout } = useActionsLayout();

  const handleClose = useCallback(
    () =>
      handleOnClose({
        teamSlug,
        navigate,
        layout,
        pathname,
        objectiveId: action.objective?.id,
      }),
    [action.objective?.id, layout, navigate, pathname, teamSlug],
  );

  return (
    <EditAction actionId={action.id} isOpen={true} onClose={handleClose} />
  );
};

export default EditActionPage;
