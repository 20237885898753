import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserValidation } from 'user/UserValidationProvider/useUserValidation';
import useHandleError from 'shared/errors/useHandleError';

import { useValidateLoggedInUserMutation } from './useValidateLoggedInUser.graphql.generated';

type UseValidateLoggedInUserReturnType = (
  accessToken: string,
) => Promise<unknown>;

export const useValidateLoggedInUser = () => {
  const { t } = useTranslation();
  const { onValidated } = useUserValidation();

  const onError = useHandleError({
    logoutOnError: true,
    defaultErrorMessage: t('signUp.verificationErrorMessage'),
  });

  const [validateLoggedInUser] = useValidateLoggedInUserMutation({
    onError,
    onCompleted: onValidated,
  });

  return useCallback<UseValidateLoggedInUserReturnType>(
    (accessToken) =>
      validateLoggedInUser({
        context: {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        },
      }),
    [validateLoggedInUser],
  );
};
