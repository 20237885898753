import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useHandleError from 'shared/errors/useHandleError';

import { useObjectiveInitiativesQuery } from './ObjectiveInitiativesProvider.graphql.generated';
import type { ObjectiveInitiativesContextValue } from './ObjectiveInitiativesProvider.context';
import { ObjectiveInitiativesContext } from './ObjectiveInitiativesProvider.context';

type ObjectiveInitiativesProviderProps = PropsWithChildren<object>;

const ObjectiveInitiativesProvider = ({
  children,
}: ObjectiveInitiativesProviderProps) => {
  const { objectiveId } = useParams() as {
    objectiveId: string;
  };

  const handleApolloError = useHandleError();

  const { data, loading: isObjectiveInitiativesLoading } =
    useObjectiveInitiativesQuery({
      variables: {
        objectiveId,
      },
      fetchPolicy: 'cache-and-network',
      onError: handleApolloError,
    });

  const contextValue = useMemo<ObjectiveInitiativesContextValue>(
    () => ({
      objective: data?.objective,
      isObjectiveInitiativesLoading: isObjectiveInitiativesLoading && !data,
    }),
    [data, isObjectiveInitiativesLoading],
  );

  return (
    <ObjectiveInitiativesContext.Provider value={contextValue}>
      {children}
    </ObjectiveInitiativesContext.Provider>
  );
};

export default ObjectiveInitiativesProvider;
