import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import { useTeamAdapter } from 'team/TeamAdapter';
import { strategyRoutesPaths } from 'strategy/strategy.routing.paths';
import Button from 'shared/components/Button';
import Emoji from 'shared/components/Emoji';

import Description from './Description';
import Header from './Header';
import Icon from './Icon';
import Title from './Title';

const PyramidGuide = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamAdapter } = useTeamAdapter();

  const handleClick = useCallback(() => {
    navigate(
      strategyRoutesPaths.overview.pyramid({
        params: { teamSlug: teamAdapter.toParam() },
      }),
    );
  }, [navigate, teamAdapter]);

  return (
    <>
      <Header>
        <Icon>
          <Emoji icon={t('strategy.guide.2steps.icon')} />
        </Icon>
        <Title>{t('strategy.guide.2steps.title')}</Title>
      </Header>
      <Description>{t('strategy.guide.2steps.description')}</Description>
      <Button onPress={handleClick} variant={'outlined'}>
        {t('strategy.guide.2steps.button')}
      </Button>
    </>
  );
};

export default PyramidGuide;
