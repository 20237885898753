import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Strategy } from 'types.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import useHandleError from 'shared/errors/useHandleError';

import { useUseDeleteStrategyDeleteStrategyMutation } from './useDeleteStrategy.graphql.generated';

type DeleteStrategyFn = (params: {
  onSuccess?: () => void;
  strategy: Pick<Strategy, 'id' | 'name'>;
}) => Promise<unknown>;

type UseDeleteStrategyReturnType = {
  deleteStrategy: DeleteStrategyFn;
};

export const useDeleteStrategy = (): UseDeleteStrategyReturnType => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const handleApolloError = useHandleError();

  const [deleteStrategy] = useUseDeleteStrategyDeleteStrategyMutation({
    onError: handleApolloError,
  });

  const handleDeleteStrategy = useCallback<DeleteStrategyFn>(
    ({ strategy, onSuccess }) =>
      deleteStrategy({
        variables: {
          strategyId: strategy.id,
        },
        update: (cache) => {
          cache.evict({
            id: `Strategy:${strategy.id}`,
            broadcast: false,
          });
        },
        onCompleted: () => {
          addToast({
            id: 'strategyDeleteSuccessToast',
            children: t('strategy.deleteSuccessToast', {
              strategyName: strategy.name,
            }),
            variant: 'success',
          });
          onSuccess?.();
        },
      }),
    [deleteStrategy, addToast, t],
  );

  return {
    deleteStrategy: handleDeleteStrategy,
  };
};
