import type { Objective } from 'types.graphql.generated';

import LinkIcon from './LinkIcon';

type Props = {
  contextObjective: Pick<Objective, 'id'>;
  strategyElement: { objective?: Pick<Objective, 'id'> };
};

const MaybeLinkIcon = ({ strategyElement, contextObjective }: Props) => {
  const isLink =
    strategyElement.objective &&
    strategyElement.objective.id !== contextObjective.id;

  if (!isLink) return null;

  return <LinkIcon />;
};

export default MaybeLinkIcon;
