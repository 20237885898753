import { Routes, Route, Outlet } from 'react-router-dom';

import { useTeamAdapter } from 'team/TeamAdapter';

import TeamMembersAddMember from './TeamMembersAddMember';
import TeamMembersListView from './TeamMembersListView';
import TeamMembersProvider from './TeamMembersProvider';
import { teamMembersRoutesPaths } from './TeamMembers.routing.paths';
import TeamMembersLayout from './TeamMembersLayout';

const TeamMembers = () => {
  return (
    <TeamMembersLayout>
      <TeamMembersListView />

      <Outlet />
    </TeamMembersLayout>
  );
};

const TeamMembersWithRouting = () => (
  <Routes>
    <Route path={teamMembersRoutesPaths.root()} element={<TeamMembers />}>
      <Route
        path={teamMembersRoutesPaths.addMember()}
        element={<TeamMembersAddMember />}
      />
    </Route>
  </Routes>
);

const TeamMembersWithProviderAndRouting = () => {
  const { teamAdapter } = useTeamAdapter();

  return (
    <TeamMembersProvider teamAdapter={teamAdapter}>
      <TeamMembersWithRouting />
    </TeamMembersProvider>
  );
};

export default TeamMembersWithProviderAndRouting;
