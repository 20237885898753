import styled from '@emotion/styled';

import { transientOptions } from 'shared/utils/emotion.utils';

const FormContainerBody = styled('div', transientOptions)<{
  $hasContentPadding: boolean;
}>`
  border-top: 1px solid ${(props) => props.theme.color.strokeMedium};
  padding: ${(props) => (props.$hasContentPadding ? '20px' : 'none')};
`;

export default FormContainerBody;
