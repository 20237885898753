import { createContext } from 'react';

import type {
  TeamMembersOrgUnitFragment,
  TeamMembersUserFragment,
} from './TeamMembersProvider.graphql.generated';

export type TeamMembersContextValue = {
  orgUnit?: TeamMembersOrgUnitFragment;
  users?: TeamMembersUserFragment[];
};

export const TeamMembersContext = createContext<
  TeamMembersContextValue | undefined
>(undefined);
