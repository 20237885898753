import cn from 'classnames';
import type { Merge } from 'type-fest';
import { useTranslation } from 'react-i18next';

import StatusMark from 'shared/status/StatusMark';
import { StatusIndicator } from 'shared/status/StatusIndicator';

import styles from './StatusButton.module.scss';

type StatusButtonProps = Merge<
  Omit<any, 'children'>,
  {
    hasError?: boolean;
    isActive?: boolean;
    statusIndicator?: StatusIndicator;
  }
>;

const StatusButton = ({
  statusIndicator = new StatusIndicator(),
  isActive,
  hasError,
  className,
  ...restProps
}: StatusButtonProps) => {
  const { t } = useTranslation();

  return (
    <div
      {...restProps}
      role={'button'}
      className={cn(
        styles.button,
        { [styles.buttonActive]: isActive, [styles.buttonError]: hasError },
        className,
      )}
    >
      <StatusMark statusIndicator={statusIndicator} />
      <span className={styles.colorName}>
        {statusIndicator.getColorName(t)}
      </span>
      <span className={styles.description}>{statusIndicator.getName(t)}</span>
    </div>
  );
};

export default StatusButton;
