import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { datadogRum } from '@datadog/browser-rum';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import { useToasts } from 'shared/toast/useToasts';
import { auth } from 'auth/AuthProvider';
import { authRoutesPaths } from 'auth/auth.routing.paths';

import { useErrorPage } from './ErrorPage/useErrorPage';
import {
  getErrorMessage,
  getErrorShouldLogout,
  getErrorShouldShowErrorPage,
} from './errors.utils';

type Params = { defaultErrorMessage?: string; logoutOnError?: boolean };

export default function useHandleError({
  logoutOnError,
  defaultErrorMessage,
}: Params = {}) {
  const { onError: showErrorPage } = useErrorPage();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const signOutWithErrorMessage = useCallback(
    (errorMessage: string) =>
      signOut(auth).then(() =>
        setTimeout(
          () =>
            navigate(
              authRoutesPaths.signUpWithError({
                query: { errorMessage },
              }),
            ),
          0,
        ),
      ),
    [navigate],
  );

  return useCallback(
    (error: Error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      datadogRum.addError(error);

      const errorMessage =
        getErrorMessage(t, error) ||
        defaultErrorMessage ||
        t('errorMessage.unknown') ||
        '';

      const shouldLogout = logoutOnError || getErrorShouldLogout(error);
      if (shouldLogout) return signOutWithErrorMessage(errorMessage);

      const shouldShowErrorPage = getErrorShouldShowErrorPage(error);
      if (shouldShowErrorPage) return showErrorPage(error);

      addToast({ variant: 'error', children: errorMessage });
    },
    [
      addToast,
      defaultErrorMessage,
      logoutOnError,
      showErrorPage,
      signOutWithErrorMessage,
      t,
    ],
  );
}
