import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { capitalize } from 'lodash';

import { hasMission } from 'strategy/strategy.utils';
import Text from 'shared/components/Text';
import type { CardBorder } from 'shared/components/Card/Card.type';
import useDialogState from 'shared/hooks/useDialogState';

import { illustrations, backgroundColors } from '../StrategyRoadmap.static';
import StrategyRoadmapCard from '../StrategyRoadmapCard';
import StrategyRoadmapMissionModal from '../StrategyRoadmapMissionModal';
import type { StrategyRoadmapStrategyFieldsFragment } from '../StrategyRoadmapProvider/StrategyRoadmapProvider.graphql.generated';

type StrategyRoadmapMissionCardProps = {
  border?: CardBorder;
  hasShadow?: boolean;
  showEditButton?: boolean;
  strategy: StrategyRoadmapStrategyFieldsFragment;
};

const StrategyRoadmapMissionCard = ({
  strategy,
  hasShadow,
  border,
  showEditButton = true,
}: StrategyRoadmapMissionCardProps) => {
  const { mission } = strategy;

  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDialogState();

  const isEmpty = useMemo(() => !hasMission(strategy), [strategy]);
  const content = useMemo(() => {
    if (!isEmpty) {
      return (
        <Text variant={'emphasis'} ellipsis={{ lineClamp: 4 }}>
          {mission?.name}
        </Text>
      );
    }
    return t('noData');
  }, [mission, isEmpty, t]);

  return (
    <>
      <StrategyRoadmapCard
        illustration={illustrations.mission}
        illustrationBackgroundColor={backgroundColors.mission}
        heading={capitalize(t('strategy.mission'))}
        onMaximize={onOpen}
        hasShadow={hasShadow}
        border={border}
      >
        {content}
      </StrategyRoadmapCard>
      <StrategyRoadmapMissionModal
        strategy={strategy}
        isOpen={isOpen}
        onClose={onClose}
        showEditButton={showEditButton}
      />
    </>
  );
};

export default StrategyRoadmapMissionCard;
