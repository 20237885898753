import compact from 'lodash/compact';
import type { TFunction } from 'react-i18next';

import type { Org, OrgUnit, Strategy } from 'types.graphql.generated';
import { ReactComponent as StrategyMapIcon } from 'shared/static/icons/icon-strategy-map.svg';
import { ReactComponent as OverviewIcon } from 'shared/static/icons/icon-overview.svg';
import { ReactComponent as ObjectivesIcon } from 'shared/static/icons/icon-flag.svg';
import { ReactComponent as MetricsIcon } from 'shared/static/icons/icon-metric.svg';
import { ReactComponent as InitiativesIcon } from 'shared/static/icons/icon-initiatives.svg';
import { ReactComponent as ActionsIcon } from 'shared/static/icons/icon-actions.svg';
import { ReactComponent as IdeaIcon } from 'shared/static/icons/icon-idea.svg';
import { strategyRoutesPaths } from 'strategy/strategy.routing.paths';
import { teamRoutesPaths } from 'team/team.routing.paths';
import { TeamAdapter } from 'team/TeamAdapter';
import type { BreadcrumbsProps } from 'shared/components/Breadcrumbs';
import { canPerformOrgUnitAction } from 'user/ability/canPerformOrgUnitAction';

type ParentOrgUnitTree = Array<
  Pick<OrgUnit, 'id' | 'name' | 'currentUserAuthorizedActions'> & {
    parentOrgUnit?: Maybe<Pick<OrgUnit, 'id'>>;
  }
>;

export const getNavigationItems = (params: {
  t: TFunction;
  teamAdapter: TeamAdapter;
}) => {
  const { t, teamAdapter } = params;

  return compact([
    {
      name: t('strategy.strategyLayout.navigation.overview'),
      icon: StrategyMapIcon,
      to: strategyRoutesPaths.overview.root({
        params: {
          teamSlug: teamAdapter.toParam(),
        },
      }),
    },
    {
      name: t('strategy.strategyLayout.navigation.strategyBoard'),
      icon: OverviewIcon,
      to: teamRoutesPaths.strategyBoard({
        params: {
          teamSlug: teamAdapter.toParam(),
        },
      }),
    },
    {
      name: t('home.breadcrumbs.insights'),
      icon: IdeaIcon,
      to: teamRoutesPaths.insights.root({
        params: {
          teamSlug: teamAdapter.toParam(),
        },
      }),
    },
    {
      name: t('strategy.strategyLayout.navigation.objectives'),
      icon: ObjectivesIcon,
      to: strategyRoutesPaths.objectives.root({
        params: {
          teamSlug: teamAdapter.toParam(),
        },
      }),
      end: true,
    },
    {
      name: t('strategy.strategyLayout.navigation.metrics'),
      icon: MetricsIcon,
      to: strategyRoutesPaths.metrics.root({
        params: {
          teamSlug: teamAdapter.toParam(),
        },
      }),
    },
    {
      name: t('strategy.strategyLayout.navigation.initiatives'),
      icon: InitiativesIcon,
      to: strategyRoutesPaths.initiatives.root({
        params: {
          teamSlug: teamAdapter.toParam(),
        },
      }),
    },
    {
      name: t('strategy.strategyLayout.navigation.actions'),
      icon: ActionsIcon,
      to: strategyRoutesPaths.actions.root({
        params: {
          teamSlug: teamAdapter.toParam(),
        },
      }),
    },
  ]);
};

export const getBreadcrumbsItems = (params: {
  activeOrg: Pick<Org, 'orgKey' | 'displayName'> & {
    currentStrategy?: Pick<Strategy, 'id'>;
  };
  orgUnit?: Maybe<
    Pick<OrgUnit, 'id' | 'name' | 'currentUserAuthorizedActions'> & {
      parentOrgUnitTree: ParentOrgUnitTree;
    }
  >;
  t: TFunction;
}): BreadcrumbsProps['items'] => {
  const { t, orgUnit, activeOrg } = params;

  const parentOrgUnits: ParentOrgUnitTree = [];
  if (orgUnit?.parentOrgUnitTree?.length) {
    parentOrgUnits.push(
      orgUnit.parentOrgUnitTree.find((item) => !item?.parentOrgUnit) as OrgUnit,
    );
    for (let i = 0; i < orgUnit?.parentOrgUnitTree?.length - 1; i++) {
      const find = orgUnit.parentOrgUnitTree.find(
        (item) =>
          item.parentOrgUnit?.id ===
          parentOrgUnits[parentOrgUnits.length - 1]?.id,
      );
      if (find) {
        parentOrgUnits.push(find);
      }
    }
  }

  return compact([
    {
      name: activeOrg.displayName as string,
      to: teamRoutesPaths.root({
        params: {
          teamSlug: TeamAdapter.fromOrg(activeOrg).toParam(),
        },
      }),
    },
    ...parentOrgUnits.map((item) => ({
      name: item.name as string,
      isLocked: !canPerformOrgUnitAction(item, 'READ'),
      to: teamRoutesPaths.root({
        params: {
          teamSlug: TeamAdapter.fromOrgUnit(item, activeOrg.orgKey).toParam(),
        },
      }),
    })),
    orgUnit
      ? {
          name: orgUnit.name as string,
          isLocked: !canPerformOrgUnitAction(orgUnit, 'READ'),
          to: teamRoutesPaths.root({
            params: {
              teamSlug: TeamAdapter.fromOrgUnit(
                orgUnit,
                activeOrg.orgKey,
              ).toParam(),
            },
          }),
        }
      : undefined,
    {
      name: t('strategy.strategy'),
    },
  ]);
};
