import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useHandleError from 'shared/errors/useHandleError';
import { useTeamAdapter } from 'team/TeamAdapter';
import { useStrategy } from 'strategy/StrategyProvider';
import type { PromptMessage } from 'ai/AskSohpieButton/AskSohpieButton.type';
import AskSohpieButton from 'ai/AskSohpieButton';
import useDialogState from 'shared/hooks/useDialogState';
import useCanPerformOrgOrOrgUnitAction from 'user/ability/useCanPerformOrgOrOrgUnitAction';

import { useStrategyOverviewAskSophieButtonQuery } from './StrategyOverviewAskSophieButton.graphql.generated';
import {
  pestel,
  porters,
  customerSegmentation,
  doubleMateriality,
  stakeholderAnalysis,
  ansoff,
  blueOcean,
  vrio,
  competitiveStrategies,
  portfolioAnalysis,
  strategyMap,
  bscStrategyBoard,
  strategyDiamond,
  businessCanvas,
  mAndA,
  valueChain,
  playingToWin,
  customerJourney,
  threeHorizons,
  swot,
  bscSwot,
  mckinsey,
  riskFramework,
  strategicRisks,
  objectiveRisks,
  riskMap,
  unsdg,
  esg,
  objectives,
  frameworks,
  taxonomy,
  csrd,
  tcfd,
  sbti,
  gri,
} from './prompts';

const StrategyOverviewAskSophieButton = () => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();
  const { strategy } = useStrategy();

  const { isOpen, onOpen } = useDialogState();

  const handleApolloError = useHandleError();

  const canManage = useCanPerformOrgOrOrgUnitAction('WRITE_STRATEGY', 'WRITE');

  const { data } = useStrategyOverviewAskSophieButtonQuery({
    onError: handleApolloError,
    variables: {
      filter: {
        orgUnitIds: teamAdapter.isOrgUnit
          ? {
              operator: 'IN',
              values: [teamAdapter.keyArg],
            }
          : { operator: 'IS_NULL' },
        status: {
          isComplete: false,
        },
      },
    },
    skip: !strategy || !isOpen,
  });

  const objectiveIds = data?.allObjectives.edges.map((edge) => edge.node.id);

  const promptMessages = useMemo<PromptMessage[]>(
    () =>
      [
        {
          content: t('ai.strategySummary.button.story'),
          mainPrompt: t('ai.strategySummary.button.story'),
          numberOfCharacters: 2000,
        },
        {
          content: t('ai.strategySummary.button.fairytale'),
          mainPrompt: 'Tell my strategy as a fairy tale story.',
          numberOfCharacters: 3000,
        },
        {
          content: t('ai.strategySummary.button.stressTest'),
          mainPrompt: 'Please do a stress test of our strategy.',
          maxNumberOfCharacters: 5000,
          hints: [
            'Use the following frameworks to analyse our strategy information (info in roadmap): Playing to Win, Strategy Maps, Strategy Diamond, Blue Ocean Strategy, McKinsey 7S framework, The Ansoff Matrix, VRIO Framework, BCG Growth Share Matrix. For each framework, group the results into a) well covered, b) potential gaps.',
          ],
        },
        canManage && {
          content: t('ai.strategySummary.button.pestel'),
          mainPrompt: pestel,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.porters'),
          mainPrompt: porters,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.customerSegmentation'),
          mainPrompt: customerSegmentation,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.doubleMateriality'),
          mainPrompt: doubleMateriality,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.stakeholderAnalysis'),
          mainPrompt: stakeholderAnalysis,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.ansoff'),
          mainPrompt: ansoff,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.blueOcean'),
          mainPrompt: blueOcean,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.vrio'),
          mainPrompt: vrio,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.competitiveStrategies'),
          mainPrompt: competitiveStrategies,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.portfolioAnalysis'),
          mainPrompt: portfolioAnalysis,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.strategyMap'),
          mainPrompt: strategyMap,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.bscStrategyBoard'),
          mainPrompt: bscStrategyBoard,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.strategyDiamond'),
          mainPrompt: strategyDiamond,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.businessCanvas'),
          mainPrompt: businessCanvas,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.mAndA'),
          mainPrompt: mAndA,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.valueChain'),
          mainPrompt: valueChain,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.playingToWin'),
          mainPrompt: playingToWin,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.customerJourney'),
          mainPrompt: customerJourney,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.threeHorizons'),
          mainPrompt: threeHorizons,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.swot'),
          mainPrompt: swot,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.bscSwot'),
          mainPrompt: bscSwot,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.mckinsey'),
          mainPrompt: mckinsey,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.riskFramework'),
          mainPrompt: riskFramework,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.strategicRisks'),
          mainPrompt: strategicRisks,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.objectiveRisks'),
          mainPrompt: objectiveRisks,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.riskMap'),
          mainPrompt: riskMap,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.unsdg'),
          mainPrompt: unsdg,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.esg'),
          mainPrompt: esg,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.objectives'),
          mainPrompt: objectives,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.frameworks'),
          mainPrompt: frameworks,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.taxonomy'),
          mainPrompt: taxonomy,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.csrd'),
          mainPrompt: csrd,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.tcfd'),
          mainPrompt: tcfd,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.sbti'),
          mainPrompt: sbti,
          maxNumberOfCharacters: 5000,
        },
        canManage && {
          content: t('ai.strategySummary.button.gri'),
          mainPrompt: gri,
          maxNumberOfCharacters: 5000,
        },
      ].filter(Boolean),
    [canManage, t],
  );

  return (
    <AskSohpieButton
      promptMessages={promptMessages}
      strategyId={strategy?.id}
      objectiveIds={objectiveIds}
      infoText={t('ai.strategySummary.start.subHeader.overview')}
      onOpen={onOpen}
    />
  );
};

export default StrategyOverviewAskSophieButton;
