import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import CollapsibleList from 'shared/components/CollapsibleList';
import { ReactComponent as LayoutLayersIcon } from 'shared/static/icons/icon-layout-layers.svg';
import type { TreeListProps } from 'shared/components/TreeList';
import TreeList from 'shared/components/TreeList';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import Space from 'shared/components/Space';
import NoMatchingResultsResult from 'shared/components/NoMatchingResultsResult';

import { useStrategyObjectives } from '../StrategyObjectivesProvider';
import styles from './StrategyObjectivesContent.module.scss';
import StrategyObjectivesObjectiveRow from '../StrategyObjectivesObjectiveRow';
import type { StrategyObjectivesObjective } from '../StrategyObjectives.type';

type StrategyObjectivesContentProps = {
  areAllSectionsExpanded?: boolean;
};

const StrategyObjectivesContent = ({
  areAllSectionsExpanded,
}: StrategyObjectivesContentProps) => {
  const { t } = useTranslation();
  const { activeOrg } = useActiveOrg();

  const { objectivesByThemes } = useStrategyObjectives();

  const renderRowContent = useCallback<
    TreeListProps<StrategyObjectivesObjective>['renderRowContent']
  >(
    ({ item, dropdownButton }) => (
      <StrategyObjectivesObjectiveRow
        objective={item}
        dropdownButton={dropdownButton}
        isHighlighted={false}
        orgKey={activeOrg.orgKey}
      />
    ),
    [activeOrg.orgKey],
  );

  if (objectivesByThemes.length === 0) {
    return (
      <Space isCentered={true}>
        <NoMatchingResultsResult />
      </Space>
    );
  }

  return (
    <>
      {objectivesByThemes.map((objectivesByTheme) => (
        <CollapsibleList
          title={objectivesByTheme.theme?.name || t('theme.noTheme')}
          type={'objective'}
          listClassName={styles.themeSection}
          containerClassName={styles.themeSectionContainer}
          titleIcon={LayoutLayersIcon}
          childrenCount={
            objectivesByTheme.objectives.length +
            objectivesByTheme.childObjectives.length
          }
          expanded={areAllSectionsExpanded}
          key={objectivesByTheme.theme?.id || '-1'}
        >
          <TreeList<StrategyObjectivesObjective>
            items={[
              ...objectivesByTheme.objectives,
              ...objectivesByTheme.childObjectives,
            ]}
            getChildItems={(item) => item.childObjectives}
            getParentItem={(item) => item.parentObjective}
            allExpanded={false}
            renderRowContent={renderRowContent}
            showDividers={{ top: false, bottom: true }}
          />
        </CollapsibleList>
      ))}
    </>
  );
};

export default StrategyObjectivesContent;
