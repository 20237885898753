import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ButtonVariant } from 'shared/components/Button';
import Button from 'shared/components/Button';

import CreateTaskWizard from './CreateTaskWizard';
import type { OneThingCampaignForCreateTask } from './OneThingCreateTaskButton.types';

type Props = {
  campaign: OneThingCampaignForCreateTask;
  variant: ButtonVariant;
};

const OneThingCreateTaskButton = ({ campaign, variant }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        variant={variant}
        color={'primary'}
        onPress={() => setIsOpen(true)}
      >
        {t('toolkit.toolPage.oneThing.createTask.button.label')}
      </Button>

      <CreateTaskWizard
        campaign={campaign}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

export default OneThingCreateTaskButton;
