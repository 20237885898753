import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useLocalStorage } from 'react-use';

import { ActingOrgKeyContext } from './ActingOrgKey.context';

const storageKey = 'actingOrgKey';

const ActingOrgKeyProvider = ({ children }: PropsWithChildren) => {
  const [actingOrgKey, setActingOrgKey, clearActingOrgKey] =
    useLocalStorage<string>(storageKey);

  const value = useMemo(
    () => ({ actingOrgKey, setActingOrgKey, clearActingOrgKey }),
    [actingOrgKey, clearActingOrgKey, setActingOrgKey],
  );

  return (
    <ActingOrgKeyContext.Provider value={value}>
      {children}
    </ActingOrgKeyContext.Provider>
  );
};

export default ActingOrgKeyProvider;
