import type { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import LockedLink from 'shared/components/LockedLink';
import { teamRoutesPaths } from 'team/team.routing.paths';
import { TeamAdapter } from 'team/TeamAdapter';
import { canPerformOrgUnitAction } from 'user/ability/canPerformOrgUnitAction';
import { canPerformOrgAction } from 'user/ability/canPerformOrgAction';
import type { OrgUnit } from 'types.graphql.generated';

const Container = styled.div`
  position: relative;
`;

type Props = PropsWithChildren<{
  orgUnit?: Pick<OrgUnit, 'id' | 'currentUserAuthorizedActions'> | null;
}>;

const TeamLink = ({ orgUnit, children }: Props) => {
  const theme = useTheme();
  const { activeOrg } = useActiveOrg();

  const teamAdapter = orgUnit
    ? TeamAdapter.fromOrgUnit(orgUnit, activeOrg.orgKey)
    : TeamAdapter.fromOrg(activeOrg);

  const link = teamRoutesPaths.root({
    params: {
      teamSlug: teamAdapter.toParam(),
    },
  });

  const canRead = orgUnit
    ? canPerformOrgUnitAction(orgUnit, 'READ')
    : canPerformOrgAction(activeOrg, 'READ');

  return (
    <Container>
      <LockedLink
        to={link}
        decorationOnHover={false}
        isActive={canRead}
        lockIconStyle={css`
          background-color: ${theme.color.white};
          position: absolute;
          right: -6px;
          bottom: -6px;
          border-radius: 3.5px;
        `}
      >
        {children}
      </LockedLink>
    </Container>
  );
};

export default TeamLink;
