import { useNavigate, useParams } from 'react-router-dom';

import InsightReportWizard from 'report/InsightReportWizard/InsightReportWizard';
import { teamRoutesPaths } from 'team/team.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';

const StrategyInsightsReportsEditReport = () => {
  const navigate = useNavigate();
  const { reportId } = useParams() as {
    reportId: string;
  };

  const { teamAdapter } = useTeamAdapter();

  return (
    <InsightReportWizard
      isOpen={true}
      onClose={() => {
        navigate('../');
      }}
      teamAdapter={teamAdapter}
      reportId={reportId}
      onPublishReport={(report) => {
        navigate(
          teamRoutesPaths.insights.reports.view({
            params: {
              teamSlug: teamAdapter.toParam(),
              reportId: report.id,
            },
          }),
        );
      }}
      key={reportId}
    />
  );
};

export default StrategyInsightsReportsEditReport;
