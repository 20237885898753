import { useTranslation } from 'react-i18next';

import Button from 'shared/components/Button';
import { ReactComponent as SendIcon } from 'shared/static/icons/icon-send.svg';
import useDialogState from 'shared/hooks/useDialogState';

import type { StrategyElement } from './useSendStatusRequest';
import SendStatusRequestsDialog from './SendStatusRequestsDialog';

type Props = {
  strategyElement: StrategyElement & {
    isCurrentUserOwner: boolean;
  };
};

const SendStatusRequestButton = ({ strategyElement }: Props) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDialogState();

  const canRequestStatus = !strategyElement.isCurrentUserOwner;

  if (!canRequestStatus) return null;

  return (
    <>
      <Button
        icon={SendIcon}
        iconPosition={'start'}
        variant={'outlined'}
        onPress={onOpen}
      >
        {t('statusRequest.action')}
      </Button>

      <SendStatusRequestsDialog
        isOpen={isOpen}
        onClose={onClose}
        strategyElements={[strategyElement]}
      />
    </>
  );
};

export default SendStatusRequestButton;
