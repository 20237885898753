import { createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';
// eslint-disable-next-line import/no-duplicates
import { setDefaultOptions } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { enUS, nb } from 'date-fns/locale';
import resourcesToBackend from 'i18next-resources-to-backend';

import {
  capitalizeFormatter,
  enablePostProcessingNestedKeysByDefault,
  lowercaseFormatter,
  resourcesToBackendImportFn,
} from 'shared/utils/i18n.utils';
import { OrgTermsPostProcessorModule } from 'org/OrgTermsPostProcessorModule';
import { date } from 'shared/services/date.service';

const defaultLanguageCode = 'en';

const initializeI18n = async (actingOrgKey?: string) => {
  const i18n = createInstance();

  const orgTermsPostProcessorModule = new OrgTermsPostProcessorModule(
    i18n,
    actingOrgKey,
  );

  await i18n
    .use(initReactI18next)
    .use(resourcesToBackend(resourcesToBackendImportFn))
    .use(orgTermsPostProcessorModule)
    .init({
      lng: localStorage.getItem('language') || defaultLanguageCode,
      fallbackLng: defaultLanguageCode,
      keySeparator: '.',
      nsSeparator: '/',
      interpolation: {
        escapeValue: false,
      },
      defaultNS: 'shared',
      postProcess: ['orgTerms'],
    });

  i18n.services.formatter?.add('lowercase', lowercaseFormatter);
  i18n.services.formatter?.add('capitalize', capitalizeFormatter);

  i18n.on('languageChanged', async (language) => {
    const locale = { en: enUS, no: nb }[language];
    setDefaultOptions({ locale });

    localStorage.setItem('language', language);

    await date.loadLocale(language);
  });

  enablePostProcessingNestedKeysByDefault(i18n);

  return i18n;
};

export { initializeI18n };
