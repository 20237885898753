import type { CSSProperties, MouseEvent, ReactNode } from 'react';
import { useId } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useKey } from 'react-use';
import styled from '@emotion/styled';
import hexToRgba from 'hex-to-rgba';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import { transientOptions } from 'shared/utils/emotion.utils';
import type { Animation } from 'shared/types/animation.type';
import { slideFromBottomAnimation } from 'shared/animations/slide.animation';
import { fadeAnimation } from 'shared/animations/fade.animation';
import type { Size } from 'shared/types/size.type';
import Portal from 'shared/components/Portal';

import type { ModalContentProps } from './ModalContent';
import Content from './ModalContent';
import Footer from './ModalFooter';
import type { ModalHeaderProps } from './ModalHeader';
import Header from './ModalHeader';
import Controls from './ModalControls';

const ModalWrapper = styled(motion.div)`
  align-items: center;
  display: flex;
  inset: 0;
  justify-content: center;
  position: fixed;
  z-index: 4500;
`;

const ModalInside = styled(motion.div, transientOptions)<{
  $size: ModalProps['size'];
}>`
  align-items: stretch;
  background-color: ${(props) => props.theme.color.white};
  border-radius: 1rem;
  box-shadow: 0 0.25rem 2.5rem
    ${(props) => hexToRgba(props.theme.color.black, 0.12)};
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  max-height: calc(100vh - 4rem);
  max-width: calc(100vw - 4rem);
  position: relative;

  ${(props) =>
    props.$size === 'full' &&
    css`
      border-radius: 0;
      box-shadow: none;
      height: 100%;
      max-height: none;
      max-width: none;
      width: 100%;
    `}

  ${(props) =>
    props.$size === 'small' &&
    css`
      width: 33.75rem;
    `}

  ${(props) =>
    props.$size === 'tiny' &&
    css`
      width: 22rem;
    `}
`;

// todo implement destroyOnClose prop
export type ModalProps = {
  animation?: Animation;
  beforeContent?: ReactNode;
  className?: string;
  contentClassName?: string;
  contentScrollbarGutter?: CSSProperties['scrollbarGutter'];
  contentStyle?: SerializedStyles;
  hasHeader?: boolean;
  hasHeaderPadding?: boolean;
  headerButtons?: ModalHeaderProps['buttons'];
  headingDescription?: string;
  isOpen: boolean;
  overlayClassName?: string;
  renderFooterContent?: () => ReactNode;
  renderHeaderContent?: () => ReactNode;
  scrollbarGutter?: CSSProperties['scrollbarGutter'];
  size?: Extract<Size, 'small' | 'tiny'> | 'full' | 'auto';
} & Pick<
  ModalContentProps,
  'scrollType' | 'subheading' | 'hasBackground' | 'children' | 'padding'
> &
  Pick<
    ModalHeaderProps,
    | 'isHeadingCentered'
    | 'belowHeading'
    | 'heading'
    | 'headingLevel'
    | 'onClose'
    | 'closeButtonClassName'
    | 'isBelowHeadingFullWidth'
  >;

const Modal = ({
  heading,
  headingLevel,
  isOpen,
  onClose,
  children,
  isHeadingCentered = false,
  hasBackground = false,
  padding = 'regular',
  renderHeaderContent,
  hasHeaderPadding = true,
  renderFooterContent,
  className,
  scrollType,
  headingDescription,
  subheading,
  belowHeading,
  beforeContent,
  animation = slideFromBottomAnimation,
  isBelowHeadingFullWidth = false,
  hasHeader = true,
  headerButtons,
  overlayClassName,
  contentClassName,
  contentStyle,
  closeButtonClassName,
  size = 'auto',
  scrollbarGutter = 'stable',
  contentScrollbarGutter = 'auto',
}: ModalProps) => {
  const modalId = useId();

  const isCurrentModalOnTop = () =>
    [...document.querySelectorAll('[role="dialog"]')]
      .at(-1)
      ?.getAttribute('id') === modalId;

  useKey('Escape', () => {
    if (isCurrentModalOnTop()) {
      onClose();
    }
  });

  return (
    <Portal>
      <AnimatePresence>
        {isOpen && (
          <ModalWrapper
            role={'dialog'}
            {...fadeAnimation}
            className={overlayClassName}
            id={modalId}
            onClick={stopPropagation}
          >
            <ModalInside {...animation} $size={size} className={className}>
              {hasHeader && (
                <Header
                  forFullSizeModal={size === 'full'}
                  isHeadingCentered={isHeadingCentered}
                  onClose={onClose}
                  heading={heading}
                  headingLevel={headingLevel}
                  description={headingDescription}
                  belowHeading={belowHeading}
                  buttons={headerButtons}
                  isBelowHeadingFullWidth={isBelowHeadingFullWidth}
                  closeButtonClassName={closeButtonClassName}
                  hasPadding={hasHeaderPadding}
                >
                  {renderHeaderContent?.()}
                </Header>
              )}
              {beforeContent}
              <Content
                scrollType={scrollType}
                subheading={subheading}
                hasBackground={hasBackground}
                className={contentClassName}
                css={contentStyle}
                padding={padding}
                scrollbarGutter={scrollbarGutter}
                contentScrollbarGutter={contentScrollbarGutter}
              >
                {children}
              </Content>
              {renderFooterContent ? (
                <Footer
                  paddingSize={size === 'full' ? 'small' : 'medium'}
                  hasShadow={hasBackground}
                >
                  {renderFooterContent()}
                </Footer>
              ) : null}
            </ModalInside>
          </ModalWrapper>
        )}
      </AnimatePresence>
    </Portal>
  );
};

const stopPropagation = (event: MouseEvent) => {
  event.stopPropagation();
};

Modal.Controls = Controls;

export default Modal;
