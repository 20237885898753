import { css } from '@emotion/react';
import styled from '@emotion/styled';
import hexToRgba from 'hex-to-rgba';
import type { PropsWithChildren, ComponentType, SVGProps } from 'react';

import { transientOptions } from 'shared/utils/emotion.utils';
import type { Theme } from 'theme';

const getVariants = (theme: Theme) => ({
  regular: {
    backgroundColor: theme.legacyColor.colorAlto,
    color: theme.legacyColor.colorDoveGray,
  },
  primary: {
    backgroundColor: hexToRgba(theme.color.primary, 0.15),
    color: theme.color.primary,
  },
  alert: {
    backgroundColor: theme.color.alert,
    color: theme.color.white,
  },
});

const TagContainer = styled('span', transientOptions)<{
  $isUppercase?: boolean;
  $variant?: 'primary' | 'regular' | 'alert';
}>`
  align-items: center;
  border-radius: 0.25em;
  display: inline-flex;
  padding: 0.125em 0.75em;
  text-transform: ${(props) => (props.$isUppercase ? 'uppercase' : 'none')};
  background-color: ${(props) =>
    getVariants(props.theme)[props.$variant || 'regular'].backgroundColor};
  color: ${(props) =>
    getVariants(props.theme)[props.$variant || 'regular'].color};
`;

const TagContent = styled('span', transientOptions)<{
  $fontSize?: 'small' | 'regular';
}>`
  font-weight: 700;
  font-size: ${(props) => (props.$fontSize === 'small' ? '.875em' : '1em')};
`;

export type TagProps = PropsWithChildren<{
  className?: string;
  fontSize?: 'small' | 'regular';
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
  isUppercase?: boolean;
  variant?: 'primary' | 'regular' | 'alert';
}>;

/**
 * @deprecated Use `shared/components/Tag` instead
 */
const Tag = ({
  variant = 'regular',
  fontSize = 'small',
  isUppercase = false,
  icon: Icon,
  children,
  className,
}: TagProps) => (
  <TagContainer
    $variant={variant}
    $isUppercase={isUppercase}
    className={className}
  >
    {Icon ? (
      <Icon
        css={css`
          height: 1em;
          margin-left: -0.5em;
          margin-right: 0.25em;
          width: 1em;
        `}
      />
    ) : null}
    <TagContent $fontSize={fontSize}>{children}</TagContent>
  </TagContainer>
);

export default Tag;
