import { useCallback } from 'react';
import { AuthErrorCodes } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import SignUpButton from 'auth/SignUpButton';
import { isAuthError } from 'auth/auth.utils';
import { useValidateLoggedInUser } from 'user/useValidateLoggedInUser';
import type { Provider } from 'auth/useSignUpWithProvider';
import { useSignUpWithProvider } from 'auth/useSignUpWithProvider';
import { useUserValidation } from 'user/UserValidationProvider/useUserValidation';
import { auth } from 'auth/AuthProvider';
import { useRedirect } from 'shared/providers/RedirectProvider/useRedirect';
import useHandleError from 'shared/errors/useHandleError';

type Props = {
  provider: Provider;
};

const authErrorsToHide = [
  AuthErrorCodes.POPUP_CLOSED_BY_USER,
  AuthErrorCodes.EXPIRED_POPUP_REQUEST,
];

const ProviderSignUpButton = ({ provider }: Props) => {
  const navigate = useNavigate();

  const { onInvalidated } = useUserValidation();
  const signUpWithProvider = useSignUpWithProvider();
  const validateLoggedInUser = useValidateLoggedInUser();
  const { performRedirect } = useRedirect();

  const onError = useHandleError({ logoutOnError: true });

  const handleSignUpError = useCallback(
    (error: any) => {
      const shouldHideError =
        isAuthError(error) && authErrorsToHide.includes(error.code);

      if (shouldHideError) return;

      onError(error);
    },
    [onError],
  );

  const handleSignUp = useCallback(async () => {
    try {
      onInvalidated();
      await signUpWithProvider(provider);
      if (!auth.currentUser) throw Error('User missing after sign up');
      const idToken = await auth.currentUser.getIdToken();
      await validateLoggedInUser(idToken).then(() => performRedirect(navigate));
    } catch (error) {
      handleSignUpError(error);
    }
  }, [
    onInvalidated,
    signUpWithProvider,
    provider,
    validateLoggedInUser,
    performRedirect,
    navigate,
    handleSignUpError,
  ]);

  return <SignUpButton provider={provider} onPress={handleSignUp} />;
};

export default ProviderSignUpButton;
