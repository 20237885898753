import type { SerializedStyles } from '@emotion/react';
import { css, useTheme } from '@emotion/react';
import type { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import LockIcon from 'user/LockIcon';

type Props = PropsWithChildren<{
  className?: string;
  color?: string;
  decoration?: boolean;
  decorationOnHover?: boolean;
  inactiveColor?: string;
  isActive?: boolean;
  isInline?: boolean;
  lockIconStyle?: SerializedStyles;
  to?: string;
}>;

const LockedLink = ({
  to,
  isActive = true,
  isInline = false,
  decoration = false,
  decorationOnHover = true,
  color,
  inactiveColor,
  lockIconStyle,
  className,
  children,
}: Props) => {
  const theme = useTheme();

  const resolvedColor = color || theme.color.black;
  const resolvedInactiveColor = inactiveColor || resolvedColor;
  const isLinkActive = !!to && isActive;

  const containerStyle = isInline
    ? css`
        display: inline;
      `
    : css`
        display: flex;
        align-items: center;
        gap: 8px;
      `;

  const lockContainerStyle = isInline
    ? css`
        display: inline;
        margin-left: 2px;

        button {
          display: inline;
          position: relative;

          svg {
            position: absolute;
            top: 2px;
          }
        }
      `
    : undefined;

  return (
    <div css={containerStyle}>
      <Link
        to={to || ''}
        className={className}
        aria-disabled={!isLinkActive}
        css={css`
          color: ${isActive ? resolvedColor : resolvedInactiveColor};
          pointer-events: ${isLinkActive ? 'all' : 'none'};
          text-decoration: ${isLinkActive && decoration ? 'underline' : 'none'};

          &:hover {
            cursor: ${isLinkActive ? 'pointer' : 'inherit'};
            text-decoration: ${isLinkActive && decorationOnHover
              ? 'underline'
              : 'none'};
          }
        `}
      >
        {children}
      </Link>

      {!isLinkActive && (
        <div css={[lockContainerStyle, lockIconStyle]}>
          <LockIcon />
        </div>
      )}
    </div>
  );
};

export default LockedLink;
