import { OAuthProvider, signInWithPopup } from 'firebase/auth';
import { useCallback } from 'react';

import { auth } from './AuthProvider';

const providers = {
  microsoft: new OAuthProvider('microsoft.com'),
  google: new OAuthProvider('google.com'),
};

providers.google.addScope('https://www.googleapis.com/auth/userinfo.email');
providers.google.addScope('https://www.googleapis.com/auth/userinfo.profile');

export type Provider = keyof typeof providers;

type UseSignUpWithMicrosoftReturnType = (provider: Provider) => Promise<{
  accessToken: string;
  idToken: string;
}>;

export const useSignUpWithProvider = (): UseSignUpWithMicrosoftReturnType =>
  useCallback(
    (provider: Provider) =>
      signInWithPopup(auth, providers[provider]).then((signInResult) => {
        const credential = OAuthProvider.credentialFromResult(signInResult);
        const accessToken = credential?.accessToken as string;
        const idToken = credential?.idToken as string;
        return { accessToken, idToken };
      }),
    [],
  );
