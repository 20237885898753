import { I18nextProvider as ReactI18nextProvider } from 'react-i18next';
import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import type { i18n } from 'i18next';

import { initializeI18n } from 'shared/config/initializeI18n';
import { useActingOrgKey } from 'org/ActingOrgKeyProvider/useActingOrgKey';
import GlobalSpinner from 'shared/spinner/GlobalSpinner';

import { isLanguageValid } from './I18nextProvider.utils';

export const forcedLanguageChangeStorageKey = 'forcedLanguageChange';

type I18nextProviderProps = PropsWithChildren<object>;

const I18nextProvider = ({ children }: I18nextProviderProps) => {
  const { actingOrgKey } = useActingOrgKey();

  const [i18n, setI18n] = useState<i18n>();

  const params = new URL(window.location as unknown as URL).searchParams;
  const language = params.get('language');

  const [, setForcedLanguageChange] = useLocalStorage<boolean>(
    forcedLanguageChangeStorageKey,
  );

  useEffect(() => {
    initializeI18n(actingOrgKey).then(setI18n);
  }, [actingOrgKey]);

  useEffect(() => {
    if (i18n && isLanguageValid(language)) {
      i18n.changeLanguage(language, () => setForcedLanguageChange(true));
    }
  }, [i18n, language, setForcedLanguageChange]);

  if (!i18n) return <GlobalSpinner />;

  return <ReactI18nextProvider i18n={i18n}>{children}</ReactI18nextProvider>;
};

export default I18nextProvider;
