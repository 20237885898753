import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';

import type { CommentsFormProps } from 'shared/components/CommentsForm';
import CommentsForm from 'shared/components/CommentsForm';
import Heading from 'shared/components/Heading';
import Text from 'shared/components/Text';
import Space from 'shared/components/Space';
import StatusMark from 'shared/status/StatusMark';
import StatusCommentsInfoCard from 'shared/status/StatusCommentsInfoCard';
import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import PreviousInsightsSidecard from 'status/PreviousInsightsSidecard/PreviousInsightsSidecard';
import { usePreviousInsights } from 'status/PreviousInsightsSidecard/usePreviousInsights';

import styles from './InitiativeStatusWizardComments.module.scss';
import type { InitiativeStatusWizardInitiativeFragment } from '../InitiativeStatusWizard.graphql.generated';

type InitiativeStatusWizardCommentsProps = Pick<
  CommentsFormProps,
  'id' | 'initialValues' | 'onSubmit' | 'onChange' | 'onError'
> & {
  initiative: InitiativeStatusWizardInitiativeFragment;
};

const InitiativeStatusWizardComments = ({
  initiative,
  ...restProps
}: InitiativeStatusWizardCommentsProps) => {
  const { t } = useTranslation();

  const [isSidesheetOpen, setIsSidesheetOpen] = useState(false);

  const { onOpenPreviousInsights } = usePreviousInsights();

  const renderForm = () => (
    <>
      <Heading level={3}>
        {t('initiative.initiativeStatusWizard.steps.comments.heading')}
      </Heading>

      <Text className={styles.description}>
        <Trans
          i18nKey={
            'initiative.initiativeStatusWizard.steps.comments.description'
          }
          values={{
            initiativeName: initiative.name,
          }}
        >
          <Text variant={'emphasis'} />
        </Trans>
      </Text>

      {initiative.currentInitiativeStatus ? (
        <Space className={styles.initiativeStatus}>
          <Text variant={'emphasis'}>
            {t(
              'initiative.initiativeStatusWizard.steps.comments.initiativeStatusLabel',
            )}
          </Text>
          <StatusMark
            statusIndicator={
              initiative.currentInitiativeStatus?.statusIndicator
            }
          />
        </Space>
      ) : null}

      <CommentsForm
        {...restProps}
        onShowPreviousInsights={onOpenPreviousInsights}
      />
    </>
  );

  const renderInfoCard = () => (
    <StatusCommentsInfoCard
      isSidesheetOpen={isSidesheetOpen}
      setIsSidesheetOpen={setIsSidesheetOpen}
    />
  );

  return (
    <FormWithInfoCardLayout
      renderForm={renderForm}
      renderInfoCard={renderInfoCard}
      renderAfterInfoCard={<PreviousInsightsSidecard />}
    />
  );
};

export default InitiativeStatusWizardComments;
