import { useNavigate } from 'react-router-dom';

import InsightReportWizard from 'report/InsightReportWizard/InsightReportWizard';
import { useUser } from 'user/UserProvider';
import { useStrategyInsightsReports } from 'strategy/StrategyInsightsReports/StrategyInsightsReportsProvider';
import { teamRoutesPaths } from 'team/team.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';

const StrategyInsightsReportsCreateReport = () => {
  const navigate = useNavigate();

  const { refetchReports } = useStrategyInsightsReports();
  const { user } = useUser();
  const { teamAdapter } = useTeamAdapter();

  return (
    <InsightReportWizard
      owner={user}
      teamAdapter={teamAdapter}
      isOpen={true}
      onClose={() => {
        navigate('../');
      }}
      onCreateReport={refetchReports}
      onPublishReport={(report) => {
        navigate(
          teamRoutesPaths.insights.reports.view({
            params: {
              teamSlug: teamAdapter.toParam(),
              reportId: report.id,
            },
          }),
        );
      }}
    />
  );
};

export default StrategyInsightsReportsCreateReport;
