import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useListState } from 'react-stately';

import { useListBoxCollectionChildren } from 'shared/components/ListBox';
import type { MultiSelectProps } from 'shared/components/MultiSelect';

import StatusMultiSelectListBoxRow from '../StatusMultiSelectListBoxRow';
import type { StatusMultiSelectProviderContextValue } from './StatusMultiSelectProvider.context';
import { StatusMultiSelectProviderContext } from './StatusMultiSelectProvider.context';
import { getItems } from './StatusMultiSelectProvider.utils';

type StatusMultiSelectProviderProps = PropsWithChildren<
  Pick<MultiSelectProps, 'onSelectionChange' | 'selectedKeys'>
>;

const StatusMultiSelectProvider = ({
  children,
  onSelectionChange,
  selectedKeys,
}: StatusMultiSelectProviderProps) => {
  const items = useMemo(() => getItems(), []);

  const collectionChildren = useListBoxCollectionChildren({
    Row: StatusMultiSelectListBoxRow,
  });
  const state = useListState({
    items,
    selectionMode: 'multiple',
    children: collectionChildren,
    selectedKeys,
    onSelectionChange,
    suppressTextValueWarning: true,
  });

  const contextValue = useMemo<StatusMultiSelectProviderContextValue>(
    () => ({
      state,
      items,
    }),
    [state, items],
  );

  return (
    <StatusMultiSelectProviderContext.Provider value={contextValue}>
      {children}
    </StatusMultiSelectProviderContext.Provider>
  );
};

export default StatusMultiSelectProvider;
