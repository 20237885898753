import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import type { Theme } from 'theme';
import { date } from 'shared/services/date.service';
import { removeTrailingDot } from 'shared/utils/string.utils';
import { transientOptions } from 'shared/utils/emotion.utils';

import Controls from './CalendarControls';
import type { CalendarItem, CalendarIntervalUnit } from './Calendar.type';
import {
  getCalendarHeight,
  getColumnFormat,
  getIsCurrentMonth,
} from './Calendar.utils';
import CalendarRow from './CalendarRow';

const Container = styled.div`
  min-height: 50rem;
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  width: 100%;
`;

const Columns = styled.div`
  display: grid;
  grid-auto-flow: column;
  height: 100%;
  position: relative;
`;

const getNonWrappedStyle = (theme: Theme) => css`
  border-bottom: 1px solid ${theme.color.strokeMedium};

  &:first-of-type {
    border-left: 1px solid ${theme.color.strokeMedium};
    border-top-left-radius: 1.25rem;
  }

  &:nth-of-type(12n) {
    border-right: 1px solid ${theme.color.strokeMedium};
    border-top-right-radius: 1.25rem;
  }
`;

const Column = styled('div', transientOptions)<{ $isWrapped: boolean }>`
  background-color: ${(props) => props.theme.color.backgroundLight};
  border-top: 1px solid ${(props) => props.theme.color.strokeMedium};

  &:first-of-type {
    border-bottom-left-radius: 1.25rem;
  }

  &:nth-of-type(2n) {
    background-color: ${(props) => props.theme.color.white};
  }

  &:nth-of-type(12n) {
    border-bottom-right-radius: 1.25rem;
  }

  ${(props) => !props.$isWrapped && getNonWrappedStyle(props.theme)}
`;

const Header = styled('div', transientOptions)<{ $isCurrentMonth: boolean }>`
  align-items: center;
  color: ${(props) =>
    props.$isCurrentMonth
      ? props.theme.color.typoPrimary
      : props.theme.color.typoSecondary};
  display: flex;
  font-size: 0.875rem;
  justify-content: center;
  padding: 7.5px 0 27.5px;
  text-transform: capitalize;
`;

const Rows = styled.div`
  inset: 60px 1px 0 1px;
  overflow: hidden;
  position: absolute;
`;

type CalendarProps = {
  className?: string;
  endDateTime: Date;
  intervalUnit: CalendarIntervalUnit;
  isWrapped?: boolean;
  items: CalendarItem[];
  startDateTime: Date;
};

const Calendar = ({
  startDateTime,
  intervalUnit,
  items,
  endDateTime,
  className,
  isWrapped = false,
}: CalendarProps) => {
  const [calendarHeight, setCalendarHeight] = useState<string | number>(0);

  const rowsRef = useRef<HTMLDivElement>(null);

  const columns = useMemo(
    () =>
      date.getEachOfInterval(
        { start: startDateTime, end: endDateTime },
        intervalUnit,
      ),
    [startDateTime, endDateTime, intervalUnit],
  );

  useLayoutEffect(() => {
    if (rowsRef.current?.childElementCount) {
      setCalendarHeight(getCalendarHeight(rowsRef.current?.childElementCount));
    }
  }, [items, startDateTime, endDateTime]);

  return (
    <Container className={className} style={{ height: calendarHeight }}>
      <Columns>
        {columns.map((column) => (
          <Column $isWrapped={isWrapped} key={column.getTime()}>
            <Header $isCurrentMonth={getIsCurrentMonth(column)}>
              {removeTrailingDot(
                date.format(column, getColumnFormat(intervalUnit)),
              )}
            </Header>
          </Column>
        ))}
        <Rows ref={rowsRef}>
          {items.map((item, itemIndex) => (
            <CalendarRow
              item={item}
              currentYearStartDateTime={startDateTime}
              currentYearEndDateTime={endDateTime}
              key={item.children.key ?? itemIndex}
            />
          ))}
        </Rows>
      </Columns>
    </Container>
  );
};

Calendar.Controls = Controls;

export default Calendar;
