import { useEffect, type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const YupTranslationHandler = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();

  useEffect(() => {
    yup.setLocale({
      mixed: {
        required: t('missingValue'),
      },
      string: {
        email: t('invalidEmail'),
      },
    });
  }, [t]);

  return <>{children}</>;
};

export default YupTranslationHandler;
