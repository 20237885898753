import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useValidateLoggedInUser } from 'user/useValidateLoggedInUser';
import GlobalSpinner from 'shared/spinner/GlobalSpinner';
import { useSignUpWithEmail } from 'auth/useSignUpWithEmail';
import { authRoutesPaths } from 'auth/auth.routing.paths';
import { useUserValidation } from 'user/UserValidationProvider/useUserValidation';
import { useRedirect } from 'shared/providers/RedirectProvider/useRedirect';
import useHandleError from 'shared/errors/useHandleError';

const FinishSignUpWithEmail = () => {
  const navigate = useNavigate();

  const { onInvalidated } = useUserValidation();
  const { email, signUpWithEmailLink } = useSignUpWithEmail();
  const validateLoggedInUser = useValidateLoggedInUser();
  const { performRedirect } = useRedirect();

  const onError = useHandleError({ logoutOnError: true });

  useEffect(() => {
    if (!email) {
      navigate(authRoutesPaths.finishSignUpWithoutEmail(), {
        state: { returnTo: window.location.href },
      });
      return;
    }

    onInvalidated();

    signUpWithEmailLink()
      .then(({ accessToken }) => accessToken)
      .then(validateLoggedInUser)
      .then(() => performRedirect(navigate))
      .catch((error) => {
        onError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <GlobalSpinner />;
};

export default FinishSignUpWithEmail;
