import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { useTeamAdapter } from 'team/TeamAdapter';
import { useToasts } from 'shared/toast/useToasts';
import Text from 'shared/components/Text';
import useHandleError from 'shared/errors/useHandleError';

import {
  StrategyRoomThemesManageThemesDocument,
  type StrategyRoomManageThemesThemeFragment,
} from './StrategyRoomThemesManageThemes.graphql.generated';
import type { OrderNumberStyle } from './ThemesListRow';
import ThemesListRow from './ThemesListRow';
import { useStrategyRoomUpdateThemeMutation } from './StrategyRoomUpdateTheme.graphql.generated';
import ThemesListRowActions from './ThemesListRowActions';
import DraggableList from './DraggableList';

const EmptyMessageContainer = styled.div`
  height: 48px;
  margin: 12px 20px;
`;

type Props = {
  emptyMessage: string;
  orderNumberStyle: OrderNumberStyle;
  themes: StrategyRoomManageThemesThemeFragment[];
};

const ThemesList = ({ orderNumberStyle, themes, emptyMessage }: Props) => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();
  const [sortedThemes, setSortedThemes] = useState(
    [] as StrategyRoomManageThemesThemeFragment[],
  );

  const handleApolloError = useHandleError();

  const [updateTheme] = useStrategyRoomUpdateThemeMutation({
    onError: handleApolloError,
    refetchQueries: [StrategyRoomThemesManageThemesDocument],
  });

  const { addToast } = useToasts();

  useEffect(() => {
    setSortedThemes(themes.sort(sortThemes));
  }, [themes]);

  const handleReorder = async (
    theme: StrategyRoomManageThemesThemeFragment,
    orderNumber: number,
    sortedThemes: StrategyRoomManageThemesThemeFragment[],
  ) => {
    setSortedThemes(sortedThemes);

    await updateTheme({
      variables: {
        input: {
          ...teamAdapter.toInput(),
          idToUpdate: theme.id,
          orderNumber,
        },
      },
    });

    addToast({
      children: t('theme.toast.reorder.success', { themeName: theme.name }),
      variant: 'success',
    });
  };

  if (themes.length === 0) {
    return (
      <EmptyMessageContainer>
        <Text isCentered={true}>{emptyMessage}</Text>
      </EmptyMessageContainer>
    );
  }

  return (
    <DraggableList
      items={sortedThemes}
      onReorder={handleReorder}
      renderItem={(
        item: StrategyRoomManageThemesThemeFragment,
        index: number,
      ) => (
        <ThemesListRow
          key={item.id}
          themeId={item.id}
          orgUnitId={teamAdapter.isOrg ? null : teamAdapter.keyArg}
          name={item.name}
          description={item.description}
          category={item.themeCategory?.name}
          objectives={item.objectives.filter(
            (objective) => !objective.currentObjectiveStatus?.complete,
          )}
          isActive={item.isActive}
          showArchivedTag={false}
          hideInactive={false}
          orderNumber={index + 1}
          orderNumberStyle={orderNumberStyle}
          headingActions={
            <ThemesListRowActions
              theme={item}
              themePrev={sortedThemes[index - 1]}
              themeNext={sortedThemes[index + 1]}
              onReorder={handleReorder}
              sectionThemes={sortedThemes}
            />
          }
        />
      )}
    />
  );
};

export default ThemesList;

type SortableTheme = { isActive: boolean; orderNumber?: number };

export const sortThemes = (t1: SortableTheme, t2: SortableTheme) =>
  (t1.orderNumber || 0) +
  (t1.isActive ? 0 : 100000) -
  ((t2.orderNumber || 0) + (t2.isActive ? 0 : 100000));
