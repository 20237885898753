import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import { useSearchParams } from 'react-router-dom';

import { useTeamAdapter } from 'team/TeamAdapter';
import { useStatusFilter } from 'shared/status/useStatusFilter';
import { useUserFilter } from 'user/useUserFilter';
import { useDateFilter } from 'shared/hooks/useDateFilter';
import { usePriorityFilter } from 'shared/priority/usePriorityFilter';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';
import { useCalendarControls } from 'shared/components/CalendarControls/useCalendarControls';
import { useCompletedFilter } from 'shared/components/CompletedFilterButton/useCompletedFilter';
import type { StatusMultiSelectItemId } from 'shared/status/StatusMultiSelect';
import { sortObjectivesByTheme } from 'objective/objective.utils';

import { getStrategyThemes } from '../StrategyBoard.utils';
import type { StrategyBoardContextValue } from './StrategyBoardProvider.context';
import { StrategyBoardContext } from './StrategyBoardProvider.context';
import {
  useStrategyBoardOrgQuery,
  useStrategyBoardOrgUnitQuery,
} from './StrategyBoardProvider.graphql.generated';
import {
  filterThemesByStatusIndicator,
  filterThemesByDates,
  getObjectivesOwners,
  filterThemesByUser,
  filterThemesByPriority,
} from './StrategyBoardProvider.utils';

type StrategyBoardProviderProps = PropsWithChildren<object>;

const StrategyBoardProvider = ({ children }: StrategyBoardProviderProps) => {
  const [, setSearchParams] = useSearchParams();

  const { teamAdapter } = useTeamAdapter();

  const { priorities, isPrioritiesLoading } = useStrategyProfilePriorities();

  const handleApolloError = useHandleError();

  const { data: strategyBoardOrgData, loading: isStrategyBoardOrgLoading } =
    useStrategyBoardOrgQuery({
      fetchPolicy: 'cache-and-network',
      skip: !teamAdapter.isOrg,
      onError: handleApolloError,
    });

  const {
    data: strategyBoardOrgUnitData,
    loading: isStrategyBoardOrgUnitLoading,
  } = useStrategyBoardOrgUnitQuery({
    variables: { orgUnitId: teamAdapter.keyArg },
    fetchPolicy: 'cache-and-network',
    skip: teamAdapter.isOrg,
    onError: handleApolloError,
  });

  const { userFilter, setUserFilter } = useUserFilter();
  const { statusFilter, setStatusFilter } = useStatusFilter();
  const { priorityFilter, setPriorityFilter } = usePriorityFilter({
    priorities,
  });

  const { dateFilter: startDateFilter, setDateFilter: setStartDateFilter } =
    useDateFilter({
      paramName: 'startDate',
    });

  const { dateFilter: endDateFilter, setDateFilter: setEndDateFilter } =
    useDateFilter({
      paramName: 'endDate',
    });

  const { calendarIntervalStartDate, calendarIntervalEndDate, calendarCheck } =
    useCalendarControls();

  const { showCompleted } = useCompletedFilter();

  const clearFilters = useCallback(() => {
    setSearchParams((searchParams) => {
      searchParams.delete('status');
      searchParams.delete('user');
      searchParams.delete('startDate');
      searchParams.delete('endDate');
      searchParams.delete('priority');
      return searchParams;
    });
  }, [setSearchParams]);

  const filters = useMemo<StrategyBoardContextValue['filters']>(
    () => ({
      statusIndicatorFilter: statusFilter,
      setStatusIndicatorFilter: setStatusFilter,
      clearFilters,
      userFilter,
      setUserFilter,
      startDateFilter,
      setStartDateFilter,
      endDateFilter,
      setEndDateFilter,
      priorityFilter,
      setPriorityFilter,
    }),
    [
      statusFilter,
      setStatusFilter,
      clearFilters,
      userFilter,
      setUserFilter,
      startDateFilter,
      setStartDateFilter,
      endDateFilter,
      setEndDateFilter,
      priorityFilter,
      setPriorityFilter,
    ],
  );

  const contextValue = useMemo<StrategyBoardContextValue>(() => {
    const objectives =
      (teamAdapter.isOrg
        ? strategyBoardOrgData?.activeOrg
        : strategyBoardOrgUnitData?.orgUnit
      )?.objectives.edges.map((n) => ({ ...n.node, isFiltersMatch: true })) ||
      [];

    const filteredObjectives = filterThemesByUser(
      filterThemesByDates(
        filterThemesByDates(
          filterThemesByStatusIndicator(
            filterThemesByPriority(objectives, filters.priorityFilter),
            [
              ...filters.statusIndicatorFilter,
              showCompleted && ('COMPLETED' as StatusMultiSelectItemId),
            ].filter(Boolean),
          ),
          filters.startDateFilter,
          filters.endDateFilter,
        ),
        calendarIntervalStartDate,
        calendarIntervalEndDate,
        calendarCheck,
      ),
      filters.userFilter,
    ).filter(
      (objective) =>
        objective.metrics.length > 0 ||
        objective.initiatives.length > 0 ||
        objective.isFiltersMatch,
    );

    const sortedAndFilteredObjectives = orderBy(
      filteredObjectives,
      ['theme', 'theme.isActive', 'theme.orderNumber'],
      ['asc', 'desc', 'asc'],
    );

    const themes = getStrategyThemes(sortedAndFilteredObjectives).filter((t) =>
      t.theme ? t.theme.isActive : true,
    );

    const sortedThemes = sortObjectivesByTheme(
      themes,
      strategyBoardOrgUnitData
        ? [
            ...strategyBoardOrgUnitData.orgUnit.parentOrgUnitTree,
            strategyBoardOrgUnitData.orgUnit,
          ].reverse()
        : [],
    );

    return {
      isStrategyBoardLoading: teamAdapter.isOrg
        ? isStrategyBoardOrgLoading && !strategyBoardOrgData
        : isStrategyBoardOrgUnitLoading && !strategyBoardOrgUnitData,
      objectives,
      themes: sortedThemes,
      filters,
      users: getObjectivesOwners(objectives),
    };
  }, [
    teamAdapter.isOrg,
    strategyBoardOrgData,
    strategyBoardOrgUnitData,
    filters,
    showCompleted,
    calendarIntervalStartDate,
    calendarIntervalEndDate,
    calendarCheck,
    isStrategyBoardOrgLoading,
    isStrategyBoardOrgUnitLoading,
  ]);

  if (isPrioritiesLoading) return <Spinner.Circle />;

  return (
    <StrategyBoardContext.Provider value={contextValue}>
      {children}
    </StrategyBoardContext.Provider>
  );
};

export default StrategyBoardProvider;
