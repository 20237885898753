import type { TFunction } from 'react-i18next';

import { strategyRoutesPaths } from 'strategy/strategy.routing.paths';
import type { TeamAdapter } from 'team/TeamAdapter';

import type { ContributionUserContributionQuery } from './ContributionUserContribution.graphql.generated';

export const getBreadcrumbItems = (
  t: TFunction,
  teamAdapter: TeamAdapter,
  user: ContributionUserContributionQuery['user'],
) => [
  {
    name: user.org?.displayName || t('team.organization') || '',
    to: strategyRoutesPaths.overview.root({
      params: { teamSlug: teamAdapter.toParam() },
    }),
  },
  {
    name: user.displayName || user.email || user.id,
    to: '',
  },
];
