import { useNavigate, useParams } from 'react-router-dom';
import { useCallback } from 'react';

import EditObjective from 'objective/EditObjective';

const EditObjectivePage = () => {
  const { objectiveId } = useParams() as { objectiveId: Maybe<string> };
  const navigate = useNavigate();

  const handleClose = useCallback(() => navigate('../'), [navigate]);

  if (!objectiveId) return null;

  return (
    <EditObjective
      objectiveId={objectiveId}
      isOpen={true}
      onClose={handleClose}
    />
  );
};

export default EditObjectivePage;
