import type { Objective } from 'types.graphql.generated';

import type {
  StrategyBoardInitiative,
  StrategyBoardMetric,
  StrategyBoardObjective,
} from '../StrategyBoard.type';
import type { StrategyOperationalItem } from '../StrategyBoardList/StrategyBoardList.type';

export const getObjectiveWithStatus = (
  objective: Omit<StrategyBoardObjective, 'metrics' | 'initiatives'>,
): StrategyOperationalItem => {
  const { currentObjectiveStatus, ...restOfObjective } = objective;
  return {
    ...restOfObjective,
    isLink: false,
    objectiveId: objective.id,
    status: currentObjectiveStatus,
  };
};

export const getMetricsWithStatus = (
  metrics: StrategyBoardMetric[],
  objectiveId: Objective['id'],
): StrategyOperationalItem[] =>
  metrics.map(({ currentMetricStatus, ...metric }) => ({
    ...metric,
    objectiveId: objectiveId,
    status: currentMetricStatus,
    isLink: metric.objective?.id !== objectiveId,
  }));

export const getInitiativesWithStatus = (
  initiatives: StrategyBoardInitiative[],
  objectiveId: Objective['id'],
): StrategyOperationalItem[] =>
  initiatives.map(({ currentInitiativeStatus, ...initiative }) => ({
    ...initiative,
    objectiveId: objectiveId,
    status: currentInitiativeStatus,
    isLink: initiative.objective?.id !== objectiveId,
  }));
