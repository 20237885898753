import { css } from '@emotion/react';

import { ReactComponent as Icon } from 'shared/static/icons/icon-info.svg';

import Tooltip from '../Tooltip';

type Props = {
  className?: string;
  content: string;
  isInline?: boolean;
};

const InfoIcon = ({ content, isInline, className }: Props) => (
  <Tooltip
    content={content}
    delay={0}
    isInline={isInline}
    css={css({ cursor: 'help' })}
    className={className}
  >
    <Icon />
  </Tooltip>
);

export default InfoIcon;
