import type { ComponentType, ReactNode, SVGProps } from 'react';
import type { SetOptional } from 'type-fest';
import styled from '@emotion/styled';
import type { SerializedStyles } from '@emotion/react';
import { css, useTheme } from '@emotion/react';

import { transientOptions } from 'shared/utils/emotion.utils';
import Space from 'shared/components/Space';
import type { HeadingProps } from 'shared/components/Heading';
import Heading from 'shared/components/Heading';

import { useCard } from './CardProvider';
import Flex from '../Flex';

type CardHeadingProps = {
  after?: ReactNode;
  before?: ReactNode;
  containerClassName?: string;
  containerCss?: SerializedStyles;
  ellipsis?: boolean;
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
} & SetOptional<HeadingProps, 'level'>;

const CardHeadingContainer = styled(Space, transientOptions)<{
  $hasMargin?: boolean;
}>`
  min-width: 0;
`;

const StyledHeading = styled(Heading, transientOptions)<{
  $hasMargin?: boolean;
}>`
  margin-top: 0;
  width: 100%;

  ${(props) =>
    props.$hasMargin &&
    css`
      margin-bottom: 1rem;
    `}
`;

const CardHeading = ({
  before,
  after,
  className,
  containerClassName,
  icon: Icon,
  level = 3,
  ellipsis = false,
  children,
  containerCss,
  ...restProps
}: CardHeadingProps) => {
  const { hasMargin, ...restPropsWithoutHasMargin } = restProps;
  const { variant } = useCard();
  const theme = useTheme();

  return (
    <CardHeadingContainer
      $hasMargin={hasMargin}
      className={containerClassName}
      css={containerCss}
    >
      <StyledHeading
        as={5}
        $hasMargin={hasMargin}
        {...restPropsWithoutHasMargin}
        level={level}
        className={className}
      >
        <Flex
          gap={8}
          alignItems={'center'}
          css={
            ellipsis
              ? css`
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  display: block;
                `
              : undefined
          }
        >
          {Icon ? (
            <Icon
              css={css`
                height: 1.5rem;
                width: 1.5rem;
                flex-shrink: 0;
                margin-left: -1rem;
                color: ${variant === 'info'
                  ? theme.color.primary
                  : theme.legacyColor.colorMineShaft};
              `}
            />
          ) : null}
          {before}
          {children}
          {after}
        </Flex>
      </StyledHeading>
    </CardHeadingContainer>
  );
};

export default CardHeading;
