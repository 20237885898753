import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { StatusIndicator } from 'shared/status/StatusIndicator';
import StatusMark from 'shared/status/StatusMark';
import Space from 'shared/components/Space';
import Text from 'shared/components/Text';

import styles from './StatusTag.module.scss';

type StatusTagProps = {
  className?: string;
  statusIndicator?: StatusIndicator;
};

const StatusTag = ({
  statusIndicator = new StatusIndicator(),
  className,
}: StatusTagProps) => {
  const { t } = useTranslation();

  return (
    <Space isCentered={true} className={cn(styles.container, className)}>
      <StatusMark statusIndicator={statusIndicator} hasBorder={false} />
      <Text className={styles.label}>{statusIndicator.getName(t)}</Text>
    </Space>
  );
};

export default StatusTag;
