import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';
import { useCallback, useContext } from 'react';
import {
  Group as AriaGroup,
  DatePickerStateContext,
} from 'react-aria-components';

import { transientOptions } from 'shared/utils/emotion.utils';

const StyledAriaGroup = styled(AriaGroup, transientOptions)<{
  $hasError?: boolean;
}>`
  display: flex;

  white-space: nowrap;
  background-color: ${(props) =>
    props.$hasError ? props.theme.color.errorLight : props.theme.color.white};
  border: 1px solid
    ${(props) =>
      props.$hasError ? props.theme.color.error : props.theme.color.strokeDark};
  border-radius: 12px;
  height: 3rem;
  padding: 0.75rem;
  gap: 10px;

  &:focus-within {
    border-color: ${(props) => props.theme.color.black};
  }

  &:hover {
    background-color: ${(props) =>
      props.$hasError
        ? props.theme.color.errorLight
        : props.theme.color.backgroundLight};
  }

  [slot='input'] {
    display: flex;
  }

  [slot='controls'] {
    flex-grow: 1;
    display: flex;
    gap: 4px;
    justify-content: flex-end;
    align-items: center;

    .react-aria-Button {
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      outline: none;
      vertical-align: middle;
      text-align: center;
      line-height: 0;

      svg {
        height: 1.4rem;
        width: 1.4rem;
      }
    }
  }

  .react-aria-DateInput {
    display: flex;
  }
`;

const Group = ({
  hasError,
  children,
}: PropsWithChildren<{ hasError?: boolean }>) => {
  const state = useContext(DatePickerStateContext)!;

  const handleClick = useCallback(() => {
    state.setOpen(true);
  }, [state]);

  return (
    <StyledAriaGroup $hasError={hasError} onClick={handleClick}>
      {children}
    </StyledAriaGroup>
  );
};

export default Group;
