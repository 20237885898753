import type { RouteObject } from 'react-router-dom';

import StrategyLayout from 'strategy/StrategyLayout';
import StrategyOverview from 'strategy/StrategyOverview';
import StrategyObjectives from 'strategy/StrategyObjectives/StrategyObjectives';
import StrategyMetrics from 'metric/StrategyMetrics/StrategyMetrics';
import StrategyInitiatives from 'initiative/StrategyInitiatives/StrategyInitiatives';
import StrategyActions from 'actions/StrategyActions/StrategyActions';
import { strategyRoutesPaths } from 'strategy/strategy.routing.paths';
import NavigateToStrategyOverview from 'strategy/NavigateToStrategyOverview';

import StrategyPyramid from './StrategyPyramid/StrategyPyramid';

export const strategyRoutes: RouteObject[] = [
  {
    path: strategyRoutesPaths.root(),
    element: <StrategyLayout />,
    children: [
      {
        index: true,
        element: <NavigateToStrategyOverview />,
      },
      {
        path: strategyRoutesPaths.overview.root({
          allowNestedRoutes: true,
        }),
        element: <StrategyOverview />,
      },
      {
        path: strategyRoutesPaths.overview.pyramid(),
        element: <StrategyPyramid />,
      },
      {
        path: strategyRoutesPaths.objectives.root(),
        element: <StrategyObjectives />,
      },
      {
        path: strategyRoutesPaths.metrics.root(),
        element: <StrategyMetrics />,
      },
      {
        path: strategyRoutesPaths.initiatives.root({
          allowNestedRoutes: true,
        }),
        element: <StrategyInitiatives />,
      },
      {
        path: strategyRoutesPaths.actions.root(),
        element: <StrategyActions />,
      },
    ],
  },
];
