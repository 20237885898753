import { Trans, useTranslation } from 'react-i18next';
import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';

import Heading from 'shared/components/Heading';
import Stats from 'shared/components/Stats';
import Stat from 'shared/components/Stats/Stat';
import Flex from 'shared/components/Flex';

import { useStrategyObjectives } from '../StrategyObjectivesProvider';
import { getObjectivesStats } from './StartegyObjectivesHeader.utils';
import StrategyObjectivesFiltersChipGroup from '../StrategyObjectivesFiltersChipGroup';

const UsersStats = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.backgroundLight};
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: space-between;
  padding: 16px;
`;

const UsersStatsText = styled.div`
  max-width: 240px;
`;

const StrategyObjectivesHeader = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { activeObjectives, completedObjectives, activeThemesCount } =
    useStrategyObjectives();

  const { hasStats, statsActive, statDone } = useMemo(
    () =>
      getObjectivesStats(
        t,
        theme,
        activeObjectives,
        completedObjectives?.length ?? 0,
      ),
    [activeObjectives, completedObjectives?.length, t, theme],
  );

  const objectivesCount = activeObjectives.length ?? 0;
  const themesCount = activeThemesCount;

  return (
    <Flex direction={'column'} gap={12} css={css({ padding: '24px' })}>
      <Flex alignItems={'center'} justifyContent={'space-between'} gap={8}>
        <Heading level={3} hasMargin={false} css={css({ flex: '1' })}>
          {t('strategy.strategyObjectives.header.heading')}
        </Heading>

        {children}
      </Flex>

      <UsersStats>
        <UsersStatsText>
          <Trans
            i18nKey={
              hasStats
                ? 'strategy.strategyObjectives.header.nrOfStats'
                : 'strategy.strategyObjectives.header.noStats'
            }
            values={{
              objectivesCount,
              themesCount,
            }}
          />
        </UsersStatsText>
        <Stats
          stats={statsActive}
          css={css({ flexBasis: 460, marginLeft: 'auto' })}
        />
        <Stat stat={statDone} />
      </UsersStats>

      <StrategyObjectivesFiltersChipGroup />
    </Flex>
  );
};

export default StrategyObjectivesHeader;
