import { createContext } from 'react';

import type { InitiativesContentObjective } from 'initiative/InitiativesContent/InitiativesContentProvider/InitiativesContentProvider.type';

export type ObjectiveInitiativesContextValue = {
  isObjectiveInitiativesLoading: boolean;
  objective: Maybe<InitiativesContentObjective>;
};

export const ObjectiveInitiativesContext = createContext<
  ObjectiveInitiativesContextValue | undefined
>(undefined);
