import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

import { useToasts } from 'shared/toast/useToasts';
import Dialog from 'shared/components/Dialog';
import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';
import { TeamTeamHierarchyDocument } from 'team/TeamTeamHierarchy/TeamTeamHierarchyProvider';
import { MyTeamsFromCurrentUserDocument } from 'team/MyTeams/MyTeamsProvider/MyTeamsProvider.graphql.generated';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import type { OrgUnitFormValues } from 'orgUnit/OrgUnitForm';
import OrgUnitForm from 'orgUnit/OrgUnitForm';

import {
  useEditOrgUnitModalQuery,
  useEditOrgUnitMutation,
} from './EditOrgUnitModal.graphql.generated';
import {
  resolveEditOrgUnitInput,
  resolveInitialValues,
} from './EditOrgUnitModal.utils';

const editOrgUnitFormId = 'editOrgUnitForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  orgUnitId: string;
};

const EditOrgUnitModal = ({ orgUnitId, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { addToast } = useToasts();
  const { activeOrg } = useActiveOrg();

  const onError = useHandleError();

  const { data } = useEditOrgUnitModalQuery({
    variables: { id: orgUnitId },
    onError,
    skip: !isOpen,
  });

  const [editOrgUnit, { loading }] = useEditOrgUnitMutation({
    onError,
    refetchQueries: [TeamTeamHierarchyDocument, MyTeamsFromCurrentUserDocument],
  });

  const initialValues = useMemo(
    () =>
      data ? resolveInitialValues(activeOrg, data.orgUnit, theme) : undefined,
    [activeOrg, data, theme],
  );

  const handleSubmit = useCallback(
    (values: OrgUnitFormValues) => {
      if (initialValues && data) {
        return editOrgUnit({
          variables: {
            input: resolveEditOrgUnitInput({
              values,
              orgUnit: data.orgUnit,
              initialValues,
              allUsers: data.allUsers.edges.map((edge) => edge.node),
            }),
          },
          onCompleted: () => {
            addToast({
              children: t('orgUnit.editOrgUnitModal.successToast'),
              variant: 'success',
            });
            onClose();
          },
        });
      }
    },
    [addToast, data, editOrgUnit, initialValues, onClose, t],
  );

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t('orgUnit.editOrgUnitModal.heading')}
      confirmLabel={t('update')}
      cancelLabel={t('cancel')}
      formId={editOrgUnitFormId}
      isConfirmDisabled={loading}
    >
      {initialValues ? (
        <OrgUnitForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          id={editOrgUnitFormId}
          orgUnitId={orgUnitId}
        />
      ) : (
        <Spinner.Circle />
      )}
    </Dialog>
  );
};

export default EditOrgUnitModal;
