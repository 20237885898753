import { Trans, useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import Heading from 'shared/components/Heading';
import Stats from 'shared/components/Stats';
import Stat from 'shared/components/Stats/Stat';
import ShareObjectiveStatusButton from 'objective/ShareObjectiveStatusButton';

import { getStats } from './ObjectiveOverviewHeader.utils';
import styles from './ObjectiveOverviewHeader.module.scss';
import { useObjectiveOverview } from '../ObjectiveOverviewProvider/useObjectiveOverview';

const ObjectiveOverviewHeader = () => {
  const { t } = useTranslation();

  const { objective } = useObjectiveOverview();

  const { hasMetricsOrInitiatives, statsActive, statDone } = useMemo(
    () => getStats(t, objective),
    [objective, t],
  );

  return (
    <header className={styles.header}>
      <div className={styles.headingSection}>
        <Heading level={3} className={styles.heading}>
          {t('objective.overview.heading')}
        </Heading>
        {objective && <ShareObjectiveStatusButton objective={objective} />}
      </div>
      <div className={styles.usersStats}>
        <div className={styles.usersStatsText}>
          <Trans
            i18nKey={
              hasMetricsOrInitiatives
                ? 'objective.overview.stats.summary.nrOfMetricsAndInitiatives'
                : 'objective.overview.stats.summary.noMetricsAndInitiatives'
            }
            values={{
              metricsCount: objective?.metrics.length,
              metrics: t('metric.metric', {
                count: objective?.metrics.length,
              }),
              initiativesCount: objective?.initiatives.length,
              initiatives: t('initiative.initiative', {
                count: objective?.initiatives.length,
              }),
            }}
          />
        </div>
        <Stats stats={statsActive} className={styles.usersStatsChart} />
        <Stat stat={statDone} />
      </div>
    </header>
  );
};

export default ObjectiveOverviewHeader;
