import { Trans, useTranslation } from 'react-i18next';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';

import Heading from 'shared/components/Heading';
import Stats from 'shared/components/Stats';
import Stat from 'shared/components/Stats/Stat';
import { fadeAnimation } from 'shared/animations/fade.animation';
import CreateInitiativeButton from 'initiative/CreateInitiativeButton';
import type { Props as CreateInitiativeProps } from 'initiative/CreateInitiativeButton/CreateInitiative/CreateInitiative';
import Flex from 'shared/components/Flex';
import type { StrategyElementWithAuthorizedActions } from 'user/ability/canPerformStrategyElementAction';
import LinkInitiativeButton from 'initiative/LinkInitiativeButton';
import type { LinkInitiativeObjectiveParam } from 'initiative/LinkInitiativeButton/LinkInitiativeButton.type';
import StrategyInitiativesAskSophieButton from 'initiative/StrategyInitiatives/StrategyInitiativesAskSophieButton';

import { getInitiativesStats } from './InitiativesContentHeader.utils';
import { useInitiativesListObjectives } from '../InitiativesContentProvider/useInitiativesContent';
import InitiativesContentLayoutToggle from '../InitiativesContentLayoutToggle/InitiativesContentLayoutToggle';

const UsersStats = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.backgroundLight};
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: space-between;
  padding: 16px;
`;

const UsersStatsText = styled(motion.div)`
  max-width: 240px;
`;

type Props = {
  afterStats?: ReactNode;
  controls?: ReactNode;
  filters?: ReactNode;
  objective?: CreateInitiativeProps['objective'] &
    StrategyElementWithAuthorizedActions &
    LinkInitiativeObjectiveParam;
};

const InitiativesContentHeader = ({
  objective,
  afterStats,
  filters,
  controls,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    allObjectives,
    allInitiatives,
    allInitiativesActive,
    allObjectivesActiveWithInitiativesActive,
    isInitiativesListObjectivesLoading,
  } = useInitiativesListObjectives();

  const { statsActive, statDone } = useMemo(
    () => getInitiativesStats(allInitiatives, allInitiativesActive, t, theme),
    [allInitiatives, allInitiativesActive, t, theme],
  );

  const hasObjectives =
    !isInitiativesListObjectivesLoading && !!allObjectives?.length;

  const statsTextKey = useMemo(() => {
    if (!isInitiativesListObjectivesLoading) {
      const context = objective ? 'objective' : 'strategy';

      switch (context) {
        case 'strategy':
          return hasObjectives
            ? `initiatives.stats.summary.strategy.nrOfInitiatives`
            : `initiatives.stats.summary.strategy.noInitiatives`;
        case 'objective':
          return hasObjectives
            ? `initiatives.stats.summary.objective.nrOfInitiatives`
            : `initiatives.stats.summary.objective.noInitiatives`;
      }
    } else return undefined;
  }, [hasObjectives, isInitiativesListObjectivesLoading, objective]);

  return (
    <Flex direction={'column'} gap={12} css={css({ padding: '24px' })}>
      <Flex justifyContent={'space-between'} alignItems={'center'} gap={8}>
        <Heading level={3} hasMargin={false} css={css({ flex: '1' })}>
          {t('initiative.strategicInitiative_other')}
        </Heading>

        {controls}

        <Flex
          gap={8}
          alignItems={'center'}
          justifyContent={'flex-end'}
          css={css({ flex: '1' })}
        >
          {filters}

          <InitiativesContentLayoutToggle />

          {!objective && <StrategyInitiativesAskSophieButton />}

          {objective && <LinkInitiativeButton objective={objective} />}

          <CreateInitiativeButton
            objective={objective}
            showSuccessDialog={!objective}
          >
            {t('initiatives.buttons.createInitiative')}
          </CreateInitiativeButton>
        </Flex>
      </Flex>

      <UsersStats>
        <AnimatePresence>
          {!isInitiativesListObjectivesLoading && (
            <UsersStatsText {...fadeAnimation}>
              <Trans
                i18nKey={statsTextKey}
                values={{
                  initiativesCount: allInitiativesActive?.length,
                  objectivesCount:
                    allObjectivesActiveWithInitiativesActive?.length,
                }}
              />
            </UsersStatsText>
          )}
        </AnimatePresence>
        <Stats
          stats={statsActive}
          css={css`
            flex-basis: 460px;
            margin-left: auto;
          `}
        />
        <Stat stat={statDone} />
      </UsersStats>

      {afterStats}
    </Flex>
  );
};

export default InitiativesContentHeader;
