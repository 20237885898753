import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { transientOptions } from 'shared/utils/emotion.utils';
import { ReactComponent as ArrowRightIcon } from 'shared/static/icons/icon-arrow-right.svg';
import Modal from 'shared/components/__DEPRECATED__/Modal';
import OrgTermAlsoKnownAs from 'org/OrgTermAlsoKnownAs';
import Drawer from 'shared/components/Drawer';
import SidesheetsTipsAndTricksContent from 'shared/sidesheets/SidesheetsTipsAndTricksContent';

import getPyramidItems from './getPyramidItems';
import Header from './Header';
import Step from './Step';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 1040px;
`;

const Steps = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
`;

const StepArrow = styled.div`
  svg {
    width: 24px;
  }
`;

const PyramidHeader = styled.div`
  font-size: 1.142rem;
  font-weight: 700;
  margin-top: 16px;
`;

const PyramidContainer = styled.div`
  flex-grow: 1;
  align-self: stretch;
  display: flex;
  justify-content: center;
`;

const PyramidBackground = styled.div`
  flex-grow: 1;
  display: flex;
  gap: 22px;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.color.backgroundLight};
  border-radius: 20px;
  padding: 20px;
`;

const PyramidItems = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 6px;
  max-width: 800px;
  width: 100%;
`;

const PyramidItem = styled('div', transientOptions)<{
  $isItemSelected: boolean;
  $isStepSelected: boolean;
}>`
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.714rem;
  font-weight: 700;
  padding: 6px 20px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;

  border: 1px solid
    ${(props) =>
      props.$isItemSelected
        ? props.theme.color.primary
        : props.$isStepSelected
        ? props.theme.color.strokeMedium
        : props.theme.color.neutral3};

  color: ${({ $isItemSelected, $isStepSelected, theme }) =>
    $isItemSelected
      ? theme.color.primary
      : $isStepSelected
      ? theme.color.black
      : theme.color.white};

  background-color: ${({ $isItemSelected, $isStepSelected, theme }) =>
    $isItemSelected
      ? theme.color.primaryVariant
      : $isStepSelected
      ? theme.color.white
      : theme.color.neutral3};
`;

const Instructions = styled('div', transientOptions)<{
  $isItemSelected: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${({ theme }) => theme.color.typoSecondary};
  max-width: 800px;
  text-align: center;
  white-space: pre-line;
  background-color: ${(props) => props.theme.color.white};
  padding: 20px;
  width: 100%;
  border-radius: 12px;
  cursor: ${(props) => (props.$isItemSelected ? 'pointer' : 'default')};
  pointer-events: ${(props) => (props.$isItemSelected ? 'all' : 'none')};
`;

const InstructionsTitle = styled.div`
  color: ${({ theme }) => theme.color.typoPrimary};
  font-weight: 700;
`;

const LearnMore = styled.div`
  color: ${(props) => props.theme.color.primary};
`;

const StrategyPyramid = () => {
  const { t } = useTranslation();
  const [selectedStepPosition, setSelectedStepPosition] = useState<1 | 2>();
  const navigate = useNavigate();

  const pyramidItems = getPyramidItems(t);

  const [selectedItem, setSelectedItem] = useState<(typeof pyramidItems)[0]>();
  const [isSidesheetOpen, setIsSidesheetOpen] = useState(false);

  return (
    <>
      <Modal
        isOpen={true}
        onClose={() => navigate('../')}
        size={'full'}
        scrollType={'outer'}
        heading={t('strategy.pyramid.modalTitle')}
      >
        <Container>
          <Content>
            <Header />

            <Steps>
              <Step
                icon={t('strategy.pyramid.steps.1.icon')}
                title={t('strategy.pyramid.steps.1.title')}
                description={t('strategy.pyramid.steps.1.description')}
                isSelected={selectedStepPosition === 1}
                onClick={() => setSelectedStepPosition(1)}
              />

              <StepArrow>
                <ArrowRightIcon />
              </StepArrow>

              <Step
                icon={t('strategy.pyramid.steps.2.icon')}
                title={t('strategy.pyramid.steps.2.title')}
                description={t('strategy.pyramid.steps.2.description')}
                isSelected={selectedStepPosition === 2}
                onClick={() => setSelectedStepPosition(2)}
              />
            </Steps>

            <PyramidHeader>{t('strategy.pyramid.items.header')}</PyramidHeader>

            <PyramidContainer>
              <PyramidBackground>
                <PyramidItems>
                  {pyramidItems.map((item, index) => (
                    <PyramidItem
                      $isStepSelected={item.step === selectedStepPosition}
                      $isItemSelected={item.title === selectedItem?.title}
                      key={item.title}
                      css={css({
                        width: `${
                          10 + (90 / pyramidItems.length) * (index + 1)
                        }%`,
                      })}
                      onClick={() => setSelectedItem(item)}
                    >
                      {item.title}
                    </PyramidItem>
                  ))}
                </PyramidItems>

                <Instructions
                  $isItemSelected={!!selectedItem}
                  onClick={() => setIsSidesheetOpen(true)}
                >
                  {selectedItem?.title && (
                    <InstructionsTitle>{selectedItem.title}</InstructionsTitle>
                  )}

                  {selectedItem?.description ||
                    t('strategy.pyramid.items.instructions')}

                  {selectedItem && (
                    <OrgTermAlsoKnownAs i18nKey={selectedItem.i18nKey} />
                  )}

                  {selectedItem && (
                    <LearnMore>
                      {t('strategy.pyramid.items.learnMore')}
                    </LearnMore>
                  )}
                </Instructions>
              </PyramidBackground>
            </PyramidContainer>
          </Content>
        </Container>
      </Modal>

      {selectedItem && (
        <Drawer
          heading={selectedItem.title}
          width={'medium'}
          padding={'none'}
          isOpen={isSidesheetOpen}
          onClose={() => setIsSidesheetOpen(false)}
          scrollbarGutter={'auto'}
          contentScrollbarGutter={'stable'}
          contentStyle={css({ padding: '2rem' })}
        >
          <SidesheetsTipsAndTricksContent
            segments={selectedItem.sidesheetSegments}
            orgTermAlsoKnownAsI18nKey={selectedItem.i18nKey}
          />
        </Drawer>
      )}
    </>
  );
};

export default StrategyPyramid;
