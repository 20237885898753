import type { User } from 'firebase/auth';
import { createContext } from 'react';

export type AuthProviderContextValue = {
  error: Error | undefined;
  isLoading: boolean;
  user: User | null;
};

export const AuthProviderContext = createContext<
  AuthProviderContextValue | undefined
>(undefined);
