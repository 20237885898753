import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import type { FC } from 'react';

import { transientOptions } from 'shared/utils/emotion.utils';
import Text from 'shared/components/Text';
import background1 from 'shared/static/backgrounds/card-1.svg';
import background2 from 'shared/static/backgrounds/card-2.svg';
import background3 from 'shared/static/backgrounds/card-3.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-basis: 0;
  flex-grow: 1;
`;

const Background = styled('div', transientOptions)<{ $imageUrl: string }>`
  background-image: url(${(props) => props.$imageUrl});
  height: 140px;
  border-radius: 12px;
  overflow: hidden;

  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

  display: flex;
  justify-content: center;
`;

const backgrounds = {
  1: background1,
  2: background2,
  3: background3,
};

type ToolOverviewCardProps = {
  Image: FC;
  background: 1 | 2 | 3;
  description: string;
  title: string;
};

const ToolOverviewCard = ({
  title,
  description,
  background,
  Image,
}: ToolOverviewCardProps) => {
  const theme = useTheme();
  const backgroundImage = backgrounds[background];

  return (
    <Container>
      <Background $imageUrl={backgroundImage}>
        <Image css={css({ marginTop: 10, marginBottom: 10 })} />
      </Background>
      <Text isBold={true} css={css({ color: theme.color.black })}>
        {title}
      </Text>
      <Text>{description}</Text>
    </Container>
  );
};

export default ToolOverviewCard;
