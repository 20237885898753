import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import MetricStatusWizard from 'metric/MetricStatusWizard';
import type { MetricStatusInput } from 'types.graphql.generated';
import { ContributionMyContributionDocument } from 'contribution/ContributionMyContribution/ContributionMyContribution.graphql.generated';
import useHandleError from 'shared/errors/useHandleError';
import type {
  MetricForStatusWizard,
  MetricStatusForStatusWizard,
} from 'metric/MetricStatusWizard/MetricStatusWizard';

import { useUpdateMetricStatusMutation } from './UpdateMetricStatus.graphql.generated';

type Props = {
  isOpen: boolean;
  metric: MetricForStatusWizard;
  onClose: () => void;
  status: MetricStatusForStatusWizard;
};

const EditMetricStatusDialog = ({ metric, status, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const onError = useHandleError();

  const [updateMetricStatus] = useUpdateMetricStatusMutation({
    onCompleted: onClose,
    onError,
    refetchQueries: [ContributionMyContributionDocument],
  });

  const onSubmit = useCallback(
    (input: MetricStatusInput) => updateMetricStatus({ variables: { input } }),
    [updateMetricStatus],
  );

  return (
    <MetricStatusWizard
      headerTitle={t('metric.updateMetricStatus.heading')}
      buttonLabel={t('update')}
      metric={metric}
      status={status}
      onSubmit={onSubmit}
      onClose={onClose}
      isOpen={isOpen}
    />
  );
};

export default EditMetricStatusDialog;
