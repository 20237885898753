import * as yup from 'yup';
import type { TFunction } from 'react-i18next';

import type { TimeLine } from 'types.graphql.generated';
import type { Interval } from 'shared/services/date.service';
import { date } from 'shared/services/date.service';

export const isTimeLineEmpty = (
  timeLine: Pick<TimeLine, 'startDate' | 'endDate'>,
) => !timeLine.startDate && !timeLine.endDate;

export const assertTimeLine = (
  timeLine: Pick<TimeLine, 'startDate' | 'endDate'>,
) => {
  if (isTimeLineEmpty(timeLine)) {
    throw new Error('TimeLine must have at least one date');
  }

  if (
    timeLine.startDate &&
    timeLine.endDate &&
    date.isBefore(timeLine.endDate, timeLine.startDate)
  ) {
    throw new Error('TimeLine startDate must be before endDate');
  }
};

export const timeLineToInterval = (
  timeLine: Pick<TimeLine, 'startDate' | 'endDate'>,
): Interval => {
  assertTimeLine(timeLine);

  return date.fillPartialInterval({
    start: timeLine.startDate,
    end: timeLine.endDate,
  });
};

export const dateBefore1900Validation = (t: TFunction) =>
  yup
    .date()
    .min(new Date('1900-01-01'), t('timeline.validation.before1900'))
    .nullable();

export const timeLineValidations = (t: TFunction) =>
  yup.object().shape({
    startDate: dateBefore1900Validation(t),
    endDate: dateBefore1900Validation(t).min(
      yup.ref('startDate'),
      t('timeline.validation.beforeStartDate'),
    ),
  });
