import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from 'shared/components/Button';
import Space from 'shared/components/Space';
import type { ModalProps } from 'shared/components/__DEPRECATED__/Modal';
import Modal from 'shared/components/__DEPRECATED__/Modal';
import type { Metric, Objective } from 'types.graphql.generated';
import { ReactComponent as ArrowRightIcon } from 'shared/static/icons/icon-arrow-right.svg';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';

export type MetricCreated = Pick<Metric, 'id' | 'name'> & {
  objective?: Pick<Objective, 'id'>;
};

type Props = Pick<ModalProps, 'onClose' | 'isOpen'> & {
  metric?: MetricCreated;
};

const MetricCreatedDialog = ({ metric, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamAdapter } = useTeamAdapter();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      heading={t('metric.createMetric.success.heading')}
      size={'small'}
    >
      <Space direction={'vertical'}>
        <Button
          icon={ArrowRightIcon}
          onPress={() =>
            metric?.objective &&
            navigate(
              objectiveRoutesPaths.metrics.metric.root({
                params: {
                  objectiveId: metric.objective.id,
                  teamSlug: teamAdapter.toParam(),
                  metricId: metric.id,
                },
              }),
            )
          }
        >
          {metric?.name}
        </Button>
        <p>{t('metric.createMetric.success.content')}</p>
      </Space>
    </Modal>
  );
};

export default MetricCreatedDialog;
