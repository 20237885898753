import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import useHandleError from 'shared/errors/useHandleError';

import { ThemeContext } from './ThemesProvider.context';
import { useTeamAdapter } from '../../team/TeamAdapter';
import {
  useThemesOrgQuery,
  useThemesOrgUnitQuery,
} from './ThemesProvider.graphql.generated';

type ThemesProviderProps = PropsWithChildren<object>;

const ThemesProvider = ({ children }: ThemesProviderProps) => {
  const { teamAdapter } = useTeamAdapter();

  const handleApolloError = useHandleError();

  const { data: orgThemesData, loading: isOrgThemesLoading } =
    useThemesOrgQuery({
      fetchPolicy: 'cache-and-network',
      skip: !teamAdapter.isOrg,
      onError: handleApolloError,
    });

  const { data: orgUnitThemesData, loading: isOrgUnitThemesLoading } =
    useThemesOrgUnitQuery({
      variables: { orgUnitId: teamAdapter.keyArg },
      fetchPolicy: 'cache-and-network',
      skip: teamAdapter.isOrg,
      onError: handleApolloError,
    });

  const themes = useMemo(
    () =>
      (teamAdapter.isOrg
        ? orgThemesData?.activeOrg.themes
        : orgUnitThemesData?.orgUnit.themes
      )?.edges
        .map((n) => n.node)
        .sort((t1, t2) => (t1?.orderNumber || 0) - (t2?.orderNumber || 0)) ||
      [],
    [teamAdapter.isOrg, orgThemesData, orgUnitThemesData],
  );

  const contextValue = useMemo(() => {
    if (themes) {
      return {
        themes,
      };
    }
    return undefined;
  }, [themes]);

  if (isOrgThemesLoading || isOrgUnitThemesLoading) {
    return null;
  }

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemesProvider;
