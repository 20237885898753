import type { TFunction } from 'react-i18next';

import type { StatusCommentType, TextItem } from 'types.graphql.generated';

export const getHeadings = (
  t: TFunction,
  headingsOverride?: Partial<Record<StatusCommentType, string>>,
): Record<StatusCommentType, string> => ({
  SUCCESS_COMMENT:
    headingsOverride?.SUCCESS_COMMENT ||
    t('status.statusComments.SUCCESS_COMMENT.heading'),
  CHALLENGE_COMMENT:
    headingsOverride?.CHALLENGE_COMMENT ||
    t('status.statusComments.CHALLENGE_COMMENT.heading'),
  ACTION_COMMENT:
    headingsOverride?.ACTION_COMMENT ||
    t('status.statusComments.ACTION_COMMENT.heading'),
});

export const hasComments = (comments: Partial<TextItem>[]) =>
  comments.some((comment) => comment.text?.trim());
