import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import Select from 'shared/components/__DEPRECATED__/Select';
import type { SelectProps } from 'shared/components/__DEPRECATED__/Select';

import { getOptionLabel } from './SelectOrgUnitInput.utils';
import { useSelectOrgUnitInput } from './SelectOrgUnitInputProvider';
import type { SelectOrgUnitInputFieldOption } from './SelectOrgUnitInput.type';

type SelectOrgUnitInputProps = Pick<
  SelectProps<SelectOrgUnitInputFieldOption, false>,
  'value' | 'isSearchable' | 'isDisabled'
> & { onChange: (newValue: SelectOrgUnitInputFieldOption | null) => void };

const SelectOrgUnitInput = ({
  isSearchable = true,
  isDisabled: isDisabledProp,
  onChange,
  ...restProps
}: SelectOrgUnitInputProps) => {
  const value = restProps.value as SelectOrgUnitInputFieldOption | null;

  const { t } = useTranslation();

  const { options } = useSelectOrgUnitInput();

  const isSingleOption =
    options.length === 1 &&
    (!options[0].options || options[0].options.length === 1);

  useEffect(() => {
    if (isSingleOption && !value) {
      const firstOption = options[0];
      if (firstOption.options) {
        onChange(firstOption.options[0]);
      } else {
        onChange(firstOption);
      }
    }
  }, [isSingleOption, onChange, options, value]);

  const isDisabled = isDisabledProp || (isSingleOption && !!value);

  const optionForValue = findOption(value, options);

  const optionsWithInitialValue = [
    optionForValue ? undefined : value,
    ...options,
  ].filter(Boolean);

  return (
    <Select<SelectOrgUnitInputFieldOption, false>
      {...restProps}
      onChange={onChange}
      value={optionForValue || value}
      options={optionsWithInitialValue}
      getOptionLabel={getOptionLabel({ t })}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
    />
  );
};

export default SelectOrgUnitInput;

function findOption(
  selectedOption: SelectOrgUnitInputFieldOption | null,
  options: SelectOrgUnitInputFieldOption[],
): SelectOrgUnitInputFieldOption | null {
  for (const option of options) {
    if (option.options) {
      return findOption(selectedOption, option.options);
    }

    if (!selectedOption?.value && !option.value) return option;

    const isMatch = selectedOption?.value === option.value;

    if (isMatch) return option;
  }

  return null;
}
