import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';

import Dialog from 'shared/components/Dialog';
import { useUser } from 'user/UserProvider';
import type { Objective, ObjectiveInput, Theme } from 'types.graphql.generated';
import { ObjectiveDocument } from 'objective/ObjectiveProvider';
import { resolveInitialValue as resolveSelectObjectiveInputInitialValue } from 'objective/SelectObjectiveInput';
import ResetFormOnChange from 'shared/form/ResetFormOnChange';
import useHandleError from 'shared/errors/useHandleError';
import {
  StrategyActionsOrgDocument,
  StrategyActionsOrgUnitDocument,
} from 'actions/StrategyActions/StrategyActionsProvider.graphql.generated';
import type { ActionFormValues } from 'actions/EditAction/ActionForm';
import ActionForm from 'actions/EditAction/ActionForm';
import { useToasts } from 'shared/toast/useToasts';
import useDefaultEndDate from 'shared/hooks/useDefaultEndDate';
import StrategyProvider from 'strategy/StrategyProvider';

import { useAddActionMutation } from './AddAction.graphql.generated';
import ActionQuickFormFields from './ActionQuickFormFields';
import type { ActionCreated } from './ActionCreatedDialog';
import ActionCreatedDialog from './ActionCreatedDialog';

const createActionFormId = 'create-action';

export type Props = {
  isOpen: boolean;
  objective?: Pick<Objective, 'id' | 'name' | 'timeLine'>;
  onClose: () => void;
  showSuccessDialog?: boolean;
  theme?: Pick<Theme, 'id' | 'name'>;
};

const CreateAction = ({
  isOpen,
  onClose,
  objective,
  showSuccessDialog,
}: Props) => {
  const { t } = useTranslation();

  const { user } = useUser();

  const handleApolloError = useHandleError();

  const [addAction, { loading }] = useAddActionMutation({
    onError: handleApolloError,
    refetchQueries: [
      ObjectiveDocument,
      StrategyActionsOrgDocument,
      StrategyActionsOrgUnitDocument,
    ],
  });

  const { addToast } = useToasts();

  const [isActionCreatedDialogOpen, setIsActionCreatedDialogOpen] =
    useState(false);
  const [createdAction, setCreatedAction] = useState<
    ActionCreated | undefined
  >();

  const defaultEndDate = useDefaultEndDate(objective);

  const initialValues: ActionFormValues = useMemo(
    () => ({
      id: null,
      name: '',
      description: '',
      owner: user.email,
      objective: resolveSelectObjectiveInputInitialValue(objective),
      priority: null,
      timeLine: {
        endDate: defaultEndDate,
      },
    }),
    [defaultEndDate, objective, user],
  );

  const handleSubmit = async (input: ObjectiveInput) => {
    const result = await addAction({ variables: { input } });

    if (result.data?.addAction && !result.errors) {
      if (showSuccessDialog) {
        setCreatedAction(result.data.addAction);
        setIsActionCreatedDialogOpen(true);
      } else {
        addToast({
          variant: 'success',
          children: t('actions.toasts.createActionSuccess'),
        });
      }

      onClose();
    }
  };

  return (
    <>
      <ActionForm
        id={createActionFormId}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <ResetFormOnChange value={isOpen} />

        <Dialog
          isOpen={isOpen}
          onClose={onClose}
          headerTitle={t('actions.form.heading.create')}
          confirmLabel={t('create')}
          cancelLabel={t('cancel')}
          formId={createActionFormId}
          isConfirmDisabled={loading}
        >
          <ActionQuickFormFields
            showObjectiveField={!initialValues.objective.value}
          />
        </Dialog>
      </ActionForm>

      <ActionCreatedDialog
        isOpen={isActionCreatedDialogOpen}
        onClose={() => setIsActionCreatedDialogOpen(false)}
        action={createdAction}
      />
    </>
  );
};

export default (props: Props) => (
  <StrategyProvider>
    <CreateAction {...props} />
  </StrategyProvider>
);
