import compact from 'lodash/compact';
import type { TFunction } from 'react-i18next';

import { contributionRoutesPaths } from 'contribution/contribution.routing.paths';
import { toolkitRoutesPaths } from 'toolkit/toolkit.routing.paths';
import { teamRoutesPaths } from 'team/team.routing.paths';
import { strategyRoutesPaths } from 'strategy/strategy.routing.paths';
import {
  canPerformOrgAction,
  type OrgWithAuthorizedActions,
} from 'user/ability/canPerformOrgAction';

export const getNavigationLinks = ({
  t,
  teamSlug,
  primaryTeamSlug,
  orgTeamSlug,
  pathname,
  strategyId,
  org,
}: {
  org: OrgWithAuthorizedActions;
  orgTeamSlug: string;
  pathname: string;
  primaryTeamSlug: string;
  strategyId?: Maybe<string>;
  t: TFunction;
  teamSlug: string;
}) =>
  compact([
    {
      to: strategyRoutesPaths.overview.root({
        params: { teamSlug: primaryTeamSlug },
      }),
      name: t('home.breadcrumbs.home'),
      end: true,
    },
    {
      to: contributionRoutesPaths.root({ params: { teamSlug: orgTeamSlug } }),
      name: t('contribution.myContribution.heading'),
      end: true,
    },
    strategyId
      ? {
          to: strategyRoutesPaths.root({ params: { teamSlug } }),
          name: t('home.breadcrumbs.strategy'),
          end: !(
            pathname.includes('objectives') ||
            pathname.includes('metrics') ||
            pathname.includes('initiatives') ||
            pathname.includes('actions') ||
            pathname.includes('board') ||
            pathname.includes('overview') ||
            pathname.includes('insights')
          ),
        }
      : {
          to: teamRoutesPaths.root({ params: { teamSlug } }),
          name: t('home.breadcrumbs.strategy'),
          end: !(
            pathname.includes('objectives') || pathname.includes('overview')
          ),
        },
    {
      to: teamRoutesPaths.myTeams(),
      name: t('team.myTeams.title'),
    },
    canPerformOrgAction(org, 'WRITE_STRATEGY') && {
      to: toolkitRoutesPaths.root({ params: { teamSlug } }),
      name: t('toolkit.toolkit'),
    },
  ]);
