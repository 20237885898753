import type { PropsWithChildren } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { signOut } from 'firebase/auth';

import GlobalSpinner from 'shared/spinner/GlobalSpinner';
import { TeamAdapter } from 'team/TeamAdapter';
import { useUserTenants } from 'user/UserTenantsProvider/useUserTenants';
import { useActingOrgKey } from 'org/ActingOrgKeyProvider/useActingOrgKey';
import { auth } from 'auth/AuthProvider';

const InitialOrgKeyResolver = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const { tenants } = useUserTenants();

  const { actingOrgKey, setActingOrgKey, clearActingOrgKey } =
    useActingOrgKey();

  const [isInitialized, setIsInitialized] = useState(false);

  const orgKeyFromUser =
    tenants.find((tenant) => tenant.isPrimaryTenantForCurrentUser)?.orgKey ||
    tenants[0]?.orgKey;

  const { teamSlug } = useParams() as { teamSlug?: string };

  const teamAdapter = useMemo<Maybe<TeamAdapter>>(
    () => (teamSlug ? TeamAdapter.fromParam(teamSlug) : undefined),
    [teamSlug],
  );

  const orgKeyFromUrlParam = teamAdapter?.orgKey;

  useEffect(() => {
    if (orgKeyFromUrlParam) {
      const userHasOrgKeyTenant = tenants.some(
        (tenant) => tenant.orgKey === orgKeyFromUrlParam,
      );

      if (userHasOrgKeyTenant) {
        setActingOrgKey(orgKeyFromUrlParam);
        setIsInitialized(true);
        return;
      } else {
        navigate('/');
      }
    }

    if (actingOrgKey) {
      const userHasOrgKeyTenant = tenants.some(
        (tenant) => tenant.orgKey === actingOrgKey,
      );

      if (userHasOrgKeyTenant) {
        setIsInitialized(true);
        return;
      } else {
        clearActingOrgKey();
      }
    }

    if (orgKeyFromUser) {
      setActingOrgKey(orgKeyFromUser);
      setIsInitialized(true);
      return;
    }

    signOut(auth);
  }, [
    actingOrgKey,
    clearActingOrgKey,
    navigate,
    orgKeyFromUrlParam,
    orgKeyFromUser,
    setActingOrgKey,
    tenants,
  ]);

  if (!isInitialized) return <GlobalSpinner />;

  return <>{children}</>;
};

export default InitialOrgKeyResolver;
