import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Field } from 'formik';

import SignUpLayout from 'auth/SignUp/SignUpLayout';
import Space from 'shared/components/Space';
import Button from 'shared/components/Button';
import Form from 'shared/form/Form';
import FieldBox from 'shared/form/FieldBox';
import { TextInputField } from 'shared/components/TextInput';
import { auth } from 'auth/AuthProvider';

type FormValues = {
  email: string;
  password: string;
};

const SignUpWithPassword = () => {
  const { t } = useTranslation();

  const initialValues = { email: '', password: '' };

  const handleSubmit = ({ email, password }: FormValues) =>
    signInWithEmailAndPassword(auth, email, password);

  return (
    <SignUpLayout heading={t('signUp.heading')} description={''}>
      <Form<FormValues> initialValues={initialValues} onSubmit={handleSubmit}>
        {({ hasError }) => (
          <Space
            direction={'vertical'}
            isCentered={true}
            css={css('align-items: stretch')}
          >
            <FieldBox
              name={'email'}
              label={t('signUpForm.email.label')}
              hasError={hasError('email')}
              css={css`
                margin-top: 2.5rem;
              `}
            >
              <Field
                name={'email'}
                component={TextInputField}
                placeholder={t('signUpForm.email.placeholder')}
              />
            </FieldBox>
            <FieldBox
              name={'password'}
              label={t('signUpForm.password.label')}
              hasError={hasError('password')}
              css={css`
                margin-top: 2.5rem;
              `}
            >
              <Field
                name={'password'}
                component={TextInputField}
                type={'password'}
              />
            </FieldBox>
            <Button
              type={'submit'}
              variant={'contained'}
              css={css`
                width: 100%;
                height: 3.75rem;
                margin-top: 1.25rem;
              `}
            >
              {t('signUpWithoutEmail.button')}
            </Button>
          </Space>
        )}
      </Form>
    </SignUpLayout>
  );
};

export default SignUpWithPassword;
