import * as yup from 'yup';
import type { TFunction } from 'react-i18next';

import type { SelectObjectiveInputFieldOption } from './SelectObjectiveInput.type';

export const hasObjective = (t: TFunction) =>
  yup
    .object()
    .test(
      'hasObjective',
      t('selectObjectiveInput.missingObjective'),
      (option: SelectObjectiveInputFieldOption) => !!option.value,
    );
