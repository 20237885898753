import { memo } from 'react';
import styled from '@emotion/styled';

import { transientOptions } from 'shared/utils/emotion.utils';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import type { OrgUnit } from 'types.graphql.generated';

export type TeamImageSize = 'micro' | 'tiny' | 'small' | 'medium';

const sizeStyles = {
  borderRadius: {
    micro: '4px',
    tiny: '4px',
    small: '.5rem',
    medium: '.75rem',
  },
  fontSize: {
    micro: '.7rem',
    tiny: '.8rem',
    small: '.875rem',
    medium: '1.5rem',
  },
  height: {
    micro: '28px',
    tiny: '32px',
    small: '2.625rem',
    medium: '4.5rem',
  },
  padding: {
    micro: '0',
    tiny: '0',
    small: '.125rem',
    medium: '.125rem',
  },
  width: {
    micro: '28px',
    tiny: '32px',
    small: '2.625rem',
    medium: '4.5rem',
  },
  border: {
    micro: 'none',
    tiny: 'none',
    small: '',
    medium: '',
  },
};

const Container = styled('div', transientOptions)<{
  $backgroundColor?: Maybe<string>;
  $size: TeamImageSize;
}>`
  align-items: center;
  background-color: ${(props) =>
    props.$backgroundColor || props.theme.legacyColor.colorAshGray};
  border: 2px solid ${(props) => props.theme.color.white};
  color: ${(props) => props.theme.color.white};
  display: flex;
  flex-shrink: 0;
  justify-content: center;

  border-radius: ${({ $size }) => sizeStyles.borderRadius[$size]};
  font-size: ${({ $size }) => sizeStyles.fontSize[$size]};
  height: ${({ $size }) => sizeStyles.height[$size]};
  padding: ${({ $size }) => sizeStyles.padding[$size]};
  width: ${({ $size }) => sizeStyles.width[$size]};
  border: ${({ $size }) => sizeStyles.border[$size]};
`;

type Props = {
  className?: string;
  orgUnit?: Maybe<
    Pick<OrgUnit, 'id' | 'name' | 'nameAbbreviated' | 'backgroundColorAsHex'>
  >;
  size?: TeamImageSize;
};

const TeamImage = ({ orgUnit, size = 'medium', className }: Props) => {
  const { activeOrg } = useActiveOrg();

  const orgName =
    activeOrg.displayName ||
    activeOrg.displayNameAbbreviated ||
    activeOrg.domainNames[0] ||
    activeOrg.id;

  const orgUnitName = orgUnit?.name || orgUnit?.nameAbbreviated || orgUnit?.id;

  const longName = orgUnitName || orgName;

  const shortName = orgUnit
    ? orgUnit.nameAbbreviated || orgUnitName?.substring(0, 3)
    : activeOrg.displayNameAbbreviated || orgName.substring(0, 3);

  const backgroundColor = orgUnit
    ? orgUnit.backgroundColorAsHex
    : activeOrg.backgroundColorAsHex;

  return (
    <Container
      $size={size}
      className={className}
      role={'img'}
      aria-label={longName}
      $backgroundColor={backgroundColor}
    >
      {shortName}
    </Container>
  );
};

export default memo(TeamImage);
