import { Trans, type TFunction } from 'react-i18next';
import styled from '@emotion/styled';

import AvatarImage from 'shared/components/Avatar/AvatarImage';
import Button from 'shared/components/Button';
import Space from 'shared/components/Space';
import StrategyRoadmapMissionCard from 'strategy/StrategyRoadmap/StrategyRoadmapMissionCard';
import type { ThemeFragment } from 'themes/ThemesProvider/ThemesProvider.graphql.generated';
import StrategyRoadmapVisionCard from 'strategy/StrategyRoadmap/StrategyRoadmapVisionCard';
import StrategyRoadmapGoalsCard from 'strategy/StrategyRoadmap/StrategyRoadmapGoalsCard';
import StrategyRoadmapChoicesCard from 'strategy/StrategyRoadmap/StrategyRoadmapChoicesCard';
import StrategyRoadmapThemesCard from 'strategy/StrategyRoadmap/StrategyRoadmapThemesCard';
import StrategyRoadmapBehaviorsCard from 'strategy/StrategyRoadmap/StrategyRoadmapBehaviorsCard';
import StrategyRoadmapValuesCard from 'strategy/StrategyRoadmap/StrategyRoadmapValuesCard';
import type { CampaignQuestionType, OrgUnit } from 'types.graphql.generated';
import type {
  StrategyRoadmapObjectiveFragment,
  StrategyRoadmapStrategyFieldsFragment,
} from 'strategy/StrategyRoadmap/StrategyRoadmapProvider/StrategyRoadmapProvider.graphql.generated';
import Emoji from 'shared/components/Emoji';
import { getObjectivesByTheme } from 'strategy/StrategyRoadmap/StrategyRoadmapProvider/StrategyRoadmapProvider.utils';
import { sortObjectivesByTheme } from 'objective/objective.utils';

import AnswerPicker from './AnswerPicker';
import Comment from './Comment';
import ResponseIcon from './ResponseIcon';
import ReadOnlyHidden from './ReadOnlyHidden';
import type { SurveyQuestion } from './QuestionsProvider.context';

const CardContainer = styled.div`
  display: flex;
  flex-grow: 1;

  section {
    flex-grow: 1;
    width: 0;
    max-width: 100%;
  }
`;

type Params = {
  campaignId: string;
  campaignOwnerEmail: string;
  campaignOwnerName?: string;
  campaignOwnerPhotoUrl?: string;
  close: () => void;
  goToNextQuestion: () => void;
  objectives: StrategyRoadmapObjectiveFragment[];
  org?: string;
  orgUnitTree: Pick<OrgUnit, 'id'>[];
  strategy: StrategyRoadmapStrategyFieldsFragment;
  t: TFunction;
  themes: ThemeFragment[];
  user?: string;
};

const getQuestions = ({
  campaignId,
  t,
  user,
  org,
  strategy,
  themes,
  orgUnitTree,
  objectives,
  campaignOwnerName,
  campaignOwnerEmail,
  campaignOwnerPhotoUrl,
  goToNextQuestion,
  close,
}: Params) => {
  const avatar = <AvatarImage photoUrl={campaignOwnerPhotoUrl} size={'big'} />;

  const continueButton = (
    <ReadOnlyHidden key={'continue'}>
      <Button onPress={goToNextQuestion}>{t('continue')}</Button>
    </ReadOnlyHidden>
  );

  const owner = campaignOwnerName || campaignOwnerEmail;

  return [
    {
      id: 'intro',
      title: t('toolkit.toolPage.walkOfStrategy.survey.questions.intro.title'),
      icon: <Emoji icon={'👋'} />,
      messages: [
        {
          avatar,
          key: 'line1',
          content: t(
            'toolkit.toolPage.walkOfStrategy.survey.questions.intro.lines.1',
            { user },
          ),
        },
        t('toolkit.toolPage.walkOfStrategy.survey.questions.intro.lines.2', {
          owner,
          org,
        }),
        t('toolkit.toolPage.walkOfStrategy.survey.questions.intro.lines.3'),
        <ReadOnlyHidden key={'answer'}>
          <Space>
            <Button onPress={goToNextQuestion}>
              {t('toolkit.toolPage.walkOfStrategy.survey.button.start')}
            </Button>
            <Button variant={'outlined'} onPress={close}>
              {t('toolkit.toolPage.walkOfStrategy.survey.button.notNow')}
            </Button>
          </Space>
        </ReadOnlyHidden>,
      ],
    },
    {
      type: 'STRATEGY_MISSION' as CampaignQuestionType,
      id: 'mission',
      title: t(
        'toolkit.toolPage.walkOfStrategy.survey.questions.mission.title',
      ),
      icon: (
        <ResponseIcon
          campaignId={campaignId}
          questionType={'STRATEGY_MISSION'}
        />
      ),
      messages: [
        {
          avatar,
          key: 'line1',
          content: t(
            'toolkit.toolPage.walkOfStrategy.survey.questions.mission.lines.1',
            { user: user },
          ),
        },
        t('toolkit.toolPage.walkOfStrategy.survey.questions.mission.lines.2', {
          org,
        }),
        <CardContainer key={'card'}>
          <StrategyRoadmapMissionCard
            strategy={strategy}
            showEditButton={false}
          />
        </CardContainer>,
        {
          key: 'line3',
          avatar,
          bubbleWrap: true,
          content: (
            <Trans
              i18nKey={
                'toolkit.toolPage.walkOfStrategy.survey.questions.mission.lines.3'
              }
            />
          ),
        },
        {
          key: 'answer',
          waitForInput: true,
          content: (
            <AnswerPicker
              campaignId={campaignId}
              questionType={'STRATEGY_MISSION'}
              questionText={stripHtmlAndNormalize(
                t(
                  'toolkit.toolPage.walkOfStrategy.survey.questions.mission.lines.3',
                ),
              )}
            />
          ),
        },
        {
          key: 'line4',
          avatar,
          content: t(
            'toolkit.toolPage.walkOfStrategy.survey.questions.mission.lines.4',
            { user },
          ),
        },
        continueButton,
      ],
    },
    {
      type: 'STRATEGY_VISION' as CampaignQuestionType,
      id: 'vision',
      title: t('toolkit.toolPage.walkOfStrategy.survey.questions.vision.title'),
      icon: (
        <ResponseIcon
          campaignId={campaignId}
          questionType={'STRATEGY_VISION'}
        />
      ),
      messages: [
        {
          avatar,
          key: 'line1',
          content: t(
            'toolkit.toolPage.walkOfStrategy.survey.questions.vision.lines.1',
            { user },
          ),
        },
        t('toolkit.toolPage.walkOfStrategy.survey.questions.vision.lines.2', {
          org,
        }),
        <CardContainer key={'card'}>
          <StrategyRoadmapVisionCard
            strategy={strategy}
            showEditButton={false}
          />
        </CardContainer>,
        {
          key: 'line3',
          avatar,
          bubbleWrap: true,
          content: (
            <Trans
              i18nKey={
                'toolkit.toolPage.walkOfStrategy.survey.questions.vision.lines.3'
              }
            />
          ),
        },
        {
          key: 'answer',
          waitForInput: true,
          content: (
            <AnswerPicker
              campaignId={campaignId}
              questionType={'STRATEGY_VISION'}
              questionText={stripHtmlAndNormalize(
                t(
                  'toolkit.toolPage.walkOfStrategy.survey.questions.vision.lines.3',
                ),
              )}
            />
          ),
        },
        {
          key: 'line4',
          avatar,
          content: t(
            'toolkit.toolPage.walkOfStrategy.survey.questions.vision.lines.4',
            { user },
          ),
        },
        continueButton,
      ],
    },
    {
      type: 'STRATEGY_GOALS' as CampaignQuestionType,
      id: 'goals',
      title: t('toolkit.toolPage.walkOfStrategy.survey.questions.goals.title'),
      icon: (
        <ResponseIcon campaignId={campaignId} questionType={'STRATEGY_GOALS'} />
      ),
      messages: [
        {
          avatar,
          key: 'line1',
          content: t(
            'toolkit.toolPage.walkOfStrategy.survey.questions.goals.lines.1',
            { user },
          ),
        },
        t('toolkit.toolPage.walkOfStrategy.survey.questions.goals.lines.2', {
          org,
        }),

        <CardContainer key={'card'}>
          <StrategyRoadmapGoalsCard
            strategy={strategy}
            showEditButton={false}
          />
        </CardContainer>,
        {
          key: 'line3',
          avatar,
          bubbleWrap: true,
          content: (
            <Trans
              i18nKey={
                'toolkit.toolPage.walkOfStrategy.survey.questions.goals.lines.3'
              }
            />
          ),
        },
        {
          key: 'answer',
          waitForInput: true,
          content: (
            <AnswerPicker
              campaignId={campaignId}
              questionType={'STRATEGY_GOALS'}
              questionText={stripHtmlAndNormalize(
                t(
                  'toolkit.toolPage.walkOfStrategy.survey.questions.goals.lines.3',
                ),
              )}
            />
          ),
        },
        {
          key: 'line4',
          avatar,
          content: t(
            'toolkit.toolPage.walkOfStrategy.survey.questions.goals.lines.4',
            { user },
          ),
        },
        continueButton,
      ],
    },
    {
      type: 'STRATEGY_CHOICES' as CampaignQuestionType,
      id: 'choices',
      title: t(
        'toolkit.toolPage.walkOfStrategy.survey.questions.choices.title',
      ),
      icon: (
        <ResponseIcon
          campaignId={campaignId}
          questionType={'STRATEGY_CHOICES'}
        />
      ),
      messages: [
        {
          avatar,
          key: 'line1',
          content: t(
            'toolkit.toolPage.walkOfStrategy.survey.questions.choices.lines.1',
            { user },
          ),
        },
        t('toolkit.toolPage.walkOfStrategy.survey.questions.choices.lines.2', {
          org,
        }),
        <CardContainer key={'card'}>
          <StrategyRoadmapChoicesCard
            strategy={strategy}
            showEditButton={false}
          />
        </CardContainer>,
        {
          key: 'line3',
          avatar,
          bubbleWrap: true,
          content: (
            <Trans
              i18nKey={
                'toolkit.toolPage.walkOfStrategy.survey.questions.choices.lines.3'
              }
            />
          ),
        },
        {
          key: 'answer',
          waitForInput: true,
          content: (
            <AnswerPicker
              campaignId={campaignId}
              questionType={'STRATEGY_CHOICES'}
              questionText={stripHtmlAndNormalize(
                t(
                  'toolkit.toolPage.walkOfStrategy.survey.questions.choices.lines.3',
                ),
              )}
            />
          ),
        },
        {
          key: 'line4',
          avatar,
          content: t(
            'toolkit.toolPage.walkOfStrategy.survey.questions.choices.lines.4',
            { user },
          ),
        },
        continueButton,
      ],
    },
    {
      type: 'STRATEGY_THEMES' as CampaignQuestionType,
      id: 'themes',
      title: t('toolkit.toolPage.walkOfStrategy.survey.questions.themes.title'),
      icon: (
        <ResponseIcon
          campaignId={campaignId}
          questionType={'STRATEGY_THEMES'}
        />
      ),
      messages: [
        {
          avatar,
          key: 'line1',
          content: t(
            'toolkit.toolPage.walkOfStrategy.survey.questions.themes.lines.1',
            { user },
          ),
        },
        t('toolkit.toolPage.walkOfStrategy.survey.questions.themes.lines.2', {
          org,
        }),
        <CardContainer key={'card'}>
          <StrategyRoadmapThemesCard
            key={'card'}
            showEditButton={false}
            objectivesByTheme={sortObjectivesByTheme(
              getObjectivesByTheme(objectives, themes),
              orgUnitTree,
            )}
          />
        </CardContainer>,
        {
          key: 'line3',
          avatar,
          bubbleWrap: true,
          content: (
            <Trans
              i18nKey={
                'toolkit.toolPage.walkOfStrategy.survey.questions.themes.lines.3'
              }
            />
          ),
        },
        {
          key: 'answer',
          waitForInput: true,
          content: (
            <AnswerPicker
              campaignId={campaignId}
              questionType={'STRATEGY_THEMES'}
              questionText={stripHtmlAndNormalize(
                t(
                  'toolkit.toolPage.walkOfStrategy.survey.questions.themes.lines.3',
                ),
              )}
            />
          ),
        },
        {
          key: 'line4',
          avatar,
          content: t(
            'toolkit.toolPage.walkOfStrategy.survey.questions.themes.lines.4',
            { user },
          ),
        },
        continueButton,
      ],
    },
    {
      type: 'STRATEGY_EXPECTATIONS' as CampaignQuestionType,
      id: 'expectations',
      title: t(
        'toolkit.toolPage.walkOfStrategy.survey.questions.behaviors.title',
      ),
      icon: (
        <ResponseIcon
          campaignId={campaignId}
          questionType={'STRATEGY_EXPECTATIONS'}
        />
      ),
      messages: [
        {
          avatar,
          key: 'line1',
          content: t(
            'toolkit.toolPage.walkOfStrategy.survey.questions.behaviors.lines.1',
            { user },
          ),
        },
        t(
          'toolkit.toolPage.walkOfStrategy.survey.questions.behaviors.lines.2',
          {
            org,
          },
        ),
        <CardContainer key={'card'}>
          <StrategyRoadmapBehaviorsCard
            strategy={strategy}
            showEditButton={false}
          />
        </CardContainer>,
        {
          key: 'line3',
          avatar,
          bubbleWrap: true,
          content: (
            <Trans
              i18nKey={
                'toolkit.toolPage.walkOfStrategy.survey.questions.behaviors.lines.3'
              }
            />
          ),
        },
        {
          key: 'answer',
          waitForInput: true,
          content: (
            <AnswerPicker
              campaignId={campaignId}
              questionType={'STRATEGY_EXPECTATIONS'}
              questionText={stripHtmlAndNormalize(
                t(
                  'toolkit.toolPage.walkOfStrategy.survey.questions.behaviors.lines.3',
                ),
              )}
            />
          ),
        },
        {
          key: 'line4',
          avatar,
          content: t(
            'toolkit.toolPage.walkOfStrategy.survey.questions.behaviors.lines.4',
            { user },
          ),
        },
        continueButton,
      ],
    },
    {
      type: 'STRATEGY_VALUES' as CampaignQuestionType,
      id: 'values',
      title: t('toolkit.toolPage.walkOfStrategy.survey.questions.values.title'),
      icon: (
        <ResponseIcon
          campaignId={campaignId}
          questionType={'STRATEGY_VALUES'}
        />
      ),
      messages: [
        {
          avatar,
          key: 'line1',
          content: t(
            'toolkit.toolPage.walkOfStrategy.survey.questions.values.lines.1',
            { user },
          ),
        },
        t('toolkit.toolPage.walkOfStrategy.survey.questions.values.lines.2', {
          org,
        }),
        <CardContainer key={'card'}>
          <StrategyRoadmapValuesCard
            strategy={strategy}
            showEditButton={false}
          />
        </CardContainer>,
        {
          key: 'line3',
          avatar,
          bubbleWrap: true,
          content: (
            <Trans
              i18nKey={
                'toolkit.toolPage.walkOfStrategy.survey.questions.values.lines.3'
              }
            />
          ),
        },
        {
          key: 'answer',
          waitForInput: true,
          content: (
            <AnswerPicker
              campaignId={campaignId}
              questionType={'STRATEGY_VALUES'}
              questionText={stripHtmlAndNormalize(
                t(
                  'toolkit.toolPage.walkOfStrategy.survey.questions.values.lines.3',
                ),
              )}
            />
          ),
        },
        {
          key: 'line4',
          avatar,
          content: t(
            'toolkit.toolPage.walkOfStrategy.survey.questions.values.lines.4',
            { user },
          ),
        },
        continueButton,
      ],
    },
    {
      id: 'comment',
      icon: <Emoji icon={'🎉'} />,
      title: t(
        'toolkit.toolPage.walkOfStrategy.survey.questions.comment.title',
      ),
      messages: [
        {
          avatar,
          key: 'line1',
          content: t(
            t(
              'toolkit.toolPage.walkOfStrategy.survey.questions.comment.lines.1',
            ),
            { user },
          ),
        },
        t('toolkit.toolPage.walkOfStrategy.survey.questions.comment.lines.2'),
        {
          key: 'comment',
          waitForInput: true,
          content: <Comment campaignId={campaignId} />,
        },
        {
          key: 'line3',
          avatar,
          content: t(
            t(
              'toolkit.toolPage.walkOfStrategy.survey.questions.comment.lines.3',
            ),
            { user },
          ),
        },
        <ReadOnlyHidden key={'close'}>
          <Button onPress={close}>
            {t('toolkit.toolPage.walkOfStrategy.survey.button.close')}
          </Button>
        </ReadOnlyHidden>,
      ],
    },
  ].filter(Boolean) satisfies SurveyQuestion[];
};

export default getQuestions;

const stripHtmlAndNormalize = (input: string) => {
  // Strip HTML
  const temp = document.createElement('div');
  temp.innerHTML = input;
  let output = temp.textContent || temp.innerText || '';

  // Remove new lines, carriage returns and tabs
  output = output.replace(/[\r\n\t]/gm, ' ');

  // Trim and reduce spaces
  output = output.replace(/\s+/g, ' ').trim();

  return output;
};
