import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import Heading from 'shared/components/Heading';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import Button from 'shared/components/Button';
import Space from 'shared/components/Space';
import TeamsTreeList from 'team/TeamsTreeList';
import type { Org } from 'types.graphql.generated';
import Text from 'shared/components/Text';
import { canWriteOrgStrategyOrWriteAnyOrgUnit } from 'user/ability/canPerformOrgUnitAction';

import { useMyTeams } from '../MyTeamsProvider';
import { myTeamsListRoutesPaths } from './MyTeamsList.routing.paths';
import MyTeamsListCreateOrgUnitModal from '../MyTeamsListCreateOrgUnitModal';
import styles from './MyTeamsList.module.scss';

type MyTeamsListProps = {
  orgKey: Org['orgKey'];
};

const MyTeamsList = ({ orgKey }: MyTeamsListProps) => {
  const { t } = useTranslation();

  const { org } = useMyTeams();
  const hasTeams = org ? org.orgUnits.length > 0 : false;

  const navigate = useNavigate();

  const handleCreateTeam = useCallback(() => {
    navigate(myTeamsListRoutesPaths.create());
  }, [navigate]);

  const showCreateOrgUnitButton =
    org && canWriteOrgStrategyOrWriteAnyOrgUnit(org, org.orgUnits);

  return (
    <>
      <div className={styles.container}>
        <Space direction={'vertical'}>
          <div className={styles.treeListContainer}>
            <Heading level={3} hasMargin={false}>
              {t('team.organization')}
            </Heading>
            <Space className={styles.controls}>
              {showCreateOrgUnitButton && (
                <Button
                  icon={PlusIcon}
                  iconPosition={'start'}
                  onPress={handleCreateTeam}
                >
                  {t('team.myTeams.createTeamButton')}
                </Button>
              )}
            </Space>
          </div>
          {org && hasTeams && (
            <TeamsTreeList
              orgKey={orgKey}
              orgUnits={org.orgUnits}
              className={styles.treeList}
            />
          )}
          {!hasTeams && (
            <Text className={styles.noTeam} isCentered={true}>
              {t('team.myTeams.noTeam')}
            </Text>
          )}
        </Space>
      </div>
      <Outlet />
    </>
  );

  return null;
};

const MyTeamsListWithRouting = (props: MyTeamsListProps) => (
  <Routes>
    <Route
      path={myTeamsListRoutesPaths.root()}
      element={<MyTeamsList {...props} />}
    >
      <Route
        path={myTeamsListRoutesPaths.create()}
        element={<MyTeamsListCreateOrgUnitModal />}
      />
    </Route>
  </Routes>
);

export default MyTeamsListWithRouting;
