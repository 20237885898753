import { Outlet } from 'react-router-dom';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { LinkItem } from 'shared/components/Layout';
import Layout from 'shared/components/Layout';
import { useTeamAdapter } from 'team/TeamAdapter';
import { useMyTeamRoutePath } from 'team/useMyTeamRoutePath';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import OrgCard from 'org/OrgCard/OrgCard';
import OrgUnitCard from 'orgUnit/OrgUnitCard/OrgUnitCard';

import {
  getNavigationItems,
  getBreadcrumbsItems,
} from './StrategyLayout.utils';

const StrategyLayout = () => {
  const { t } = useTranslation();

  const { teamAdapter } = useTeamAdapter();
  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const { routePath: myTeamRoutePath, isMatch: isMyTeamRoutePathMatch } =
    useMyTeamRoutePath();

  const navigationItems = useMemo(
    () => getNavigationItems({ t, teamAdapter }),
    [t, teamAdapter],
  );

  const breadcrumbsItems = useMemo(
    () =>
      getBreadcrumbsItems({
        t,
        orgUnit,
        activeOrg,
      }),
    [t, orgUnit, activeOrg],
  );

  const teamCard = useMemo<ReactNode>(() => {
    if (teamAdapter.isOrg) {
      return <OrgCard />;
    }
    if (teamAdapter.isOrgUnit) {
      return (
        <OrgUnitCard
          orgUnitId={teamAdapter.keyArg}
          headingLevel={3}
          orgKey={teamAdapter.orgKey}
        />
      );
    }
    return null;
  }, [teamAdapter]);

  const topRightNavItem = useMemo<Maybe<LinkItem>>(() => {
    if (!isMyTeamRoutePathMatch) {
      return {
        name: t('team.layout.goToTeam'),
        to: myTeamRoutePath,
      };
    }
    return undefined;
  }, [myTeamRoutePath, t, isMyTeamRoutePathMatch]);

  return (
    <>
      <Layout.Header
        navigationItems={navigationItems}
        aboveHeading={teamCard}
        breadcrumbsItems={breadcrumbsItems}
        topRightNavItem={topRightNavItem}
      />
      <Outlet />
    </>
  );
};

export default StrategyLayout;
