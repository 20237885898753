import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useState } from 'react';

import { transientOptions } from 'shared/utils/emotion.utils';
import Space from 'shared/components/Space';
import Checkbox from 'shared/components/Checkbox';
import Button from 'shared/components/Button';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';

import { useObjectiveSelection } from './ObjectiveSelctionProvider';
import ChangeThemeModal from './ChangeThemeModal';
import type { StrategyRoomManageObjectivesThemeFragment } from './StrategyRoomThemesManageObjectives.graphql.generated';

const Container = styled(
  'div',
  transientOptions,
)<{ $isVisible: boolean }>(({ theme, $isVisible }) => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.color.white,
  borderTop: `1px solid ${theme.color.hoverDark}`,
  bottom: 0,
  height: '80px',
  left: 0,
  position: 'fixed',
  right: 0,
  transform: $isVisible ? 'translateY(0)' : 'translateY(81px)',
  transition: '.2s transform ease-in-out',
  padding: '0 40px',
}));

const Content = styled(Space)`
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  max-width: 1200px;
  margin-left: 0 24px;
`;

const Left = styled(Space)`
  align-items: center;
`;

type Props = {
  themes: StrategyRoomManageObjectivesThemeFragment[];
};

const ObjectivesSelection = ({ themes }: Props) => {
  const { t } = useTranslation();
  const { selectedObjectives, clearSelectedObjectives: clear } =
    useObjectiveSelection();
  const [isChangeThemeModalOpen, setIsChangeThemeModalOpen] = useState(false);

  const isVisible = selectedObjectives.length > 0;

  return (
    <>
      <Container $isVisible={isVisible}>
        <Content>
          <Left>
            <Checkbox checked={true} onChange={clear} />
            {t('strategy.strategyRoom.themes.drawer.selected', {
              count: selectedObjectives.length,
            })}
          </Left>
          <Space>
            <Button
              variant={'outlined'}
              icon={PlusIcon}
              iconPosition={'start'}
              onPress={() => setIsChangeThemeModalOpen(true)}
            >
              {t('strategy.strategyRoom.themes.drawer.changeTheme')}
            </Button>
          </Space>
        </Content>
      </Container>

      <ChangeThemeModal
        isOpen={isChangeThemeModalOpen}
        themes={themes}
        onClose={() => setIsChangeThemeModalOpen(false)}
      />
    </>
  );
};

export default ObjectivesSelection;
