import sample from 'lodash/sample';

import type { StatusIndicator as StatusIndicatorEnum } from 'types.graphql.generated';

import { StatusIndicator } from './StatusIndicator';

export const mockStatusIndicator = (valueParam?: StatusIndicatorEnum) => {
  const value =
    valueParam ||
    sample(['ON_TRACK', 'AT_RISK', 'BLOCKED'] as StatusIndicatorEnum[]);
  return new StatusIndicator(value);
};
