import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import CreateOrgUnitModal from 'orgUnit/CreateOrgUnitModal';

import { useMyTeams } from '../MyTeamsProvider';

const MyTeamsListCreateOrgUnitModal = () => {
  const navigate = useNavigate();

  const { refetchMyTeams } = useMyTeams();

  const handleClose = useCallback(() => navigate('../'), [navigate]);

  return (
    <CreateOrgUnitModal
      isOpen={true}
      onClose={handleClose}
      onSuccess={refetchMyTeams}
    />
  );
};

export default MyTeamsListCreateOrgUnitModal;
