import { useTranslation } from 'react-i18next';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import UserAvatar from 'user/UserAvatar';
import Text from 'shared/components/Text';
import UserActiveTag from 'user/UserActiveTag';
import Flex from 'shared/components/Flex';
import IconButton from 'shared/components/IconButton';
import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';

import type { Member } from '../Members.type';

const RemoveButtonContainer = styled(Flex)`
  margin-top: -4px;
  height: 28px;
  opacity: 0;
`;

const Row = styled(Flex)`
  border-radius: 4px;
  padding: 8px;
  margin-left: -8px;

  &:hover {
    background-color: ${(props) => props.theme.color.hoverDark};

    ${RemoveButtonContainer} {
      opacity: 1;
    }
  }
`;

type MembersMemberProps = {
  isOwner: boolean;
  member: Member;
  onRemoveMember?: () => void;
};

const MembersMember = ({
  member,
  isOwner,
  onRemoveMember,
}: MembersMemberProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const ownerText = `(${t('user.members.owner')})`;
  const roleText = `(${member.role})`;

  return (
    <Row alignItems={'center'} gap={8}>
      <UserAvatar user={member.user} showTooltip={false} />
      {member.user?.displayName || member.user?.email}
      {isOwner && <Text whiteSpace={'noWrap'}>{ownerText}</Text>}
      {member.role && (
        <Text
          whiteSpace={'noWrap'}
          css={css`
            color: ${theme.color.typoSecondary};
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
        >
          {roleText}
        </Text>
      )}
      {member.user && <UserActiveTag user={member.user} />}
      <RemoveButtonContainer justifyContent={'flex-end'} grow={1}>
        {onRemoveMember && (
          <IconButton icon={TrashIcon} onClick={onRemoveMember}>
            {t('remove')}
          </IconButton>
        )}
      </RemoveButtonContainer>
    </Row>
  );
};

export default MembersMember;
