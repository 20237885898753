import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import Button from 'shared/components/Button';
import { teamRoutesPaths } from 'team/team.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import useCanPerformOrgOrOrgUnitAction from 'user/ability/useCanPerformOrgOrOrgUnitAction';

const TeamInsightsCreateReportButton = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { teamAdapter } = useTeamAdapter();

  const handleClick = useCallback(() => {
    navigate(
      teamRoutesPaths.insights.reports.create({
        params: {
          teamSlug: teamAdapter.toParam(),
        },
      }),
    );
  }, [navigate, teamAdapter]);

  const canAddReport = useCanPerformOrgOrOrgUnitAction(
    'WRITE_STRATEGY',
    'WRITE',
  );

  if (!canAddReport) return null;

  return (
    <Button onPress={handleClick} iconPosition={'start'} icon={PlusIcon}>
      {t('report.newInsightReport')}
    </Button>
  );
};

export default TeamInsightsCreateReportButton;
