import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';
import hexToRgba from 'hex-to-rgba';

import { transientOptions } from 'shared/utils/emotion.utils';
import Space from 'shared/components/Space';
import type { IconButtonProps } from 'shared/components/IconButton';
import IconButton from 'shared/components/IconButton';
import Text from 'shared/components/Text';

const Container = styled(Space, transientOptions)<{ $isVisible: boolean }>`
  align-items: center;
  display: flex;
  height: 4.25rem;
  justify-content: center;
  left: 0;
  position: sticky;
  right: 0;
  top: 0;
  transition: background-color 0.2s, box-shadow 0.2s;
  z-index: 1000;
  background-color: ${(props) =>
    props.$isVisible ? props.theme.color.white : 'transparent'};
  box-shadow: ${(props) =>
    props.$isVisible
      ? `0 .25rem 1.25rem ${hexToRgba(props.theme.color.black, 0.12)}`
      : 'none'};
`;

const Content = styled(Text, transientOptions)<{ $isVisible: boolean }>`
  flex: 1;
  font-size: 1.25rem;
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  padding: 2rem;
  transition: opacity 0.2s;
`;

const Buttons = styled(Space)`
  justify-content: flex-end;
  padding: 0 1rem;
`;

type SideModalTopBarProps = PropsWithChildren<{
  buttons?: IconButtonProps[];
  isVisible: boolean;
}>;

const SideModalTopBar = ({
  isVisible,
  buttons,
  children,
}: SideModalTopBarProps) => (
  <Container $isVisible={isVisible}>
    <Content $isVisible={isVisible} variant={'emphasis'} ellipsis={true}>
      {children}
    </Content>
    <Buttons size={'custom'} value={0}>
      {buttons?.map((button, buttonIndex) => (
        <IconButton {...button} key={buttonIndex} />
      ))}
    </Buttons>
  </Container>
);

export default SideModalTopBar;
