import type { PropsWithChildren } from 'react';

import { ReactComponent as AiWandIcon } from 'shared/static/icons/icon-ai-wand.svg';
import Button from 'shared/components/Button';

type Props = PropsWithChildren<{ isDisabled: boolean; onClick: () => void }>;

const PromptButton = ({ isDisabled, onClick, children }: Props) => (
  <Button
    variant={'outlined'}
    onPress={onClick}
    isDisabled={isDisabled}
    icon={AiWandIcon}
    iconPosition={'start'}
  >
    {children}
  </Button>
);

export default PromptButton;
