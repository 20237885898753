import styled from '@emotion/styled';
import hexToRgba from 'hex-to-rgba';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { transientOptions } from 'shared/utils/emotion.utils';
import type { CampaignResultsQuery } from 'toolkit/WalkOfStrategyCampaignResultsLayout/CampaignResults.graphql.generated';
import { useCampaignResults } from 'toolkit/WalkOfStrategyCampaignResultsLayout/CampaignResultsProvider';
import type {
  CampaignQuestionType,
  LikertScaleRating,
} from 'types.graphql.generated';

const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
`;

const Header = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.color.strokeLight};
  text-align: center;
  padding-bottom: 12px;
`;

const QuestionTypeCell = styled('div', transientOptions)<{
  $isFirstRow: boolean;
}>`
  padding: 12px 12px 12px 24px;
  background-color: ${(props) => props.theme.color.backgroundLight};
  border-style: solid;
  border-color: ${(props) => props.theme.color.white};
  border-width: 0;
  border-top-width: ${(props) => (props.$isFirstRow ? 0 : 1)}px;
`;

const ValueCell = styled('div', transientOptions)<{ $isFirstRow: boolean }>`
  text-align: center;
  background-color: ${(props) => props.theme.color.backgroundLight};
  border-style: solid;
  border-color: ${(props) => props.theme.color.white};
  border-width: 0;
  border-top-width: ${(props) => (props.$isFirstRow ? 0 : 1)}px;
  border-left-width: 1px;
`;

const ValueCellBackground = styled('div', transientOptions)<{ $value: number }>`
  padding: 12px;
  color: ${(props) =>
    props.$value >= 60 ? props.theme.color.white : props.theme.color.black};
  background-color: ${(props) =>
    hexToRgba(props.theme.color.primary, props.$value / 100)};
`;

const DistributionSection = () => {
  const { t } = useTranslation();
  const { campaign } = useCampaignResults();

  const ratings = [
    'STRONGLY_DISAGREE' as LikertScaleRating,
    'DISAGREE' as LikertScaleRating,
    'NEUTRAL' as LikertScaleRating,
    'AGREE' as LikertScaleRating,
    'STRONGLY_AGREE' as LikertScaleRating,
  ].map((value) => ({
    label: t(`toolkit.toolPage.walkOfStrategy.survey.anwer.${value}.label`),
    value,
  }));

  return (
    <Table>
      <Header />
      {ratings.map((rating) => (
        <Header key={rating.label}>{rating.label}</Header>
      ))}
      {campaign.questions.map(({ questionType }, index) => (
        <Fragment key={questionType}>
          <QuestionTypeCell $isFirstRow={index === 0}>
            {t(
              `toolkit.toolPage.walkOfStrategy.results.questionType.${questionType}`,
            )}
          </QuestionTypeCell>
          {ratings.map((rating) => (
            <ValueCell key={rating.label} $isFirstRow={index === 0}>
              <ValueCellBackground
                $value={getDistribution(campaign, questionType, rating.value)}
              >
                {getDistribution(campaign, questionType, rating.value)}
                {'%'}
              </ValueCellBackground>
            </ValueCell>
          ))}
        </Fragment>
      ))}
    </Table>
  );
};

export default DistributionSection;

const getDistribution = (
  campaign: CampaignResultsQuery['campaign'],
  questionType: CampaignQuestionType,
  rating: LikertScaleRating,
) => {
  const questionAnswers = campaign.allUserResponses
    .map((response) =>
      response.answers.find(
        (answer) => answer.question.questionType === questionType,
      ),
    )
    .filter(Boolean);

  if (questionAnswers.length === 0) return 0;

  const ratingAnswersCount = questionAnswers.reduce(
    (result, answer) => (answer.rating === rating ? result + 1 : result),
    0,
  );

  return Math.round((ratingAnswersCount / questionAnswers.length) * 100);
};
