import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserLimitedProfileFragmentDoc } from '../../../user/userProfile.graphql.generated';
import { OrgUnitForTeamImageFragmentDoc } from '../../../orgUnit/OrgUnit.graphql.generated';
export type InitiativesContentObjectiveFragment = { __typename: 'Objective', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, isCurrentUserOwner: boolean, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, complete: boolean } | undefined, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined, completeDateTime?: any | undefined }, initiatives: Array<{ __typename: 'Initiative', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, isCurrentUserOwner: boolean, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, currentInitiativeStatus?: { __typename: 'InitiativeStatus', id: string, complete: boolean, statusIndicator: StatusIndicatorClass, keyIndicatorOnOutcome?: boolean | undefined, keyIndicatorOnTime?: boolean | undefined, keyIndicatorOnBudget?: boolean | undefined } | undefined, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined, completeDateTime?: any | undefined }, milestones: Array<{ __typename: 'Milestone', id: string, name?: string | undefined, description?: string | undefined, currentMilestoneStatus?: { __typename: 'MilestoneStatus', id: string, statusIndicator: StatusIndicatorClass, completionRate?: number | undefined, comment?: string | undefined, complete: boolean } | undefined }>, objective?: { __typename: 'Objective', id: string, name?: string | undefined, orgUnit?: { __typename: 'OrgUnit', currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined } | undefined } | undefined }> };

export type InitiativesContentInitiativeFragment = { __typename: 'Initiative', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, isCurrentUserOwner: boolean, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, currentInitiativeStatus?: { __typename: 'InitiativeStatus', id: string, complete: boolean, statusIndicator: StatusIndicatorClass, keyIndicatorOnOutcome?: boolean | undefined, keyIndicatorOnTime?: boolean | undefined, keyIndicatorOnBudget?: boolean | undefined } | undefined, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined, completeDateTime?: any | undefined }, milestones: Array<{ __typename: 'Milestone', id: string, name?: string | undefined, description?: string | undefined, currentMilestoneStatus?: { __typename: 'MilestoneStatus', id: string, statusIndicator: StatusIndicatorClass, completionRate?: number | undefined, comment?: string | undefined, complete: boolean } | undefined }>, objective?: { __typename: 'Objective', id: string, name?: string | undefined, orgUnit?: { __typename: 'OrgUnit', currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined } | undefined } | undefined };

export const InitiativesContentInitiativeFragmentDoc = gql`
    fragment initiativesContentInitiative on Initiative {
  id
  name
  currentUserAuthorizedActions
  isCurrentUserOwner
  owner {
    ...userLimitedProfile
  }
  currentInitiativeStatus {
    id
    complete
    statusIndicator
    keyIndicatorOnOutcome
    keyIndicatorOnTime
    keyIndicatorOnBudget
  }
  timeLine {
    startDate
    endDate
    completeDateTime
  }
  milestones {
    id
    name
    description
    currentMilestoneStatus {
      id
      statusIndicator
      completionRate
      comment
      complete
    }
  }
  objective {
    id
    name
    orgUnit {
      ...orgUnitForTeamImage
      currentUserAuthorizedActions
    }
  }
}
    ${UserLimitedProfileFragmentDoc}
${OrgUnitForTeamImageFragmentDoc}`;
export const InitiativesContentObjectiveFragmentDoc = gql`
    fragment initiativesContentObjective on Objective {
  id
  name
  currentUserAuthorizedActions
  isCurrentUserOwner
  orgUnit {
    id
  }
  currentObjectiveStatus {
    id
    complete
  }
  timeLine {
    startDate
    endDate
    completeDateTime
  }
  initiatives {
    ...initiativesContentInitiative
  }
}
    ${InitiativesContentInitiativeFragmentDoc}`;