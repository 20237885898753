import type { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

type Props = PropsWithChildren<{
  className?: string;
  isActive?: boolean;
  to?: string;
}>;

const OptionalLink = ({ to, className, isActive = true, children }: Props) => {
  if (to && isActive) {
    return (
      <Link to={to} className={className}>
        {children}
      </Link>
    );
  } else {
    return <div className={className}>{children}</div>;
  }
};

export default OptionalLink;
