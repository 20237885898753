import type { PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import { useErrorPage } from 'shared/errors/ErrorPage/useErrorPage';

import type { ObjectiveProviderContextValue } from './ObjectiveProvider.context';
import { ObjectiveProviderContext } from './ObjectiveProvider.context';
import { useObjectiveQuery } from './ObjectiveProvider.graphql.generated';

type ObjectiveProviderProps = PropsWithChildren<object>;

const ObjectiveProvider = ({ children }: ObjectiveProviderProps) => {
  const { objectiveId } = useParams() as { objectiveId: string };

  const onError = useHandleError();

  const { data: objectiveData, loading: isObjectiveLoading } =
    useObjectiveQuery({
      variables: { objectiveId },
      fetchPolicy: 'cache-and-network',
      onError,
    });

  const { onErrorCode } = useErrorPage();

  useEffect(() => {
    if (
      objectiveData &&
      !canPerformStrategyElementAction(objectiveData.objective, 'READ')
    ) {
      onErrorCode('ACCESS_DENIED');
    }
  }, [objectiveData, onErrorCode]);

  const contextValue = useMemo<
    ObjectiveProviderContextValue | undefined
  >(() => {
    if (objectiveData) {
      return {
        objective: objectiveData.objective,
      };
    }
    return undefined;
  }, [objectiveData]);

  if (contextValue) {
    return (
      <ObjectiveProviderContext.Provider value={contextValue}>
        {children}
      </ObjectiveProviderContext.Provider>
    );
  }

  if (isObjectiveLoading && !objectiveData) {
    return <Spinner.Circle />;
  }

  return null;
};

export default ObjectiveProvider;
