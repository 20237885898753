import { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';

import type { ActivityTimelineProps } from 'shared/components/ActivityTimeline/ActivityTimeline';
import ActivityTimeline from 'shared/components/ActivityTimeline/ActivityTimeline';

import type { MetricOverviewMetricMetricStatus } from '../MetricOverview.type';
import MetricInsightsFirstRow from '../MetricOverviewInsightsFirstRow';
import MetricInsightsRow from '../MetricOverviewInsightsRow';
import styles from './MetricOverviewActivityTimeline.module.scss';
import { getMetricStatusListWithMetricCreationDate } from '../MetricOverview.utils';
import { useMetricOverview } from '../MetricOverviewProvider';

const Container = styled.div`
  border-top: 1px solid ${(props) => props.theme.color.strokeMedium};
`;

const MetricOverviewActivityTimeline = () => {
  const { metric } = useMetricOverview();

  const metricStatusList = useMemo(
    () =>
      getMetricStatusListWithMetricCreationDate(
        metric,
        metric?.metricStatusListAll,
      ),
    [metric],
  );

  const renderRowContent = useCallback<
    ActivityTimelineProps<MetricOverviewMetricMetricStatus>['renderRowContent']
  >(
    ({ isRowExpanded, isLastRow, item }) =>
      item.context === 'metricCreated' ? (
        <MetricInsightsFirstRow
          isLastRow={isLastRow}
          metric={metric}
          key={metric?.id}
        />
      ) : (
        metric && (
          <MetricInsightsRow
            metric={metric}
            isRowExpanded={isRowExpanded}
            isLastRow={isLastRow}
            status={item}
            key={item.id}
          />
        )
      ),
    [metric],
  );

  return (
    <Container>
      {metric ? (
        <ActivityTimeline
          itemDateKey={'statusDateTime'}
          items={metricStatusList}
          renderRowContent={renderRowContent}
          className={styles.activityTimeline}
        />
      ) : null}
    </Container>
  );
};

export default MetricOverviewActivityTimeline;
