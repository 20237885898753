import { useContext } from 'react';

import RedirectContext from './Redirect.context';

export const useRedirect = () => {
  const stepsContext = useContext(RedirectContext);
  if (!stepsContext) {
    throw new Error(`${useRedirect.name} should be used inside step`);
  }
  return stepsContext;
};
