import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditObjectiveQueryVariables = Types.Exact<{
  objectiveId: Types.Scalars['ID'];
}>;


export type EditObjectiveQuery = { __typename: 'Query', objective: { __typename: 'Objective', id: string, name?: string | undefined, description?: string | undefined, isCurrentUserOwner: boolean, owner?: { __typename: 'UserLimited', id: string, email: string } | undefined, theme?: { __typename: 'Theme', id: string, name?: string | undefined } | undefined, timeLine: { __typename: 'TimeLine', endDate?: any | undefined, startDate?: any | undefined }, parentObjective?: { __typename: 'Objective', id: string, name?: string | undefined } | undefined, priority?: { __typename: 'Priority', number: number, label: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, attributes: Array<{ __typename: 'StrategyItemAttribute', id: string, name?: string | undefined, title?: string | undefined, description?: string | undefined, orderNumber?: number | undefined }> } };

export type UpdateObjectiveMutationVariables = Types.Exact<{
  input: Types.ObjectiveInput;
}>;


export type UpdateObjectiveMutation = { __typename: 'Mutation', updateObjective: { __typename: 'Objective', id: string, name?: string | undefined, description?: string | undefined, isCurrentUserOwner: boolean, owner?: { __typename: 'UserLimited', id: string, email: string } | undefined, theme?: { __typename: 'Theme', id: string, name?: string | undefined } | undefined, timeLine: { __typename: 'TimeLine', endDate?: any | undefined, startDate?: any | undefined }, parentObjective?: { __typename: 'Objective', id: string, name?: string | undefined } | undefined, priority?: { __typename: 'Priority', number: number, label: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, attributes: Array<{ __typename: 'StrategyItemAttribute', id: string, name?: string | undefined, title?: string | undefined, description?: string | undefined, orderNumber?: number | undefined }> } };

export type EditObjectiveObjectiveFragment = { __typename: 'Objective', id: string, name?: string | undefined, description?: string | undefined, isCurrentUserOwner: boolean, owner?: { __typename: 'UserLimited', id: string, email: string } | undefined, theme?: { __typename: 'Theme', id: string, name?: string | undefined } | undefined, timeLine: { __typename: 'TimeLine', endDate?: any | undefined, startDate?: any | undefined }, parentObjective?: { __typename: 'Objective', id: string, name?: string | undefined } | undefined, priority?: { __typename: 'Priority', number: number, label: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, attributes: Array<{ __typename: 'StrategyItemAttribute', id: string, name?: string | undefined, title?: string | undefined, description?: string | undefined, orderNumber?: number | undefined }> };

export const EditObjectiveObjectiveFragmentDoc = gql`
    fragment editObjectiveObjective on Objective {
  id
  name
  description
  isCurrentUserOwner
  owner {
    id
    email
  }
  theme {
    id
    name
  }
  timeLine {
    endDate
    startDate
  }
  parentObjective {
    id
    name
  }
  priority {
    number
    label
  }
  orgUnit {
    id
    name
  }
  attributes {
    id
    name
    title
    description
    orderNumber
  }
}
    `;
export const EditObjectiveDocument = gql`
    query EditObjective($objectiveId: ID!) {
  objective(id: $objectiveId) {
    ...editObjectiveObjective
  }
}
    ${EditObjectiveObjectiveFragmentDoc}`;

/**
 * __useEditObjectiveQuery__
 *
 * To run a query within a React component, call `useEditObjectiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditObjectiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditObjectiveQuery({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *   },
 * });
 */
export function useEditObjectiveQuery(baseOptions: Apollo.QueryHookOptions<EditObjectiveQuery, EditObjectiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditObjectiveQuery, EditObjectiveQueryVariables>(EditObjectiveDocument, options);
      }
export function useEditObjectiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditObjectiveQuery, EditObjectiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditObjectiveQuery, EditObjectiveQueryVariables>(EditObjectiveDocument, options);
        }
export type EditObjectiveQueryHookResult = ReturnType<typeof useEditObjectiveQuery>;
export type EditObjectiveLazyQueryHookResult = ReturnType<typeof useEditObjectiveLazyQuery>;
export type EditObjectiveQueryResult = Apollo.QueryResult<EditObjectiveQuery, EditObjectiveQueryVariables>;
export const UpdateObjectiveDocument = gql`
    mutation UpdateObjective($input: ObjectiveInput!) {
  updateObjective(input: $input) {
    ...editObjectiveObjective
  }
}
    ${EditObjectiveObjectiveFragmentDoc}`;
export type UpdateObjectiveMutationFn = Apollo.MutationFunction<UpdateObjectiveMutation, UpdateObjectiveMutationVariables>;

/**
 * __useUpdateObjectiveMutation__
 *
 * To run a mutation, you first call `useUpdateObjectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateObjectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateObjectiveMutation, { data, loading, error }] = useUpdateObjectiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateObjectiveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateObjectiveMutation, UpdateObjectiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateObjectiveMutation, UpdateObjectiveMutationVariables>(UpdateObjectiveDocument, options);
      }
export type UpdateObjectiveMutationHookResult = ReturnType<typeof useUpdateObjectiveMutation>;
export type UpdateObjectiveMutationResult = Apollo.MutationResult<UpdateObjectiveMutation>;
export type UpdateObjectiveMutationOptions = Apollo.BaseMutationOptions<UpdateObjectiveMutation, UpdateObjectiveMutationVariables>;