import type { NavigateFunction } from 'react-router-dom';

import type { ActionsLayoutType } from 'actions/ActionsLayout/ActionsLayoutProvider';
import { getActionsContext } from 'actions/action.utils';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { strategyRoutesPaths } from 'strategy/strategy.routing.paths';
import type { Scalars } from 'types.graphql.generated';

export const handleOnClose = ({
  teamSlug,
  objectiveId,
  pathname,
  navigate,
  layout,
}: {
  layout: ActionsLayoutType;
  navigate: NavigateFunction;
  objectiveId?: Scalars['ID'];
  pathname: string;
  teamSlug: string;
}) => {
  const isObjectiveContext = getActionsContext(pathname) === 'objective';

  if (isObjectiveContext && objectiveId) {
    navigate(
      objectiveRoutesPaths.actions.root({
        params: {
          teamSlug,
          objectiveId,
        },
        query: {
          view: layout,
        },
      }),
    );
  } else {
    navigate(
      strategyRoutesPaths.actions.root({
        params: {
          teamSlug,
        },
      }),
    );
  }
};
