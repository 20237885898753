import styled from '@emotion/styled';

import { transientOptions } from 'shared/utils/emotion.utils';

import type { FollowUpActivityItem } from './ContributionMyFollowUpsViewMore.type';
import TimelineEntryContent from './TimelineEntryContent';
import TimelineEntryMessage from './TimelineEntryMessage';

type ContributionMyFollowUpsViewMoreItemProps = {
  isLastRow: boolean;
  item: FollowUpActivityItem;
};

const Content = styled.div`
  border-left: 1px solid ${(props) => props.theme.color.strokeMedium};
  margin-left: 10px;
  padding-left: 24px;
`;

const InnerContent = styled('div', transientOptions)<{ $isLastRow: boolean }>`
  padding-top: 8px;
  padding-bottom: ${(props) => (props.$isLastRow ? '0' : '30px')};
`;

const TimelineItem = ({
  item,
  isLastRow,
}: ContributionMyFollowUpsViewMoreItemProps) => (
  <li>
    <TimelineEntryMessage item={item} />
    <Content>
      <InnerContent $isLastRow={isLastRow}>
        <TimelineEntryContent item={item} />
      </InnerContent>
    </Content>
  </li>
);

export default TimelineItem;
