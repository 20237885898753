import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';

import type { TeamTeamHierarchyProviderContextValue } from './TeamTeamHierarchyProvider.context';
import { TeamTeamHierarchyProviderContext } from './TeamTeamHierarchyProvider.context';
import { useTeamTeamHierarchyQuery } from './TeamTeamHierarchyProvider.graphql.generated';

type TeamTeamHierarchyProviderProps = PropsWithChildren<object>;

const TeamTeamHierarchyProvider = ({
  children,
}: TeamTeamHierarchyProviderProps) => {
  const handleApolloError = useHandleError();

  const { data: teamTeamHierarchyData, loading: isTeamTeamHierarchyLoading } =
    useTeamTeamHierarchyQuery({ onError: handleApolloError });

  const contextValue = useMemo<Maybe<TeamTeamHierarchyProviderContextValue>>(
    () =>
      teamTeamHierarchyData
        ? { orgUnits: teamTeamHierarchyData.activeOrg.orgUnits }
        : undefined,
    [teamTeamHierarchyData],
  );

  if (isTeamTeamHierarchyLoading) {
    return <Spinner.Circle />;
  }

  if (contextValue) {
    return (
      <TeamTeamHierarchyProviderContext.Provider value={contextValue}>
        {children}
      </TeamTeamHierarchyProviderContext.Provider>
    );
  }

  return null;
};

export default TeamTeamHierarchyProvider;
