import type { ReactNode } from 'react';
import styled from '@emotion/styled';
import { AnimatePresence } from 'framer-motion';

import { transientOptions } from 'shared/utils/emotion.utils';

import type { RenderActivityTimelineRowContentParams } from './ActivityTimeline.type';

type ActivityTimelineSectionProps<ActivityTimelineItem> = {
  isCurrentTimePeriod: boolean;
  isOldestTimePeriod: boolean;
  items: Record<string, unknown>[];
  renderFirstRowContent?: () => ReactNode;
  renderLastRowContent?: () => ReactNode;
  renderRowContent: (
    params: RenderActivityTimelineRowContentParams<ActivityTimelineItem>,
  ) => ReactNode;
  timePeriod: number;
};

const TimePeriod = styled('li', transientOptions)<{ $isCurrent: boolean }>`
  align-items: center;
  display: flex;
  gap: 20px;
  height: 48px;
  margin-left: 6px;
  overflow: hidden;
  position: relative;

  &::before,
  &::after {
    border-left: 1px solid ${(props) => props.theme.color.strokeMedium};
    content: '';
    display: block;
    left: 3.5px;
    position: absolute;
    width: 1px;
  }

  &::before {
    height: ${(props) => (props.$isCurrent ? 0 : '10px')};
    top: 0;
  }

  &::after {
    bottom: 0;
    height: 100%;
    top: 36px;
  }
`;

const Dot = styled.div`
  background-color: ${(props) => props.theme.color.strokeMedium};
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;

const ItemList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ActivityTimelineSection = <ActivityItem extends { id: string }>({
  items,
  isCurrentTimePeriod,
  isOldestTimePeriod,
  timePeriod,
  renderRowContent,
  renderFirstRowContent,
  renderLastRowContent,
}: ActivityTimelineSectionProps<ActivityItem>) => (
  <section>
    <ItemList>
      {renderFirstRowContent?.()}
      <TimePeriod $isCurrent={isCurrentTimePeriod}>
        <Dot />
        {timePeriod}
      </TimePeriod>
      <AnimatePresence>
        {items.map((item, index) => {
          const isYoungestItem = index === 0;
          const isOldestItem = index === items.length - 1;

          const isRowExpanded = isCurrentTimePeriod && isYoungestItem;
          const isLastRow =
            isOldestTimePeriod && isOldestItem && !renderLastRowContent;

          return renderRowContent({
            isRowExpanded,
            isLastRow,
            item: item as any,
          });
        })}
      </AnimatePresence>
      {isOldestTimePeriod && renderLastRowContent?.()}
    </ItemList>
  </section>
);

export default ActivityTimelineSection;
