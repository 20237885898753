import { useLocation, useNavigate } from 'react-router-dom';

import { usePageTitle } from 'shared/hooks/usePageTitle';
import ActionProvider, { useAction } from 'actions/ActionProvider';
import { useActionsLayout } from 'actions/ActionsLayout/ActionsLayoutProvider';
import { useTeamSlug } from 'team/TeamAdapter/useTeamSlug';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';

import { handleOnClose } from './ActionStatusModal.utils';
import ActionStatusModal from './ActionStatusModal';

const ActionStatusModalPage = () => {
  const { teamSlug } = useTeamSlug();
  const { action } = useAction();
  const { layout } = useActionsLayout();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  usePageTitle(action.name);

  const onClose = () =>
    action.objective &&
    handleOnClose({
      teamSlug,
      navigate,
      layout,
      pathname,
      objectiveId: action.objective?.id,
    });

  const onEdit = () =>
    action.objective &&
    navigate(
      objectiveRoutesPaths.actions.action.edit({
        params: {
          teamSlug,
          objectiveId: action.objective.id,
          actionId: action.id,
        },
      }),
    );

  return (
    <ActionStatusModal
      actionId={action.id}
      onClose={onClose}
      isOpen={true}
      onEdit={onEdit}
    />
  );
};

export default () => (
  <ActionProvider>
    <ActionStatusModalPage />
  </ActionProvider>
);
