import { Outlet, Route, Routes } from 'react-router-dom';
import { Suspense } from 'react';

import { usePageTitle } from 'shared/hooks/usePageTitle';
import Layout from 'shared/components/Layout';
import Spinner from 'shared/spinner/Spinner';
import CreateMetricStatusPage from 'metric/CreateMetricStatusPage';

import { metricOverviewRoutesPaths } from './MetricOverview.routing.paths';
import MetricOverviewProvider, {
  useMetricOverview,
} from './MetricOverviewProvider';
import MetricOverviewNavigateToInsights from './MetricOverviewNavigateToInsights/MetricOverviewNavigateToInsights';
import MetricOverviewInsights from './MetricOverviewInsights';
import MetricOverviewAttributes from './MetricOverviewAttributes';
import EditMetricPage from '../EditMetric/EditMetricPage';
import MetricOverviewLayoutHeader from './MetricOverviewLayoutHeader';
import MetricOverviewSider from './MetricOverviewSider';

const MetricOverview = () => {
  const { metric } = useMetricOverview();

  usePageTitle(metric?.name);

  return (
    <Suspense fallback={<Spinner.Circle />}>
      <MetricOverviewLayoutHeader />
      <Layout.Content
        hasPadding={false}
        renderSider={() => <MetricOverviewSider />}
        variant={'contained'}
      >
        <Outlet />
      </Layout.Content>
    </Suspense>
  );
};

const MetricWithRouting = () => (
  <Routes>
    <Route path={metricOverviewRoutesPaths.root()} element={<MetricOverview />}>
      <Route index={true} element={<MetricOverviewNavigateToInsights />} />
      <Route
        path={metricOverviewRoutesPaths.insights.root()}
        element={<MetricOverviewInsights />}
      />
      <Route
        path={metricOverviewRoutesPaths.insights.submit()}
        element={<CreateMetricStatusPage />}
      />
      <Route
        path={metricOverviewRoutesPaths.attributes()}
        element={<MetricOverviewAttributes />}
      />
      <Route
        path={metricOverviewRoutesPaths.edit()}
        element={<EditMetricPage />}
      />
    </Route>
  </Routes>
);

export default () => (
  <MetricOverviewProvider>
    <MetricWithRouting />
  </MetricOverviewProvider>
);
