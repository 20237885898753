import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import type { ListBoxRowComponentProps } from 'shared/components/ListBox';
import Text from 'shared/components/Text';

import type { StatusMultiSelectItem } from '../StatusMultiSelect.type';

type StatusMultiSelectListBoxRowProps =
  ListBoxRowComponentProps<StatusMultiSelectItem>;

const StatusMultiSelectListBoxRow = ({
  item,
}: StatusMultiSelectListBoxRowProps) => {
  const { t } = useTranslation();

  const title = useMemo(() => {
    switch (item.id) {
      case 'ALL': {
        return t('status.allStatus');
      }
      case 'UNKNOWN': {
        return t('status.noStatus');
      }
      default: {
        return item.statusIndicator?.getName(t);
      }
    }
  }, [item, t]);

  return <Text variant={'emphasis'}>{title}</Text>;
};

export default StatusMultiSelectListBoxRow;
