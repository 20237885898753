import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'shared/components/Tooltip';
import { ReactComponent as LinkIconSVG } from 'shared/static/icons/icon-link.svg';

type Props = {
  className?: string;
  isInline?: boolean;
};

const LinkIcon = ({ isInline, className }: Props) => {
  const { t } = useTranslation();

  const sizeStyle = css`
    width: 14px;
    height: 14px;
  `;

  return (
    <Tooltip
      content={t('linkIcon.tooltip')}
      delay={0}
      isInline={isInline}
      css={sizeStyle}
      className={className}
    >
      <LinkIconSVG css={[sizeStyle, isInline && css({ marginTop: -2 })]} />
    </Tooltip>
  );
};

export default LinkIcon;
