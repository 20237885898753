import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import Button from 'shared/components/Button';
import type { SpaceProps } from 'shared/components/Space';
import Space from 'shared/components/Space';

import type { StrategyRoadmapChipProps } from '../StrategyRoadmapChip';
import StrategyRoadmapChip from '../StrategyRoadmapChip';
import styles from './StrategyRoadmapChips.module.scss';

type StrategyRoadmapChipsProps = {
  className?: string;
  direction?: SpaceProps['direction'];
  items: Array<StrategyRoadmapChipProps & { id: string }>;
  maxItemsCount?: number;
  onClickMore: () => void;
};

const StrategyRoadmapChips = ({
  items,
  maxItemsCount = 3,
  direction = 'vertical',
  onClickMore,
  className,
}: StrategyRoadmapChipsProps) => {
  const { t } = useTranslation();

  const slicedItems = maxItemsCount > 0 ? items.slice(0, maxItemsCount) : items;
  const itemsLeft = items.length - maxItemsCount;

  const moreButton = useMemo(() => {
    if (itemsLeft > 0) {
      return (
        <Button variant={'outlined'} color={'mono'} onPress={onClickMore}>
          {t('moreItems', { count: itemsLeft })}
        </Button>
      );
    }
    return null;
  }, [itemsLeft, onClickMore, t]);

  return (
    <>
      <Space
        direction={direction}
        isWrap={direction === 'horizontal'}
        size={'small'}
        className={cn(
          styles.strategyRoadmapChips,
          {
            [styles.strategyRoadmapChipsDirectionHorizontal]:
              direction === 'horizontal',
          },
          className,
        )}
      >
        {slicedItems.map(({ id, className, ...restItem }) => (
          <StrategyRoadmapChip
            {...restItem}
            className={cn(className, styles.strategyRoadmapChipsChip)}
            key={id}
          />
        ))}
      </Space>
      {moreButton}
    </>
  );
};

export default StrategyRoadmapChips;
