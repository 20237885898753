import { useCallback, useMemo, type PropsWithChildren } from 'react';
import { useSessionStorage } from 'react-use';
import type { NavigateFunction } from 'react-router-dom';

import RedirectContext from './Redirect.context';

const redirectPathStorageKey = 'redirectPath';

const RedirectProvider = ({ children }: PropsWithChildren) => {
  const [redirectPath, setRedirectPath] = useSessionStorage<string | null>(
    redirectPathStorageKey,
    null,
  );

  const performRedirect = useCallback(
    (navigate: NavigateFunction) => {
      if (redirectPath) {
        navigate(redirectPath);
        setRedirectPath(null);
      }
    },
    [redirectPath, setRedirectPath],
  );

  const clearRedirectPath = useCallback(() => {
    setRedirectPath(null);
  }, [setRedirectPath]);

  const value = useMemo(
    () => ({
      performRedirect,
      setRedirectPath,
      clearRedirectPath,
    }),
    [clearRedirectPath, performRedirect, setRedirectPath],
  );

  return (
    <RedirectContext.Provider value={value}>
      {children}
    </RedirectContext.Provider>
  );
};

export default RedirectProvider;
