import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMap } from 'react-use';
import type { Entries, SetOptional } from 'type-fest';
import ms from 'ms';

import Heading from 'shared/components/Heading';
import type {
  MilestoneStatusFormValues,
  MilestoneStatusFormSubmitValues,
} from 'initiative/MilestoneStatusForm';
import MilestoneStatusForm from 'initiative/MilestoneStatusForm';
import type { Milestone } from 'types.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import type { FormProps } from 'shared/form/Form';
import Form from 'shared/form/Form';
import Text from 'shared/components/Text';
import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import InfoCard from 'shared/components/InfoCard';
import PreviousInsightsSidecard from 'status/PreviousInsightsSidecard/PreviousInsightsSidecard';

import styles from './InitiativeStatusWizardMilestones.module.scss';
import InitiativeStatusWizardMilestonesDrawer from '../InitiativeStatusWizardMilestonesDrawer';
import InitiativeStatusWizardMilestonesList from '../InitiativeStatusWizardMilestonesList';
import type { InitiativeStatusWizardInitiativeFragment } from '../InitiativeStatusWizard.graphql.generated';

export type InitiativeStatusWizardMilestonesValues = Record<
  Milestone['id'],
  MilestoneStatusFormSubmitValues | null
>;

const createInitialValues = (
  milestones: Milestone[],
): InitiativeStatusWizardMilestonesValues =>
  Object.fromEntries(milestones.map((milestone) => [milestone.id, null]));

type InitiativeStatusWizardMilestonesProps = SetOptional<
  Pick<
    FormProps<InitiativeStatusWizardMilestonesValues>,
    'id' | 'initialValues' | 'onChange' | 'onSubmit' | 'onError'
  >,
  'initialValues'
> & {
  initiative: InitiativeStatusWizardInitiativeFragment;
};

// todo divide InitiativeStatusWizardMilestones into smaller components
const InitiativeStatusWizardMilestones = ({
  initiative,
  initialValues,
  onChange,
  ...restProps
}: InitiativeStatusWizardMilestonesProps) => {
  const { milestones } = initiative;

  const { t } = useTranslation();

  const { addToast } = useToasts();

  const shouldSetNextMilestoneIdAfterSubmit = useRef(false);

  const [
    milestoneStatusesData,
    { set: setMilestoneStatusData, get: getMilestoneStatusData },
  ] = useMap<InitiativeStatusWizardMilestonesValues>(
    initialValues || createInitialValues(milestones as any),
  );
  const [currentMilestoneId, setCurrentMilestoneId] =
    useState<Milestone['id']>();

  useEffect(() => {
    onChange?.(milestoneStatusesData);
  }, [milestoneStatusesData, onChange]);

  const currentMilestone = milestones.find(
    (milestone) => milestone.id === currentMilestoneId,
  );
  const emptyMilestoneStatusesCount = Object.entries(
    milestoneStatusesData,
  ).filter(([_, value]) => !value).length;

  useEffect(() => {
    if (
      shouldSetNextMilestoneIdAfterSubmit.current &&
      emptyMilestoneStatusesCount > 0
    ) {
      setTimeout(() => {
        const nextMilestone = (
          Object.entries(
            milestoneStatusesData,
          ) as Entries<InitiativeStatusWizardMilestonesValues>
        ).find(([_, value]) => !value);
        if (nextMilestone) {
          setCurrentMilestoneId(nextMilestone[0]);
        }
      }, ms('0.5 seconds'));
    }
    if (!shouldSetNextMilestoneIdAfterSubmit.current) {
      shouldSetNextMilestoneIdAfterSubmit.current = true;
    }
  }, [milestoneStatusesData, emptyMilestoneStatusesCount]);

  const milestoneStatusFormInitialValues = useMemo<
    MilestoneStatusFormValues | undefined
  >(() => {
    if (currentMilestoneId) {
      const milestoneStatusData = getMilestoneStatusData(currentMilestoneId);
      const currentMilestoneStatus = currentMilestone?.currentMilestoneStatus;
      return {
        statusIndicator:
          milestoneStatusData?.statusIndicator ||
          currentMilestoneStatus?.statusIndicator?.value ||
          null,
        completionRate:
          milestoneStatusData?.completionRate ||
          currentMilestoneStatus?.completionRate ||
          0,
        comment: milestoneStatusData?.comment || '',
        complete: milestoneStatusData?.complete || false,
      };
    }
    return undefined;
  }, [currentMilestone, currentMilestoneId, getMilestoneStatusData]);

  const renderMilestoneStatusForm = () => (
    <MilestoneStatusForm
      initialValues={milestoneStatusFormInitialValues}
      onSuccess={(values) => {
        addToast({
          variant: 'success',
          children: t(
            `initiative.initiativeStatusWizard.steps.milestones.${
              emptyMilestoneStatusesCount === 1
                ? 'lastMilestoneStatusAddedToast'
                : 'milestoneStatusAddedToast'
            }`,
            {
              milestoneName: currentMilestone?.name,
            },
          ),
        });
        setMilestoneStatusData(currentMilestone!.id, values);
        setCurrentMilestoneId(undefined);
      }}
      className={styles.milestoneStatusDrawerForm}
    />
  );

  const renderAboveForm = () => (
    <>
      <Heading level={3}>
        {t('initiative.initiativeStatusWizard.steps.milestones.heading')}
      </Heading>
      <Text>
        <Trans
          i18nKey={
            'initiative.initiativeStatusWizard.steps.keyIndicators.description'
          }
          values={{
            initiativeName: initiative.name,
          }}
        >
          <Text variant={'emphasis'} />
        </Trans>
      </Text>
    </>
  );

  const renderForm = () => (
    <>
      {renderAboveForm()}
      <Form<InitiativeStatusWizardMilestonesValues>
        initialValues={milestoneStatusesData}
        {...restProps}
        className={styles.form}
      >
        <InitiativeStatusWizardMilestonesList
          milestones={milestones}
          getMilestoneStatusData={getMilestoneStatusData}
          setCurrentMilestoneId={setCurrentMilestoneId}
        />
      </Form>
    </>
  );

  const renderInfoCard = () => (
    <InfoCard
      title={t(
        'initiative.initiativeStatusWizard.steps.milestones.infoCard.heading',
      )}
      contentKey={
        'initiative.initiativeStatusWizard.steps.milestones.infoCard.content'
      }
    />
  );

  return (
    <>
      <FormWithInfoCardLayout
        renderForm={renderForm}
        renderInfoCard={renderInfoCard}
        renderAfterInfoCard={<PreviousInsightsSidecard />}
      />
      <InitiativeStatusWizardMilestonesDrawer
        currentMilestone={currentMilestone}
        milestones={milestones}
        renderMilestoneStatusForm={renderMilestoneStatusForm}
        setCurrentMilestoneId={setCurrentMilestoneId}
      />
    </>
  );
};

export default InitiativeStatusWizardMilestones;
