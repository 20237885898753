import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useStatusMultiSelect } from 'shared/status/StatusMultiSelect';
import { useThemeMultiSelect } from 'themes/ThemeMultiSelect';
import type { ChipGroupProps } from 'shared/components/ChipGroup';
import ChipGroup from 'shared/components/ChipGroup';
import { useUserMultiSelect } from 'user/UserMultiSelect';
import { date } from 'shared/services/date.service';
import { usePriorityMultiSelect } from 'shared/priority/PriorityMultiSelect';

import { useStrategyObjectives } from '../StrategyObjectivesProvider';
import type { StrategyObjectivesFiltersChipGroupItem } from './StrategyObjectivesFiltersChipGroup.type';
import useChipGroupItems from './useChipGroupItems';

const StrategyObjectivesFiltersChipGroup = () => {
  const { t } = useTranslation();

  const {
    filters: { clearFilters, setStartDateFilter, setEndDateFilter },
  } = useStrategyObjectives();

  const { state: statusMultiSelectState } = useStatusMultiSelect();
  const { state: themeMultiSelectState } = useThemeMultiSelect();
  const { state: userMultiSelectState } = useUserMultiSelect();
  const { state: priorityMultiSelectState } = usePriorityMultiSelect();

  const renderLabel = useCallback<
    NonNullable<
      ChipGroupProps<StrategyObjectivesFiltersChipGroupItem>['renderLabel']
    >
  >(
    (item) => {
      if (item.theme) {
        return item.theme.name;
      }
      if (item.status) {
        return item.status.statusIndicator?.getName(t);
      }
      if (item.user) {
        return item.user.displayName || item.user.email;
      }
      if (item.startDate) {
        return `${t('from')}: ${date.formatShort(item.startDate)}`;
      }
      if (item.endDate) {
        return `${t('to')}: ${date.formatShort(item.endDate)}`;
      }
      if (item.priority) {
        return item.priority.label;
      }
    },
    [t],
  );

  const onRemove = useCallback(
    (item: StrategyObjectivesFiltersChipGroupItem) => {
      if (item.theme) {
        themeMultiSelectState.selectionManager.toggleSelection(item.theme.id);
      } else if (item.status) {
        statusMultiSelectState.selectionManager.toggleSelection(item.status.id);
      } else if (item.user) {
        userMultiSelectState.selectionManager.toggleSelection(item.user.id);
      } else if (item.startDate) {
        setStartDateFilter(null);
      } else if (item.endDate) {
        setEndDateFilter(null);
      } else if (item.priority) {
        priorityMultiSelectState.selectionManager.toggleSelection(
          item.priority.id,
        );
      }
    },
    [
      themeMultiSelectState.selectionManager,
      statusMultiSelectState.selectionManager,
      userMultiSelectState.selectionManager,
      setStartDateFilter,
      setEndDateFilter,
      priorityMultiSelectState.selectionManager,
    ],
  );

  const chipGroupItems = useChipGroupItems();

  if (chipGroupItems.length === 0) return null;

  const isRemoveAllEnabled = chipGroupItems.length > 1;

  return (
    <ChipGroup<StrategyObjectivesFiltersChipGroupItem>
      items={chipGroupItems}
      onRemove={onRemove}
      onRemoveAll={isRemoveAllEnabled ? clearFilters : undefined}
      renderLabel={renderLabel}
    />
  );
};

export default StrategyObjectivesFiltersChipGroup;
