import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useState, type FocusEventHandler } from 'react';
import { TextArea as AriaTextArea } from 'react-aria-components';

import { transientOptions } from 'shared/utils/emotion.utils';

const Container = styled('div', transientOptions)<{
  $hasError: boolean;
  $isFocused: boolean;
}>`
  border: 1px solid
    ${(props) =>
      props.$isFocused
        ? props.theme.color.black
        : props.$hasError
        ? props.theme.color.error
        : props.theme.color.strokeDark};
  border-radius: 12px;
  overflow: hidden;
  box-shadow: ${(props) =>
    props.$isFocused ? `0 0 0 2px ${props.theme.color.primary}` : 'none'};

  &[aria-invalid='true'] {
    background-color: ${(props) => props.theme.color.errorLight};
  }
`;

const StyledTextArea = styled(AriaTextArea)`
  border: none;
  width: 100%;
  padding: 0.75rem;
  resize: none;
  margin-bottom: -6px;

  &:focus-visible {
    outline: none;
  }
`;

type Props = {
  fullWidth?: boolean;
  hasError?: boolean;
  id?: string;
  isDisabled?: boolean;
  maxLength?: number;
  name?: string;
  onBlur?: FocusEventHandler;
  onChange: (value: string) => void;
  placeholder?: string;
  value?: string;
};

const TextArea = ({
  id,
  name,
  value,
  placeholder,
  maxLength,
  fullWidth,
  hasError = false,
  isDisabled,
  onChange,
  onBlur,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Container
      $isFocused={isFocused}
      $hasError={hasError}
      css={fullWidth && css({ width: '100%' })}
    >
      <StyledTextArea
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        aria-invalid={!!hasError}
        disabled={isDisabled}
        onChange={(event) => onChange(event.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={(event) => {
          setIsFocused(false);
          onBlur?.(event);
        }}
      />
    </Container>
  );
};

export default TextArea;
