import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ButtonVariant } from 'shared/components/Button';
import Button from 'shared/components/Button';

import UpdateTaskDialog from './UpdateTaskDialog';
import type { OneThingTaskForUpdate } from './OneThingCreateTaskButton.types';

type Props = {
  isReadOnly: boolean;
  task: OneThingTaskForUpdate;
  variant: ButtonVariant;
};

const OneThingUpdateTaskButton = ({ task, isReadOnly, variant }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        variant={isReadOnly ? 'outlined' : variant}
        color={isReadOnly ? 'mono' : 'primary'}
        onPress={() => setIsOpen(true)}
      >
        {isReadOnly
          ? t('toolkit.toolPage.oneThing.updateTask.button.view')
          : t('toolkit.toolPage.oneThing.updateTask.button.update')}
      </Button>

      <UpdateTaskDialog
        task={task}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        isReadOnly={isReadOnly}
      />
    </>
  );
};

export default OneThingUpdateTaskButton;
