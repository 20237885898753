import type { PropsWithChildren } from 'react';
import { createContext } from 'react';

import { useDefinedContext } from 'shared/utils/context.utils';

type Value = {
  actionIds: string[];
  initiativeIds: string[];
  metricIds: string[];
  objectiveIds: string[];
  strategyId?: string;
};

const AskSophieContext = createContext<Value | undefined>(undefined);

export const useAskSophie = () =>
  useDefinedContext(AskSophieContext, {
    hookName: useAskSophie.name,
    providerName: AskSophieContext.Provider.name,
  });

type Props = PropsWithChildren<{
  actionIds: string[];
  initiativeIds: string[];
  metricIds: string[];
  objectiveIds: string[];
  strategyId?: string;
}>;

const AskSophieProvider = ({
  objectiveIds,
  metricIds,
  initiativeIds,
  actionIds,
  strategyId,
  children,
}: Props) => (
  <AskSophieContext.Provider
    value={{ objectiveIds, metricIds, initiativeIds, actionIds, strategyId }}
  >
    {children}
  </AskSophieContext.Provider>
);

export default AskSophieProvider;
