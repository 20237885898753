import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  Scalars,
  StatusComment,
  StatusCommentType,
} from 'types.graphql.generated';
import type { ListProps } from 'shared/components/List';
import List from 'shared/components/List';
import type { HeadingProps } from 'shared/components/Heading';
import Heading from 'shared/components/Heading';

import { getHeadings, hasComments } from './StatusComments.utils';
import styles from './StatusComments.module.scss';

type StatusCommentsItem = Pick<StatusComment, 'id' | 'text' | 'type'>;

export type StatusCommentsProps = {
  className?: string;
  comment?: Maybe<Scalars['String']>;
  headingProps?: Partial<Omit<HeadingProps, 'children'>>;
  headings?: Partial<Record<StatusCommentType, string>>;
  items: StatusCommentsItem[];
  listProps?: Partial<Omit<ListProps, 'children'>>;
  types?: StatusCommentType[];
};

const StatusComments = ({
  items,
  headings: headingsProp,
  types = ['SUCCESS_COMMENT', 'CHALLENGE_COMMENT', 'ACTION_COMMENT'],
  listProps = {},
  headingProps = {},
  className,
  comment,
}: StatusCommentsProps) => {
  const { t } = useTranslation();
  const headings = getHeadings(t, headingsProp);

  const renderItemsOfType = (type: StatusCommentType) => {
    const listItems = items
      .filter((item) => Boolean(item.text))
      .filter((item) => item.type === type);
    if (listItems.length && hasComments(listItems)) {
      return (
        <section>
          <Heading
            level={4}
            as={5}
            {...headingProps}
            className={styles.heading}
          >
            {headings[type]}
          </Heading>
          <List isOrdered={false} {...listProps}>
            {listItems.map((item) => (
              <List.Item key={item.id}>{item.text}</List.Item>
            ))}
          </List>
        </section>
      );
    }
    return null;
  };

  return (
    <div className={className}>
      {comment && (
        <section>
          <p className={styles.comment}>{comment}</p>
        </section>
      )}
      {types?.map((type) => (
        <Fragment key={type}>{renderItemsOfType(type)}</Fragment>
      ))}
    </div>
  );
};

const MemoizedStatusComments = memo(StatusComments);

export default MemoizedStatusComments;
