import { css } from '@emotion/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import Flex from 'shared/components/Flex';
import Tabs from 'shared/components/Tabs';

import { usePreviousInsightsData } from './usePreviousInsightsData';
import Section from './Section';
import { usePreviousInsights } from './usePreviousInsights';

type InsightsSection = 'previous' | 'related' | 'children';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow-y: auto;
`;

const SideModalContent = () => {
  const { t } = useTranslation();

  const [section, setSection] = useState<InsightsSection>('previous');

  const { metricId, initiativeId } = usePreviousInsights();

  const context = metricId
    ? 'metric'
    : initiativeId
    ? 'initiative'
    : 'objective';

  const {
    previousInsights,
    relatedInsights,
    relatedInsightsOlder,
    childrenInsights,
    childrenInsightsOlder,
  } = usePreviousInsightsData();

  return (
    <Flex direction={'column'} css={css({ height: '100%' })}>
      <div css={css({ margin: '0 20px' })}>
        {t(`previousInsights.${context}.description`)}
      </div>

      <Tabs
        tabIds={['previous', 'related', 'children']}
        activeTabId={section}
        css={css({ padding: '0 1.5rem', margin: 0 })}
      >
        <Tabs.Item
          onClick={() => setSection('previous')}
          isActive={section === 'previous'}
        >
          {t('previousInsights.tab.previous')}
        </Tabs.Item>
        <Tabs.Item
          onClick={() => setSection('related')}
          isActive={section === 'related'}
        >
          {t('previousInsights.tab.related')}
        </Tabs.Item>
        <Tabs.Item
          onClick={() => setSection('children')}
          isActive={section === 'children'}
        >
          {t('previousInsights.tab.children')}
        </Tabs.Item>
      </Tabs>

      <ContentContainer>
        <div css={css({ margin: '20px 20px 0 20px' })}>
          {t(`previousInsights.${context}.tab.${section}`)}
        </div>

        <Content>
          {section === 'previous' && (
            <Section elements={previousInsights} showOrgUnit={false} />
          )}

          {section === 'related' && (
            <Section
              elements={relatedInsights}
              olderElements={relatedInsightsOlder}
              showOrgUnit={false}
            />
          )}

          {section === 'children' && (
            <Section
              elements={childrenInsights}
              olderElements={childrenInsightsOlder}
              showOrgUnit={true}
            />
          )}

          <div>&nbsp;</div>
        </Content>
      </ContentContainer>
    </Flex>
  );
};

export default SideModalContent;
