import { useEffect } from 'react';

import { useErrorPage } from './useErrorPage';

type Props = {
  error: Error;
};

const TriggerErrorPage = ({ error }: Props) => {
  const { onError } = useErrorPage();

  useEffect(() => {
    onError(error);
  }, [error, onError]);

  return null;
};

export default TriggerErrorPage;
