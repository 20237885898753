import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { startOfDay } from 'date-fns';

import Dialog from 'shared/components/Dialog';
import { useUser } from 'user/UserProvider';
import type { Objective, ObjectiveInput, Theme } from 'types.graphql.generated';
import { ObjectiveDocument } from 'objective/ObjectiveProvider';
import { resolveInitialValue as resolveSelectObjectiveInputInitialValue } from 'objective/SelectObjectiveInput';
import StrategyProfileAttributesProvider, {
  useStrategyProfileAttributes,
} from 'strategy/StrategyProfileAttributesProvider';
import Spinner from 'shared/spinner/Spinner';
import TextButton from 'shared/components/TextButton';
import ResetFormOnChange from 'shared/form/ResetFormOnChange';
import type { MetricFormValues } from 'metric/EditMetric/MetricForm';
import {
  StrategyMetricsOrgDocument,
  StrategyMetricsOrgUnitDocument,
} from 'metric/StrategyMetrics/StrategyMetricsProvider/StrategyMetricsProvider.graphql.generated';
import MetricForm from 'metric/EditMetric/MetricForm';
import MetricFormModal from 'metric/EditMetric/MetricFormModal/MetricFormModal';
import useHandleError from 'shared/errors/useHandleError';
import useDefaultEndDate from 'shared/hooks/useDefaultEndDate';
import { useToasts } from 'shared/toast/useToasts';
import StrategyProvider from 'strategy/StrategyProvider';

import { useAddMetricMutation } from './AddMetric.graphql.generated';
import MetricQuickFormFields from './MetricQuickFormFields';
import type { MetricCreated } from './MetricCreatedDialog';
import MetricCreatedDialog from './MetricCreatedDialog';

export type Props = {
  isOpen: boolean;
  objective?: Pick<Objective, 'id' | 'name' | 'timeLine'>;
  onClose: () => void;
  showSuccessDialog?: boolean;
  theme?: Pick<Theme, 'id' | 'name'>;
};

const CreateMetric = ({
  isOpen,
  onClose,
  objective,
  showSuccessDialog,
}: Props) => {
  const { t } = useTranslation();

  const { user } = useUser();

  const { addToast } = useToasts();

  const handleApolloError = useHandleError();

  const [addMetric, { loading }] = useAddMetricMutation({
    onError: handleApolloError,
    refetchQueries: [
      ObjectiveDocument,
      StrategyMetricsOrgDocument,
      StrategyMetricsOrgUnitDocument,
    ],
  });

  const [isMetricCreatedDialogOpen, setIsMetricCreatedDialogOpen] =
    useState(false);
  const [createdMetric, setCreatedMetric] = useState<
    MetricCreated | undefined
  >();

  const [isMaximized, setIsMaximized] = useState(false);

  const { attributes, isAttributesLoading } = useStrategyProfileAttributes();

  const defaultEndDate = useDefaultEndDate(objective);

  const initialValues: MetricFormValues = useMemo(
    () => ({
      id: null,
      name: '',
      description: '',
      startValue: '',
      targetValue: '',
      unitOfMeasure: '',
      owner: user.email,
      objective: resolveSelectObjectiveInputInitialValue(objective),
      priority: null,
      timeLine: {
        startDate: startOfDay(new Date()),
        endDate: defaultEndDate,
      },
      attributes:
        attributes?.map((attribute) => ({
          name: attribute.name || '',
          title: attribute.title || '',
          description: '',
        })) || [],
    }),
    [attributes, defaultEndDate, objective, user],
  );

  const handleClose = () => {
    onClose();
    setIsMaximized(false);
  };

  const handleSubmit = async (input: ObjectiveInput) => {
    const result = await addMetric({
      variables: { input },
    });

    if (result.data?.addMetric && !result.errors) {
      handleClose();
      setCreatedMetric(result.data.addMetric);

      if (showSuccessDialog) {
        setIsMetricCreatedDialogOpen(true);
      } else {
        addToast({
          variant: 'success',
          children: t('metric.createMetric.success.heading'),
        });
      }
    }
  };

  if (isAttributesLoading) return <Spinner.Circle />;

  const formId = 'metric_new';

  return (
    <>
      <MetricForm
        formId={formId}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <ResetFormOnChange value={isOpen} />

        <Dialog
          isOpen={isOpen && !isMaximized}
          onClose={handleClose}
          onMaximize={() => setIsMaximized(true)}
          headerTitle={t('metric.createMetric.heading')}
          confirmLabel={t('create')}
          cancelLabel={t('cancel')}
          formId={formId}
          isConfirmDisabled={loading}
        >
          <MetricQuickFormFields
            showObjectiveField={!initialValues.objective.value}
          />
          <TextButton onPress={() => setIsMaximized(true)}>
            {t('metric.metricForm.addMoreDetails')}
          </TextButton>
        </Dialog>

        <MetricFormModal
          formId={formId}
          headerTitle={t('metric.createMetric.heading')}
          confirmLabel={t('create')}
          isOpen={isOpen && isMaximized}
          onClose={handleClose}
          animation={'zoom'}
          isConfirmDisabled={loading}
        />
      </MetricForm>

      <MetricCreatedDialog
        isOpen={isMetricCreatedDialogOpen}
        onClose={() => setIsMetricCreatedDialogOpen(false)}
        metric={createdMetric}
      />
    </>
  );
};

export default (props: Props) => (
  <StrategyProvider>
    <StrategyProfileAttributesProvider types={['METRIC']}>
      <CreateMetric {...props} />
    </StrategyProfileAttributesProvider>
  </StrategyProvider>
);
