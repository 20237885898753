import { Navigate } from 'react-router-dom';

import { strategyRoutesPaths } from 'strategy/strategy.routing.paths';
import { useTeamSlug } from 'team/TeamAdapter/useTeamSlug';

const NavigateToTeamStrategyRoot = () => {
  const { teamSlug } = useTeamSlug();

  const to = strategyRoutesPaths.root({ params: { teamSlug } });

  return <Navigate to={to} replace={true} />;
};

export default NavigateToTeamStrategyRoot;
