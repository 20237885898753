import type { PropsWithChildren } from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { User } from 'firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';
import { datadogRum } from '@datadog/browser-rum';

import type { AuthProviderContextValue } from './AuthProvider.context';
import { AuthProviderContext } from './AuthProvider.context';
import { auth } from './auth';

const AuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (nextUser) => {
        setUser(nextUser);

        if (user && !nextUser) {
          sessionStorage.clear();
          localStorage.clear();
        }

        setIsLoading(false);
      },
      setError,
    );

    return unsubscribe;
  }, [user]);

  useEffect(() => {
    if (user) {
      datadogRum.setUser({
        id: user.uid,
        name: user.displayName || user.email || undefined,
        email: user.email || undefined,
      });
    } else {
      datadogRum.clearUser();
    }
  }, [user]);

  const authContextValue = useMemo<AuthProviderContextValue>(
    () => ({ user, isLoading, error }),
    [error, isLoading, user],
  );

  return (
    <AuthProviderContext.Provider value={authContextValue}>
      {children}
    </AuthProviderContext.Provider>
  );
};

export default AuthProvider;
