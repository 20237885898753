import type { TFunction } from 'react-i18next';

import { contributionRoutesPaths } from 'contribution/contribution.routing.paths';
import type { TeamAdapter } from 'team/TeamAdapter';

export const getBreadcrumbItems = (t: TFunction, teamAdapter: TeamAdapter) => [
  {
    name: t('contribution.myContribution.heading'),
    to: contributionRoutesPaths.root({
      params: {
        teamSlug: teamAdapter.toParam(),
      },
    }),
  },
  {
    name: t('followUps.followUps'),
  },
];
