import { useTranslation } from 'react-i18next';

import type { ModalProps } from 'shared/components/__DEPRECATED__/Modal';
import Modal from 'shared/components/__DEPRECATED__/Modal';

export type ConfirmationModalProps = Omit<ModalProps, 'renderFooterContent'> & {
  onClose: () => void;
  onConfirm?: () => void;
  onDeny?: () => void;
};

/**
 * @param {() => void} [props.onConfirm] function executed upon clicking "Yes" button
 * @param {() => void} [props.onDeny] function executed upon clicking "No" button
 * @param {() => void} props.onClose function executed upon clicking either of the buttons or clicking outside of the component
 */
const ConfirmationModal = ({
  children: childrenProp,
  onConfirm,
  onDeny,
  onClose,
  ...restProps
}: ConfirmationModalProps) => {
  const { t } = useTranslation();

  const renderFooterContent = () => (
    <Modal.Controls
      buttons={[
        {
          children: t('no'),
          variant: 'outlined',
          onPress: async () => {
            onDeny?.();
            onClose();
          },
        },
        {
          children: t('yes'),
          variant: 'contained',
          onPress: async () => {
            onConfirm?.();
            onClose();
          },
        },
      ]}
    />
  );

  const children = childrenProp || t('areYouSure');

  return (
    <Modal
      hasHeader={false}
      size={'tiny'}
      onClose={onClose}
      {...restProps}
      renderFooterContent={renderFooterContent}
    >
      {children}
    </Modal>
  );
};

export default ConfirmationModal;
