import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import Flex from 'shared/components/Flex';
import Tag from 'shared/components/Tag';
import TeamAvatar from 'team/TeamAvatar/TeamAvatar';

import type { TeamMembersMemberhipFragment } from '../TeamMembersProvider/TeamMembersProvider.graphql.generated';

type Props = {
  membership: TeamMembersMemberhipFragment;
};

const MembershipRow = ({ membership }: Props) => {
  const { t } = useTranslation();

  const roles = [...membership.roles]
    .map((role) => t(`orgUnitRole.${role}`))
    .sort();

  return (
    <Flex
      key={membership.orgUnit.id}
      justifyContent={'flex-end'}
      gap={8}
      css={css({ height: 35 })}
    >
      <Flex gap={8}>
        {roles.map((roleLabel) => (
          <Tag key={roleLabel} size={'tiny'}>
            {roleLabel}
          </Tag>
        ))}
      </Flex>
      <TeamAvatar orgUnit={membership.orgUnit} size={'tiny'} />
    </Flex>
  );
};

export default MembershipRow;
