import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { css } from '@emotion/react';

import Text from 'shared/components/Text';
import Spinner from 'shared/spinner/Spinner';
import Button from 'shared/components/Button';
import { ReactComponent as ChevronDownIcon } from 'shared/static/icons/icon-chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'shared/static/icons/icon-chevron-up.svg';
import Flex from 'shared/components/Flex';

import Card from './Card';
import type { PreviousInsightsCardData } from './PreviousInsightsSidecard.type';
import { sortStatusListByStatusDate } from './PreviousInsightsSidecard.utils';

type Props = {
  elements?: PreviousInsightsCardData[];
  olderElements?: PreviousInsightsCardData[];
  showOrgUnit: boolean;
};

const Section = ({ elements, olderElements, showOrgUnit }: Props) => {
  const { t } = useTranslation();
  const [showOlder, setShowOlder] = useState(false);

  if (!elements) return <Spinner.Circle />;

  const sortedElements = sortStatusListByStatusDate(elements);
  const sortedOlderElements = sortStatusListByStatusDate(olderElements);

  return (
    <Flex direction={'column'} gap={'1rem'}>
      {sortedElements?.map((element) => (
        <Card
          key={element.status.id}
          cardData={element}
          showOrgUnit={showOrgUnit}
        />
      ))}

      {sortedOlderElements && sortedOlderElements.length > 0 && (
        <Flex justifyContent={'center'}>
          <Button
            variant={'outlined'}
            color={'primary'}
            onPress={() => setShowOlder(!showOlder)}
            icon={showOlder ? ChevronUpIcon : ChevronDownIcon}
            iconPosition={'end'}
            css={css`
              svg {
                width: 12px;
              }
            `}
          >
            {showOlder
              ? t('sidesheets.context.status.hideOlder')
              : t('sidesheets.context.status.showOlder')}
          </Button>
        </Flex>
      )}

      {showOlder &&
        sortedOlderElements?.map((element) => (
          <Card
            key={element.status.id}
            cardData={element}
            showOrgUnit={showOrgUnit}
          />
        ))}

      {elements.length === 0 && <Text>{t('sidesheets.noComments')}</Text>}
    </Flex>
  );
};

export default Section;
