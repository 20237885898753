import { useDefinedContext } from 'shared/utils/context.utils';

import { ActingOrgKeyContext } from './ActingOrgKey.context';
import ActingOrgKeyProvider from './ActingOrgKeyProvider';

export const useActingOrgKey = () =>
  useDefinedContext(ActingOrgKeyContext, {
    hookName: useActingOrgKey.name,
    providerName: ActingOrgKeyProvider.name,
  });
