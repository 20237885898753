import { useNavigate, useParams } from 'react-router-dom';
import { useCallback } from 'react';

import EditMetric from 'metric/EditMetric';

const EditMetricPage = () => {
  const { metricId } = useParams() as { metricId: Maybe<string> };
  const navigate = useNavigate();

  const handleClose = useCallback(() => navigate('../'), [navigate]);

  if (!metricId) return null;

  return <EditMetric metricId={metricId} isOpen={true} onClose={handleClose} />;
};

export default EditMetricPage;
