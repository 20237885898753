import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserLimitedProfileFragmentDoc } from '../../../user/userProfile.graphql.generated';
import { OrgUnitForTeamImageFragmentDoc } from '../../../orgUnit/OrgUnit.graphql.generated';
export type MetricsListObjectiveFragment = { __typename: 'Objective', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, isCurrentUserOwner: boolean, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, complete: boolean } | undefined, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined, completeDateTime?: any | undefined }, metrics: Array<{ __typename: 'Metric', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, isCurrentUserOwner: boolean, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, currentMetricStatus?: { __typename: 'MetricStatus', id: string, complete: boolean, statusIndicator: StatusIndicatorClass } | undefined, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined, completeDateTime?: any | undefined }, objective?: { __typename: 'Objective', id: string, name?: string | undefined, orgUnit?: { __typename: 'OrgUnit', currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined } | undefined } | undefined }> };

export type MetricsListObjectiveMetricFragment = { __typename: 'Metric', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, isCurrentUserOwner: boolean, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, currentMetricStatus?: { __typename: 'MetricStatus', id: string, complete: boolean, statusIndicator: StatusIndicatorClass } | undefined, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined, completeDateTime?: any | undefined }, objective?: { __typename: 'Objective', id: string, name?: string | undefined, orgUnit?: { __typename: 'OrgUnit', currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined } | undefined } | undefined };

export const MetricsListObjectiveMetricFragmentDoc = gql`
    fragment metricsListObjectiveMetric on Metric {
  id
  name
  currentUserAuthorizedActions
  isCurrentUserOwner
  owner {
    ...userLimitedProfile
  }
  currentMetricStatus {
    id
    complete
    statusIndicator
  }
  timeLine {
    startDate
    endDate
    completeDateTime
  }
  objective {
    id
    name
    orgUnit {
      ...orgUnitForTeamImage
      currentUserAuthorizedActions
    }
  }
}
    ${UserLimitedProfileFragmentDoc}
${OrgUnitForTeamImageFragmentDoc}`;
export const MetricsListObjectiveFragmentDoc = gql`
    fragment metricsListObjective on Objective {
  id
  name
  currentUserAuthorizedActions
  isCurrentUserOwner
  orgUnit {
    id
  }
  currentObjectiveStatus {
    id
    complete
  }
  timeLine {
    startDate
    endDate
    completeDateTime
  }
  metrics {
    ...metricsListObjectiveMetric
  }
}
    ${MetricsListObjectiveMetricFragmentDoc}`;