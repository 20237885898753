import { useCallback, useMemo } from 'react';
import cn from 'classnames';

import { determineIfIsOverdue } from 'actions/action.utils';
import ActionChipOverdue from 'actions/ActionChipOverdue';
import { ReactComponent as DateIcon } from 'shared/static/icons/icon-date.svg';
import BaseModal from 'shared/components/__DEPRECATED__/BaseModal';
import Heading from 'shared/components/Heading';
import IconButton from 'shared/components/IconButton';
import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';
import ObjectiveLink from 'objective/ObjectiveLink';
import UserAvatar from 'user/UserAvatar';
import { date } from 'shared/services/date.service';
import CompletionRate from 'shared/components/CompletionRate';
import useHandleError from 'shared/errors/useHandleError';
import useDialogState from 'shared/hooks/useDialogState';
import EditAction from 'actions/EditAction';
import Spinner from 'shared/spinner/Spinner';
import SendStatusRequestsDialog from 'status/SendStatusRequestsDialog';
import Flex from 'shared/components/Flex';
import TeamAvatar from 'team/TeamAvatar/TeamAvatar';

import ActionStatusForm from './ActionStatusForm';
import styles from './ActionStatusModal.module.scss';
import { useActionStatusModalQuery } from './ActionStatusModal.graphql.generated';

type Props = {
  actionId: string;
  isOpen: boolean;
  onClose: () => void;
  onEdit?: () => void;
};

const ActionStatusModal = ({ actionId, isOpen, onClose, onEdit }: Props) => {
  const onError = useHandleError();

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDialogState();

  const {
    isOpen: isSendStatusRequestOpen,
    onOpen: onSendStatusRequestOpen,
    onClose: onSendStatusRequestClose,
  } = useDialogState();

  const { data } = useActionStatusModalQuery({
    variables: { actionId },
    onError,
    skip: !isOpen,
  });

  const action = data?.action;

  const isActionOverdue = useMemo(() => determineIfIsOverdue(action), [action]);

  const handleEdit = useCallback(() => {
    if (onEdit) {
      onEdit();
    } else {
      onClose();
      onEditOpen();
    }
  }, [onClose, onEdit, onEditOpen]);

  if (isEditOpen)
    return (
      <EditAction
        actionId={actionId}
        isOpen={isEditOpen}
        onClose={onEditClose}
      />
    );

  if (isSendStatusRequestOpen)
    return (
      <SendStatusRequestsDialog
        isOpen={isSendStatusRequestOpen}
        onClose={onSendStatusRequestClose}
        strategyElements={[action].filter(Boolean)}
      />
    );

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modal}
      containerClassName={styles.container}
    >
      {action ? (
        <>
          <header className={styles.header}>
            <Heading level={3} className={styles.heading}>
              {action.name}
            </Heading>
            <p className={styles.description}>{action.description}</p>
            {action.objective && (
              <ObjectiveLink objective={action.objective} isLink={false} />
            )}
            <div className={styles.metadata}>
              <div className={styles.timeline}>
                <DateIcon className={styles.timelineIcon} />
                <div
                  className={cn(styles.timelineText, {
                    [styles.overdue]: isActionOverdue,
                  })}
                >
                  {date.format(action.timeLine.endDate, 'dd MMM')}
                </div>
                {isActionOverdue && <ActionChipOverdue />}
                <CompletionRate
                  complete={action.currentStatus?.complete}
                  completionRate={action.currentStatus?.completionRate}
                />
              </div>
              <Flex gap={8}>
                <TeamAvatar orgUnit={action.orgUnit} size={'small'} />
                <UserAvatar
                  user={action.owner}
                  hasLabel={true}
                  containerClassName={styles.user}
                  showTooltip={false}
                />
              </Flex>
            </div>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
              icon={CloseIcon}
              color={'black'}
              className={styles.icon}
            />
          </header>

          <ActionStatusForm
            action={action}
            onClose={onClose}
            onEditOpen={handleEdit}
            onSendStatusRequest={onSendStatusRequestOpen}
          />
        </>
      ) : (
        <Spinner.Circle />
      )}
    </BaseModal>
  );
};

export default ActionStatusModal;
