import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { uniqBy } from 'lodash';

import Spinner from 'shared/spinner/Spinner';
import {
  getObjectivesThemes,
  groupObjectivesByTheme,
  sortObjectivesByTheme,
} from 'objective/objective.utils';
import { useStatusFilter } from 'shared/status/useStatusFilter';
import { useTeamAdapter } from 'team/TeamAdapter';
import { useThemeFilter } from 'themes/useThemeFilter';
import { useUserFilter } from 'user/useUserFilter';
import { useDateFilter } from 'shared/hooks/useDateFilter';
import { usePriorityFilter } from 'shared/priority/usePriorityFilter';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import useHandleError from 'shared/errors/useHandleError';
import { useCalendarControls } from 'shared/components/CalendarControls/useCalendarControls';
import { useCompletedFilter } from 'shared/components/CompletedFilterButton/useCompletedFilter';
import type { StatusMultiSelectItemId } from 'shared/status/StatusMultiSelect';

import type { StrategyObjectivesProviderContextValue } from './StrategyObjectivesProvider.context';
import { StrategyObjectivesProviderContext } from './StrategyObjectivesProvider.context';
import type { StrategyObjectivesThemeFragment } from './StrategyObjectivesProvider.graphql.generated';
import {
  useStrategyObjectivesOrgQuery,
  useStrategyObjectivesOrgUnitQuery,
} from './StrategyObjectivesProvider.graphql.generated';
import {
  filterObjectivesByStatusIndicator,
  filterObjectivesByUser,
  filterObjectivesByDates,
  getObjectivesOwners,
  filterObjectivesByPriority,
  filterObjectivesByTheme,
} from './StrategyObjectivesProvider.utils';
import type {
  StrategyObjectivesByTheme,
  StrategyObjectivesObjective,
} from '../StrategyObjectives.type';

type StrategyObjectivesProviderProps = PropsWithChildren<object>;

const StrategyObjectivesProvider = ({
  children,
}: StrategyObjectivesProviderProps) => {
  const [, setSearchParams] = useSearchParams();
  const { priorities, isPrioritiesLoading } = useStrategyProfilePriorities();

  const { teamAdapter } = useTeamAdapter();

  const handleApolloError = useHandleError();

  const { data: orgData } = useStrategyObjectivesOrgQuery({
    fetchPolicy: 'cache-and-network',
    skip: teamAdapter.isOrgUnit,
    onError: handleApolloError,
  });

  const { data: orgUnitData } = useStrategyObjectivesOrgUnitQuery({
    variables: { orgUnitId: teamAdapter.keyArg },
    fetchPolicy: 'cache-and-network',
    skip: teamAdapter.isOrg,
    onError: handleApolloError,
  });

  const { statusFilter, setStatusFilter } = useStatusFilter();

  const { themeFilter, setThemeFilter } = useThemeFilter();

  const { userFilter, setUserFilter } = useUserFilter();

  const { dateFilter: startDateFilter, setDateFilter: setStartDateFilter } =
    useDateFilter({ paramName: 'startDate' });

  const { dateFilter: endDateFilter, setDateFilter: setEndDateFilter } =
    useDateFilter({ paramName: 'endDate' });

  const { priorityFilter, setPriorityFilter } = usePriorityFilter({
    priorities,
  });

  const { calendarIntervalStartDate, calendarIntervalEndDate, calendarCheck } =
    useCalendarControls();

  const { showCompleted } = useCompletedFilter();

  const clearFilters = useCallback(() => {
    setSearchParams((searchParams) => {
      searchParams.delete('status');
      searchParams.delete('theme');
      searchParams.delete('user');
      searchParams.delete('startDate');
      searchParams.delete('endDate');
      searchParams.delete('priority');
      return searchParams;
    });
  }, [setSearchParams]);

  const filters = useMemo<StrategyObjectivesProviderContextValue['filters']>(
    () => ({
      priorityFilter,
      setPriorityFilter,
      startDateFilter,
      setStartDateFilter,
      endDateFilter,
      setEndDateFilter,
      setThemeFilter,
      themeFilter,
      statusIndicatorFilter: statusFilter,
      setStatusIndicatorFilter: setStatusFilter,
      clearFilters,
      userFilter,
      setUserFilter,
    }),
    [
      priorityFilter,
      setPriorityFilter,
      startDateFilter,
      setStartDateFilter,
      endDateFilter,
      setEndDateFilter,
      setThemeFilter,
      themeFilter,
      statusFilter,
      setStatusFilter,
      clearFilters,
      userFilter,
      setUserFilter,
    ],
  );

  const topLevelObjectives = useMemo(
    () =>
      (teamAdapter.isOrg
        ? orgData?.activeOrg.objectives
        : orgUnitData?.orgUnit.objectives
      )?.edges.map((n) => n.node) || [],
    [teamAdapter.isOrg, orgData, orgUnitData],
  );

  const contextValue = useMemo<
    StrategyObjectivesProviderContextValue | undefined
  >(() => {
    if (!topLevelObjectives || isPrioritiesLoading) return;

    const allObjectives = uniqBy(
      [
        ...topLevelObjectives,
        ...topLevelObjectives.flatMap(
          (objective) => objective.childObjectiveTree,
        ),
      ],
      (objective) => objective.id,
    ).map((objective) => ({ ...objective, isFiltersMatch: true }));

    const users = getObjectivesOwners(allObjectives);
    const themes = getObjectivesThemes(allObjectives) || [];

    const objectivesWithFilterFlag = filterObjectivesByDates(
      filterObjectivesByDates(
        filterObjectivesByUser(
          filterObjectivesByStatusIndicator(
            filterObjectivesByPriority(
              filterObjectivesByTheme(allObjectives, filters.themeFilter),
              filters.priorityFilter,
            ),
            [
              ...filters.statusIndicatorFilter,
              showCompleted && ('COMPLETED' as StatusMultiSelectItemId),
            ].filter(Boolean),
          ),
          filters.userFilter,
        ),
        filters.startDateFilter,
        filters.endDateFilter,
      ),
      calendarIntervalStartDate,
      calendarIntervalEndDate,
      calendarCheck,
    );

    const matchedObjectivesIds = objectivesWithFilterFlag
      .filter((objective) => objective.isFiltersMatch)
      .map((objective) => objective.id);

    const filteredObjectives = objectivesWithFilterFlag.filter(
      (objective) =>
        objective.isFiltersMatch ||
        objective.childObjectiveTree.some((childObjective) =>
          matchedObjectivesIds.includes(childObjective.id),
        ),
    );

    const allObjectiveIds = allObjectives.map((objective) => objective.id);
    const filteredTopLevelObjectives = filteredObjectives.filter(
      (objective) =>
        !objective.parentObjective ||
        !allObjectiveIds.includes(objective.parentObjective.id),
    );

    const objectivesByThemes = groupObjectivesByTheme<
      StrategyObjectivesObjective,
      StrategyObjectivesThemeFragment
    >(filteredTopLevelObjectives).reduce<StrategyObjectivesByTheme[]>(
      (result, objectivesByTheme) => [
        ...result,
        {
          ...objectivesByTheme,
          childObjectives: objectivesByTheme.objectives.flatMap((objective) =>
            objective.childObjectiveTree
              .map((childObjective) =>
                filteredObjectives.find(
                  (objective) => objective.id === childObjective.id,
                ),
              )
              .filter(Boolean),
          ),
        },
      ],
      [],
    );

    const sortedObjectivesByThemes = sortObjectivesByTheme(
      objectivesByThemes,
      orgUnitData
        ? [
            orgUnitData.orgUnit,
            ...[...orgUnitData.orgUnit.parentOrgUnitTree].reverse(),
          ]
        : [],
    );

    const activeObjectives = allObjectives.filter(
      (objective) => objective.timeLine.completeDateTime == null,
    );
    const activeThemes = getObjectivesThemes(activeObjectives) || [];
    const completedObjectives = allObjectives.filter(
      (objective) => objective.timeLine.completeDateTime !== null,
    );
    const filteredCompletedObjectives = filterObjectivesByStatusIndicator(
      completedObjectives,
      filters.statusIndicatorFilter,
    );

    return {
      users,
      activeThemesCount: activeThemes.length,
      themes,
      objectives: filteredObjectives,
      objectivesByThemes: sortedObjectivesByThemes,
      activeObjectives,
      completedObjectives: filteredCompletedObjectives,
      filters,
    };
  }, [
    calendarCheck,
    calendarIntervalEndDate,
    calendarIntervalStartDate,
    filters,
    isPrioritiesLoading,
    orgUnitData,
    showCompleted,
    topLevelObjectives,
  ]);

  if (!contextValue) return <Spinner.Circle />;

  return (
    <StrategyObjectivesProviderContext.Provider value={contextValue}>
      {children}
    </StrategyObjectivesProviderContext.Provider>
  );
};

export default StrategyObjectivesProvider;
