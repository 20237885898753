import { ApolloProvider } from '@apollo/client';
import { useMemo, type PropsWithChildren } from 'react';

import { createApolloClient } from 'shared/config/createApolloClient';
import { useAuth } from 'auth/AuthProvider';
import { useActingOrgKey } from 'org/ActingOrgKeyProvider/useActingOrgKey';

const GraphqlProvider = ({ children }: PropsWithChildren) => {
  const { user } = useAuth();
  const { actingOrgKey } = useActingOrgKey();

  const client = useMemo(
    () =>
      createApolloClient({
        firebaseUser: user || undefined,
        actingOrgKey,
      }),
    [actingOrgKey, user],
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphqlProvider;
