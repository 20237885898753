import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { css } from '@emotion/react';

import Dialog from 'shared/components/Dialog';
import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';
import { useToasts } from 'shared/toast/useToasts';

import type { LinkMetricDialogMetricFragment } from './LinkMetricDialog.graphql.generated';
import {
  useLinkMetricDialogQuery,
  useLinkMetricToObjectiveMutation,
} from './LinkMetricDialog.graphql.generated';
import MetricSelection from './MetricSelection';
import type { LinkMetricObjectiveParam } from '../LinkMetricButton.type';

type Props = {
  isOpen: boolean;
  objective: LinkMetricObjectiveParam;
  onClose: () => void;
};

const LinkMetricDialog = ({ objective, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [selectedMetric, setSelectedMetric] =
    useState<LinkMetricDialogMetricFragment>();

  const onError = useHandleError();

  const { data } = useLinkMetricDialogQuery({
    skip: !isOpen,
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { objectiveId: objective.id },
  });

  const [linkMetric, { loading }] = useLinkMetricToObjectiveMutation({
    onError,
  });

  const handleClose = useCallback(() => {
    setSelectedMetric(undefined);
    onClose();
  }, [onClose]);

  const handleConfirm = useCallback(async () => {
    if (!selectedMetric) return;

    await linkMetric({
      variables: { objectiveId: objective.id, metricId: selectedMetric.id },
    });

    addToast({
      variant: 'success',
      children: t('metric.linkMetric.dialog.toast.success'),
    });

    handleClose();
  }, [addToast, handleClose, linkMetric, objective.id, selectedMetric, t]);

  const objectives = data?.objective.childObjectives
    .map((childObjective) => ({
      ...childObjective,
      metrics: childObjective.metrics.filter(
        (metric) =>
          !objective.metrics.map((metric) => metric.id).includes(metric.id),
      ),
    }))
    .filter((objective) => objective.metrics.length > 0);

  const orgUnits = [...(data?.activeOrg.orgUnits || [])];

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      headerTitle={t('metric.linkMetric.dialog.header')}
      headerSubtitle={t('metric.linkMetric.dialog.description')}
      confirmLabel={t('confirm')}
      isConfirmDisabled={!selectedMetric || loading}
      cancelLabel={t('cancel')}
      onConfirm={handleConfirm}
      animateOpen={false}
      animateClose={false}
    >
      {objectives ? (
        <MetricSelection
          objectives={objectives}
          orgUnits={orgUnits}
          selectedMetric={selectedMetric}
          setSelectedMetric={setSelectedMetric}
        />
      ) : (
        <div css={css({ marginTop: '5rem' })}>
          <Spinner.Circle />
        </div>
      )}
    </Dialog>
  );
};

export default LinkMetricDialog;
