import { useMemo } from 'react';

import type { SelectProps } from 'shared/components/Select/Select';
import Select from 'shared/components/Select/Select';
import { useUserTenants } from 'user/UserTenantsProvider/useUserTenants';

import type { SelectUserTenantInputOption } from './SelectUserTenantInput.type';
import SelectUserTenantInputRow from './SelectUserTenantInputRow';

export type SelectUserTenantInputProps = Pick<
  SelectProps<SelectUserTenantInputOption>,
  'value' | 'onChange'
> & {
  getInitialValue?: (
    options: SelectUserTenantInputOption[],
  ) => Maybe<SelectUserTenantInputOption>;
};

const SelectUserTenantInput = ({
  value,
  onChange,
  getInitialValue,
}: SelectUserTenantInputProps) => {
  const { tenants } = useUserTenants();

  const options = useMemo(
    () => tenants.map((tenant) => ({ id: tenant.id, value: tenant })),
    [tenants],
  );

  const initialValue = useMemo(
    () => getInitialValue?.(options),
    [options, getInitialValue],
  );

  if (options.length === 1) return null;

  return (
    <Select<SelectUserTenantInputOption>
      items={options}
      value={value || initialValue}
      onChange={onChange}
      ListBoxRow={SelectUserTenantInputRow}
    />
  );
};

export default SelectUserTenantInput;
