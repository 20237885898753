/* eslint-disable i18next/no-literal-string */
import type { Merge } from 'type-fest';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import type { RadioCardProps } from 'shared/components/RadioCard';
import RadioCard from 'shared/components/RadioCard';
import type { StatusIndicator } from 'shared/status/StatusIndicator';
import StatusMark from 'shared/status/StatusMark';
import Text from 'shared/components/Text';
import Space from 'shared/components/Space';

import styles from './StatusRadioCard.module.scss';

type StatusRadioCardProps = Merge<
  Omit<RadioCardProps, 'children'>,
  {
    statusIndicator?: StatusIndicator;
  }
>;

const StatusRadioCard = ({
  statusIndicator,
  className,
  ...restProps
}: StatusRadioCardProps) => {
  const { t } = useTranslation();

  return (
    <RadioCard
      value={statusIndicator?.value}
      {...restProps}
      className={cn(styles.container, className)}
    >
      <Space className={styles.content}>
        <StatusMark statusIndicator={statusIndicator} />
        <Text variant={'strong'}>{statusIndicator?.getColorName(t)}</Text>
        <Text variant={'emphasis'}>{statusIndicator?.getName(t)}</Text>
      </Space>
    </RadioCard>
  );
};

export default StatusRadioCard;
