import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import StrategyOperationalItemRow from 'strategy/StrategyOperationalItemRow';
import type { Contribution } from 'contribution/ContributionTeamList/ContributionTeamList';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import MetricContextMenu from 'metric/MetricContextMenu/MetricContextMenu';
import InitiativeContextMenu from 'initiative/InitiativeContextMenu/InitiativeContextMenu';
import ObjectiveContextMenu from 'objective/ObjectiveContextMenu';
import ActionContextMenu from 'actions/ActionContextMenu/ActionContextMenu';

import {
  getStatusIndicator,
  getSubTitleItem,
  getTitleItem,
} from './ContributionSectionListRow.utils';

export type Props = {
  showSubtitle: boolean;
  strategyElement: Contribution;
};

const ContributionSectionListRow = ({
  strategyElement,
  showSubtitle,
}: Props) => {
  const { activeOrg } = useActiveOrg();
  const { t } = useTranslation();

  const statusIndicator = useMemo(
    () => getStatusIndicator(strategyElement),
    [strategyElement],
  );

  const titleItem = useMemo(
    () => getTitleItem(activeOrg, strategyElement),
    [activeOrg, strategyElement],
  );

  const subTitleItem = useMemo(
    () =>
      showSubtitle ? getSubTitleItem(activeOrg, strategyElement) : undefined,
    [activeOrg, showSubtitle, strategyElement],
  );

  const contextMenuTriggerStyle = css`
    width: 18px;
    height: 18px;
    padding: 0;
  `;

  const contextMenu =
    strategyElement.__typename === 'Objective' ? (
      <ObjectiveContextMenu
        objective={strategyElement}
        triggerStyle={contextMenuTriggerStyle}
      />
    ) : strategyElement.__typename === 'Metric' ? (
      <MetricContextMenu
        metric={strategyElement}
        triggerStyle={contextMenuTriggerStyle}
      />
    ) : strategyElement.__typename === 'Initiative' ? (
      <InitiativeContextMenu
        initiative={strategyElement}
        triggerStyle={contextMenuTriggerStyle}
      />
    ) : strategyElement.__typename === 'Action' ? (
      <ActionContextMenu
        action={strategyElement}
        triggerStyle={contextMenuTriggerStyle}
      />
    ) : undefined;

  return (
    <StrategyOperationalItemRow
      titleItem={titleItem}
      subTitleItem={subTitleItem}
      user={strategyElement.owner}
      typename={strategyElement.__typename}
      date={strategyElement.timeLine.endDate}
      dateTooltip={t('timeline.endDate')}
      statusIndicator={statusIndicator}
      contextMenu={contextMenu}
      expandable={false}
      isLink={false}
    />
  );
};

export default ContributionSectionListRow;
