import type { TeamImageSize } from 'team/TeamImage/TeamImage';
import TeamImage from 'team/TeamImage/TeamImage';
import TeamLink from 'team/TeamLink';
import TeamTooltip from 'team/TeamTooltip';
import type { OrgUnit } from 'types.graphql.generated';
import type { OrgUnitWithAuthorizedActions } from 'user/ability/canPerformOrgUnitAction';

type Props = {
  className?: string;
  hasLink?: boolean;
  hasTooltip?: boolean;
  orgUnit?: Maybe<
    Pick<OrgUnit, 'id' | 'name' | 'nameAbbreviated' | 'backgroundColorAsHex'> &
      OrgUnitWithAuthorizedActions
  >;
  size?: TeamImageSize;
};

const TeamAvatar = ({
  orgUnit,
  hasLink = true,
  hasTooltip = true,
  size,
  className,
}: Props) => {
  if (hasTooltip && hasLink) {
    return (
      <TeamTooltip orgUnit={orgUnit}>
        <TeamLink orgUnit={orgUnit}>
          <TeamImage orgUnit={orgUnit} size={size} className={className} />
        </TeamLink>
      </TeamTooltip>
    );
  }

  if (hasTooltip) {
    return (
      <TeamTooltip orgUnit={orgUnit}>
        <TeamImage orgUnit={orgUnit} size={size} className={className} />
      </TeamTooltip>
    );
  }

  if (hasLink) {
    return (
      <TeamLink orgUnit={orgUnit}>
        <TeamImage orgUnit={orgUnit} size={size} className={className} />
      </TeamLink>
    );
  }

  return <TeamImage orgUnit={orgUnit} size={size} className={className} />;
};

export default TeamAvatar;
