import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import 'shared/config/firebase.config';
import 'shared/chart/chart.config';
import 'shared/styles/index.scss';

import { theme } from 'theme';
import I18nextProvider from 'shared/providers/I18nextProvider';
import ToastProvider from 'shared/toast/ToastProvider';
import { GlobalSpinnerProvider } from 'shared/spinner/GlobalSpinner';
import { FormRefsProvider } from 'shared/form/Form';
import AuthProvider from 'auth/AuthProvider';
import './polyfills';
import useSupressResizeObservableErrors from 'shared/hooks/useSupressResizeObservableErrors';
import UserValidationProvider from 'user/UserValidationProvider';
import GraphqlProvider from 'shared/graphql/GraphqlProvider';
import ActingOrgKeyProvider from 'org/ActingOrgKeyProvider/ActingOrgKeyProvider';
import RedirectProvider from 'shared/providers/RedirectProvider';
import YupTranslationHandler from 'shared/providers/YupTranslationHandler';

import { routes } from './App.routing';

const router = createBrowserRouter(routes);

const App = () => {
  useSupressResizeObservableErrors();

  return (
    <GlobalSpinnerProvider>
      <ActingOrgKeyProvider>
        <AuthProvider>
          <UserValidationProvider>
            <I18nextProvider>
              <YupTranslationHandler>
                <ThemeProvider theme={theme}>
                  <ToastProvider>
                    <GraphqlProvider>
                      <FormRefsProvider>
                        <RedirectProvider>
                          <RouterProvider router={router} />
                        </RedirectProvider>
                      </FormRefsProvider>
                    </GraphqlProvider>
                  </ToastProvider>
                </ThemeProvider>
              </YupTranslationHandler>
            </I18nextProvider>
          </UserValidationProvider>
        </AuthProvider>
      </ActingOrgKeyProvider>
    </GlobalSpinnerProvider>
  );
};

export default App;
