import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ObjectiveStatusWizard from 'objective/ObjectiveStatusWizard';
import type { Objective, ObjectiveStatusInput } from 'types.graphql.generated';
import useHandleError from 'shared/errors/useHandleError';
import { ContributionMyContributionDocument } from 'contribution/ContributionMyContribution/ContributionMyContribution.graphql.generated';

import { useAddObjectiveStatusMutation } from './AddObjectiveStatus.graphql.generated';

export type ObjectiveStatusDialogObjective = Pick<
  Objective,
  'id' | 'name' | 'description'
>;

type Props = {
  completeStatus?: boolean;
  isOpen: boolean;
  objective: ObjectiveStatusDialogObjective;
  onClose: () => void;
};

const CreateObjectiveStatusDialog = ({
  objective,
  completeStatus,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const onError = useHandleError();

  const [addObjectiveStatus] = useAddObjectiveStatusMutation({
    onCompleted: onClose,
    onError,
    refetchQueries: [ContributionMyContributionDocument],
  });

  const onSubmit = useCallback(
    (input: ObjectiveStatusInput) =>
      addObjectiveStatus({ variables: { input } }),
    [addObjectiveStatus],
  );

  return (
    <ObjectiveStatusWizard
      headerTitle={t('objective.createObjectiveStatus.heading')}
      buttonLabel={t('submit')}
      objective={objective}
      completeStatus={completeStatus}
      onSubmit={onSubmit}
      onClose={onClose}
      isOpen={isOpen}
    />
  );
};

export default CreateObjectiveStatusDialog;
