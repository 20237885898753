import { createRoutePath } from 'shared/utils/router';

export const authRoutesPaths = {
  signUp: createRoutePath('/sign-up'),
  signUpWithError: createRoutePath('/sign-up', {
    query: { errorMessage: 'string' },
  }),
  signUpWithPassword: createRoutePath('/sign-up/password'),
  signUpWithNewUser: createRoutePath('/sign-up/new-user'),
  finishSignUp: createRoutePath('/finish-sign-up'),
  finishSignUpWithoutEmail: createRoutePath('/finish-sign-up/email'),
};
