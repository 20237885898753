import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { css } from '@emotion/react';

import type { FormProps, OnSubmitFn } from 'shared/form/Form';
import Form from 'shared/form/Form';
import FieldBox from 'shared/form/FieldBox';
import { TextInputField } from 'shared/components/TextInput';
import useHandleError from 'shared/errors/useHandleError';

import { createInitialValues } from './SignUpWithEmailForm.utils';
import type { SignUpWithEmailFormValues } from './SignUpWithEmailForm.type';
import { createValidationSchema } from './SignUpWithEmailForm.schema';

export const signUpWithEmailFormPromiseTrackerArea =
  'signUpWithEmailFormPromiseTrackerArea';

export type SignUpWithEmailFormProps = Pick<
  FormProps<SignUpWithEmailFormValues>,
  'id' | 'className'
> & {
  onSubmit: (email: string) => Promise<void>;
  onSuccess: (email: string) => void;
};

const SignUpWithEmailForm = ({
  onSubmit,
  onSuccess,
  id,
  className,
}: SignUpWithEmailFormProps) => {
  const { t } = useTranslation();

  const initialValues = useMemo(() => createInitialValues(), []);

  const validationSchema = useMemo(() => createValidationSchema({ t }), [t]);

  const onError = useHandleError();

  const handleSubmit = useCallback<OnSubmitFn<SignUpWithEmailFormValues>>(
    async (values) => {
      try {
        await trackPromise(
          onSubmit(values.email),
          signUpWithEmailFormPromiseTrackerArea,
        );
        onSuccess(values.email);
      } catch (error: any) {
        onError(error);
      }
    },
    [onError, onSubmit, onSuccess],
  );

  return (
    <Form<SignUpWithEmailFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
      id={id}
      className={className}
    >
      {({ hasError }) => (
        <FieldBox
          name={'email'}
          label={t('signUpForm.email.label')}
          hasError={hasError('email')}
          css={css`
            margin-top: 2.5rem;
          `}
        >
          <Field
            name={'email'}
            component={TextInputField}
            placeholder={t('signUpForm.email.placeholder')}
          />
        </FieldBox>
      )}
    </Form>
  );
};

export default SignUpWithEmailForm;
