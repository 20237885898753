import { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import type {
  CampaignQuestionType,
  LikertScaleRating,
} from 'types.graphql.generated';
import { useTeamAdapter } from 'team/TeamAdapter';
import useHandleError from 'shared/errors/useHandleError';

import AnswerPickerOption from './AnswerPickerOption';
import { useSaveCampaignUserResponseMutation } from './SaveCampaignResponse.graphql.generated';
import { useChat } from './ChatProvider';
import { useCampaignResponsesQuery } from './CampaignResponses.graphql.generated';
import { useQuestions } from './QuestionsProvider.context';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
`;

type Props = {
  campaignId: string;
  questionText: string;
  questionType: CampaignQuestionType;
};

const AnswerPicker = ({ campaignId, questionType, questionText }: Props) => {
  const { teamAdapter } = useTeamAdapter();
  const [selectedAnswer, setSelectedAnswer] = useState<LikertScaleRating>();

  const handleApolloError = useHandleError();

  const { data } = useCampaignResponsesQuery({
    variables: { campaignId },
    onError: handleApolloError,
  });

  const [saveResponse] = useSaveCampaignUserResponseMutation({
    onError: handleApolloError,
  });

  const { isReadOnly } = useQuestions();

  const { continueChat } = useChat();

  useEffect(() => {
    if (!isReadOnly) return;

    const answer = data?.campaign.currentUserResponse?.answers.find(
      (answer) => answer.question.questionType === questionType,
    )?.rating;

    setSelectedAnswer(answer);
  }, [data?.campaign.currentUserResponse?.answers, isReadOnly, questionType]);

  const ratings = [
    'STRONGLY_AGREE',
    'AGREE',
    'NEUTRAL',
    'DISAGREE',
    'STRONGLY_DISAGREE',
  ] satisfies LikertScaleRating[];

  const handleSelect = (rating: LikertScaleRating) => {
    setSelectedAnswer(rating);
    saveResponse({
      variables: {
        input: {
          campaignId,
          orgKey: teamAdapter.orgKey,
          answers: [
            {
              questionType,
              questionText,
              rating,
            },
          ],
        },
      },
    });
    continueChat();
  };

  return (
    <Container>
      {ratings.map((rating) => (
        <AnswerPickerOption
          key={rating}
          rating={rating}
          isSelected={rating === selectedAnswer}
          onSelect={() => handleSelect(rating)}
        />
      ))}
    </Container>
  );
};

export default AnswerPicker;
