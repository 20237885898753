import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import GlobalSpinner from 'shared/spinner/GlobalSpinner';
import useHandleError from 'shared/errors/useHandleError';
import TriggerErrorPage from 'shared/errors/ErrorPage/TriggerErrorPage';

import type { UserProviderContextValue } from './UserProvider.context';
import { UserProviderContext } from './UserProvider.context';
import { useCurrentUserQuery } from './UserProvider.graphql.generated';

type UserProviderProps = PropsWithChildren<object>;

const UserProvider = ({ children }: UserProviderProps) => {
  const onApolloError = useHandleError();

  const { data, error } = useCurrentUserQuery({ onError: onApolloError });

  const contextValue = useMemo<UserProviderContextValue | undefined>(
    () =>
      data
        ? {
            user: data.currentUser,
            userOrgUnits: data.currentUser.accessControl.orgUnitAccessList.map(
              (orgUnitAccess) => orgUnitAccess.orgUnit,
            ),
          }
        : undefined,
    [data],
  );

  if (error) return <TriggerErrorPage error={error} />;

  if (!contextValue) return <GlobalSpinner />;

  return (
    <UserProviderContext.Provider value={contextValue}>
      {children}
    </UserProviderContext.Provider>
  );
};

export default UserProvider;
