import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import type { Theme } from 'types.graphql.generated';
import type { ContextMenuItem } from 'shared/components/ContextMenu';
import ContextMenu from 'shared/components/ContextMenu';
import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import { ReactComponent as PauseIcon } from 'shared/static/icons/icon-pause.svg';
import { ReactComponent as CheckIcon } from 'shared/static/icons/icon-check.svg';
import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import { useToasts } from 'shared/toast/useToasts';
import EditThemeModal from 'themes/EditThemeModal';
import useHandleError from 'shared/errors/useHandleError';

import { useStrategyRoomUpdateThemeMutation } from './StrategyRoomUpdateTheme.graphql.generated';
import { useStrategyRoomDeleteThemeMutation } from './StrategyRoomDeleteTheme.graphql.generated';
import { StrategyRoomThemesManageThemesDocument } from './StrategyRoomThemesManageThemes.graphql.generated';

type StrategyRoomThemesListRowContextMenuProps = {
  isActive: boolean;
  isEmpty: boolean;
  themeId: Theme['id'];
};

type MenuItemId = 'edit' | 'archive' | 'activate' | 'delete';

type MenuItem = ContextMenuItem<MenuItemId>;

const StrategyRoomThemesListRowContextMenu = ({
  themeId,
  isActive,
  isEmpty,
}: StrategyRoomThemesListRowContextMenuProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const handleApolloError = useHandleError();

  const [updateTheme] = useStrategyRoomUpdateThemeMutation({
    onError: handleApolloError,
  });

  const [deleteTheme] = useStrategyRoomDeleteThemeMutation({
    onError: handleApolloError,
    refetchQueries: [StrategyRoomThemesManageThemesDocument],
  });

  const [isEditOpen, setIsEditOpen] = useState(false);

  const items = useMemo<MenuItem[]>(
    () => [
      {
        id: 'edit',
        icon: EditIcon,
        title: t('strategy.strategyRoom.themes.context.edit'),
      },
      isActive
        ? {
            id: 'archive',
            icon: PauseIcon,
            title: t('strategy.strategyRoom.themes.context.archive'),
          }
        : {
            id: 'activate',
            icon: CheckIcon,
            title: t('strategy.strategyRoom.themes.context.unarchive'),
          },
      {
        id: 'delete',
        icon: TrashIcon,
        title: t('strategy.strategyRoom.themes.context.remove'),
        variant: 'danger',
      },
    ],
    [isActive, t],
  );

  const onAction = async (id: string) => {
    if (id === 'edit') {
      setIsEditOpen(true);
    } else if (id === 'archive') {
      await updateTheme({
        variables: { input: { idToUpdate: themeId, isActive: false } },
      });
      addToast({
        children: t('theme.toast.archive.success'),
        variant: 'success',
      });
    } else if (id === 'activate') {
      await updateTheme({
        variables: { input: { idToUpdate: themeId, isActive: true } },
      });
      addToast({
        children: t('theme.toast.activate.success'),
        variant: 'success',
      });
    } else if (id === 'delete') {
      const result = await deleteTheme({ variables: { themeId } });
      if (result.data?.deleteThemes) {
        addToast({
          children: t('theme.toast.delete.success'),
          variant: 'success',
        });
      }
    }
  };

  const disabledKeys = useMemo(() => (isEmpty ? [] : ['delete']), [isEmpty]);

  return (
    <>
      <ContextMenu
        items={items}
        onAction={onAction}
        disabledKeys={disabledKeys}
        triggerStyle={css(`
          height: 32px;
          padding: 0;
          width: 32px;
        `)}
      />
      {isEditOpen && (
        <EditThemeModal
          themeId={themeId}
          isOpen={true}
          onClose={() => setIsEditOpen(false)}
        />
      )}
    </>
  );
};

export default StrategyRoomThemesListRowContextMenu;
