import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import Button from 'shared/components/Button';
import Dialog from 'shared/components/Dialog';
import CampaignFormInfoBox from 'toolkit/CampaignFormInfoBox/CampaignFormInfoBox';
import OneThingCampaignForm from 'toolkit/OneThingCampaignForm';
import { campaignFormId } from 'toolkit/OneThingCampaignForm/OneThingCampaignForm';
import { canWriteOrgStrategyOrWriteAnyOrgUnit } from 'user/ability/canPerformOrgUnitAction';
import { useUser } from 'user/UserProvider';

const OneThingCreateCampaignButton = () => {
  const { t } = useTranslation();

  const { activeOrg } = useActiveOrg();
  const { userOrgUnits } = useUser();

  const [isOpen, setIsOpen] = useState(false);

  const initialValues = { name: '', orgUnit: null };

  const onClose = () => setIsOpen(false);

  const canCreateCampaign = canWriteOrgStrategyOrWriteAnyOrgUnit(
    activeOrg,
    userOrgUnits,
  );

  if (!canCreateCampaign) return null;

  return (
    <>
      <Button onPress={() => setIsOpen(true)}>
        {t('toolkit.toolPage.oneThing.campaigns.newButton')}
      </Button>

      <Dialog
        headerTitle={t('toolkit.toolPage.oneThing.campaigns.create.title')}
        isOpen={isOpen}
        onClose={onClose}
        confirmLabel={t('create')}
        cancelLabel={t('cancel')}
        formId={campaignFormId}
      >
        <OneThingCampaignForm
          initialValues={initialValues}
          onSuccess={onClose}
        />
        <CampaignFormInfoBox />
      </Dialog>
    </>
  );
};

export default OneThingCreateCampaignButton;
