import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import Button from 'shared/components/Button';
import { ReactComponent as LinkIcon } from 'shared/static/icons/icon-link.svg';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import useDialogState from 'shared/hooks/useDialogState';

import LinkMetricDialog from './LinkMetricDialog';
import type { LinkMetricObjectiveParam } from './LinkMetricButton.type';

type Props = {
  objective: LinkMetricObjectiveParam;
};

const LinkMetricButton = ({ objective }: Props) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDialogState();

  const isAllowed = canPerformStrategyElementAction(objective, 'WRITE');

  if (!isAllowed) return null;

  return (
    <>
      <Button
        onPress={onOpen}
        variant={'outlined'}
        icon={LinkIcon}
        iconPosition={'start'}
      >
        {t('metric.linkMetric.button')}
      </Button>

      <div css={css({ display: 'none' })}>
        <LinkMetricDialog
          isOpen={isOpen}
          onClose={onClose}
          objective={objective}
        />
      </div>
    </>
  );
};

export default LinkMetricButton;
